import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoansService } from '../../services/api/loans.service';
import { LanguageService } from 'src/app/services/language-service/language.service';

@Component({
  selector: 'app-credit-bureau-print',
  templateUrl: './credit-bureau-print.component.html',
  styleUrls: ['./credit-bureau-print.component.scss']
})
export class CreditBureauPrintComponent implements OnInit {

  constructor(private router : Router, private loansService: LoansService, private languageService: LanguageService) { 
    const fromQueryParams = window.location.search.match('lang=([^&]+)');
    const defLang = fromQueryParams ? fromQueryParams[1] : 'en';
    this.languageService.setLanguagePreference(defLang);
  }
  public creditBureauReportText: string;
  public datePulled: string;
  public creditBureauNameDd: string;
  public applicationId: string;
  public clientName: string;

  public creditBureaus: any;
  public isFirst: boolean;

  ngOnInit() {
    this.isFirst = true;
    if (this.router.url != "/") {
      this.loansService.getCreditBureauReportText(this.router.url.split('/creditBureauPrint?id=')[1].split('_')[0]).subscribe(data => {
        this.creditBureaus = data;
      })
      this.applicationId = this.router.url.split('/creditBureauPrint?id=')[1].split('_')[1];
      this.clientName = this.router.url.split('/creditBureauPrint?id=')[1].split('_')[2].replace(/[^a-zA-Z]/g, '');  
    }
  }

  public getCreditBureaus() {
    if (this.creditBureaus) {
      return this.creditBureaus;
    }
  }

  public getApplicationId() {
    return this.applicationId;
  }

  public getCreditBureauReportText(creditBureau) {
    if (creditBureau != null && creditBureau != undefined) {
      this.creditBureauReportText = creditBureau.creditReport.creditReportText;
      var re = /MONTHLY PAYMENTS/gi;
      this.creditBureauReportText = this.creditBureauReportText.replace(re,"\nMONTHLY PAYMENTS");
      re = /TOTAL \nMONTHLY PAYMENTS/gi;
      this.creditBureauReportText = this.creditBureauReportText.replace(re,"TOTAL MONTHLY PAYMENTS");

      this.isFirst = false;
      return this.creditBureauReportText;
    }
  }

  public getDatePulled(creditBureau) {
    if (creditBureau != null && creditBureau != undefined) {
      var date = new Date(creditBureau.datePulled);
      if (date.toString() != "Invalid Date") {
        return date.toLocaleDateString().replace('/', '-').replace('/', '-') + " " + date.toLocaleTimeString();
      }
    }
  }

  public getCreditBureauNameDd(creditBureau) {
    if(creditBureau != null && creditBureau != undefined){
      if (creditBureau.creditBureauNameDd[0].text == "Equifax") {
        return "Equifax Canada Inc.";
      }else {
        return "TransUnion";
      }
    }
  }

}
