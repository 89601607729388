import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import { ApplicationList } from '../../models/application-list.model';
import { DTOLoanApplication } from '../../models/DTOLoanApplication';
import { Settings } from '../../components/common/constants/settings';
import { AuthService } from '../security/auth.service';
import { AuthGuardService } from '../security/auth-guard.service';
import { AppConfigService } from '../config-service/app-config.service';
import { LoanDecision } from '../../models/loan-decision.model';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../language-service/language.service';

@Injectable({
  providedIn: 'root'
})

export class LoansService {

    private updateLoanUrl = AppConfigService.settings.apiEndpoint_microserviceLoanApp + '/api/loan-apps';
    private getLoanByIdUrl = AppConfigService.settings.apiEndpoint_microserviceLoanApp + '/api/loan-apps/loan';
    private getLeadsUrl = AppConfigService.settings.apiEndpoint_microserviceLoanApp + '/api/loan-apps/leads';
    private getMyDealsUrl = AppConfigService.settings.apiEndpoint_microserviceLoanApp+ '/api/loan-apps/myDeals';
    private createLeadsSearchUrl = AppConfigService.settings.apiEndpoint_microserviceLoanApp + '/api/loan-apps/leads/search';
    private createMyDealsSearchUrl = AppConfigService.settings.apiEndpoint_microserviceLoanApp + '/api/loan-apps/myDeals/search';
    private loanDecisionUrl = AppConfigService.settings.apiEndpoint_microserviceLoanDecision + '/api/loan-decision';
    private uploadUri = AppConfigService.settings.apiEndpoint_microserviceLoanDecision + '/api/loan-decision/letterOfCommitmentUploadUri';
    private getCreditBureauReportTextUrl = AppConfigService.settings.apiEndpoint_microserviceLoanApp + '/api/loan-apps/credit-bureau';
    private getApplicationSummaryUrl = AppConfigService.settings.apiEndpoint_microserviceLoanApp + '/api/loan-apps/application-details';

    constructor(private languageService: LanguageService, private cookieService: CookieService, private http: HttpClient, private authService: AuthService, private authGuardService: AuthGuardService) { }

    getLoanById(id: string): Observable<any>{
        return this.http.get(this.getLoanByIdUrl,{
            params: new HttpParams()
                .set('id', id),
            headers: Settings.headers.set('authorizationtoken',this.cookieService.get('IdToken'))
        })
    }

    addLoanDecision(file: File, decisionObject: LoanDecision): Observable<any> {
        const http = this.http;
        const service = this;

        if (file) {
            const subject = new Subject();
            http.get(this.uploadUri, { headers: Settings.headers.set('authorizationtoken', this.cookieService.get('IdToken'))})
                .subscribe((uploadUriResult: { uri: string, fileName: string }) => {
                    http.put(uploadUriResult.uri, file, {headers: {'Content-Type': 'application/pdf'}})
                        .subscribe(() => {
                            decisionObject.setPdfName(uploadUriResult.fileName);
                            http.post(service.loanDecisionUrl, JSON.stringify(decisionObject), {
                                headers: Settings.headers.set('authorizationtoken', service.cookieService.get('IdToken'))
                            }).subscribe(decisionResult => {
                                subject.next(decisionResult);
                            });
                        });
                });
            return subject;
        } else {
            return http.post(service.loanDecisionUrl, JSON.stringify(decisionObject), {
                headers: Settings.headers.set('authorizationtoken', service.cookieService.get('IdToken'))
            })
        }
    }

    updateLoanDecision(file: File, decisionObject: LoanDecision): Observable<any> {
        const http = this.http;
        const service = this;

        if (file) {
            const subject = new Subject();
            http.get(this.uploadUri, {headers: Settings.headers.set('authorizationtoken', this.cookieService.get('IdToken'))})
                .subscribe((uploadUriResult: { uri: string, fileName: string }) => {
                    http.put(uploadUriResult.uri, file, {headers: {'Content-Type': 'application/pdf'}})
                        .subscribe(() => {
                            decisionObject.setPdfName(uploadUriResult.fileName);
                            http.put(service.loanDecisionUrl, JSON.stringify(decisionObject), {
                                headers: Settings.headers.set('authorizationtoken', service.cookieService.get('IdToken'))
                            }).subscribe(decisionResult => {
                                subject.next(decisionResult);
                            });
                        });
                });
            return subject;
        } else {
            return http.put(service.loanDecisionUrl, JSON.stringify(decisionObject), {
                headers: Settings.headers.set('authorizationtoken', service.cookieService.get('IdToken'))
            });
        }
    }

    getLoanDecisionById(loanDecisionId: string): Observable<any> {
        return this.http.get<any>(this.loanDecisionUrl, {
            params: new HttpParams()
                .set('loanDecisionId', loanDecisionId),
            headers: Settings.headers.set('authorizationtoken',this.cookieService.get('IdToken'))
        });
    }

    getPdfUri(pdfName: string): Observable<any> {
        return this.http.get<any>(this.loanDecisionUrl + '/pdf-uri', {
            params: new HttpParams()
                .set('pdfName', pdfName),
            headers: Settings.headers.set('authorizationtoken',this.cookieService.get('IdToken'))
        });
    }

    getVideoUri(): Observable<any> {
        return this.http.get<any>(this.loanDecisionUrl + '/video-uri', {
            headers: Settings.headers.set('authorizationtoken',this.cookieService.get('IdToken'))
        });
    }

    getLeadsWithoutParameters(): Observable<ApplicationList>  {
        return this.http.get<ApplicationList>(this.getLeadsUrl, {
            headers: Settings.headers.set('authorizationtoken',this.cookieService.get('IdToken')).set('Accept-Language', this.languageService.getLanguage())}) ;
            
    }

    getMyDealsWithoutParamaters(): Observable<ApplicationList> {
        return this.http.get<ApplicationList>(this.getMyDealsUrl, { 
            headers: Settings.headers.set('authorizationtoken',this.cookieService.get('IdToken')).set('Accept-Language', this.languageService.getLanguage())});
    }

    getLeads(filterStatus:any, pageNumber: number, nrOfResultsPerPage: number) {
        return this.http.get<ApplicationList>(this.getLeadsUrl, {
            params: new HttpParams()
                .set('statuses', filterStatus ? filterStatus.toString() : null)
                .set('pageNumber', pageNumber || pageNumber === 0 ? pageNumber.toString() : null)
                .set('nrOfResultsPerPage', nrOfResultsPerPage ? nrOfResultsPerPage.toString() : null),
            headers: Settings.headers.set('authorizationtoken', this.cookieService.get('IdToken')).set('Accept-Language', this.languageService.getLanguage())
        });
    }

    getMyDeals(myDealsActiveStatusFilters: any, pageNumber: number, nrOfResultsPerPage: number = 10) {
        return this.http.get<ApplicationList>(this.getMyDealsUrl, {
            params: new HttpParams()
                .set('statuses', myDealsActiveStatusFilters ? myDealsActiveStatusFilters.toString() : null)
                .set('pageNumber', pageNumber || pageNumber === 0 ? pageNumber.toString() : null)
                .set('nrOfResultsPerPage', nrOfResultsPerPage ? nrOfResultsPerPage.toString() : null),
            headers: Settings.headers.set('authorizationtoken', this.cookieService.get('IdToken')).set('Accept-Language', this.languageService.getLanguage())
        });
    }

    updateLoan(loan: DTOLoanApplication): Observable<any> {
        return this.http.put(this.updateLoanUrl, JSON.stringify(loan), {
            headers: Settings.headers.set('authorizationtoken',this.cookieService.get('IdToken'))
        });
    }

    getLeadsByPropertyAddress(searchInput: any, filterStatus: any, pageNumber: number, nrOfResultsPerPage: number) {
        let params = new HttpParams()
            .set('addressInput', searchInput.toString())
            .set('statuses', filterStatus.toString()) 
            .set('pageNumber', pageNumber.toString())
            .set('nrOfResultsPerPage', nrOfResultsPerPage.toString())
        let headers = Settings.headers
            .set('action', "propertyAddress")
            .set('authorizationtoken',this.cookieService.get('IdToken'))
            .set('Accept-Language', this.languageService.getLanguage())
        return this.http.get<ApplicationList>(this.createLeadsSearchUrl, {
            headers, params
        });
    }

    getLoanByApplicationId(searchInput: any, myDealsActiveStatusFilters: any, pageNumber: number, nrOfResultsPerPage: number) {
        let params = new HttpParams()
            .set('applicationId', searchInput.toString())
            .set('statuses', myDealsActiveStatusFilters.toString())
            .set('pageNumber', pageNumber.toString())
            .set('nrOfResultsPerPage', nrOfResultsPerPage.toString())
        let headers = Settings.headers
            .set('action', "applicationId")
            .set('authorizationtoken',this.cookieService.get('IdToken'))
            .set('Accept-Language', this.languageService.getLanguage())
        return this.http.get<ApplicationList>(this.createMyDealsSearchUrl, {
            headers, params
        });
    }

    getLoanByClientName(searchInput: any, myDealsActiveStatusFilters: any, pageNumber: number, nrOfResultsPerPage: number) {
        let params = new HttpParams()
            .set('clientName', searchInput.toString())
            .set('statuses', myDealsActiveStatusFilters.toString())
            .set('pageNumber', pageNumber.toString())
            .set('nrOfResultsPerPage', nrOfResultsPerPage.toString())
        let headers = Settings.headers
            .set('action', "clientName")
            .set('authorizationtoken',this.cookieService.get('IdToken'))
            .set('Accept-Language', this.languageService.getLanguage())
        return this.http.get<ApplicationList>(this.createMyDealsSearchUrl, {
            headers, params
        });
    }

    getLoanBySourceName(searchInput: any, myDealsActiveStatusFilters: any, pageNumber: number, nrOfResultsPerPage: number) {
        let params = new HttpParams()
            .set('sourceName', searchInput.toString())
            .set('statuses', myDealsActiveStatusFilters.toString())
            .set('pageNumber', pageNumber.toString())
            .set('nrOfResultsPerPage', nrOfResultsPerPage.toString())
        let headers = Settings.headers
            .set('action', "sourceName")
            .set('authorizationtoken',this.cookieService.get('IdToken'))
            .set('Accept-Language', this.languageService.getLanguage())
        return this.http.get<ApplicationList>(this.createMyDealsSearchUrl, {
            headers, params
        });
    }

    getLeadsBySourceName(searchInput: any, leadsActiveStatusFilters: any, pageNumber: number, nrOfResultsPerPage: number) {
        let params = new HttpParams()
            .set('sourceName', searchInput.toString())
            .set('statuses', leadsActiveStatusFilters.toString())
            .set('pageNumber', pageNumber.toString())
            .set('nrOfResultsPerPage', nrOfResultsPerPage.toString())
        let headers = Settings.headers
            .set('action', "sourceName")
            .set('authorizationtoken',this.cookieService.get('IdToken'))
            .set('Accept-Language', this.languageService.getLanguage())
        return this.http.get<ApplicationList>(this.createLeadsSearchUrl, {
            headers, params
        });
    }

    getMyDealsByPropertyAddress(searchInput: any, myDealsActiveStatusFilters: any, pageNumber: number, nrOfResultsPerPage: number) {
        let params = new HttpParams()
            .set('addressInput', searchInput.toString())
            .set('statuses', myDealsActiveStatusFilters.toString())
            .set('pageNumber', pageNumber.toString())
            .set('nrOfResultsPerPage', nrOfResultsPerPage.toString())
        let headers = Settings.headers
            .set('action', "propertyAddress")
            .set('authorizationtoken',this.cookieService.get('IdToken'))
            .set('Accept-Language', this.languageService.getLanguage())
        return this.http.get<ApplicationList>(this.createMyDealsSearchUrl, {
            headers, params
        });
    }
    
    getCreditBureauReportText(id: string) {
        let headers = Settings.headers.set('authorizationtoken',this.cookieService.get('IdToken'))
        let params = new HttpParams()
            .set('id', id.toString())
        return this.http.get<any>(this.getCreditBureauReportTextUrl, {headers, params});
    }

    getApplicationSummary(id: number) {
        let headers = Settings.headers.set('authorizationtoken',this.cookieService.get('IdToken'))
        return this.http.get<any>(this.getApplicationSummaryUrl, {
            params: new HttpParams()
                .set('id', id.toString()),
            headers
        });
    }
}
