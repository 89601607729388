<div>
    <div id="pdf">
        <div id="pdfTitle" class="noReportMessage" style="min-height: 84.5px;">
            <br>
            {{getTitleForPdf()}}
        </div>
        <div *ngIf="!isStatusInsights">
            <mat-table *ngIf="isDataAvailable()" class="table" matSort matSortActive="date" matSortDirection="desc"
                [dataSource]="dataSourcePDF">

                <ng-container matColumnDef="date" alt="date">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before">
                        <div class="headerTitle">Date</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div *ngIf="isLenderCodeLenderNameReportDisplayed == true">
                            {{element.creationDate | date: 'MMM dd, yyyy'}}
                            {{element.creationDate | date: ', hh:mm a'}}
                        </div>
                        <div *ngIf="isLenderCodeLenderNameReportDisplayed == false">
                            {{element.date | date: 'MMM dd, yyyy'}}
                            {{element.date | date: ', hh:mm a'}}
                        </div>

                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lenderCode" alt="lender code">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before" style="max-width: 6rem;">
                        <div class="headerTitle">Lender Code</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="max-width: 6rem;">
                        {{element.lenderCode}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lenderName" alt="lender name">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before">
                        <div class="headerTitle">Lender Name</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.lenderName}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="receivedLead" alt="received lead ID">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before">
                        <div class="headerTitle">Received lead</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.receivedLead}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="paidLead" alt="paid lead ID">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before">
                        <div class="headerTitle">Paid Lead</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.paidLeadId}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="approvedDeal" alt="approved deal ID">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before">
                        <div class="headerTitle">Approved deal</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.approvedDeal}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="declinedDeal" alt="declined deal ID">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before">
                        <div class="headerTitle">Declined deal</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.declinedDeal}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="fundedDeal" alt="funded deal ID">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before">
                        <div class="headerTitle">Funded deal</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.fundedDeal}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="loanId" alt="loan id">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before">
                        <div class="headerTitle">Loan ID</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.loanId}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="applicationId" alt="received loan id">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before">
                        <div class="headerTitle">Received Loan</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.applicationId}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="hasCreditBureau" alt="credit bureau">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before" style="max-width: 7rem;">
                        <div class="headerTitle">Credit Bureau</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="max-width: 7rem;">
                        {{element.hasCreditBureau == true? 'Yes':'No'}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="isEfxTu" alt="efx/tu">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before" style="max-width: 5rem;">
                        <div class="headerTitle">EFX/TU</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="max-width: 5rem;">
                        {{element.isEfxTu? element.isEfxTu:'N/A'}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="propertyAddress" alt="property address">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before">
                        <div class="headerTitle">Property Address</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.propertyAddress.property.address.streetNumber + " " +
                        element.propertyAddress.property.address.streetName + ","}}
                        <br>
                        {{element.propertyAddress.property.address.city + ", " +
                        element.propertyAddress.property.address.provinceDd[0].text}}
                        <br>
                        {{element.propertyAddress.property.address.postalFsa + " " +
                        element.propertyAddress.property.address.postalLdu}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="denialReason" alt="denial reason">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before">
                        <div class="headerTitle">Denial Reason</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.denialReason}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="firmName" alt="agent name / brokerage">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before">
                        <div class="headerTitle">Agent Name / Brokerage</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.firmName}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status" alt="status">
                    <mat-header-cell *matHeaderCellDef arrowPosition="before">
                        <div class="headerTitle">Status</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.isActive == true? 'Active':'Inactive'}}
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="getColumnsToDisplay()" class="matHeaderRow"></mat-header-row>
                <mat-row *matRowDef="let row; columns: getColumnsToDisplay();" class="matRowPDF"></mat-row>
            </mat-table>
        </div>

        <div *ngIf="isStatusInsights">

            <div *ngIf="isDataAvailable()"
                style="display: inline-flex;margin-top: 20px;width: 95%;margin-left: 30px;margin-right: 40px;min-height: 45px;">
                <div style="width: 36%;font-weight: bolder;font-size: small;align-self: center; padding-left:2%">
                    Lender Code / Lender Name</div>
                <div style="width: 22%;font-weight: bolder;font-size: small;align-self: center;">Status</div>
                <div style="width: 18%;font-weight: bolder;font-size: small;align-self: center;">Application Id
                </div>
                <div style="font-weight: bolder;font-size: small;align-self: center;">Date</div>
            </div>

            <mat-table *ngIf="isDataAvailableForStatusReportTable()" class="tableStatuses" matSort matSortActive="date"
                matSortDirection="desc" [dataSource]="statusReportDataSourcePDF">

                <ng-container matColumnDef="lenderCode&lenderName" alt="lender code and lender name">
                    <mat-cell *matCellDef="let element" class="lenderCodeAndNameColumn">
                        {{element.lenderCodeAndName}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="loanData" alt="loan data">
                    <mat-cell *matCellDef="let element" style="padding-bottom: 0px !important; padding-right: 0px;">
                        <mat-table class="table" matSort matSortActive="date" matSortDirection="desc"
                            [dataSource]="extractDatasourceForSecondTable(element.loanData)"
                            class="innerTableFirst">

                            <ng-container matColumnDef="status" alt="status" style>
                                <mat-cell *matCellDef="let element1" style="max-width: 25%;">
                                    {{element1.status}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="loanData" alt="status">
                                <mat-cell *matCellDef="let element1">

                                    <mat-table class="tableIn" matSort matSortActive="date" matSortDirection="desc"
                                        [dataSource]="extractDatasourceForThirdTable(element1.loans)"
                                        class="innerTables">

                                        <ng-container matColumnDef="loanId" alt="loan id">
                                            <mat-cell *matCellDef="let element2">
                                                {{element2.applicationId}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="date" alt="date">
                                            <mat-cell *matCellDef="let element2">
                                                {{element2.date | date: 'yyyy-MM-dd'}}
                                                {{element2.date | date: ', hh:mm a'}}
                                            </mat-cell>
                                        </ng-container>

                                        <mat-row *matRowDef="let row; columns: getColumnsToDisplayForThirdTable();"
                                            class="matRowStatusesLoanInfoRow"></mat-row>
                                    </mat-table>

                                </mat-cell>
                            </ng-container>

                            <mat-row *matRowDef="let row; columns: getColumnsToDisplayForSecondTable();"
                                class="matRowStatusesSecondTable"></mat-row>
                        </mat-table>

                    </mat-cell>
                </ng-container>

                <mat-row *matRowDef="let row; columns: getColumnsToDisplay();" class="matRowStatuses"
                    style="padding-bottom: 0px !important;"></mat-row>

            </mat-table>
        </div>

    </div>
</div>