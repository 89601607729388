import {Injectable} from '@angular/core';
import {AppConfigService} from '../services/config-service/app-config.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from './User';
import {CookieService} from 'ngx-cookie-service';
import {Settings} from '../components/common/constants/settings';

@Injectable()
export class UserService {
    private getUsersUrl = AppConfigService.settings.apiEndpoint_userManagement + '/api/users';
    private userUrl = AppConfigService.settings.apiEndpoint_userManagement + '/api/user';

    constructor(private http: HttpClient, private cookieService: CookieService) {
    }

    public getUsers(): Observable<User[]> {
        // @ts-ignore
        return this.http.get(this.getUsersUrl, this.getOptions());
    }

    updateUser(user: User) {
        return this.http.put(this.userUrl, user, this.getOptions());
    }

    addUser(user: User) {
        return this.http.post(this.userUrl, user, this.getOptions());
    }

    deleteUser(user: User) {
        return this.http.delete(`${this.userUrl}?id=${user.id}`, this.getOptions());
    }

    private getOptions() {
        return {
            headers: Settings.headers.set('authorizationtoken', this.cookieService.get('IdToken'))
        };
    }
}
