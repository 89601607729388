import { Component, Injectable, OnInit, ViewEncapsulation } from '@angular/core';
import { ApplicationSummaryComponent } from '../../application-summary/application-summary.component';
import { MatSnackBar } from "@angular/material/snack-bar";
@Injectable({
    providedIn: 'root'
  })
@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SnackbarComponent implements OnInit {
  addExtraClass: boolean = false;
  constructor(private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  openSnackBar(snackBarMessage: string) {
    this.snackBar.open(snackBarMessage, "", {duration: 800});
  }

}
