import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { LanguageService } from 'src/app/services/language-service/language.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public showContainer: boolean;
  public languageService;
  public selectedLanguageEn: boolean = false;
  public selectedLanguageFr: boolean = false;
  constructor(languageService: LanguageService ) {
    this.languageService = languageService;
  }


  ngOnInit() {
   if(this.languageService.getLanguage() == "en"){
   this.selectedLanguageEn = true;
   }
   else {
     if (this.languageService.getLanguage() == "fr"){
     this.selectedLanguageFr = true;
   }
  }
  }



}
