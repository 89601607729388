export class InsurerSettings {
    public static insurer = {
        'en': {
            1: 'CMHC',
            2: 'Sagen',
            3: 'Canada Guaranty'
        },
        'fr': {
            1: 'SCHL',
            2: 'Sagen',
            3: 'Canada Guaranty'
        }
    };
}
