import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';

interface Size {
  value: number;
  viewValue: number;
}

export class Page {
  pageSize: number;
  activePage: number;
  nrOfPages: number;
}

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Input() pageSize: number = 0;
  @Input() activePage: number = 0;
  @Input() nrOfPages: number = 0;
  @Input() pageSizeOptions: Size[];
  @Output() onPageChange = new EventEmitter();
    
  public selectedValue: number;
  private pageModel: Page;
  public isFocusedLeft: boolean = false;
  public isFocusedRight: boolean = false;
  public pageSizes: Size[];
  public isOnChangeResultsPerPage: boolean = false;
  public indexForOnChangeResultsPerPage : number = 0;
  public indexResultsPerPageMoreThanOnce : number = 0;

  ngOnInit() {
    this.pageSizes = this.pageSizeOptions;
    this.selectedValue = this.pageSize;
    setTimeout(() => {
      this.pageModel = {
        pageSize : this.pageSize,
        activePage : this.activePage,
        nrOfPages: this.nrOfPages
      }
    }, 2000)
  }
  
  public getCurrentPage() {
  if(this.isOnChangeResultsPerPage && this.indexForOnChangeResultsPerPage == 0){
    this.activePage = 0;
    this.indexForOnChangeResultsPerPage = 1;
  }
   if (this.indexResultsPerPageMoreThanOnce > 1){
     this.activePage = 0;
     this.indexResultsPerPageMoreThanOnce = 0;
   }
    if(this.activePage != undefined){
      return escape((this.activePage + 1).toString());
    }
  }

  public getNumberOfPages() {
    if(this.nrOfPages){
      return escape(this.nrOfPages.toString());
    }   
  }

  public next() {
    if (this.activePage + 1 < this.nrOfPages) {
      this.activePage = this.activePage + 1;
      this.pageModel.activePage = this.activePage;
      this.onPageChange.emit(this.pageModel);
    }
  }

  public prev() {
    if (this.activePage > 0) {
      this.activePage --;
      this.pageModel.activePage = this.activePage;
      this.onPageChange.emit(this.pageModel);
    }
  }

  public onChangeSize(event: any) {
    this.indexResultsPerPageMoreThanOnce ++;
    this.isOnChangeResultsPerPage = true;
    if (event.value != this.pageSize) {
      this.pageSize = event.value;
      this.pageModel.pageSize = this.pageSize;
      this.activePage = 0;

      this.onPageChange.emit(this.pageModel);
    }
  }

  public checkPrev() {
    return this.activePage == 0;
  }

  public checkNext() {
    return this.activePage + 1 == this.nrOfPages;
    }

    arrowLeftEvent(event: any) {
        if (event.key == 'ArrowLeft') {
            this.isFocusedLeft = true;
            this.isFocusedRight = false;
            this.prev();
        } else if (event.key == 'ArrowRight') {
            this.isFocusedLeft = false;
            this.isFocusedRight = true;
            this.next();
        } else if (event.key == 'Tab') {
            this.isFocusedLeft = true;
            this.isFocusedRight = false;
        }
    }

    arrowRightEvent(event: any) {
        if (event.key == 'ArrowLeft') {
            this.isFocusedRight = false;
            this.isFocusedLeft = true;
            this.prev();
        } else if (event.key == 'ArrowRight') {
            this.isFocusedRight = true;
            this.isFocusedLeft = false;
            this.next();
        } else if (event.key == 'Tab') {
            this.isFocusedRight = true;
            this.isFocusedLeft = false;
        }
    }

    getSizeValue(size: Size) {
      if(size.value != undefined){
        return escape(size.value.toString());
      }
      
    }
}
