import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { ProfileDetails } from '../../models/profile-details.model';
import { IncomingLenderPayload } from './incoming.lender.payload';
import { Observable} from 'rxjs';
import { Settings } from '../../components/common/constants/settings';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../security/auth.service';
import { AppConfigService } from '../config-service/app-config.service';
import { ProfileDetailsDTO } from '../../models/dto-profile-details';
import { LenderInfoDTO } from '../../models/LenderInfoDTO.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class LenderService {

    @Output() sendPromptText: EventEmitter<any> = new EventEmitter<any>();
    private getLendersUrl;
    private lenderUrl;
    private lenderUserUrl;
    private searchLenderByLenderCodeOrLenderName;

    constructor(private cookieService: CookieService, private http: HttpClient) {
        AppConfigService.loaded.subscribe(() => {
            this.getLendersUrl = AppConfigService.settings.apiEndpoint_microserviceLender + '/api/lenders';
            this.lenderUrl = AppConfigService.settings.apiEndpoint_microserviceLender + '/api/lender';
            this.lenderUserUrl = AppConfigService.settings.apiEndpoint_microserviceLender + '/api/lender-user';
            this.searchLenderByLenderCodeOrLenderName = AppConfigService.settings.apiEndpoint_microserviceLender + '/api/lender/search';
        });
    }

    getLenders(pageNumber: number, nrOfResultsPerPage: number): Observable<IncomingLenderPayload> {
        return this.http.get<IncomingLenderPayload>(this.getLendersUrl, {
            params: new HttpParams()
                .set('pageNumber', pageNumber.toString())
                .set('nrOfResultsPerPage', nrOfResultsPerPage.toString()),
            headers: new HttpHeaders({
                'authorizationtoken':this.cookieService.get('IdToken')
            })
        });
    }
    
    getLendersWithoutParameters(): Observable<IncomingLenderPayload> {
        return this.http.get<IncomingLenderPayload>(this.getLendersUrl,{
            headers: new HttpHeaders({
                'authorizationtoken':this.cookieService.get('IdToken')
            })
        });
    }
  
    getLenderByLenderCodeOrLenderName(inputSearch: string,  pageNumber: number, nrOfResultsPerPage: number): Observable<IncomingLenderPayload> {
        let params = new HttpParams()
            .set('inputSearch', inputSearch.toString())
            .set('pageNumber', pageNumber.toString())
            .set('nrOfResultsPerPage', nrOfResultsPerPage.toString())
            let headers = new HttpHeaders()
            .set('authorizationtoken',this.cookieService.get('IdToken'))
            return this.http.get<IncomingLenderPayload>(this.searchLenderByLenderCodeOrLenderName, {
                headers, params
        });
     }

     getProfileDetails(lenderCode: string): Observable<ProfileDetailsDTO> {
        let param: any = { 'lenderCode': lenderCode };
        return this.http.get<ProfileDetailsDTO>(this.lenderUrl, {
            params: param,
            headers: Settings.headers.set('authorizationtoken',this.cookieService.get('IdToken'))
        });
     }

    addLender(lender: ProfileDetailsDTO): Observable<ProfileDetails> {
        return this.http.post<ProfileDetails>(this.lenderUrl, lender, {
            headers: Settings.headers.set('authorizationtoken',this.cookieService.get('IdToken'))
        });
    }

    updateLender(lender: ProfileDetailsDTO): Observable<ProfileDetails> {
        return this.http.put<ProfileDetails>(this.lenderUrl, lender, {
            headers: Settings.headers.set('authorizationtoken',this.cookieService.get('IdToken'))
        });
    }

    updateLenderUser(lenderInfoLender: LenderInfoDTO): Observable<ProfileDetails> {
        return this.http.put<ProfileDetails>(this.lenderUserUrl, lenderInfoLender, {
            headers: Settings.headers.set('authorizationtoken',this.cookieService.get('IdToken'))
        });
    }

    createLenderUser(lenderInfoLender: LenderInfoDTO): Observable<ProfileDetails> {
        return this.http.post<ProfileDetails>(this.lenderUserUrl, lenderInfoLender, {   
            headers: Settings.headers.set('authorizationtoken',this.cookieService.get('IdToken'))
        });
    }

    getProfileDetailsByEmail(email: string): Observable<any> {
        let params = new HttpParams()
            .set('email', email.toString())
        
        let headers = Settings.headers
        .set('authorizationtoken',this.cookieService.get('IdToken'))
     
          return this.http.get<any>(AppConfigService.settings.apiEndpoint_microserviceLender + '/api/lender/email', {
             params, headers
        });
     }

}
