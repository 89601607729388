import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuardService } from 'src/app/services/security/auth-guard.service';
import { AuthService } from 'src/app/services/security/auth.service';

@Component({
  selector: 'app-error-permissions',
  templateUrl: './error-permissions.component.html',
  styleUrls: ['./error-permissions.component.scss']
})
export class ErrorPermissionsComponent implements OnInit {

  constructor(private elementRef: ElementRef, private router: Router, private authService: AuthService) { }

  ngOnInit() {
    this.authService.profileDetails = null;
  }

  ngAfterViewInit(){
    this.elementRef.nativeElement.ownerDocument.body.style.background = "url('../assets/login-backgroung.png') center center fixed";
    this.elementRef.nativeElement.ownerDocument.body.style.width='100%'
    this.elementRef.nativeElement.ownerDocument.body.style.height='auto'
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundRepeat='no-repeat'
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundSize='cover'
 }

 public exit() {
  this.authService.signOut();
 }

}
