import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LenderRegistrationComponent } from './components/lender-registration/lender-registration.component';
import { LendersListComponent } from './components/lenders-list/lenders-list.component';
import { LenderviewComponent } from './components/lenderview/lenderview.component';
import { ModuleWithProviders } from '@angular/core';
import { AccountDetailsCsComponent } from './components/account-details-cs/account-details-cs.component';
import { AccountDetailsLenderComponent } from './components/account-details-lender/account-details-lender.component';
import { CreditBureauComponent } from './components/credit-bureau/credit-bureau.component';
import { ApplicationSummaryComponent } from './components/application-summary/application-summary.component';
import { AuthGuardService } from './services/security/auth-guard.service';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { ProfileRole } from './models/profile-role.model';
import { HomeComponent } from './components/home/home.component';
import { BlankPageComponent } from './components/blank-page/blank-page.component';
import { MapComponent } from './components/map/map.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { PayPageComponent } from './components/pay-page/pay-page.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { CreatePasswordComponent } from './components/forgot-password/create-password/create-password.component';
import { ErrorPageUnavailableComponent } from './components/common/error-pages/error-page-unavailable/error-page-unavailable.component';
import { ErrorPermissionsComponent } from './components/common/error-pages/error-permissions/error-permissions.component';
import { ErrorServerComponent } from './components/common/error-pages/error-server/error-server.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { ReportDetailsComponent } from './components/report-details/report-details.component';
import {InternalUsersComponent} from "./users/internal-users.component";
import { ApplicationSummaryPrintComponent } from "./components/application-summary-print/application-summary-print.component";
import { CreditBureauPrintComponent } from './components/credit-bureau-print/credit-bureau-print.component';
import { ReportDetailsPrintComponent } from './components/report-details-print/report-details-print.component';

const routes: Routes = [
    { 
        path: '',   
        redirectTo: '/home', 
        pathMatch: 'full' 
    },
    { 
        path: 'lenderRegistration',
        data: {
            breadcrumb: 'Lender Registration',
            role: [ProfileRole.CS_ADMIN]
        },
        component: LenderRegistrationComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'lendersList',
        data: {
            breadcrumb: 'Lenders',
            role: [ProfileRole.CS_ADMIN]
        },
        component: LendersListComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'leads',
        data: {
            breadcrumb: 'Dashboard',
            role: [ProfileRole.LENDER_USER]
        },
        component: LenderviewComponent,
        canActivate: [AuthGuardService],
        runGuardsAndResolvers: "always"
    },
    {
        path: 'accountDetailsCS',
        data: {
            breadcrumb: 'Account Details',
            role: [ProfileRole.CS_ADMIN]
        },
        component: AccountDetailsCsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'accountDetailsLender',
        data: {
            breadcrumb: 'Account Details',
            role: [ProfileRole.LENDER_USER]
        },
        component: AccountDetailsLenderComponent,
        canActivate: [AuthGuardService]
    }, 
    {
        path: 'creditBureau',
        data: {
            role: [ProfileRole.LENDER_USER]
        },
        component: CreditBureauComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'creditBureauPrint',
        data: {
            role: [ProfileRole.LENDER_USER]
        },
        component: CreditBureauPrintComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'applicationSummary',
        data: {
            role:[ProfileRole.LENDER_USER]
        },
        component: ApplicationSummaryComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'applicationSummaryPrint',
        data: {
            role:[ProfileRole.LENDER_USER]
        },
        component: ApplicationSummaryPrintComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'home',
        data: {
            role:[ProfileRole.LENDER_USER, ProfileRole.CS_ADMIN]
        },
        component: HomeComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'map',
        data: {
            role:[ProfileRole.LENDER_USER]
        },
        component: MapComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'auth-callback',
        component: AuthCallbackComponent
    },
    {
        path:'errorPage',
        component: ErrorPageComponent
    },
    {
        path: 'blankPage',
        component: BlankPageComponent
    },
    {
        path: 'login',
        component: SignInComponent
    }, 
    {
        path: 'new-password',
        component: NewPasswordComponent
    },
    {
        path: 'reports',
        data: {
            role: [ProfileRole.REPORTING]
        },
        component: ReportingComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'SystemAccess',
        data: {
            breadcrumb: 'System Access',
            role: [ProfileRole.SYSTEM_ADMIN]
        },
        component: InternalUsersComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'report-details',
        data: {
            role:[ProfileRole.REPORTING]
        },
        component: ReportDetailsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'report-details-print',
        data: {
            role:[ProfileRole.REPORTING]
        },
        component: ReportDetailsPrintComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'create-password',
        component: CreatePasswordComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'pay',
        data: {
            breadcrumb: 'Review Lead',
            role:[ProfileRole.LENDER_USER]
        },
        component: PayPageComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'error_400_404',
        data: {
            role:[ProfileRole.LENDER_USER, ProfileRole.CS_ADMIN]
        },
        component: ErrorPageUnavailableComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'error_401_403',
        component: ErrorPermissionsComponent
    },
    {
        path: 'error_500',
        data: {
            role:[ProfileRole.LENDER_USER, ProfileRole.CS_ADMIN]
        },
        component: ErrorServerComponent,
        canActivate: [AuthGuardService]
    },
    { 
        path: '**',   
        redirectTo: '/error_400_404', 
        pathMatch: 'full' 
    }
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes);

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false, scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload'})], exports: [RouterModule]
})
export class AppRoutingModule { }
