import { ContactDetails } from './contact-details.model';
import { AddressInformation } from './address-information.model';
import { ProfileType } from './profile-type.model';
import { ProfileRole } from './profile-role.model';

export class ProfileDetails {
    lenderName: string;
    lenderFrenchName: string;
    equifaxMember: boolean;
    equifaxCredentialNumber: string;
    transUnionMember: boolean;
    transUnionCredentialNumber: string;
    acceptTUBureaus: boolean;
    finastraContractsCompleted: boolean;
    lenderVettingCompleted: boolean;
    contactDetails: ContactDetails;
    addressInformation: AddressInformation;
    lenderCode: string;
    id: string;
    isActive: boolean;
    sentToExpert: boolean;
    creationDate: Date;
    profileType: ProfileType;
    profileRole: ProfileRole[];
    fk_LenderAccount: String;
    fk_CsAdmin:String;
    stripe_id:String;
    loan_price:number;

    constructor(lenderName: string, lenderFrenchName: string, equifaxMember: boolean, equifaxMemberNumber: string,
        transUnionMember: boolean, transUnionMemberNumber: string, acceptTUBureaus: boolean,
        finastraContractsCompleted: boolean, lenderVettingCompleted: boolean, contactDetails: ContactDetails,
        addressInformation: AddressInformation, lenderCode: string, id: string, isActive: boolean, sentToExpert: boolean, creationDate: Date,
        stripe_clientId:String, loan_price:number, profileRole: ProfileRole[]) {
        this.lenderName = lenderName;
        this.lenderFrenchName = lenderFrenchName;
        this.equifaxMember = equifaxMember;
        this.equifaxCredentialNumber = equifaxMemberNumber;
        this.transUnionCredentialNumber = transUnionMemberNumber;
        this.transUnionMember = transUnionMember;
        this.acceptTUBureaus = acceptTUBureaus;
        this.finastraContractsCompleted = finastraContractsCompleted;
        this.lenderVettingCompleted = lenderVettingCompleted;
        this.contactDetails = contactDetails;
        this.addressInformation = addressInformation;
        this.lenderCode = lenderCode;
        this.id = id;
        this.isActive = isActive;
        this.sentToExpert = sentToExpert;
        this.creationDate = creationDate;
        this.stripe_id=stripe_clientId;
        this.loan_price=loan_price
        this.profileRole = profileRole;
    }

    get getLenderName(): string {
        return this.lenderName;
    }

    get getLenderFrenchName(): string {
        return this.lenderFrenchName;
    }
    get getEquifaxMember(): boolean {
        return this.equifaxMember;
    }
    get getCredentialNumberEq(): string {
        return this.equifaxCredentialNumber;
    }

    get getCredentialNumberTu(): string {
        return this.transUnionCredentialNumber;
    }
    get getTuMemberLender(): boolean {
        return this.transUnionMember;
    }
    get getAcceptTUBureaus(): boolean {
        return this.acceptTUBureaus;
    }
    get getFinastraContracts(): boolean {
        return this.finastraContractsCompleted;
    }
    get getLenderVetting(): boolean {
        return this.lenderVettingCompleted;
    }
    get getContactDetails(): ContactDetails {
        return this.contactDetails;
    }
    get getAddressInformation(): AddressInformation {
        return this.addressInformation;
    }
    get getLenderCode(): string {
        return this.lenderCode;
    }

    get getId(): string {
        return this.id;
    }

    get getIsActive(): boolean {
        return this.isActive;
    }

    get getSentToExpert(): boolean {
        return this.sentToExpert;
    }

    get getCreationDate(): Date {
        return this.creationDate
    }

    get getProfileType(): ProfileType {
        return this.profileType
    }

    get getFk_CsAdmin(): String {
        return this.fk_CsAdmin
    }

    get getFk_LenderAccount(): String{
        return this.fk_LenderAccount
    }

    get getStripe_clientId(): String{
        return this.stripe_id;
    }

    set setFk_LenderAccount(fk_LenderAccount: string){
        this.fk_LenderAccount = fk_LenderAccount;
    }

    set setProfileType(profileType: string) {
       this.profileType = ProfileType.LENDER;
    }

    set setStripeClientId(stripe_clientId:String){
        this.stripe_id=stripe_clientId;
    }
}
