export class AsciiCodes{
    
    public static BACKSPACE_KEY = 8;
    public static TAB_KEY = 9;
    public static DELETE_KEY = 46;
    public static LEFT_ARROW_KEY = 37;
    public static RIGHT_ARROW_KEY = 39;
    public static KEYPAD_ZERO = 48;
    public static NUMPAD_ZERO = 96;
    
    public static ALPHA_UPPER = 65;
    public static ALPHA_LOWER = 97;
}