import { Injectable, ErrorHandler } from '@angular/core';
import { Subject } from 'rxjs';
import { ApplicationInsightsService } from '../applicationInsights/application-insights.service';
import {Observable} from "rxjs/internal/Observable";

@Injectable({
    providedIn: 'root'
})
export class ErrorService extends ErrorHandler{

    private errors = new Subject<string[]>();

    constructor(private applicationInsigtsService: ApplicationInsightsService) { 
        super();
    }

    public addErrors = (errors: string[]): void =>
        this.errors.next(errors);

    public getErrors = () =>
        this.errors.asObservable();

    handleError(error: Error) {
        if(error.stack != undefined){
            console.log(error.stack.toString());
        }
        // this.applicationInsigtsService.logException(error); // Manually log exception
    }
}
