<div *ngIf="allDataFetched" style="max-width: 100%; min-width: 100%;overflow: hidden;">

    <div id="DealInformationToPdf" style="max-width: 100%; min-width: 100%;">
        <div class="col-sm-12">

            <div id="id3-pdf">
                <div id="originator-details-to-pdf">
                    <div id="originatorDetails-pdf">
                        <div class="col-12" style="display:flex">
                            <div class="format col-sm-4">
                                <p class="paragraph">{{'theStoryLabel' | translate}}</p>
                            </div>
                        </div>
                        <div class="col-sm-12 border-bottom border-dark">
                            <h1 class="applicant-group-paragraph">{{'firm_appSummary' | translate}}</h1>
                        </div>
                        <div class="col-12 border-bottom border-dark">
                            {{firmName}}
                        </div>

                        <div class="format col-sm-12 border-bottom border-dark">
                        
                            <div *ngIf="addressProvince == 'Quebec' && languageSelected == 'fr'; then contentFirmAddress else other_contentFA">
                                </div>
                                <ng-template #contentFirmAddress> {{verifyValue(firmAddress.streetNumber, false) + " " +verifyValue(firmAddress.streetTypeDd, true) + " " + verifyValue(firmAddress.streetName, false) + " " }}</ng-template> 

                                <ng-template #other_contentFA>{{verifyValue(firmAddress.streetNumber, false) + " " +verifyValue(firmAddress.streetName, false) + " " + verifyValue(firmAddress.streetTypeDd, true) + " " }}</ng-template>

                            {{verifyValue(firmAddress.streetDirectionDd, true) + " " +
                            verifyUnitNumber(firmAddress?.unitNumber) + " " + verifyValue(firmAddress.city, false) + ", " }}
                            {{verifyValue(firmAddress.provinceDd, true) + " " + verifyValue(firmAddress.postalFsa, false) + " " +
                            verifyValue(firmAddress.postalLdu, false)}}
                        </div>

                        <div class="col-sm-12">
                            <h1 class="applicant-group-paragraph">{{'broker_appSummary' | translate}}</h1>
                        </div>
                        <div class="format col-sm-12 border-bottom border-dark"
                             *ngIf="agentContact?.contactName.contactFirstName != null">
                            {{ agentContact?.contactName.contactFirstName + " " +
                        verifyValue(agentContact.contactName.contactMiddleInitial, false) + " " +
                        verifyValue(agentContact.contactName.contactLastName, false)}}
                        </div>

                        <div class="format col-sm-12 border-bottom border-dark">
                            <div class="d-flex">
                                <div *ngIf="verifyUnitNumber(agentAddress?.unitNumber)">
                                    {{verifyUnitNumber(agentAddress?.unitNumber)+" - " }}
                                </div>
                             
                                <div *ngIf="agentProvince == 'Quebec' && languageSelected == 'fr'; then contentAgentProvince else other_contentAP">
                                    </div>
                                    <ng-template #contentAgentProvince> {{verifyValue(agentAddress.streetNumber, false) + " " + verifyValue(agentAddress.streetTypeDd, true) + " " + verifyValue(agentAddress.streetName, false) + " " }}</ng-template> 
    
                                    <ng-template #other_contentAP> {{verifyValue(agentAddress.streetNumber, false) + " " + verifyValue(agentAddress.streetName, false) + " " + verifyValue(agentAddress.streetTypeDd, true) + " " }}</ng-template>

                                {{verifyValue(agentAddress.streetDirectionDd, true) + " " + " " +
                                verifyValue(agentAddress.city, false) + ", " }}
                                {{verifyValue(agentAddress.provinceDd, true) + " " + verifyValue(agentAddress.postalFsa, false) + " "
                                + verifyValue(agentAddress.postalLdu, false)}}
                            </div>
                        </div>

                        <div class="" style="display:flex">
                            <div class="format col-sm-4 border-bottom border-dark"
                                 *ngFor="let contact of agentContact?.contactPhone">
                                <div class="d-inline">
                                    <strong>{{getLanguageBasedValue(contact?.phoneTypeDd, false)}} {{'phone' | translate}}: </strong>
                                    {{formatPhoneNumberAndSin(contact.phoneNumber)}} <div
                                        *ngIf="contact.phoneExtension">{{" Ext. " +contact.phoneExtension}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="format col-sm-12 border-bottom border-dark">
                            <strong>{{'email_appSummary' | translate}}: </strong><a
                                href="mailto:{{agentContact?.contactEmailAddress}}">{{agentContact?.contactEmailAddress}}</a>
                        </div>

                        <div *ngIf="agentContact?.preferredContactMethodDd"
                             class="format col-sm-12 border-bottom border-dark">
                            <strong>{{'preferredMethod_appSummary' | translate}}:</strong> {{getLanguageBasedValue(agentContact?.preferredContactMethodDd, false)}}
                        </div>

                        <div *ngIf="getDealNotes()" class="col-sm-12 mt-4 border-bottom border-dark">
                            <h1 class="applicant-group-paragraph">{{'dealNotes_appSummary' | translate}}</h1>
                        </div>
                        <div *ngIf="getDealNotes()" class="format col-sm-12 border-bottom border-dark">
                            {{getDealNotes()}}
                        </div>
                    </div>
                </div>
            </div>

            <div id="id2-pdf">
                <div id="displayPropertyDataToPdf">
                    <div class="format col-sm-4">
                        <p class="paragraph">{{'propertyLabel' | translate}}</p>
                    </div>
                    <div class="propertyData">
                        <div class="col-12" style="display:flex">
                            <div class="col-sm-12">
                                <h1 class="applicant-group-paragraph">{{'subjectProperty_appSummary' | translate}}</h1>
                            </div>
                        </div>
                        <div class="col-12" style="display:flex">
                            <div class="format col-sm-12 border-bottom border-dark">
                                <strong>{{'propertyAddress_appSummary' | translate}}:</strong>
                                                        
                                <div *ngIf="subjectPropertyProvince == 'Quebec' && languageSelected == 'fr'; then contentSubjectProperty else other_contentSP">
                                    </div>
                                    <ng-template #contentSubjectProperty> {{verifyValue(subjectProperty.address.streetNumber, false) +
                                        " " + verifyValue(subjectProperty.address.streetTypeDd, true) + " " +
                                        verifyValue(subjectProperty.address.streetName, false) + " " }}</ng-template> 
    
                                    <ng-template #other_contentSP>  {{verifyValue(subjectProperty.address.streetNumber, false) +
                                        " " + verifyValue(subjectProperty.address.streetName, false) + " " +
                                        verifyValue(subjectProperty.address.streetTypeDd, true) + " " }}</ng-template>

                                       

                                {{verifyValue(subjectProperty.address.streetDirectionDd, true) +
                                verifyUnitNumber(subjectProperty?.address.unitNumber) + " " +
                                verifyValue(subjectProperty.address.city, false) + ", " }}
                                {{verifyValue(subjectProperty.address.provinceDd, true) + " " +
                                verifyValue(subjectProperty.address.postalFsa, false) + " " +
                                verifyValue(subjectProperty.address.postalLdu, false)}}
                            </div>
                        </div>
                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-4" *ngIf="subjectProperty?.legalLine1">
                                <strong>{{'lot_appSummary' | translate}}:</strong> {{ subjectProperty?.legalLine1}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark" *ngIf="subjectProperty?.legalLine2">
                                <strong>{{'block_appSummary' | translate}}:</strong> {{ subjectProperty?.legalLine2}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark" *ngIf="subjectProperty?.legalLine3">
                                <strong>{{'concessionTownship_appSummary' | translate}}:</strong> {{ subjectProperty?.legalLine3}}
                            </div>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-4" *ngIf="subjectProperty?.appraisalDateAct">
                                <strong>{{'appraisedDate_appSummary' | translate}}:</strong> {{subjectProperty?.appraisalDateAct | date:
                                'yyyy-MM-dd'}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark"
                                 *ngIf="subjectProperty?.occupancyTypeDd">
                                <strong>{{'occupancy_appSummary' | translate}}:</strong> {{getLanguageBasedValue(subjectProperty?.occupancyTypeDd, false)}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark" *ngIf="subjectProperty?.structureAge">
                                <strong>{{'age_appSummary' | translate}}:</strong> {{subjectProperty?.structureAge}} {{'years' | translate}}
                            </div>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-12" *ngIf="subjectProperty?.heatTypeDd">
                                <strong>{{'heatingType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(subjectProperty?.heatTypeDd, false)}}
                            </div>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-4" *ngIf="subjectProperty?.livingSpace">
                                <strong>{{'livingSpace_appSummary' | translate}}:</strong> {{subjectProperty?.livingSpace + " " +
                            verifyValue(subjectProperty.livingSpaceUnitOfMeasurDd, true)}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark" *ngIf="subjectProperty?.lotSize">
                                <strong>{{'lotSize_appSummary' | translate}}:</strong> {{subjectProperty?.lotSize + " " +
                            verifyValue(subjectProperty.lotSizeUnitOfMeasureDd, true)}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark"
                                 *ngIf="subjectProperty?.dwellingTypeDd">
                                <strong>{{'dwellingType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(subjectProperty?.dwellingTypeDd, false)}}
                            </div>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-4" *ngIf="subjectProperty?.dwellingStyleDd">
                                <strong>{{'dwellingStyle_appSummary' | translate}}:</strong> {{getLanguageBasedValue(subjectProperty?.dwellingStyleDd, false)}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark" *ngIf="subjectProperty?.garageSizeDd">
                                <strong>{{'garageSize_appSummary' | translate}}:</strong> {{getLanguageBasedValue(subjectProperty?.garageSizeDd, false)}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark" *ngIf="subjectProperty?.garageTypeDd">
                                <strong>{{'garageType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(subjectProperty?.garageTypeDd, false)}}
                            </div>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-4" *ngIf="subjectProperty?.taxationYear">
                                <strong>{{'taxationYear_appSummary' | translate}}:</strong> {{subjectProperty?.taxationYear}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark"
                                 *ngIf="loanApplication?.deal.taxPayorDd">
                                <strong>{{'taxesPaidBy_appSummary' | translate}}:</strong> {{getLanguageBasedValue(loanApplication?.deal.taxPayorDd, false)}}
                            </div>
                        </div>

                        <div class="col-12" style="display:flex">
                            <div class="col-sm-12 format border-bottom border-dark"
                                 *ngIf="subjectProperty?.insulatedWithUffi">
                                <strong>{{'enviromentalHazard_appSummary' | translate}}:</strong>
                                {{convertValue(subjectProperty?.insulatedWithUffi)}}
                            </div>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-4" *ngIf="subjectProperty?.purchasePrice">
                                <strong>{{'purchasePrice_appSummary' | translate}}:</strong> {{subjectProperty?.purchasePrice |
                                currency:'USD':'symbol':'1.2-2'}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark"
                                 *ngIf="subjectProperty?.estimatedAppraisalValue">
                                <strong>{{'estimatedValue_appSummary' | translate}}:</strong> {{subjectProperty?.estimatedAppraisalValue |
                                currency:'USD':'symbol':'1.2-2'}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark"
                                 *ngIf="subjectProperty?.actualAppraisalValue">
                                <strong>{{'appraisedValue_appSummary' | translate}}:</strong> {{subjectProperty?.actualAppraisalValue |
                                currency:'USD':'symbol':'1.2-2'}}
                            </div>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-4">
                                <strong>{{'heatingCost_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense, 'Heating Expense') | currency:'USD':'symbol':'1.2-2'}}
                            </div>
                            <div class="format col-sm-4  border-left border-dark">
                                <strong>{{'condoFees_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense, 'Condo Fees') | currency:'USD':'symbol':'1.2-2'}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark">
                                <strong>{{'annualTaxes_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense, 'Municipal Taxes') | currency:'USD':'symbol':'1.2-2'}}
                            </div>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-6" *ngIf="loanApplication?.deal.refiImprovementsDesc">
                                <strong>{{'improvements_appSummary' | translate}}:</strong> {{loanApplication?.deal.refiImprovementsDesc}}
                            </div>
                            <div class="format col-sm-4" *ngIf="loanApplication?.deal.refiImprovementAmount">
                                <strong>{{'valueOfImprovements_appSummary' | translate}}:</strong> {{loanApplication?.deal.refiImprovementAmount |
                                currency:'USD':'symbol':'1.2-2'}}
                            </div>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="col-sm-12 format">
                                <strong>{{'totalExpenses_appSummary' | translate}}:</strong> {{getTotalExpenses(subjectProperty?.propertyExpense) |
                                currency:'USD':'symbol':'1.2-2'}}
                            </div>
                        </div>

                        <div class="col-12" style="display:flex">
                            <div class="col-sm-12">
                                <h1 class="applicant-group-paragraph">{{'rentalPropertyExpense_appSummary' | translate}}</h1>
                            </div>
                        </div>
                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-6">
                                <strong>{{'monthlyRentalIncome_appSummary' | translate}}:</strong> {{getRentalIncome(subjectProperty?.rentalIncome)
                                | currency:'USD':'symbol':'1.2-2'}}
                            </div>
                            <div class="format col-sm-6  border-left border-dark"
                                 *ngIf="subjectProperty?.rentalOffsetOption">
                                <strong>{{'rentalOffsetOption_appSummary' | translate}}:</strong> {{getLanguageBasedValue(subjectProperty?.rentalOffsetOption, false)}}
                            </div>
                        </div>

                        <div class="col-sm-12 border-bottom border-dark">
                            <div class="format col-sm-6">
                                <strong>{{'offset_appSummary' | translate}} %:</strong> {{getOffset(subjectProperty?.rentalIncome)}}
                            </div>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-4">
                                <strong>{{'insurance_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense, 'Insurance')
                                | currency:'USD':'symbol':'1.2-2'}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark">
                                <strong>{{'hydro_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense, 'Hydro') |
                                currency:'USD':'symbol':'1.2-2'}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark">
                                <strong>{{'managementExpenses_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense,
                                'Management Expenses') | currency:'USD':'symbol':'1.2-2'}}
                            </div>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-4">
                                <strong>{{'repairs_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense, 'Repairs') |
                                currency:'USD':'symbol':'1.2-2'}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark">
                                <strong>{{'interestCharges_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense,
                                'Interest Charges') | currency:'USD':'symbol':'1.2-2'}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark">
                                <strong>{{'generalExpenses_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense,
                                'General Expenses') | currency:'USD':'symbol':'1.2-2'}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="id1-pdf">
                <div id="financingDataToPdf">
                    <div class="col-12" style="display:flex">
                        <div class="col-sm-12">
                            <p class="paragraph">{{'financingLabel' | translate}}</p>
                        </div>
                    </div>

                    <div class="col-12" style="display:flex">
                        <div class="col-sm-12">
                            <h1 class="applicant-group-paragraph">{{'requestedMortgage_appSummary' | translate}}</h1>
                        </div>
                    </div>

                    <div class="col-12 border-bottom border-dark"
                         *ngIf="searchMortgage?.requested.lenderSubmission.lenderProfile.lenderName"
                         style="display:flex">
                        <div class="format col-sm-4">
                            <strong>{{'lender_appSummary' | translate}}:</strong>
                            {{searchMortgage?.requested.lenderSubmission.lenderProfile.lenderName}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark"
                             *ngIf="searchMortgage.requested.loanTypeDd">
                            <strong>{{'loanType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(searchMortgage?.requested.loanTypeDd, false)}}
                        </div>
                    </div>

                    <div class="col-12 border-bottom border-dark" style="display:flex">
                        <div class="format col-sm-4"
                             *ngIf="searchDeal?.dealPurposeDd !== undefined || searchDeal?.refiPurpose !== undefined">
                            <strong>{{'purpose_appSummary' | translate}}:</strong> {{verifyValue(searchDeal.dealPurposeDd, true) + " " +
                        verifyValue(searchDeal.refiPurpose, false)}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark"
                             *ngIf="searchMortgage?.requested.mortgageTypeDd">
                            <strong>{{'mortgageType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(searchMortgage?.requested.mortgageTypeDd, false)}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark" *ngIf="searchDeal?.estimatedClosingDate">
                            <strong>{{'closingDate_appSummary' | translate}}:</strong> {{searchDeal?.estimatedClosingDate | date: 'yyyy-MM-dd'}}
                        </div>
                    </div>

                    <div class="col-12 border-bottom border-dark" style="display:flex">
                        <div class="format col-sm-4" *ngIf="searchMortgage?.requested.paymentFrequencyDd">
                            <strong>{{'paymentFrequency_appSummary' | translate}}:</strong> {{getLanguageBasedValue(searchMortgage?.requested.paymentFrequencyDd, false)}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark"
                             *ngIf="checkPurchaseValue(subjectProperty)">
                            <strong>{{'purchaseValue_appSummary' | translate}}:</strong> {{checkPurchaseValue(subjectProperty) |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark">
                            <strong>{{'insurancePremium_appSummary' | translate}}:</strong> {{searchMortgage?.requested.miPremiumAmount |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>


                    </div>

                    <div class="col-12 border-bottom border-dark" style="display:flex">
                        <div class="format col-sm-4" *ngIf="searchMortgage?.requested.pAndIPaymentAmountMonthly">
                            <strong>{{'monthlyPayment_appSummary' | translate}}:</strong> {{searchMortgage?.requested.pAndIPaymentAmountMonthly |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-4 border-dark" *ngIf="searchMortgage?.requested.totalLoanAmount">
                            <strong>{{'totalMortgageAmount_appSummary' | translate}}:</strong> {{searchMortgage?.requested.totalLoanAmount |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark">
                            <strong>{{'rateType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(searchMortgage?.requested.interestTypeDd, false)}}
                        </div>
                    </div>

                    <div class="col-12 border-bottom border-dark" style="display:flex">
                        <div class="format col-sm-3" *ngIf="searchMortgage?.requested.rate.netRate">
                            <strong>{{'netRate_appSummary' | translate}}:</strong> {{searchMortgage?.requested.rate.netRate/100 | percent:'1.3-5'}}
                        </div>
                    </div>

                    <div class="col-12 border-bottom border-dark" style="display:flex">
                        <div class="format col-sm-4" *ngIf="searchMortgage?.requested.paymentTermDd">
                            <strong>{{'termType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(searchMortgage?.requested.paymentTermDd, false)}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark"
                             *ngIf="searchMortgage?.requested.actualPaymentTerm">
                            <strong>{{'term_appSummary' | translate}}:</strong> {{searchMortgage?.requested.actualPaymentTerm}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark"
                             *ngIf="searchMortgage?.requested.amortizationTerm">
                            <strong>{{'amortization_appSummary' | translate}}:</strong> {{searchMortgage?.requested.amortizationTerm}}
                        </div>
                    </div>

                    <div class="col-12 border-bottom border-dark" style="display:flex">
                        <div class="format col-sm-4" *ngIf="searchMortgage?.requested.interestOnlyFlag">
                            <strong>{{'repaymentType_appSummary' | translate}}:</strong> {{searchMortgage?.requested.interestOnlyFlag}}
                        </div>
                    </div>

                    <div class="col-12" style="display:flex">
                        <div class="col-sm-12">
                            <h1 class="applicant-group-paragraph">{{'downPayment_appSummary' | translate}}</h1>
                        </div>
                    </div>

                    <div class="col-12 border-bottom border-dark" style="display:flex">
                        <div class="format col-sm-4"><strong>Source</strong></div>
                        <div class="format col-sm-4 border-left border-dark"><strong>{{'description_appSummary' | translate}}</strong></div>
                        <div class="format col-sm-4 border-left border-dark"><strong>{{'amount_appSummary' | translate}}</strong></div>
                    </div>

                    <div *ngFor="let downPayment of downPaymentSourceVariable">
                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-4">{{getLanguageBasedValue(downPayment.downPaymentSourceTypeDd, false)}}</div>
                            <div class="format col-sm-4 border-left border-dark">{{downPayment.description}}</div>
                            <div class="format col-sm-4 border-left border-dark">{{downPayment.amount |
                                currency:'USD':'symbol':'1.2-2'}}</div>
                        </div>
                    </div>

                    <div class="col-12 border-bottom border-dark" style="display:flex">
                        <div class="format col-sm-4"></div>
                        <div class="format col-sm-4"><strong>{{'total_appSummary' | translate}}</strong></div>
                        <div class="format col-sm-4">{{calculateTotalDownPayments() | currency:'USD':'symbol':'1.2-2'}}
                        </div>
                    </div>

                    <div *ngIf="concurrentMortgages?.length != 0 && concurrentMortgages != undefined">
                        <div class="col-12" style="display:flex">
                            <div class="col-sm-12">
                                <h1 class="applicant-group-paragraph">{{'concurrent_appSummary' | translate}}</h1>
                            </div>
                        </div>

                        <div id="concurrent">
                            <div *ngFor="let concurrentMortgage of concurrentMortgages">
                                <div class="col-12 border-bottom border-dark" style="display:flex">
                                    <div class="format col-sm-4" *ngIf="concurrentMortgage.mortgageTypeDd">
                                        <strong>{{'mortgageType_appSummary' | translate}}: </strong> {{getLanguageBasedValue(concurrentMortgage.mortgageTypeDd, false)}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark"
                                         *ngIf="concurrentMortgage.maturityDate">
                                        <strong>{{'maturityDate_appSummary' | translate}}: </strong> {{concurrentMortgage.maturityDate | date:
                                        'yyyy-MM-dd'}}
                                    </div>
                                </div>

                                <div class="col-12 border-bottom border-dark" style="display:flex">
                                    <div class="format col-sm-4" *ngIf="concurrentMortgage.totalLoanAmount">
                                        <strong>{{'totalMortgageAmount_appSummary' | translate}}: </strong> {{concurrentMortgage.totalLoanAmount |
                                        currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark"
                                         *ngIf="concurrentMortgage.rate.netRate">
                                        <strong>{{'netRate_appSummary' | translate}}: </strong> {{concurrentMortgage.rate.netRate/100 |
                                        percent:'1.3-5'}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark"
                                         *ngIf="concurrentMortgage.pAndIPaymentAmountMonthly">
                                        <strong>{{'monthlyPayment_appSummary' | translate}}: </strong>
                                        {{concurrentMortgage.pAndIPaymentAmountMonthly |
                                        currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="existingMortgages?.length !=0">
                        <div class="col-sm-12 mt-4">
                            <p class="paragraph">{{'existingMortgages_appSummary' | translate}}</p>
                        </div>

                        <div *ngFor="let mortgage of existingMortgages">

                            <div class="col-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-sm-4" *ngIf="mortgage.mortgageTypeDd">
                                    <strong>{{'mortgageType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.mortgageTypeDd, false)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.balanceRemaining">
                                    <strong>{{'mortgageBalance_appSummary' | translate}}:</strong> {{ mortgage.balanceRemaining |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark"
                                     *ngIf="mortgage.pAndIPaymentAmount">
                                    <strong>{{'frequencyPayment_appSummary' | translate}}:</strong> {{mortgage.pAndIPaymentAmount |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                            </div>

                            <div class="col-12 border-dark" style="display:flex">
                                <div class="format col-sm-4" *ngIf="mortgage.paymentFrequencyDd">
                                    <strong>{{'paymentFrequency_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentFrequencyDd, false)}}
                                </div>
                            </div>

                            <div class="col-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-sm-4" *ngIf="mortgage.maturityDate">
                                    <strong>{{'maturityDate_appSummary' | translate}}:</strong> {{mortgage.maturityDate | date: 'yyyy-MM-dd'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.interestTypeDd">
                                    <strong>{{'rateType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.interestTypeDd, false)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.paymentTermDd">
                                    <strong>{{'termType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentTermDd, false)}}
                                </div>
                            </div>

                            <div class="col-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-sm-4" *ngIf="mortgage.rate">
                                    <div *ngIf="mortgage.rate !=null && mortgage.rate.interestRate != null">
                                        <strong>{{'mtgInterestRate_appSummary' | translate}}:</strong> {{mortgage.rate.interestRate/100 |
                                        percent:'1.3-5'}}
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-sm-6" *ngIf="mortgage.existingMortgageHolder">
                                    <strong>{{'mortgageHolder_appSummary' | translate}}:</strong> {{mortgage.existingMortgageHolder}}
                                </div>
                                <div class="format col-sm-6 border-left border-dark" *ngIf="mortgage.loanTypeDd">
                                    <strong>{{'loanType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.loanTypeDd, false)}}
                                </div>
                            </div>

                            <div class="col-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-sm-3" *ngIf="mortgage.originalMortgageAmount">
                                    <strong>{{'originalMtgAmt_appSummary' | translate}}:</strong> {{mortgage.originalMortgageAmount |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-6 border-left border-dark"
                                     *ngIf="mortgage.currentMortgageNumber">
                                    <strong>{{'mortgage_appSummary' | translate}} #:</strong> {{mortgage.currentMortgageNumber}}
                                </div>
                            </div>

                            <div class="col-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-sm-4" *ngIf="mortgage.insuredFlag">
                                    <strong>{{'insured_appSummary' | translate}}:</strong> {{convertValue(mortgage.insuredFlag)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.mortgageInsurerId">
                                    <strong>{{'insurer_appSummary' | translate}}:</strong> {{getInsurer(mortgage.mortgageInsurerId)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.miReferenceNumber">
                                    <strong>{{'insuranceAccount_appSummary' | translate}} #:</strong> {{mortgage.miReferenceNumber}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="refinanceSwiftETOMortgages?.length !=0">
                        <div class="col-12" style="display:flex">
                            <div class="col-sm-12">
                                <h1 class="applicant-group-paragraph">{{'refinanceSwitchEto_appSummary' | translate}}</h1>
                            </div>
                        </div>

                        <div *ngFor="let mortgage of refinanceSwiftETOMortgages">

                            <div class="col-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-lg-4" *ngIf="mortgage.mortgageTypeDd">
                                    <strong>{{'mortgageType_appSummary' | translate}}:</strong> {{ getLanguageBasedValue(mortgage.mortgageTypeDd, false)}}
                                </div>
                                <div class="format col-lg-4 border-left border-dark" *ngIf="mortgage.balanceRemaining">
                                    <strong>{{'mortgageBalance_appSummary' | translate}}:</strong> {{ mortgage.balanceRemaining |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-lg-4 border-left border-dark"
                                     *ngIf="mortgage.pAndIPaymentAmount">
                                    <strong>{{'frequencyPayment_appSummary' | translate}}:</strong> {{mortgage.pAndIPaymentAmount |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                            </div>

                            <div class="col-12 border-dark" style="display:flex">
                                <div class="format col-lg-4" *ngIf="mortgage.paymentFrequencyDd">
                                    <strong>{{'paymentFrequency_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentFrequencyDd, false)}}
                                </div>
                            </div>

                            <div class="col-lg-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-lg-4" *ngIf="mortgage.maturityDate">
                                    <strong>{{'maturityDate_appSummary' | translate}}:</strong> {{mortgage.maturityDate | date: 'yyyy-MM-dd'}}
                                </div>
                                <div class="format col-lg-4 border-left border-dark" *ngIf="mortgage.interestTypeDd">
                                    <strong>{{'rateType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.interestTypeDd, false)}}
                                </div>
                                <div class="format col-lg-4 border-left border-dark" *ngIf="mortgage.paymentTermDd">
                                    <strong>{{'termType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentTermDd, false)}}
                                </div>
                            </div>

                            <div class="col-lg-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-sm-4" *ngIf="mortgage.rate">
                                    <div *ngIf="mortgage.rate !=null && mortgage.rate.interestRate != null">
                                        <strong>{{'mtgInterestRate_appSummary' | translate}}:</strong> {{mortgage.rate.interestRate/100 |
                                        percent:'1.3-5'}}
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-lg-4" *ngIf="mortgage.existingMortgageHolder">
                                    <strong>{{'mortgageHolder_appSummary' | translate}}:</strong> {{mortgage.existingMortgageHolder}}
                                </div>
                                <div class="format col-lg-4 border-left border-dark" *ngIf="mortgage.loanTypeDd">
                                    <strong>{{'loanType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.loanTypeDd, false)}}
                                </div>
                            </div>

                            <div class="col-lg-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-lg-4" *ngIf="mortgage.originalMortgageAmount">
                                    <strong>{{'originalMtgAmt_appSummary' | translate}}:</strong> {{mortgage.originalMortgageAmount |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-lg-4 border-left border-dark"
                                     *ngIf="mortgage.currentMortgageNumber">
                                    <strong>{{'mortgage_appSummary' | translate}} #:</strong> {{mortgage.currentMortgageNumber}}
                                </div>
                                <div class="format col-lg-4 border-left border-dark"
                                     *ngIf="mortgage.refiBlendedAmortization">
                                    <strong>{{'blendedAmortization_appSummary' | translate}}:</strong> {{mortgage.refiBlendedAmortization}}
                                </div>
                            </div>

                            <div class="col-lg-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-lg-4" *ngIf="mortgage.insuredFlag">
                                    <strong>{{'insured_appSummary' | translate}}:</strong> {{convertValue(mortgage.insuredFlag)}}
                                </div>
                                <div class="format col-lg-4 border-left border-dark" *ngIf="mortgage.mortgageInsurerId">
                                    <strong>{{'insurer_appSummary' | translate}}:</strong> {{getInsurer(mortgage.mortgageInsurerId)}}
                                </div>
                                <div class="format col-lg-4 border-left border-dark" *ngIf="mortgage.miReferenceNumber">
                                    <strong>{{'insuranceAccount_appSummary' | translate}} #:</strong> {{mortgage.miReferenceNumber}}
                                </div>
                            </div>

                            <div class="col-lg-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-lg-4" *ngIf="subjectProperty.originalPurchasePrice">
                                    <strong>{{'originalPurchasePrice_appSummary' | translate}}:</strong> {{subjectProperty.originalPurchasePrice |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-lg-4 border-left border-dark"
                                     *ngIf="subjectProperty.refiOrigPurchaseDate">
                                    <strong>{{'purchaseDate_appSummary' | translate}}:</strong> {{subjectProperty.refiOrigPurchaseDate | date:
                                    'yyyy-MM-dd'}}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div id="id0-pdf"
                *ngIf="displayApplicantData || displayFinancingData || displayPropertyData || displayOriginatorDetails">
                <div id="applicantDataToPdf" class="pdfView">

                    <div id="id01-pdf">
                        <div class="format col-sm-4">
                            <p class="paragraph">{{'applicantLabel' | translate}}</p>
                        </div>

                        <!--Primary Applicant Section-->
                        <div *ngFor="let applicant of primaryApplicantGroup?.applicant; let i = index"
                            class="primaryApplicant">

                            <div class="col-12" style="display:flex">
                                <div class="col-sm-12">
                                    <h1 class="applicant-group-paragraph">{{ createName(applicant.name)}}</h1>
                                </div>
                            </div>

                            <!-- Logic required for the persistence of currentAddress field-->
                            <div *ngIf="getCurrentAddress(applicant.addressDetail)"></div>

                            <div class="col-12" *ngIf="createAddress(currentAddress?.address)" style="display:flex">
                                <div class="col-sm-12 border-bottom border-dark mt-4">
                                    <strong>{{'address' | translate}}:</strong> {{createAddress(currentAddress?.address)}}
                                </div>
                            </div>

                            <div class="col-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-sm-4" *ngIf="currentAddress?.residentialStatusDd">
                                    <strong>{{'residentialStatus_appSummary' | translate}}:</strong> {{getLanguageBasedValue(currentAddress?.residentialStatusDd, false)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark"
                                    *ngIf="currentAddress?.monthsAtAddress">
                                    <strong>{{'timeAtResidence_appSummary' | translate}}:</strong>
                                    {{formatTime(currentAddress?.monthsAtAddress)}}
                                </div>
                            </div>

                            <div class="col-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-sm-6" class="work-phone-pdf-div" *ngIf="getNumber(applicant.phone, 'Work')">
                                    <strong>{{'workPhone_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Work')}}
                                </div>
                                <div class="format col-sm-6 border-left border-dark"
                                    *ngIf="getNumber(applicant.phone, 'Cell')">
                                    <strong>{{'cellPhone_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Cell')}}
                                </div>
                            </div>

                            <div class="col-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-sm-6"
                                    *ngIf="getNumber(applicant.phone, 'Home')">
                                    <strong>{{'homePhone_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Home')}}
                                </div>
                                <div class="format col-sm-6  border-left border-dark" *ngIf="getNumber(applicant.phone, 'Fax')">
                                    <strong>{{'faxNumber_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Fax')}}
                                </div>
                            </div>

                            <div class="col-12 border-bottom border-dark"style="display:flex">
                                <div class="format col-sm-6" *ngIf="applicant.emailAddress">
                                    <strong>{{'email_appSummary' | translate}}:</strong> {{applicant.emailAddress}}
                                </div>
                            </div>

                            <div class="col-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-sm-4" *ngIf="applicant.maritalStatusDd">
                                    <strong>{{'maritalStatus_appSummary' | translate}}:</strong> {{getLanguageBasedValue(applicant.maritalStatusDd, false)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="applicant.birthDate">
                                    <strong>{{'dateOfBirth_appSummary' | translate}}:</strong> {{applicant.birthDate | date: 'yyyy-MM-dd'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark"
                                    *ngIf="applicant.numberOfDependents != null || applicant.numberOfDependents != undefined">
                                    <strong>{{'dependents_appSummary' | translate}}:</strong> {{applicant.numberOfDependents}}
                                </div>
                            </div>

                            <div class="col-12 border-bottom border-dark" style="display:flex">
                                <div class="format col-sm-4" *ngIf="applicant.socialInsuranceNumber">
                                    <strong>{{'sin_appSummary' | translate}}:</strong>
                                    {{formatPhoneNumberAndSin(applicant.socialInsuranceNumber)}}
                                </div>
                            </div>

                            <!-- Logic required for the persistence of previousAddress field-->
                            <div *ngIf="getPreviousAddress(applicant.addressDetail)"></div>

                            <div *ngIf="previousAddresses !== null && previousAddresses.length != 0">
                                <div *ngFor="let previousAddress of previousAddresses">
                                    <div class="col-12" *ngIf="createAddress(previousAddress?.address)"
                                        style="display:flex">
                                        <div class="col-sm-12 mt-4">
                                            <strong>{{'previousAddress_appSummary' | translate}}:</strong>
                                            {{createAddress(previousAddress?.address)}}
                                        </div>
                                    </div>

                                    <div class="col-12 border-bottom border-dark" style="display:flex">
                                        <div class="format col-sm-4" *ngIf="previousAddress?.residentialStatusDd">
                                            <strong>{{'residentialStatus_appSummary' | translate}}:</strong>
                                            {{getLanguageBasedValue(previousAddress?.residentialStatusDd, false)}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="previousAddress?.monthsAtAddress !== 0 && previousAddress?.monthsAtAddress !== undefined">
                                            <strong>{{'timeAtResidence_appSummary' | translate}}:</strong>
                                            {{formatTime(previousAddress?.monthsAtAddress)}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Logic required for the persistence of currentEmployement field-->
                            <div *ngIf="getEmployment(applicant.employmentHistory, 'Current')"></div>

                            <div
                                *ngIf="getCurrentEmployments(applicant.employmentHistory) != null && getCurrentEmployments(applicant.employmentHistory).length != 0">
                                <div
                                    *ngFor="let currentEmployment of getCurrentEmployments(applicant.employmentHistory)">
                                    <div class="col-12 border-bottom border-dark" style="display:flex">
                                        <div class="format col-sm-4" *ngIf="currentEmployment.employerName">
                                            <strong>{{'currentEmployer_appSummary' | translate}}:</strong> {{currentEmployment.employerName}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="currentEmployment.monthsOfService !== 0 && currentEmployment.monthsOfService !== undefined">
                                            <strong>{{'timeAtJob_appSummary' | translate}}:</strong>
                                            {{formatTime(currentEmployment.monthsOfService)}}
                                        </div>
                                    </div>

                                    <div class="col-12 border-bottom border-dark" style="display:flex">
                                        <div class="format col-sm-4 border-dark" *ngIf="currentEmployment.occupationDd">
                                            <strong>{{'occupation_appSummary' | translate}}:</strong> {{getLanguageBasedValue(currentEmployment.occupationDd, false)}}
                                        </div>
                                        <div class="format col-sm-4" *ngIf="currentEmployment.jobTitle">
                                            <strong>{{'jobTitle_appSummary' | translate}}:</strong> {{currentEmployment.jobTitle}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="currentEmployment.employmentHistoryTypeDd">
                                            <strong>{{'employmentType_appSummary' | translate}}:</strong>
                                            {{getLanguageBasedValue(currentEmployment.employmentHistoryTypeDd, false)}}
                                        </div>
                                    </div>

                                    <div class="col-12 border-bottom border-dark" style="display:flex">
                                        <div class="format col-sm-4"></div>
                                        <div class="format col-sm-4 border-dark"
                                            *ngIf="currentEmployment.income.incomeTypeDd">
                                            <strong>{{'incomeType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(currentEmployment.income.incomeTypeDd, false)}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="currentEmployment.income.incomeAmount">
                                            <strong>{{'annualIncome_appSummary' | translate}}:</strong>
                                            {{calculateAnnualIncome(currentEmployment.income, false) |
                                            currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Logic required for the persistence of previousEmployement field-->
                            <div *ngIf="getEmployment(applicant.employmentHistory, 'Previous')"></div>

                            <div
                                *ngIf="getPreviousEmployments(applicant.employmentHistory) != null && getPreviousEmployments(applicant.employmentHistory).length > 0">
                                <div *ngFor="let prevEmployment of getPreviousEmployments(applicant.employmentHistory)">

                                    <div class="col-12 border-bottom border-dark" style="display:flex">
                                        <div class="format col-sm-4 border-dark" *ngIf="prevEmployment.employerName">
                                            <strong>{{'previousEmployer_appSummary' | translate}}:</strong> {{prevEmployment.employerName}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="prevEmployment.monthsOfService !== 0 && prevEmployment.monthsOfService !== undefined">
                                            <strong>{{'timeAtJob_appSummary' | translate}}:</strong>
                                            {{formatTime(prevEmployment.monthsOfService)}}
                                        </div>
                                    </div>

                                    <div class="col-12 border-bottom border-dark" style="display:flex">
                                        <div class="format col-sm-4" *ngIf="prevEmployment.occupationDd">
                                            <strong>{{'occupation_appSummary' | translate}}:</strong> {{getLanguageBasedValue(prevEmployment.occupationDd, false)}}
                                        </div>
                                        <div class="format col-sm-4" *ngIf="prevEmployment.jobTitle">
                                            <strong>{{'jobTitle_appSummary' | translate}}:</strong> {{prevEmployment.jobTitle}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="prevEmployment.employmentHistoryTypeDd">
                                            <strong>{{'employmentType_appSummary' | translate}}:</strong>
                                            {{getLanguageBasedValue(prevEmployment.employmentHistoryTypeDd, false)}}
                                        </div>
                                    </div>

                                    <div class="col-12 border-bottom border-dark" style="display:flex">
                                        <div class="format col-sm-4"></div>
                                        <div class="format col-sm-4" *ngIf="prevEmployment.income?.incomeTypeDd">
                                            <strong>{{'incomeType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(prevEmployment.income.incomeTypeDd, false)}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="prevEmployment.income?.incomeAmount">
                                            <strong>{{'annualIncome_appSummary' | translate}}:</strong>
                                            {{calculateAnnualIncome(prevEmployment.income, true) |
                                            currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!-- otherIncomeFinancialLiabilities -->
            <div id="id02-pdf" class="pdfView">
                <!--Other Income-->
                <div *ngIf="primaryApplicantGroup?.applicant[0].otherIncome != null && primaryApplicantGroup?.applicant[0].otherIncome.length !== 0"
                     id="otherIncome">
                    <div id="id03-pdf">
                        <div class="col-sm-12 border-bottom border-dark">
                            <h1 class="applicant-group-paragraph">{{'otherIncome_appSummary' | translate}}</h1>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-2"><strong>Type</strong></div>
                            <div class="format col-sm-2 border-left border-dark"
                                 *ngIf="displayOtherIncomeDescriptionColumns[0]"><strong>{{'description_appSummary' | translate}}</strong>
                            </div>
                            <div class="format col-sm-2 border-left border-dark"><strong>{{'period_appSummary' | translate}}</strong></div>
                            <div class="format col-sm-2 border-left border-dark"><strong>{{'amount_appSummary' | translate}}</strong></div>
                        </div>

                        <div class="col-12 border-bottom border-dark"
                             *ngFor="let otherIncome of primaryApplicantGroup?.applicant[0].otherIncome"
                             style="display:flex">
                            <div class="format col-sm-2">{{getLanguageBasedValue(otherIncome.incomeTypeDd, false)}}</div>
                            <div class="format col-sm-2 border-left border-dark"
                                 *ngIf="displayOtherIncomeDescriptionColumns[0]">
                                {{otherIncome.incomeDescription}}
                            </div>
                            <div class="format col-sm-2 border-left border-dark">{{getLanguageBasedValue(otherIncome.incomePeriodDd, false)}}
                            </div>
                            <div class="format col-sm-2 border-left border-dark">{{otherIncome.incomeAmount |
                                currency:'USD':'symbol':'1.2-2'}}</div>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-2"></div>
                            <div class="format col-sm-2" *ngIf="displayOtherIncomeDescriptionColumns[0]"></div>
                            <div class="format col-sm-2"><strong>{{'total_appSummary' | translate}}</strong></div>
                            <div class="format col-sm-2">
                                <strong>{{caluclateTotalOtherIncome(primaryApplicantGroup?.applicant[0].otherIncome)
                                    |
                                    currency:'USD':'symbol':'1.2-2'}}</strong>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Financial-->
                <div
                        *ngIf="primaryApplicantGroup?.applicant[0].asset != null && primaryApplicantGroup?.applicant[0].asset.length !== 0">
                    <div id="id04-pdf">
                        <div class="col-sm-12">
                            <h1 class="applicant-group-paragraph">{{'financial_appSummary' | translate}}</h1>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-4"><strong>{{'assets_appSummary' | translate}}</strong></div>
                            <div class="format col-sm-4 border-left border-dark"
                                 *ngIf="displayAssetsDescriptionColumns[0]"><strong>{{'description_appSummary' | translate}}</strong></div>
                            <div class="format col-sm-2 border-left border-dark"><strong>{{'value_appSummary' | translate}}</strong></div>
                        </div>

                        <div class="col-12 border-bottom border-dark"
                             *ngFor="let asset of primaryApplicantGroup?.applicant[0].asset"
                             style="display:flex">
                            <div class="format col-sm-4">{{getLanguageBasedValue(asset.assetTypeDd, false)}}</div>
                            <div class="format col-sm-4 border-left border-dark"
                                 *ngIf="displayAssetsDescriptionColumns[0]">{{asset.assetDescription}}</div>
                            <div class="format col-sm-2 border-left border-dark">{{asset.assetValue |
                                currency:'USD':'symbol':'1.2-2'}}</div>
                        </div>

                        <div class="col-12 border-bottom border-dark" style="display:flex">
                            <div class="format col-sm-4" *ngIf="displayAssetsDescriptionColumns[0]"></div>
                            <div class="format col-sm-4"><strong>{{'total_appSummary' | translate}}</strong></div>
                            <div class="format col-sm-2">
                                <strong>{{caluclateTotalAssetValue(primaryApplicantGroup?.applicant[0].asset) |
                                    currency:'USD':'symbol':'1.2-2'}}</strong>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Liabilities-->
                <div
                        *ngIf="primaryApplicantGroup?.applicant[0].liability != null && primaryApplicantGroup?.applicant[0].liability.broker != null">

                    <div class="col-12 border-bottom border-dark" style="display:flex">
                        <div class="format col-sm-2"><strong>{{'liabiliies_appSummary' | translate}}</strong></div>
                        <div class="format col-sm-2 border-left border-dark"
                             *ngIf="displayLiabilitiesDescriptionColumns[0]"><strong>{{'description_appSummary' | translate}}</strong>
                        </div>
                        <div class="format col-sm-2 border-left border-dark" style="max-width: 100px;min-width: 100px;"><strong>{{'value_appSummary' | translate}}</strong></div>
                        <div class="format col-sm-2" style="max-width: 100px;min-width: 100px;"><strong>{{'balance_appSummary' | translate}}</strong></div>
                        <div class="format col-sm-2" style="max-width: 141px;min-width: 141px;"><strong>{{'monthlyPayment_appSummary' | translate}}</strong></div>
                        <div class="format col-sm-3 border-left border-dark" *ngIf="displayPayOffColumns[0]">
                            <strong>{{'payoff_appSummary' | translate}}</strong>
                        </div>
                    </div>

                    <div class="col-12 border-bottom border-dark"
                         *ngFor="let liability of primaryApplicantGroup?.applicant[0].liability.broker"
                         style="display:flex">
                        <div class="format col-sm-2">{{getLanguageBasedValue(liability.liabilityTypeDd, false)}}</div>
                        <div class="format col-sm-2 border-left border-dark"
                             *ngIf="displayLiabilitiesDescriptionColumns[0]">
                            {{liability.liabilityDescription}}
                        </div>
                        <div class="format col-sm-2 border-left border-dark" style="max-width: 100px;min-width: 100px;">{{liability.creditLimit |
                            currency:'USD':'symbol':'1.2-2'}}</div>
                        <div class="format col-sm-2 border-left border-dark" style="max-width: 100px;min-width: 100px;">{{liability.liabilityAmount |
                            currency:'USD':'symbol':'1.2-2'}}</div>
                        <div class="format col-sm-2 border-left border-dark" style="max-width: 141px;min-width: 141px;">{{liability.liabilityMonthlyPayment |
                            currency:'USD':'symbol':'1.2-2'}}</div>
                        <div class="format col-sm-3 border-left border-dark" *ngIf="displayPayOffColumns[0] && liability.liabilityPayOffTypeDd">
                            {{getLanguageBasedValue(liability.liabilityPayOffTypeDd, false)}}</div>
                    </div>

                    <div class="col-12 border-bottom border-dark" style="display:flex">
                        <div class="format col-sm-2" *ngIf="displayLiabilitiesDescriptionColumns[0]"></div>
                        <div class="format col-sm-2 border-left border-dark"><strong>{{'total_appSummary' | translate}}</strong></div>
                        <div class="format col-sm-2" style="max-width: 100px;min-width: 100px;">
                            <strong>{{caluclateTotalValue(primaryApplicantGroup?.applicant[0].liability.broker)
                                |
                                currency:'USD':'symbol':'1.2-2'}}</strong>
                        </div>
                        <div class="format col-sm-2" style="max-width: 100px;min-width: 100px;">
                            <strong>{{caluclateTotalBalance(primaryApplicantGroup?.applicant[0].liability.broker)
                                |
                                currency:'USD':'symbol':'1.2-2'}}</strong>
                        </div>
                        <div class="format col-sm-2">
                            <strong>{{caluclateTotalMonthlyPayment(primaryApplicantGroup?.applicant[0].liability.broker)
                                | currency:'USD':'symbol':'1.2-2'}}</strong>
                        </div>
                    </div>
                </div>
            </div>

            <!--Other Properties-->
            <div id="id06-pdf">
                <div
                        *ngIf="primaryApplicantGroup?.applicant[0].otherProperty != null && primaryApplicantGroup?.applicant[0].otherProperty.length !== 0">
                    <div class="col-sm-12">
                        <h1 class="applicant-group-paragraph">{{'otherProperties_appSummary' | translate}}</h1>
                    </div>

                    <div
                            *ngFor="let otherProperty of primaryApplicantGroup?.applicant[0].otherProperty; let i=index">
                        <div [id]="'id06-pdf-'+i">
                            <div class="format col-sm-12"
                                 *ngIf="createAddress(otherProperty?.property.address)">
                                <strong>{{'address_appSummary' | translate}}:</strong> {{ createAddress(otherProperty?.property.address)}}
                            </div>

                            <div class="format col-sm-12 border-bottom border-dark"
                                 *ngIf="otherProperty?.property.estimatedAppraisalValue">
                                <strong>{{'propertyValue_appSummary' | translate}}:</strong> {{
                                otherProperty?.property.estimatedAppraisalValue
                                    |
                                    currency:'USD':'symbol':'1.2-2'}}
                            </div>

                            <div class="composite-pdf col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-4">
                                    <strong>{{'monthlyRentalIncome_appSummary' | translate}}:</strong>
                                    {{getRentalIncome(otherProperty?.property.rentalIncome)|
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4  border-left border-dark"
                                     *ngIf="otherProperty?.property.rentalOffsetOption">
                                    <strong>{{'rentalOffsetOption_appSummary' | translate}}:</strong>
                                    {{getLanguageBasedValue(otherProperty?.property.rentalOffsetOption, false)}}
                                </div>
                            </div>

                            <div class="composite-pdf col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-6">
                                    <strong>{{'offset_appSummary' | translate}} %:</strong>
                                    {{getOffset(otherProperty?.property.rentalIncome)}}
                                </div>
                            </div>

                            <div class="col-12 composite-pdf border-bottom border-dark" style="display:flex">
                                <div class="format col-sm-4">
                                    <strong>{{'propertyTaxes_appSummary' | translate}}:</strong>
                                    {{getExpense(otherProperty?.property.propertyExpense, 'Municipal Taxes') |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark">
                                    <strong>{{'condoFees_appSummary' | translate}}:</strong>
                                    {{getExpense(otherProperty?.property.propertyExpense,
                                    'Condo Fees') | currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark">
                                    <strong>{{'heating_appSummary' | translate}}:</strong>
                                    {{getExpense(otherProperty?.property.propertyExpense,
                                    'Heating Expense') | currency:'USD':'symbol':'1.2-2'}}
                                </div>

                            </div>

                            <div class="col-12 border-bottom border-dark composite-pdf" style="display:flex">
                                <div class="format col-sm-4">
                                    <strong>{{'hydro_appSummary' | translate}}:</strong>
                                    {{getExpense(otherProperty?.property.propertyExpense,
                                    'Hydro') | currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark">
                                    <strong>{{'repairs_appSummary' | translate}}:</strong>
                                    {{getExpense(otherProperty?.property.propertyExpense,
                                    'Repairs') | currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark">
                                    <strong>{{'mgmtExpenses_appSummary' | translate}}:</strong>
                                    {{getExpense(otherProperty?.property.propertyExpense, 'Management Expenses')
                                    |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                            </div>

                            <div class="col-12 border-bottom border-dark composite-pdf" style="display:flex">
                                <div class="format col-sm-4">
                                    <strong>{{'interestCharges_appSummary' | translate}}:</strong>
                                    {{getExpense(otherProperty?.property.propertyExpense, 'Interest Charges') |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark">
                                    <strong>{{'generalExpenses_appSummary' | translate}}:</strong> {{
                                    getExpense(otherProperty?.property.propertyExpense, 'General Expenses') |
                                        currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark">
                                    <strong>{{'insurance_appSummary' | translate}}:</strong>
                                    {{getExpense(otherProperty?.property.propertyExpense,
                                    'Insurance') | currency:'USD':'symbol':'1.2-2'}}
                                </div>
                            </div>

                            <div *ngIf="otherProperty.mortgage != null && otherProperty.mortgage.length != 0">
                                <div *ngFor="let mortgage of otherProperty.mortgage">
                                    <div class="col-12 border-dark composite-pdf" style="display:flex">
                                        <div class="format col-sm-4" *ngIf="mortgage.mortgageTypeDd">
                                            <strong>{{'mortgageType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.mortgageTypeDd, false)}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                             *ngIf="mortgage.balanceRemaining">
                                            <strong>{{'mortgageBalance_appSummary' | translate}}:</strong> {{mortgage.balanceRemaining |
                                            currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                             *ngIf="mortgage.pAndIPaymentAmount">
                                            <strong>{{'frequencyPayment_appSummary' | translate}}:</strong> {{mortgage.pAndIPaymentAmount |
                                            currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                    </div>

                                    <div class="col-12 composite-pdf" style="display:flex">
                                        <div class="format col-sm-4 border-right border-dark"
                                             *ngIf="mortgage.paymentFrequencyDd">
                                            <strong>{{'paymentFrequency_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentFrequencyDd, false)}}
                                        </div>
                                    </div>

                                    <div class="col-12 border-bottom border-dark composite-pdf" style="display:flex">
                                        <div class="format col-sm-3" class="maturity-date-pdf-div" *ngIf="mortgage.maturityDate">
                                            <strong>{{'maturityDate_appSummary' | translate}}:</strong> {{mortgage.maturityDate | date:
                                            'yyyy-MM-dd'}}
                                        </div>
                                        <div class="format col-sm-3 border-left border-dark"
                                             *ngIf="mortgage.interestTypeDd">
                                            <strong>{{'rateType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.interestTypeDd, false)}}
                                        </div>
                                        <div class="format col-sm-3 border-left border-dark" *ngIf="mortgage.paymentTermDd">
                                            <strong>{{'termType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentTermDd, false)}}
                                        </div>
                                        <div class="format col-sm-3 border-left border-dark"
                                             *ngIf="mortgage.rate !=null && mortgage.rate.interestRate != null">
                                            <strong>{{'mtgInterestRate_appSummary' | translate}}:</strong>
                                            {{mortgage.rate.interestRate/100 |
                                            percent:'1.3-5'}}
                                        </div>
                                    </div>

                                    <div class="col-12 border-bottom border-dark" style="display:flex">
                                        <div class="format col-sm-6" *ngIf="mortgage.existingMortgageHolder">
                                            <strong>{{'mortgageHolder_appSummary' | translate}}:</strong>
                                            {{mortgage.existingMortgageHolder}}
                                        </div>
                                        <div class="format col-sm-3 border-left border-dark"
                                             *ngIf="mortgage.payoffTypeDd">
                                            <strong>{{'payoff_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.payoffTypeDd, false)}}
                                        </div>
                                        <div class="format col-sm-3 border-left border-dark"
                                             *ngIf="mortgage.loanTypeDd">
                                            <strong>{{'loanType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.loanTypeDd, false)}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!----------Rest of Applicat groups + Co-signor and Guarantor groups---------->
            <div *ngIf="allApplicantGroups.length">
                <div id="restOfApplicantsDataToPdf" class="pdfView">
                    <div *ngFor="let applicantGroup of allApplicantGroups; let j = index">

                        <hr class="applicantgroup-line">
                        <br />
                        <br />
                        <br />

                        <div [id]='"id07-pdf-"+j' class="pdfView">
                            <!--Primary Applicant Section-->
                            <div *ngFor="let applicant of applicantGroup?.applicant; let i = index"
                                 id="restOfApplicantGroups">

                                <div
                                        *ngIf="getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) === 'Applicant'
                                         || getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) === 0">
                                    <p class="other-applicant-group-paragraph ml-3">{{createName(applicant.name)}}</p>
                                </div>
                                <div
                                        *ngIf="getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) === 'Co Signer'
                                         || getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) === 1">
                                    <p class="other-applicant-group-paragraph ml-3">{{'coSigner_appSummary' | translate}} ({{createName(applicant.name)}})</p>
                                </div>
                                <div
                                        *ngIf="getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) === 'Guarantor'
                                         || getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) === 2">
                                    <p class="other-applicant-group-paragraph ml-3">{{'guarantor_appSummary' | translate}} ({{createName(applicant.name)}})</p>
                                </div>


                                <!-- Logic required for the persistence of currentAddress field-->
                                <div *ngIf="getCurrentAddress(applicant.addressDetail)"></div>

                                <div class="format col-sm-12 border-bottom border-dark"
                                     *ngIf="createAddress(currentAddress?.address)">
                                    <strong>{{'address_appSummary' | translate}}:</strong> {{createAddress(currentAddress?.address)}}
                                </div>

                                <div class="col-12 border-bottom border-dark" style="display:flex">
                                    <div class="format col-sm-3" *ngIf="currentAddress?.residentialStatusDd">
                                        <strong>{{'residentialStatus_appSummary' | translate}}:</strong> {{getLanguageBasedValue(currentAddress?.residentialStatusDd, false)}}
                                    </div>
                                    <div class="format col-sm-6 border-left border-dark"
                                         *ngIf="currentAddress?.monthsAtAddress">
                                        <strong>{{'timeAtResidence_appSummary' | translate}}:</strong>
                                        {{formatTime(currentAddress?.monthsAtAddress)}}
                                    </div>
                                </div>

                                <div class="col-12 border-bottom border-dark" style="display:flex">
                                    <div class="format col-sm-6" class="work-phone-pdf-div" *ngIf="getNumber(applicant.phone, 'Work')">
                                        <strong>{{'workPhone_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Work')}}
                                    </div>
                                    <div class="format col-sm-6 border-left border-dark"
                                         *ngIf="getNumber(applicant.phone, 'Cell')">
                                        <strong>{{'cellPhone_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Cell')}}
                                    </div>
                                </div>

                                <div class="col-12 border-bottom border-dark" style="display:flex">
                                    <div class="format col-sm-6"
                                         *ngIf="getNumber(applicant.phone, 'Home')">
                                        <strong>{{'homePhone_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Home')}}
                                    </div>
                                    <div class="format col-sm-6 border-left border-dark" *ngIf="getNumber(applicant.phone, 'Fax')">
                                        <strong>{{'faxNumber_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Fax')}}
                                    </div>
                                </div>

                                <div class="col-12 border-bottom border-dark" style="display:flex">
                                    <div class="format col-sm-6" *ngIf="applicant.emailAddress">
                                        <strong>{{'email_appSummary' | translate}}:</strong> {{applicant.emailAddress}}
                                    </div>
                                </div>

                                <div class="col-12 border-bottom border-dark" style="display:flex">
                                    <div class="format col-sm-4" *ngIf="applicant.maritalStatusDd">
                                        <strong>{{'maritalStatus_appSummary' | translate}}:</strong> {{getLanguageBasedValue(applicant.maritalStatusDd, false)}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark" *ngIf="applicant.birthDate">
                                        <strong>{{'dateOfBirth_appSummary' | translate}}:</strong> {{applicant.birthDate | date: 'yyyy-MM-dd'}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark"
                                         *ngIf="applicant.numberOfDependents != null || applicant.numberOfDependents != undefined">
                                        <strong>{{'dependents_appSummary' | translate}}:</strong> {{applicant.numberOfDependents}}
                                    </div>
                                </div>

                                <div class="col-12 border-bottom border-dark" style="display:flex">
                                    <div class="format col-sm-4" *ngIf="applicant.socialInsuranceNumber">
                                        <strong>{{'sin_appSummary' | translate}}:</strong>
                                        {{formatPhoneNumberAndSin(applicant.socialInsuranceNumber)}}
                                    </div>
                                </div>

                                <!-- Logic required for the persistence of previousAddress field-->
                                <div *ngIf="getPreviousAddress(applicant.addressDetail)"></div>

                                <div *ngIf="previousAddresses !== null && previousAddresses.length != 0">
                                    <div *ngFor="let previousAddress of previousAddresses">
                                        <div class="format col-sm-12 border-bottom border-dark"
                                             *ngIf="createAddress(previousAddress?.address)">
                                            <strong>{{'previousAddress_appSummary' | translate}}:</strong>
                                            {{createAddress(previousAddress?.address)}}
                                        </div>

                                        <div class="col-12 border-bottom border-dark" style="display:flex">
                                            <div class="format col-sm-3" *ngIf="previousAddress?.residentialStatusDd">
                                                <strong>{{'residentialStatus_appSummary' | translate}}:</strong>
                                                {{getLanguageBasedValue(previousAddress?.residentialStatusDd, false)}}
                                            </div>
                                            <div class="format col-sm-6 border-left border-dark"
                                                 *ngIf="previousAddress?.monthsAtAddress !== 0 && previousAddress?.monthsAtAddress !== undefined">
                                                <strong>{{'timeAtResidence_appSummary' | translate}}:</strong>
                                                {{formatTime(previousAddress?.monthsAtAddress)}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Logic required for the persistence of currentEmployement field-->
                                <div *ngIf="getEmployment(applicant.employmentHistory, 'Current')"></div>

                                <div>
                                    <div *ngFor="let currentEmployment of currentEmployments">
                                        <div class="col-12 border-bottom border-dark" style="display:flex">
                                            <div class="format col-sm-6" *ngIf="currentEmployment.employerName">
                                                <strong>{{'currentEmployer_appSummary' | translate}}:</strong> {{currentEmployment.employerName}}
                                            </div>
                                            <div class="format col-sm-3 border-left border-dark"
                                                 *ngIf="currentEmployment.monthsOfService !== 0 && currentEmployment.monthsOfService !== undefined">
                                                <strong>{{'timeAtJob_appSummary' | translate}}:</strong>
                                                {{formatTime(currentEmployment.monthsOfService)}}
                                            </div>
                                        </div>

                                        <div class="col-12 border-bottom border-dark" style="display:flex">
                                            <div class="format col-sm-4" *ngIf="currentEmployment.occupationDd">
                                                <strong>{{'occupation_appSummary' | translate}}:</strong> {{getLanguageBasedValue(currentEmployment.occupationDd, false)}}
                                            </div>
                                            <div class="format col-sm-4" *ngIf="currentEmployment.jobTitle">
                                                <strong>{{'jobTitle_appSummary' | translate}}:</strong> {{currentEmployment.jobTitle}}
                                            </div>
                                            <div class="format col-sm-4 border-left border-dark"
                                                 *ngIf="currentEmployment.employmentHistoryTypeDd">
                                                <strong>{{'employmentType_appSummary' | translate}}:</strong>
                                                {{getLanguageBasedValue(currentEmployment.employmentHistoryTypeDd, false)}}
                                            </div>
                                        </div>

                                        <div class="col-12 border-bottom border-dark" style="display:flex">
                                            <div class="format col-sm-4"></div>
                                            <div class="format col-sm-4" *ngIf="currentEmployment.income.incomeTypeDd">
                                                <strong>{{'incomeType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(currentEmployment.income.incomeTypeDd, false)}}
                                            </div>
                                            <div class="format col-sm-4 border-left border-dark"
                                                 *ngIf="currentEmployment.income.incomeAmount">
                                                <strong>{{'annualIncome_appSummary' | translate}}:</strong>
                                                {{calculateAnnualIncome(currentEmployment.income, false) |
                                                currency:'USD':'symbol':'1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Logic required for the persistence of previous Employement field-->
                                <div *ngIf="getEmployment(applicant.employmentHistory, 'Previous')"></div>

                                <div *ngIf="previousEmployments != null && previousEmployments.length > 0">
                                    <div *ngFor="let prevEmployment of previousEmployments">

                                        <div class="col-12 border-bottom border-dark" style="display:flex">
                                            <div class="format col-sm-6" *ngIf="prevEmployment.employerName">
                                                <strong>{{'previousEmployer_appSummary' | translate}}:</strong> {{prevEmployment.employerName}}
                                            </div>
                                            <div class="format col-sm-3 border-left border-dark"
                                                 *ngIf="prevEmployment.monthsOfService !== 0 && prevEmployment.monthsOfService !== undefined">
                                                <strong>{{'timeAtJob_appSummary' | translate}}:</strong>
                                                {{formatTime(prevEmployment.monthsOfService)}}
                                            </div>
                                        </div>

                                        <div class="col-12 border-bottom border-dark">
                                            <div class="format col-sm-4" *ngIf="prevEmployment.occupationDd">
                                                <strong>{{'occupation_appSummary' | translate}}:</strong> {{getLanguageBasedValue(prevEmployment.occupationDd, false)}}
                                            </div>
                                            <div class="format col-sm-4" *ngIf="prevEmployment.jobTitle">
                                                <strong>{{'jobTitle_appSummary' | translate}}:</strong> {{prevEmployment.jobTitle}}
                                            </div>
                                            <div class="format col-sm-4 border-left border-dark"
                                                 *ngIf="prevEmployment.employmentHistoryTypeDd">
                                                <strong>{{'employmentType_appSummary' | translate}}:</strong>
                                                {{getLanguageBasedValue(prevEmployment.employmentHistoryTypeDd, false)}}
                                            </div>
                                        </div>

                                        <div *ngIf="prevEmployment.income" class="col-12 border-bottom border-dark" style="display:flex">
                                            <div class="format col-sm-4"></div>
                                            <div class="format col-sm-4" *ngIf="prevEmployment.income.incomeTypeDd">
                                                <strong>{{'incomeType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(prevEmployment.income.incomeTypeDd, false)}}
                                            </div>
                                            <div class="format col-sm-4 border-left border-dark"
                                                 *ngIf="prevEmployment.income.incomeAmount">
                                                <strong>{{'annualIncome_appSummary' | translate}}:</strong>
                                                {{calculateAnnualIncome(prevEmployment.income, true) |
                                                currency:'USD':'symbol':'1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div [id]='"id08-pdf-"+j' class="pdfView">
                            <!--Other Income-->
                            <div
                                    *ngIf="applicantGroup?.applicant[0].otherIncome != null && applicantGroup?.applicant[0].otherIncome.length !== 0">
                                <div class="col-sm-12">
                                    <h1 class="applicant-group-paragraph">{{'otherIncome_appSummary' | translate}}</h1>
                                </div>

                                <div class="col-12 border-bottom border-dark" style="display:flex">
                                    <div class="format col-sm-2"><strong>{{'type_appSummary' | translate}}</strong></div>
                                    <div class="format col-sm-2 border-left border-dark"
                                         *ngIf="displayOtherIncomeDescriptionColumns[j+1]"><strong>{{'description_appSummary' | translate}}</strong>
                                    </div>
                                    <div class="format col-sm-2 border-left border-dark"><strong>{{'period_appSummary' | translate}}</strong></div>
                                    <div class="format col-sm-2 border-left border-dark"><strong>{{'amount_appSummary' | translate}}</strong></div>
                                </div>

                                <div class="col-12 border-bottom border-dark"
                                     *ngFor="let otherIncome of applicantGroup?.applicant[0].otherIncome"
                                     style="display:flex">
                                    <div class="format col-sm-2">{{getLanguageBasedValue(otherIncome.incomeTypeDd, false)}}</div>
                                    <div class="format col-sm-2 border-left border-dark"
                                         *ngIf="displayOtherIncomeDescriptionColumns[j+1]">
                                        {{otherIncome.incomeDescription}}
                                    </div>
                                    <div class="format col-sm-2 border-left border-dark">{{getLanguageBasedValue(otherIncome.incomePeriodDd, false)}}
                                    </div>
                                    <div class="format col-sm-2 border-left border-dark">{{otherIncome.incomeAmount |
                                        currency:'USD':'symbol':'1.2-2'}}</div>
                                </div>

                                <div class="col-12 border-bottom border-dark" style="display:flex">
                                    <div class="format col-sm-2"></div>
                                    <div class="format col-sm-2" *ngIf="displayOtherIncomeDescriptionColumns[j+1]"></div>
                                    <div class="format col-sm-2"><strong>{{'total_appSummary' | translate}}</strong></div>
                                    <div class="format col-sm-2">
                                        <strong>{{caluclateTotalOtherIncome(applicantGroup?.applicant[0].otherIncome) |
                                            currency:'USD':'symbol':'1.2-2'}}</strong>
                                    </div>
                                </div>
                            </div>

                            <!--Financial-->
                            <div
                                    *ngIf="applicantGroup?.applicant[0].asset != null && applicantGroup?.applicant[0].asset.length !== 0">
                                <div class="col-sm-12">
                                    <h1 class="applicant-group-paragraph">{{'financial_appSummary' | translate}}</h1>
                                </div>

                                <div class="col-12 border-bottom border-dark" style="display:flex">
                                    <div class="format col-sm-4"><strong>{{'assets_appSummary' | translate}}</strong></div>
                                    <div class="format col-sm-4 border-left border-dark"
                                         *ngIf="displayAssetsDescriptionColumns[j+1]"><strong>{{'description_appSummary' | translate}}</strong></div>
                                    <div class="format col-sm-2 border-left border-dark"><strong>{{'value_appSummary' | translate}}</strong></div>
                                </div>

                                <div class="col-12 border-bottom border-dark"
                                     *ngFor="let asset of applicantGroup?.applicant[0].asset" style="display:flex">
                                    <div class="format col-sm-4">{{getLanguageBasedValue(asset.assetTypeDd, false)}}</div>
                                    <div class="format col-sm-4 border-left border-dark"
                                         *ngIf="displayAssetsDescriptionColumns[j+1]">{{asset.assetDescription}}</div>
                                    <div class="format col-sm-2 border-left border-dark">{{asset.assetValue |
                                        currency:'USD':'symbol':'1.2-2'}}</div>
                                </div>

                                <div class="col-12 border-bottom border-dark" style="display:flex">
                                    <div class="format col-sm-4" *ngIf="displayAssetsDescriptionColumns[j+1]"></div>
                                    <div class="format col-sm-4"><strong>{{'total_appSummary' | translate}}</strong></div>
                                    <div class="format col-sm-2">
                                        <strong>{{caluclateTotalAssetValue(applicantGroup?.applicant[0].asset) |
                                            currency:'USD':'symbol':'1.2-2'}}</strong>
                                    </div>
                                </div>
                            </div>

                            <!--Liabilities-->
                            <div
                                    *ngIf="applicantGroup?.applicant[0].liability != null && applicantGroup?.applicant[0].liability.broker != null">
                                <div class="col-12 border-bottom border-dark" style="display:flex">
                                    <div class="format col-sm-2"><strong>{{'liabiliies_appSummary' | translate}}</strong></div>
                                    <div class="format col-sm-4 border-left border-dark"
                                         *ngIf="displayLiabilitiesDescriptionColumns[j+1]"><strong>Description</strong>
                                    </div>
                                    <div class="format col-sm-2 border-left border-dark" style="max-width: 100px;min-width: 100px;"><strong>{{'value_appSummary' | translate}}</strong></div>
                                    <div class="format col-sm-2" style="max-width: 100px;min-width: 100px;"><strong>{{'balance_appSummary' | translate}}</strong></div>
                                    <div class="format col-sm-2" style="max-width: 141px;min-width: 141px;"><strong>{{'monthlyPayment_appSummary' | translate}}</strong></div>
                                    <div class="format col-sm-2" *ngIf="displayPayOffColumns[j+1]">
                                        <strong>{{'payoff_appSummary' | translate}}</strong> 
                                    </div>
                                </div>

                                <div class="col-12 border-bottom border-dark"
                                     *ngFor="let liability of applicantGroup?.applicant[0].liability.broker"
                                     style="display:flex">
                                    <div class="format col-sm-2">{{getLanguageBasedValue(liability.liabilityTypeDd, false)}}</div>
                                    <div class="format col-sm-4 border-left border-dark"
                                         *ngIf="displayLiabilitiesDescriptionColumns[j+1]">
                                        {{liability.liabilityDescription}}
                                    </div>
                                    <div class="format col-sm-2 border-left border-dark" style="max-width: 100px;min-width: 100px;">{{liability.creditLimit |
                                        currency:'USD':'symbol':'1.2-2'}}</div>
                                    <div class="format col-sm-2 border-left border-dark" style="max-width: 100px;min-width: 100px;">{{liability.liabilityAmount |
                                        currency:'USD':'symbol':'1.2-2'}}</div>
                                    <div class="format col-sm-2 border-left border-dark" style="max-width: 141px;min-width: 141px;">{{liability.liabilityMonthlyPayment
                                        | currency:'USD':'symbol':'1.2-2'}}</div>
                                    <div class="format col-sm-2 border-left border-dark"
                                         *ngIf="displayPayOffColumns[j+1] && liability.liabilityPayOffTypeDd">
                                        {{getLanguageBasedValue(liability.liabilityPayOffTypeDd, false)}}</div>
                                </div>

                                <div class="col-sm-12 border-bottom border-dark" style="display:flex">
                                    <div class="format col-sm-2"></div>
                                    <div class="format col-sm-4" *ngIf="displayLiabilitiesDescriptionColumns[j+1]"></div>
                                    <div class="format col-sm-3">
                                        <strong>{{'total_appSummary' | translate}}</strong>
                                    </div>
                                    <div class="format col-sm-2" style="max-width: 100px;min-width: 100px;">
                                        <strong>{{caluclateTotalValue(applicantGroup?.applicant[0].liability.broker) |
                                            currency:'USD':'symbol':'1.2-2'}}</strong>
                                    </div>
                                    <div class="format col-sm-2" style="max-width: 100px;min-width: 100px;">
                                        <strong>{{caluclateTotalBalance(applicantGroup?.applicant[0].liability.broker) |
                                            currency:'USD':'symbol':'1.2-2'}}</strong>
                                    </div>
                                    <div class="format col-sm-2">
                                        <strong>{{caluclateTotalMonthlyPayment(applicantGroup?.applicant[0].liability.broker)
                                            | currency:'USD':'symbol':'1.2-2'}}</strong>
                                    </div>
                                </div>
                            </div>

                            <!--Other Properties-->
                            <div
                                    *ngIf="applicantGroup?.applicant[0].otherProperty != null && applicantGroup?.applicant[0].otherProperty.length !== 0">
                                <div class="col-sm-12">
                                    <h1 class="applicant-group-paragraph">{{'otherProperties_appSummary' | translate}}</h1>
                                </div>

                                <div *ngFor="let otherProperty of applicantGroup?.applicant[0].otherProperty">
                                    <div class="format col-sm-12"
                                         *ngIf="createAddress(otherProperty?.property.address)">
                                        <strong>{{'address_appSummary' | translate}}:</strong> {{createAddress(otherProperty?.property.address)}}
                                    </div>

                                    <div class="format col-sm-12 border-bottom border-dark"
                                         *ngIf="otherProperty?.property.estimatedAppraisalValue">
                                        <strong>{{'propertyValue_appSummary' | translate}}:</strong> {{
                                        otherProperty?.property.estimatedAppraisalValue
                                            | currency:'USD':'symbol':'1.2-2'}}
                                    </div>

                                    <div class="composite-pdf col-sm-12 border-bottom  border-dark">
                                        <div class="format col-sm-4">
                                            <strong>{{'monthlyRentalIncome_appSummary' | translate}}:</strong>
                                            {{getRentalIncome(otherProperty?.property.rentalIncome)|
                                            currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                        <div class="format col-sm-4  border-left border-dark"
                                             *ngIf="otherProperty?.property.rentalOffsetOption">
                                            <strong>{{'rentalOffsetOption_appSummary' | translate}}:</strong>
                                            {{getLanguageBasedValue(otherProperty?.property.rentalOffsetOption, false)}}
                                        </div>
                                    </div>

                                    <div class="composite-pdf col-sm-12 border-bottom  border-dark">
                                        <div class="format col-sm-6">
                                            <strong>{{'offset_appSummary' | translate}} %:</strong>
                                            {{getOffset(otherProperty?.property.rentalIncome)}}
                                        </div>
                                    </div>

                                    <div class="col-12 border-dark  composite-pdf" style="display:flex">
                                        <div class="format col-sm-4">
                                            <strong>{{'propertyTaxes_appSummary' | translate}}:</strong>
                                            {{getExpense(otherProperty?.property.propertyExpense, 'Municipal Taxes') |
                                            currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark">
                                            <strong>{{'condoFees_appSummary' | translate}}:</strong>
                                            {{getExpense(otherProperty?.property.propertyExpense, 'Condo Fees') |
                                            currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark">
                                            <strong>{{'heating_appSummary' | translate}}:</strong>
                                            {{getExpense(otherProperty?.property.propertyExpense,
                                            'Heating Expense') | currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                    </div>

                                    <div class="col-12 border-dark  composite-pdf" style="display:flex">
                                        <div class="format col-sm-4">
                                            <strong>{{'hydro_appSummary' | translate}}:</strong>
                                            {{getExpense(otherProperty?.property.propertyExpense,
                                            'Hydro') | currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark">
                                            <strong>{{'repairs_appSummary' | translate}}:</strong>
                                            {{getExpense(otherProperty?.property.propertyExpense,
                                            'Repairs') | currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark">
                                            <strong>{{'mgmtExpenses_appSummary' | translate}}:</strong>
                                            {{getExpense(otherProperty?.property.propertyExpense, 'Management Expenses')
                                            |
                                            currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                    </div>

                                    <div class="col-12 border-bottom border-dark  composite-pdf" style="display:flex">
                                        <div class="format col-sm-4">
                                            <strong>{{'interestCharges_appSummary' | translate}}:</strong>
                                            {{getExpense(otherProperty?.property.propertyExpense, 'Interest Charges') |
                                            currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                        <div class="format col-sm-4">
                                            <strong>{{'generalExpenses_appSummary' | translate}}:</strong> {{
                                            getExpense(otherProperty?.property.propertyExpense, 'General Expenses') |
                                                currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                        <div class="format col-sm-4">
                                            <strong>{{'insurance_appSummary' | translate}}:</strong>
                                            {{getExpense(otherProperty?.property.propertyExpense, 'Insurance') |
                                            currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                    </div>

                                    <div *ngIf="otherProperty.mortgage != null && otherProperty.mortgage.length != 0">
                                        <div *ngFor="let mortgage of otherProperty.mortgage">
                                            <div class="col-12 border-bottom border-dark composite-pdf" style="display:flex">
                                                <div class="format col-sm-4" *ngIf="mortgage.mortgageTypeDd">
                                                    <strong>{{'mortgageType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.mortgageTypeDd, false)}}
                                                </div>
                                                <div class="format col-sm-4 border-left border-dark"
                                                     *ngIf="mortgage.balanceRemaining">
                                                    <strong>{{'mortgageBalance_appSummary' | translate}}:</strong> {{mortgage.balanceRemaining |
                                                    currency:'USD':'symbol':'1.2-2'}}
                                                </div>
                                                <div class="format col-sm-4 border-left border-dark"
                                                     *ngIf="mortgage.pAndIPaymentAmount">
                                                    <strong>{{'frequencyPayment_appSummary' | translate}}:</strong> {{mortgage.pAndIPaymentAmount}}
                                                </div>
                                            </div>

                                            <div class="col-12 border-dark composite-pdf" style="display:flex">
                                                <div class="format col-sm-4" *ngIf="mortgage.paymentFrequencyDd">
                                                    <strong>{{'paymentFrequency_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentFrequencyDd, false)}}
                                                </div>
                                            </div>

                                            <div class="col-12 border-bottom border-dark composite-pdf" style="display:flex">
                                                <div class="format col-sm-3" class="maturity-date-pdf-div" *ngIf="mortgage.maturityDate">
                                                    <strong>{{'maturityDate_appSummary' | translate}}:</strong> {{mortgage.maturityDate | date:
                                                    'yyyy-MM-dd'}}
                                                </div>
                                                <div class="format col-sm-3 border-left border-dark"
                                                     *ngIf="mortgage.interestTypeDd">
                                                    <strong>{{'rateType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.interestTypeDd, false)}}
                                                </div>
                                                <div class="format col-sm-3 border-left border-dark"
                                                     *ngIf="mortgage.paymentTermDd">
                                                    <strong>{{'termType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentTermDd, false)}}
                                                </div>
                                                <div class="format col-sm-3 border-left border-dark"
                                                     *ngIf="mortgage.rate !=null && mortgage.rate.interestRate != null">
                                                    <strong>{{'mtgInterestRate_appSummary' | translate}}:</strong>
                                                    {{mortgage.rate.interestRate/100 |
                                                    percent:'1.3-5'}}
                                                </div>
                                            </div>

                                            <div class="col-12 border-bottom border-dark" style="display:flex">
                                                <div class="format col-sm-6" *ngIf="mortgage.existingMortgageHolder">
                                                    <strong>{{'mortgageHolder_appSummary' | translate}}:</strong>
                                                    {{mortgage.existingMortgageHolder}}
                                                </div>
                                                <div class="format col-sm-3 border-left border-dark"
                                                     *ngIf="mortgage.payoffTypeDd">
                                                    <strong>{{'payoff_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.payoffTypeDd, false)}}
                                                </div>
                                                <div class="format col-sm-3 border-left border-dark"
                                                     *ngIf="mortgage.loanTypeDd">
                                                    <strong>{{'loanType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.loanTypeDd, false)}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>