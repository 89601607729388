import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from 'src/app/services/security/auth.service';
import {
    ComparePassword
} from "../new-password/compare-pass";
class CrossFieldErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.touched && control.invalid;
    }
}

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
    public changePassForm: FormGroup;
    public fieldTextTypeNewPass: boolean;
    public fieldTextTypeReEnter: boolean;
    public entersNewPass: boolean = false;
    public passWordsMatch: boolean = false;
    public newPassValidation: any = {
        hasLowerCase: false, hasUpperCase: false, hasSpecialCharacter: false, hasNumber: false, hasEightLetters: false
    };
    public text: string;
    public isFromForgotPassword = false;
    constructor(private formBuilder: FormBuilder, private authService: AuthService,  private elementRef: ElementRef) {
        this.changePassForm = this.formBuilder.group({
            pass1: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@!%*?&])[A-Za-z\d$@$!%*?&].{7,}$')]],
            pass2: ['', [Validators.required]]
        },
        {
            validator: ComparePassword('pass1', 'pass2')
        });
    }

    ngAfterViewInit(){
        this.elementRef.nativeElement.ownerDocument.body.style.background = "url('../assets/login-backgroung.png') center center fixed";
        this.elementRef.nativeElement.ownerDocument.body.style.width='100%'
        this.elementRef.nativeElement.ownerDocument.body.style.height='auto'
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundRepeat='no-repeat'
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundSize='cover'
     }

    toggleNewPassType() {
        this.fieldTextTypeNewPass = !this.fieldTextTypeNewPass;
    }

    toggleReEnterType() {
        this.fieldTextTypeReEnter = !this.fieldTextTypeReEnter;
    }

    newPass(event: any, controlName) {
        if (event) {
            var value = event.target.value;
            if (value.match(/^\s* {1}$/)) {
                value = value.substring(0, value.length - 1);
            }
            else if (value.length >= 2 && value.substring(value.length - 1, value.length).match(/^\s* {1}$/)) {
                value = value.substring(0, value.length - 1);
            }
            this.changePassForm.get(controlName).setValue(value);
            this.changePassForm.get(controlName).markAsTouched();
            if (controlName == 'pass1') {
                this.entersNewPass = true;
                this.newPassValidation.hasLowerCase = this.changePassForm.get(controlName).value.match('.*[a-z].*') ? true : false;
                this.newPassValidation.hasUpperCase = this.changePassForm.get(controlName).value.match('.*[A-Z].*') ? true : false;
                this.newPassValidation.hasNumber = this.changePassForm.get(controlName).value.match('.*[0-9].*') ? true : false;
                this.newPassValidation.hasSpecialCharacter = this.changePassForm.get(controlName).value.match(/[$@!%*?&]+/) ? true : false;
                this.newPassValidation.hasEightLetters = this.changePassForm.get(controlName).value.length > 7? true : false;
            } else if (this.changePassForm.get(controlName).valid) {
                this.entersNewPass = false;
                this.passWordsMatch = this.changePassForm.get(controlName).value == this.changePassForm.get('pass1').value ? true : false;
            }
        }
    }

    changePassword(){
        this.authService.changePassFirstTime(this.changePassForm.get('pass2').value);
    }

    ngOnInit() {
    }
}
