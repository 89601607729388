import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LoansService} from '../../services/api/loans.service';
import {MatDialog} from '@angular/material/dialog';
import html2PDF from 'html2pdf.js';
import {PromptModel} from '../../models/prompt.model';
import {PromptsMessages} from '../common/constants/promptsMessages';
import {DecisionPromptComponent} from '../common/decision-prompt/decision-prompt.component';
import {LoanDecision} from '../../models/loan-decision.model';
import {DTOLoanApplication} from '../../models/DTOLoanApplication';
import {GeneralPromptComponent} from '../common/general-prompt/general-prompt.component';
import {IsLoadingService} from 'src/app/services/is-loading.service';
import {LoanStatusEnum} from 'src/app/models/commons/loan-status-enum';
import {SnackbarComponent} from '../common/snackbar/snackbar.component';
import {LenderLanguage} from 'src/app/models/lender-language';
import {LanguageService} from 'src/app/services/language-service/language.service';
import {InsurerSettings} from '../common/constants/insurer';
import {AuthGuardService} from 'src/app/services/security/auth-guard.service';
import {MultiTabEvents, MultiTabNotification} from "../../services/mutli-tab-notification.service";
import {AuthService} from "../../services/security/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabChangeEvent } from "@angular/material/tabs";

@Component({
    selector: 'app-application-summary-print',
    templateUrl: './application-summary-print.component.html',
    styleUrls: ['./application-summary-print.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ApplicationSummaryPrintComponent implements OnInit {

    public loanApplication: any;
    public subjectProperty: any;
    public applicantGroups: any[] = [];
    public guarantorGroups: any[] = [];
    public coSignorGroups: any[] = [];
    public allApplicantGroups: any[] = [];
    public primaryApplicantGroup: any;
    public currentAddress = null;
    public previousAddresses: any[] = [];
    public currentEmployments = null;
    public previousEmployments: any[] = [];
    public agentContact = null;
    public agentAddress = null;
    public firmAddress = null;
    public firmName = null;
    public originatorNotes = null;
    public selectedIndex = 0;
    public mortgage: any;
    public searchMortgage: any;
    public displayApplicantData = false;
    public displayFinancingData = false;
    public displayPropertyData = false;
    public displayOriginatorDetails = true;
    public displayPayOffColumns: any[] = [];
    public displayLiabilitiesDescriptionColumns: any[] = [];
    public displayAssetsDescriptionColumns: any[] = [];
    public displayOtherIncomeDescriptionColumns: any[] = [];

    public searchDeal: any;
    public subjectProperties: any[] = [];
    public existingMortgages: any[] = [];
    public downPaymentSourceVariable: any[] = [];
    public totalAmount: number;
    public concurrentMortgages: any[] = [];
    public refinanceSwiftETOMortgages: any[] = [];

    public documentPDF: any;
    public pdfName: string;
    @Output() sendPromptText: EventEmitter<any> = new EventEmitter<any>();
    @Output() sendDialogueBoxText: EventEmitter<any> = new EventEmitter<any>();
    public id: any;
    public applicationDecision: any;
    public applicationDecisionChip: any;
    public activeLoanDecision: any;
    public isLoanApproved: boolean = false;
    public isLoanFunded: boolean = false;
    public isLoanDeclined: boolean = false;
    public wasLoanApproved: boolean = false;
    public wasLoanApprovedTwice: boolean = false;
    public wasLoanDeclined: boolean = false;
    public wasLoanDeclinedTwice: boolean = false;
    public isEligibleForRemove: boolean = false;
    public removedDeal: boolean = false;
    public infoHoverMessage: string;
    public wasPreviousStatusDeclined: boolean = false;
    public previousStatus: any;
    public allDataFetched: boolean = false;
    public canBeApproved: boolean;
    public canBeFunded: boolean;
    public canBeDeclined: boolean;
    public loanStatus: string;
    public addressProvince: string;
    public languageSelected: string;
    public agentProvince: string;
    public subjectPropertyProvince: string;

    constructor(private loansService: LoansService, private route: ActivatedRoute,
        public dialog: MatDialog, private isLoadingService: IsLoadingService, 
        private snackBarCustom: SnackbarComponent, private snackBar: MatSnackBar, 
        private languageService: LanguageService, private authGuardService: AuthGuardService,
                private authService: AuthService,
                private multiTabNotification: MultiTabNotification) {
        const that = this;
        const fromQueryParams = window.location.search.match('lang=([^&]+)');
        const defLang = fromQueryParams ? fromQueryParams[1] : 'en';
        this.languageService.setLanguagePreference(defLang);
        this.multiTabNotification.subscribe(MultiTabEvents.LOAN_DECISION_UPDATE_LENDERS_VIEW, () => {
            that.updateLoanInfo(that.id);
        });
    }

    public mapStatusesEnglishFrench = new Map<string, string>();

    async ngOnInit() {
        this.languageSelected = this.languageService.getLanguage();

        this.mapStatusesEnglishFrench.set("New", "Nouvelle");
        this.mapStatusesEnglishFrench.set("Review", "Réviser");
        this.mapStatusesEnglishFrench.set("Reject", "Rejeter");
        this.mapStatusesEnglishFrench.set("In Review", "En revue");
        this.mapStatusesEnglishFrench.set("Decline", "Refuser");
        this.mapStatusesEnglishFrench.set("Declined", "Refusée");
        this.mapStatusesEnglishFrench.set("Funded", "Fonds déboursés");
        this.mapStatusesEnglishFrench.set("Approved", "Approuvée");
        this.mapStatusesEnglishFrench.set("Remove", "Eliminer");
        this.mapStatusesEnglishFrench.set("Rejected", "Rejeté");
        this.mapStatusesEnglishFrench.set("Removed", "Eliminé");

        var id;

        this.route.queryParams
            .subscribe(params => {
                id = params['id'];
                this.id = id;
                this.activeLoanDecision = params['activeLoanDecision'];
            });

        this.updateLoanInfo(id);
    }

    private updateLoanInfo(id: any){
        this.loansService.getLoanById(id).subscribe(response => {
            const loan = response;
            this.loanStatus = loan.status.toString()
            this.applicationDecisionChip = LoanStatusEnum[this.loanStatus];
            this.applicationDecisionChip == "In_Review" ? this.applicationDecisionChip = "In Review" : this.applicationDecisionChip;

            if (loan.activeLoanDecision != "null" && loan.activeLoanDecision != null) {
                this.activeLoanDecision = loan.activeLoanDecision;
                this.applicationDecision = LoanStatusEnum[loan.status.toString()];
                this.previousStatus = loan.previousStaus != undefined ? LoanStatusEnum[loan.previousStaus.toString()] : undefined;
            }

            this.isEligibleForRemove = loan.isEligibleForRemove;
            this.getApplicationSumarry(loan.loanApplication);
            this.setStateForDecisionButtons(loan.possibleStatuses);
        });
    }

    getApplicationDecisionChip() {
        if (this.languageService.getLanguage() === 'fr') {
            return this.mapStatusesEnglishFrench.get(this.applicationDecisionChip);
        }
        return this.applicationDecisionChip;
    }

    getApplicationSumarry(loanApplication: any) {
        this.loanApplication = loanApplication;
        this.findSubjectProperty(loanApplication);
        this.findPrimaryApplicant(loanApplication.applicantGroup);
        this.populateDisplayCollumnArrays(loanApplication.applicantGroup);
        this.populateGroups(loanApplication.applicantGroup);
        this.findMortgage(loanApplication.mortgage);
        this.findDeal(loanApplication.deal);
        this.populateSubjectProperties(loanApplication.subjectProperty);
        this.populateDownPaymentSources(loanApplication.deal.downPaymentSource);
        this.populateMortgageConcurrent(loanApplication.mortgage.concurrent);
        this.populateExistingMortgages(loanApplication.subjectProperty[0].existingMortgage);
        this.populateRefinanceSwitchETOMortgages(loanApplication.subjectProperty[0].existingMortgage);
        this.populateOriginatorDetails(loanApplication.deal, loanApplication.mortgage);

        if (this.isEligibleForRemove) {
            this.infoHoverMessage = PromptsMessages.translate("QUESTION_MARK_RESUBMITED_1", this.languageService.getLanguage()) + this.searchDeal.applicationId + PromptsMessages.translate("QUESTION_MARK_RESUBMITED_2", this.languageService.getLanguage());
        }

        /*
        * Flag used for persisting the loader on the screen untill data is received form API
        */
        this.allDataFetched = true;
        this.isLoadingService.remove();
        this.subjectPropertyProvince = this.subjectProperty.address.provinceDd[0].text;
    }

    setStateForDecisionButtons(possibleStatuses: any[]) {
        this.canBeApproved = false;
        this.canBeFunded = false;
        this.canBeDeclined = false;

        possibleStatuses.forEach(ps => {
            switch (ps) {
                case 3: {
                    this.canBeApproved = true;
                    break;
                }
                case 4: {
                    this.canBeFunded = true;
                    break;
                }
                case 5: {
                    this.canBeDeclined = true;
                    break;
                }
            }
        });
    }

    populateOriginatorDetails(deal: any, mortgage: any) {
        this.agentContact = deal.agentProfile.contact;
        this.agentAddress = deal.agentProfile.contact.address.address1Type;
        this.agentProvince = this.agentAddress.provinceDd[0].text;
        this.firmAddress = deal.firmProfile.contact.address.address1Type;
        this.firmName = deal.firmProfile.firmName;
        this.originatorNotes = mortgage.requested.lenderSubmission.originatorNotes;
    }

    public setIndex(index: any) {
        this.selectedIndex = index;
    }

    public onTabChange(event: MatTabChangeEvent) {
        switch (event.index) {
            case 0: {
                this.displayOriginatorDetails = true;
                this.displayPropertyData = false;
                this.displayFinancingData = false;
                this.displayApplicantData = false;
                break;
            }
            case 1: {
                this.displayOriginatorDetails = false;
                this.displayPropertyData = true;
                this.displayFinancingData = false;
                this.displayApplicantData = false;
                break;
            }
            case 2: {
                this.displayOriginatorDetails = false;
                this.displayPropertyData = false;
                this.displayFinancingData = true;
                this.displayApplicantData = false;
                break;
            }
            case 3: {
                this.displayOriginatorDetails = false;
                this.displayPropertyData = false;
                this.displayFinancingData = false;
                this.displayApplicantData = true;
                break;
            }
        }
    }

    findSubjectProperty(applicationSumarry) {
        for (var subjProperty of applicationSumarry.subjectProperty) {
            subjProperty.property.subjectPropertyFlag === "Y" ? this.subjectProperty = subjProperty.property : this.subjectProperty = null;
        }
    }


    findPrimaryApplicant(applicantGroups: any) {
        //In all casses the first applicant Group holds the primary applicant 
        this.primaryApplicantGroup = applicantGroups[0];

    }

    populateDisplayCollumnArrays(applicantGroups: any[]) {
        //Logic required for the decision of displaying the description and Payoff Column
        for (var applicantGroup of applicantGroups) {
            this.displayColumns(applicantGroup.applicant[0].otherIncome, "otherIncomeDescription");
            this.displayColumns(applicantGroup.applicant[0].asset, "assetsDescription");
            this.displayColumns(applicantGroup.applicant[0].liability == null ? null : applicantGroup.applicant[0].liability.broker, "liabilitiesDescription");
            this.displayColumns(applicantGroup.applicant[0].liability == null ? null : applicantGroup.applicant[0].liability.broker, "payOff");
        }
    }

    populateGroups(applicantGroups: any[]) {
        for (var applicantGroup of applicantGroups) {
            if (this.getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) == "Applicant" || this.getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) == "0") {
                //logic for recovering all applicantGroups = Applicat exluding the applicantGroup which contains the PRIMARY Applicant
                for (var applicant of applicantGroup.applicant) {
                    if (applicant.primaryApplicantFlag != "Y") {
                        this.allApplicantGroups.push(applicantGroup);
                        break;
                    } else {
                        break;
                    }
                }
            }
            if (this.getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) == "Co Signer" || this.getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) == "1") {
                this.allApplicantGroups.push(applicantGroup);
            }
            if (this.getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) == "Guarantor" || this.getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) == "2") {
                this.allApplicantGroups.push(applicantGroup);
            }

        }
    }

    findMortgage(mortgage: any) {
        this.searchMortgage = mortgage;
        this.searchMortgage.requested.actualPaymentTerm = this.formatTime(mortgage.requested.actualPaymentTerm);
        this.searchMortgage.requested.amortizationTerm = this.formatTime(mortgage.requested.amortizationTerm);

        if (mortgage.requested.interestOnlyFlag == "Y") {
            this.searchMortgage.requested.interestOnlyFlag = this.languageService.getLanguage() === LenderLanguage.EN ? "Interest Only" : "Intérêt seulement";
        }
        else {
            this.searchMortgage.requested.interestOnlyFlag = this.languageService.getLanguage() === LenderLanguage.EN ? "Principal and Interest" : "Principal et des intérêts";
        }
    }

    findDeal(deal: any) {
        this.searchDeal = deal;
    }

    populateSubjectProperties(subjectPropertiesVariable: any[]) {
        for (var subjectProperty of subjectPropertiesVariable) {
            if (subjectProperty.property.propertyValueIndexId === "P") {
                this.subjectProperties.push(subjectProperty.property.purchasePrice);
            }

            if (subjectProperty.property.propertyValueIndexId === "E") {
                this.subjectProperties.push(subjectProperty.property.estimatedAppraisalValue);
            }

            if (subjectProperty.property.propertyValueIndexId === "A") {
                this.subjectProperties.push(subjectProperty.property.actualAppraisalValue);
            }
        }

    }

    populateDownPaymentSources(downPaymentSourceList: any[]) {
        this.downPaymentSourceVariable = downPaymentSourceList;

        if (this.downPaymentSourceVariable != undefined) {
            let total = 0;
            for (var i = 0; i < downPaymentSourceList.length; i++) {
                total += downPaymentSourceList[i].amount;
            }
            this.totalAmount = total;
        } else {
            this.totalAmount = null;
        }

    }

    populateExistingMortgages(existingMortgages: any[]) {
        if (existingMortgages != null && existingMortgages.length > 0) {
            for (var mortgage of existingMortgages) {
                if (mortgage.payoffTypeDd == undefined || mortgage.payoffTypeDd == null) {
                    this.existingMortgages.push(mortgage);
                }
            }
        }

    }


    populateRefinanceSwitchETOMortgages(swiftMortgages: any[]) {
        if (swiftMortgages != null && swiftMortgages.length > 0) {
            for (var mortgage of swiftMortgages) {
                if (mortgage.payoffTypeDd != undefined || mortgage.payoffTypeDd != null) {
                    this.refinanceSwiftETOMortgages.push(mortgage);
                }
            }
        }
    }

    populateMortgageConcurrent(concurrentMortgage: any[]) {
        this.concurrentMortgages = concurrentMortgage;
    }

    verifyValue(value: any, isForDropdown: boolean): string {
        if(value == undefined || value == null){
            return "";
        }
        if(isForDropdown){
            return this.getLanguageBasedValue(value, false);
        }

        return value;
    }

    getLanguageBasedValue(dropdownList: any[], onlyEnglishValue: boolean) {
        if (this.languageService.getLanguage() === LenderLanguage.EN
            || this.languageService.getLanguage() == null  || onlyEnglishValue === true || dropdownList.length === 1){
            const enText = dropdownList.find(element => element.language === 'en-ca');
            return enText ? enText.text : '';
       }

        const findFr = dropdownList.find(element => element.language === 'fr-ca');
        if (!findFr && dropdownList.length) {
            return dropdownList[0].text || '';
        }
        return findFr ? findFr.text : '';
    }

    verifyUnitNumber(unitNumber: any): string {
        return !unitNumber ? "" : " Unit " + unitNumber;
    }

    verifyPhoneExtension(phoneExt: any): string {
        return !phoneExt ? "" : phoneExt;
    }

    convertValue(value: any): string {
        if(this.languageService.getLanguage() === LenderLanguage.EN){
            return value == "Y" ? "Yes" : "No";
        }
        return value == "Y" ? "Oui" : "Non";
    }

    checkExpense(propertyExpenses: any[], expenseTypeDd: string): boolean {
        if (propertyExpenses != undefined && propertyExpenses != null) {
            for (var propertyExpense of propertyExpenses) {
                if (this.getLanguageBasedValue(propertyExpense.propertyExpenseTypeDd, true) == expenseTypeDd) {
                    return true;
                }
            }
        }
        return false;
    }

    getExpense(propertyExpenses: any[], expenseTypeDd: string): string {
        if (propertyExpenses != undefined) {
            for (var propertyExpense of propertyExpenses) {
                if (this.getLanguageBasedValue(propertyExpense.propertyExpenseTypeDd, true) == expenseTypeDd) {
                    return propertyExpense.propertyExpenseAmount;
                }
            }
        }
        return "0";
    }

    getTotalExpenses(propertyExpenses: any[]) {
        var totalExpense = 0;
        if (propertyExpenses != undefined) {
            for (var propertyExpense of propertyExpenses) {
                if (this.getLanguageBasedValue(propertyExpense.propertyExpenseTypeDd, true) != "Condo Fees") {
                    if (this.getLanguageBasedValue(propertyExpense.propertyExpenseTypeDd, true) == "Municipal Taxes") {
                        totalExpense += (propertyExpense.propertyExpenseAmount / 12);
                    } else {
                        totalExpense += propertyExpense.propertyExpenseAmount;
                    }
                }
            }
        }
        return totalExpense
    }

    getRentalIncome(rentalIncome: any) {
        if (rentalIncome != null && rentalIncome != undefined && rentalIncome.incomeAmount != null) {
            return rentalIncome.incomeAmount;
        }

        return 0;
    }

    getCurrentAddress(addresses: any[]): any {
        this.currentAddress = null;
        for (var a of addresses) {
            if (this.getLanguageBasedValue(a.addressTypeDd, true) == "Current") {
                this.currentAddress = a;
            }
        }
    }

    formatTime(monthsAtResidence: number) {
        var timeAtResidence = null;

        if (monthsAtResidence != undefined) {
            timeAtResidence += Math.floor(monthsAtResidence / 12);
            var years = timeAtResidence;
            if (timeAtResidence > 1) {
                timeAtResidence += PromptsMessages.translate("YEARS", this.languageService.getLanguage());
            } else if (timeAtResidence == 1) {
                timeAtResidence += PromptsMessages.translate("YEAR", this.languageService.getLanguage());
            } else {
                timeAtResidence = "";
            }

            var remainingMonths = monthsAtResidence % 12;
            if (remainingMonths == 0) {
                return timeAtResidence;
            } else {
                if (years != 0) {
                    timeAtResidence += PromptsMessages.translate("AND", this.languageService.getLanguage());
                    if (remainingMonths == 1) {
                        timeAtResidence += remainingMonths + PromptsMessages.translate("MONTH", this.languageService.getLanguage());
                    } else {
                        timeAtResidence += remainingMonths + PromptsMessages.translate("MONTHS", this.languageService.getLanguage());
                    }
                } else {
                    if (remainingMonths == 1) {
                        timeAtResidence += remainingMonths + PromptsMessages.translate("MONTH", this.languageService.getLanguage());
                    } else {
                        timeAtResidence += remainingMonths + PromptsMessages.translate("MONTHS", this.languageService.getLanguage());
                    }
                    timeAtResidence += remainingMonths + PromptsMessages.translate("MONTHS", this.languageService.getLanguage());
                }

            }
        }
        return timeAtResidence;
    }

    getPreferredLanguage(){
        let preferredLanguage = this.languageService.getLanguage();
        return preferredLanguage == undefined ? "en" : preferredLanguage;
    }
    

    getNumber(phoneNumbers: any[], phoneType: string) {
        if (phoneNumbers != undefined && phoneNumbers != null && phoneNumbers.length >= 1) {
            let phone = phoneNumbers.find(e => this.getLanguageBasedValue(e.phoneTypeDd, true) == phoneType);
            if (phone == undefined) {
                return false;
            }
            if (phoneType == "Work") {
                var formatedPhoneNumber = this.formatPhoneNumberAndSin(phone.phoneNumber);

                return phone.phoneExtension == null ? formatedPhoneNumber : formatedPhoneNumber + " x" + phone.phoneExtension;
            } else if (phoneType != "Work") {
                return this.formatPhoneNumberAndSin(phone.phoneNumber);
            }
        }
        return false;
    }

    formatPhoneNumberAndSin(unformatedPhoneNumberOrSin: string): string {
        var formatedPhoneNumberOrSin = [unformatedPhoneNumberOrSin.slice(0, 3), "-", unformatedPhoneNumberOrSin.slice(3)].join('');
        return [formatedPhoneNumberOrSin.slice(0, 7), "-", formatedPhoneNumberOrSin.slice(7)].join('');
    }

    getPreviousAddress(addresses: any[]): any {
        this.previousAddresses = addresses.filter(a => this.getLanguageBasedValue(a.addressTypeDd,true) == "Previous");
    }

    getEmployment(employmentHistory: any[], employmentType: string) {
        this.currentEmployments = [];
        this.previousEmployments = [];

        if (employmentHistory != null) {
            switch (employmentType) {
                case "Current": {
                    this.currentEmployments = employmentHistory.filter(eh => this.getLanguageBasedValue(eh.employmentHistoryStatusDd, true) == "Current");
                    break;
                }
                case "Previous": {
                    this.previousEmployments = employmentHistory.filter(eh => this.getLanguageBasedValue(eh.employmentHistoryStatusDd, true) == "Previous");
                    break;
                }
            }
        }
    }

    getCurrentEmployments(employmentHistory: any[]) {
        if (employmentHistory != null) {
            return employmentHistory.filter(eh => this.getLanguageBasedValue(eh.employmentHistoryStatusDd, true) == "Current");
        }
        return null;
    }

    getPreviousEmployments(employmentHistory: any[]) {
        if (employmentHistory != null) {
            return employmentHistory.filter(eh => this.getLanguageBasedValue(eh.employmentHistoryStatusDd, true) == "Previous");
        }
        return null;
    }

    calculateAnnualIncome(income: any, isPreviousEmployment: boolean): number {
        var annualIncome: number;

        /*
        *Condition needed for prev employment (some prev empl do not have incomePeriodDd)
        */
        if(isPreviousEmployment && income.incomePeriodDd == null){
            return income.incomeAmount;
        }
        
        switch (this.getLanguageBasedValue(income.incomePeriodDd, true)) {
            case "Annuel":
            case "Annual": {
                annualIncome = income.incomeAmount * 1;
                break;
            }
            case "Semestriel":
            case "Semi-Annual": {
                annualIncome = income.incomeAmount * 2;
                break;
            }
            case "Trimestriel":
            case "Quarterly": {
                annualIncome = income.incomeAmount * 4;
                break;
            }
            case "Mensuel":
            case "Monthly": {
                annualIncome = income.incomeAmount * 12;
                break;
            }
            case "Semi-mensuelle":
            case "Semi-Monthly": {
                annualIncome = income.incomeAmount * 24;
                break;
            }
            case "Bihebdomadaire":
            case "Bi-Weekly": {
                annualIncome = income.incomeAmount * 26;
                break;
            }
            case "Hebdomadaire":
            case "Weekly": {
                annualIncome = income.incomeAmount * 52;
                break;
            }
        }

        if (income.incomePeriodDd == null || income.incomePeriodDd == undefined) {
            annualIncome = income.incomeAmount;
        }

        return annualIncome;
    }

    caluclateTotalOtherIncome(otherIncome: any[]): number {
        var totalIncome = 0;

        if (otherIncome != null) {
            otherIncome.forEach(i => totalIncome += i.annualIncomeAmount);
        }
        return totalIncome;
    }

    caluclateTotalAssetValue(assets: any[]): number {
        var totalValue = 0;

        if (assets != null) {
            assets.forEach(a => totalValue += a.assetValue);
        }
        return totalValue;
    }

    caluclateTotalValue(liabilities: any[]): number {
        var totalValue = 0;

        if (liabilities != null) {
            liabilities.forEach(l => totalValue += l.creditLimit);
        }
        return totalValue;
    }

    caluclateTotalBalance(liabilities: any[]): number {
        var totalValue = 0;

        if (liabilities != null) {
            liabilities.forEach(l => totalValue += l.liabilityAmount);
        }
        return totalValue;
    }

    caluclateTotalMonthlyPayment(liabilities: any[]): number {
        var totalValue = 0;

        if (liabilities != null) {
            liabilities.forEach(l => totalValue += l.liabilityMonthlyPayment);
        }
        return totalValue;
    }

    createAddress(address: any) {
        this.addressProvince = this.firmAddress.provinceDd[0].text;
        var formatedAddress = undefined;

        if (address == null) {
            return undefined;
        }

        if (address.streetNumber != undefined) {
            formatedAddress = address.streetNumber;
        }


        if(this.languageService.getLanguage() === LenderLanguage.EN.toLowerCase()){
            if (address.streetName != undefined) {
                formatedAddress += " " + address.streetName;
            }
            if (address.streetTypeDd != undefined) {
                formatedAddress += " " + this.getLanguageBasedValue(address.streetTypeDd, false) + ",";
            }
        }else{
            if (address.streetTypeDd != undefined) {
                formatedAddress += " " + this.getLanguageBasedValue(address.streetTypeDd, false);
            }
            if (address.streetName != undefined) {
                formatedAddress += " " + address.streetName + ",";
            }
        }
        
        if (address.streetDirectionDd != undefined) {
            formatedAddress += " " + this.getLanguageBasedValue(address.streetDirectionDd, false);
        }
        formatedAddress += this.verifyUnitNumber(address.unitNumber);
        if (address.city != undefined) {
            formatedAddress += " " + address.city + ",";
        }
        if (address.provinceDd != undefined) {
            formatedAddress += " " + this.getLanguageBasedValue(address.provinceDd, false);
        }
        if (address.postalFsa != undefined) {
            formatedAddress += " " + address.postalFsa;
        }
        if (address.postalLdu != undefined) {
            formatedAddress += " " + address.postalLdu;
        }

        return formatedAddress == "undefined" ? undefined : formatedAddress;
    }

    onToPdf() {
        //TODO: add implementation in jira-6352
    }

    checkPurchaseValue(property: any) {
        if (property !== undefined && property !== null) {
            switch (property.propertyValueIndexId) {
                case "P": {
                    return property.purchasePrice;
                }
                case "E": {
                    return property.estimatedAppraisalValue;
                }
                case "A": {
                    return property.actualAppraisalValue;
                }
            }
        }


        return undefined;
    }

    calculateTotalDownPayments() {
        var total = 0;
        if (this.downPaymentSourceVariable != null) {
            for (var downPayment of this.downPaymentSourceVariable) {
                total += downPayment.amount;
            }
            return total;
        }

        return "";

    }

    displayColumns(array: any[], column: string) {
        if (array != null && array.length != 0) {
            switch (column) {
                case "otherIncomeDescription": {
                    for (var otherIncome of array) {
                        if (otherIncome.incomeDescription != undefined) {
                            this.displayOtherIncomeDescriptionColumns.push(true);
                            return;
                        }
                    }
                    this.displayOtherIncomeDescriptionColumns.push(false);
                    break;
                }
                case "assetsDescription": {
                    for (var asset of array) {
                        if (asset.assetDescription != undefined) {
                            this.displayAssetsDescriptionColumns.push(true);
                            return;
                        }
                    }
                    this.displayAssetsDescriptionColumns.push(false);
                    break;
                }
                case "liabilitiesDescription": {
                    for (var liability of array) {
                        if (liability.liabilityDescription != undefined) {
                            this.displayLiabilitiesDescriptionColumns.push(true);
                            return;
                        }
                    }
                    this.displayLiabilitiesDescriptionColumns.push(false);
                    break;
                }
                case "payOff": {
                    for (var liability of array) {
                        if (liability.liabilityPayOffTypeDd != undefined) {
                            this.displayPayOffColumns.push(true);
                            return;
                        }
                    }
                    this.displayPayOffColumns.push(false);
                    break;
                }
            }
        }
    }

    getOffset(rentalIncome: any) {
        if (rentalIncome != null) {
            if (rentalIncome.incPercentInGds != null && rentalIncome.incPercentInGds != "0") {
                return rentalIncome.incPercentInGds;
            } else if (rentalIncome.incPercentInTds && rentalIncome.incPercentInTds != "0") {
                return rentalIncome.incPercentInTds;
            }
        }
        return "0";
    }

    public downloadPDF() {
        let financingData = document.getElementById('financingDataToPdf');
        let propertyData = document.getElementById('displayPropertyDataToPdf');
        let originatorDetails = document.getElementById('originator-details-to-pdf');

        let applicant = document.getElementById('id01-pdf');
        let otherIncomeFinancialLiabilities = document.getElementById('id02-pdf');
        let otherProperties = document.getElementById('id06-pdf');

        let opt = {
            margin: [5, 5, 0, 5],
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { dpi: 192, letterRendering: true, scrollY: 0 },
            filename: 'ApplicationSummary' + '.pdf',
            pagebreak: { mode: ['css', 'legacy'] }
        };

        let appSummary = html2PDF().set(opt).from(originatorDetails).toPdf().get('pdf')
            .then(pdf => pdf.addPage()
            ).from(propertyData).toContainer().toCanvas().toPdf().get('pdf')
            .then(pdf => pdf.addPage()
            ).from(financingData).toContainer().toCanvas().toPdf().get('pdf')
            .then(pdf => pdf.addPage()
            ).from(applicant).toContainer().toCanvas().toPdf().get('pdf')
            .then(pdf => pdf.addPage()
            ).from(otherIncomeFinancialLiabilities).toContainer().toCanvas().toPdf().get('pdf')
            .then(pdf => pdf.addPage());

        if (this.allApplicantGroups.length > 0) {
            appSummary = appSummary.from(otherProperties).toContainer().toCanvas().toPdf().get('pdf')
                .then(pdf => pdf.addPage());

            for (let i = 0; i < this.allApplicantGroups.length; i++) {
                let restPrimaryApplicant = document.getElementById('id07-pdf-' + i);
                appSummary = appSummary.from(restPrimaryApplicant).toContainer().toCanvas().toPdf().get('pdf')
                    .then(pdf => pdf.addPage());

                if (i == this.allApplicantGroups.length - 1) {
                    let restOtherIncomeFinancial = document.getElementById('id08-pdf-' + i);
                    appSummary = appSummary.from(restOtherIncomeFinancial).toContainer().toCanvas().toPdf().get('pdf');
                } else {
                    let restOtherIncomeFinancial = document.getElementById('id08-pdf-' + i);
                    appSummary = appSummary.from(restOtherIncomeFinancial).toContainer().toCanvas().toPdf().get('pdf')
                        .then(pdf => pdf.addPage());
                }
            }
        } else {
            appSummary = appSummary.from(otherProperties).toContainer().toCanvas().toPdf().get('pdf');
        }

        appSummary.save();
    }
    
    public removeDeal() {
        var promptInfo = new PromptModel([{ content: PromptsMessages.translate("REMOVE_MYDEALS", this.languageService.getLanguage()), isTextBold: false }], true, true, '', 
        PromptsMessages.translate("DIALOGUE_BOX_BUTTON_REMOVE", this.languageService.getLanguage()), 
        PromptsMessages.translate("DIALOGUE_BOX_BUTTON_CANCEL", this.languageService.getLanguage()), true, false, false, false, false);
        this.sendPromptText.emit(promptInfo);
        let dialogRef = this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });

        dialogRef.afterOpened().subscribe(() => {
            dialogRef.componentInstance.onOk.subscribe(() => {
                var newLoanApplication = new DTOLoanApplication(this.id, this.searchDeal.applicationId, "Removed", this.authService.profileDetails.fk_LenderAccount, true);
                this.loansService.updateLoan(newLoanApplication).subscribe(() => { });
                this.removedDeal = true;
            });

            dialogRef.componentInstance.onCancel.subscribe(() => { });
        });
    }

    createName(name: any) {
        var processedName = "";

        if (name.salutationDd !== undefined && name.salutationDd !== null) {
            processedName += this.getLanguageBasedValue(name.salutationDd, false) + " ";
        }
        if (name.firstName !== undefined && name.firstName !== null) {
            processedName += name.firstName + " ";
        }
        if (name.lastName !== undefined && name.lastName !== null) {
            processedName += name.lastName + " ";
        }
        if (name.suffixDd !== undefined && name.suffixDd !== null) {
            processedName += this.getLanguageBasedValue(name.suffixDd, false);
        }

        return processedName;
    }

    async getLoanDecision(element) {
        return await this.loansService.getLoanDecisionById(element.activeLoanDecision).toPromise();
    }

    async getUpdatedLoanDecision(id) {
        return await this.loansService.getLoanDecisionById(id).toPromise();
    }

    public async onApprove() {
        var promptInfo: PromptModel;
        if (this.activeLoanDecision != "null" && this.activeLoanDecision != null) {
            const loanDecisionResponse = await this.loansService.getLoanDecisionById(
                this.activeLoanDecision ).toPromise();
            this.documentPDF = loanDecisionResponse.loanDecision.response.commitment ? loanDecisionResponse.loanDecision.response.commitment.documentPDF.documentImage : "";
            this.pdfName = loanDecisionResponse.pdfName;
        } else {
            this.documentPDF = "";
        }
        var dialogueInfo = new PromptModel([{
            loanStatus: "Approved", dialogBoxTitle: PromptsMessages.translate("APPROVED_MYDEALS_TITLE", this.languageService.getLanguage()),
            isAttachedDocument: true,
            attachedDocumentText: PromptsMessages.translate("DIALOGUE_BOX_ATTACH_DOCUMENT", this.languageService.getLanguage()),
            isAttachedLetter: true,
            attachedLetterText: PromptsMessages.translate("DIALOGUE_BOX_ATTACH_LETTER", this.languageService.getLanguage()),
            attachedNotesText: PromptsMessages.translate("DIALOGUE_BOX_ATTACH_NOTES", this.languageService.getLanguage()),
            fileExtensionError: PromptsMessages.translate("DIALOGUE_BOX_FILE_EXTENSION_ERROR", this.languageService.getLanguage()),
            pdfSizeError: PromptsMessages.translate("DIALOGUE_BOX_PDF_SIZE_ERROR", this.languageService.getLanguage()),
            notesLengthError: PromptsMessages.translate("DIALOGUE_BOX_NOTES_LENGTH_ERROR", this.languageService.getLanguage()),
            pdf: this.documentPDF,
            pdfName: this.pdfName
        }], true, true, '',
            PromptsMessages.translate("DIALOGUE_BOX_BUTTON_CANCEL", this.languageService.getLanguage()),
        PromptsMessages.translate("DIALOGUE_BOX_BUTTON_APPROVE", this.languageService.getLanguage()), false, false, false, false, false);
        this.sendDialogueBoxText.emit(dialogueInfo);
        let dialogueBoxRef = this.dialog.open(DecisionPromptComponent, { autoFocus: false, data: dialogueInfo });
        dialogueBoxRef.afterOpened().subscribe(() => {
            dialogueBoxRef.componentInstance.onApprove.subscribe(async () => {
                dialogueBoxRef.afterClosed().subscribe(async data => {
                    if (this.activeLoanDecision === "null" || this.activeLoanDecision == null) {
                        this.loansService.addLoanDecision(data.file, new LoanDecision(this.id, this.loanApplication.deal.applicationId, "Approved", data.notes, null, null, "", false))
                            .subscribe(async (result) => {
                                this.updateLoanInfo(this.id);
                                this.snackBarCustom.openSnackBar(PromptsMessages.translate("APPLICATION_WAS", this.languageService.getLanguage()) + this.getApplicationDecision("Approved"));
                                this.multiTabNotification.trigger(MultiTabEvents.LOAN_DECISION_UPDATE_APP_SUMMARY);
                            });
                    }
                    else {
                        this.loansService.updateLoanDecision(data.file, new LoanDecision(this.id, this.loanApplication.deal.applicationId, "Approved", data.notes, "", data.pdfName, "", false))
                            .subscribe(async (result) => {
                                this.updateLoanInfo(this.id);
                                this.snackBarCustom.openSnackBar(PromptsMessages.translate("APPLICATION_WAS", this.languageService.getLanguage()) + this.getApplicationDecision("Approved"));
                                this.multiTabNotification.trigger(MultiTabEvents.LOAN_DECISION_UPDATE_APP_SUMMARY);
                            });
                    }
                });
            });
        });
    }

    public onDecline(event: any) {
        var dialogueInfo = new PromptModel([{
            loanStatus: "Declined", dialogBoxTitle: PromptsMessages.translate("DECLINE_MYDEALS_TITLE", this.languageService.getLanguage()),
            isAttachedDocument: false,
            attachedDocumentText: PromptsMessages.translate("DIALOGUE_BOX_ATTACH_DOCUMENT", this.languageService.getLanguage()),
            isAttachedLetter: false,
            attachedLetterText: PromptsMessages.translate("DIALOGUE_BOX_ATTACH_LETTER", this.languageService.getLanguage()),
            attachedNotesText: PromptsMessages.translate("DIALOGUE_BOX_ATTACH_NOTES", this.languageService.getLanguage()),
            fileExtensionError: PromptsMessages.translate("DIALOGUE_BOX_FILE_EXTENSION_ERROR", this.languageService.getLanguage()),
            pdfSizeError: PromptsMessages.translate("DIALOGUE_BOX_PDF_SIZE_ERROR", this.languageService.getLanguage()),
            notesLengthError: PromptsMessages.translate("DIALOGUE_BOX_NOTES_LENGTH_ERROR", this.languageService.getLanguage()), pdf: "",
            listDropDownForDecline: true
        }], true, true, '', PromptsMessages.translate("DIALOGUE_BOX_BUTTON_CANCEL", this.languageService.getLanguage()),
        PromptsMessages.translate("DIALOGUE_BOX_BUTTON_SUBMIT", this.languageService.getLanguage()), false, false, false, false, false);
        this.sendDialogueBoxText.emit(dialogueInfo);
        let dialogueBoxRef = this.dialog.open(DecisionPromptComponent, { autoFocus: false, data: dialogueInfo });
        dialogueBoxRef.afterOpened().subscribe(() => {
            dialogueBoxRef.componentInstance.onApprove.subscribe(() => {
                dialogueBoxRef.afterClosed().subscribe(data => {
                    this.loansService.addLoanDecision(data.file, new LoanDecision(this.id, this.loanApplication.deal.applicationId, "Declined", data.notes, "", "", data.denialReason, false))
                        .subscribe(async (result) => {
                            this.updateLoanInfo(this.id);
                            this.snackBarCustom.openSnackBar(PromptsMessages.translate("APPLICATION_WAS", this.languageService.getLanguage()) +  this.getApplicationDecision("Declined"));
                            this.multiTabNotification.trigger(MultiTabEvents.LOAN_DECISION_UPDATE_APP_SUMMARY);
                        });
                })
            })
        });
    }

    public async onFund() {
        var updatedLoan = await this.getUpdatedLoanDecision(this.activeLoanDecision);
        var promptInfo: PromptModel;
        promptInfo = new PromptModel([{ content: PromptsMessages.translate("FUNDED_MYDEALS", this.languageService.getLanguage()), isTextBold: false }], true, true, '', PromptsMessages.translate("YES", this.languageService.getLanguage()), PromptsMessages.translate("NO", this.languageService.getLanguage()), false, false, false, false, false);
        this.sendPromptText.emit(promptInfo);
        let dialogRef = this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
        dialogRef.afterOpened().subscribe(() => {
            dialogRef.componentInstance.onOk.subscribe(() => {
                var newLoanApplication = new DTOLoanApplication(this.id, this.loanApplication.deal.applicationId, "Funded", this.authService.profileDetails.fk_LenderAccount, true);
                this.loansService.updateLoan(newLoanApplication).subscribe((response) => {
                    this.updateLoanInfo(this.id);
                    this.snackBarCustom.openSnackBar(PromptsMessages.translate("APPLICATION_WAS", this.languageService.getLanguage()) +  this.getApplicationDecision("Funded"));
                    this.multiTabNotification.trigger(MultiTabEvents.LOAN_DECISION_UPDATE_APP_SUMMARY);
                })
            })
        });
    }

    public messageToBroker() {
        var dialogueInfo = new PromptModel([{
            loanStatus: "Approved", dialogBoxTitle: PromptsMessages.translate("IN_REVIEW_APP_SUMMARY", this.languageService.getLanguage()),
            isAttachedDocument: false,
            attachedDocumentText: PromptsMessages.translate("DIALOGUE_BOX_ATTACH_DOCUMENT", this.languageService.getLanguage()),
            isAttachedLetter: false,
            attachedLetterText: PromptsMessages.translate("DIALOGUE_BOX_ATTACH_LETTER", this.languageService.getLanguage()),
            attachedNotesText: PromptsMessages.translate("DIALOGUE_BOX_ATTACH_NOTES_MESSAGE", this.languageService.getLanguage()),
            fileExtensionError: PromptsMessages.translate("DIALOGUE_BOX_FILE_EXTENSION_ERROR", this.languageService.getLanguage()),
            pdfSizeError: PromptsMessages.translate("DIALOGUE_BOX_PDF_SIZE_ERROR", this.languageService.getLanguage()),
            notesLengthError: PromptsMessages.translate("DIALOGUE_BOX_NOTES_LENGTH_ERROR", this.languageService.getLanguage()), pdf: "",
            messageToBroker: true
        }], true, true, '', PromptsMessages.translate("DIALOGUE_BOX_BUTTON_CANCEL", this.languageService.getLanguage()), 
        PromptsMessages.translate("DIALOGUE_BOX_BUTTON_SEND_MESSAGE", this.languageService.getLanguage()), false, false, false, false, false);
        this.sendDialogueBoxText.emit(dialogueInfo);
        let dialogueBoxRef = this.dialog.open(DecisionPromptComponent, { autoFocus: false, data: dialogueInfo });

        dialogueBoxRef.afterOpened().subscribe(() => {
            dialogueBoxRef.componentInstance.onApprove.subscribe(() => {
                dialogueBoxRef.afterClosed().subscribe(data => {
                    this.loansService.addLoanDecision(data.file, new LoanDecision(this.id, this.loanApplication.deal.applicationId, "In Review", data.notes, "", data.pdfName, "", true)).subscribe(result => {
                        this.multiTabNotification.trigger(MultiTabEvents.LOAN_DECISION_UPDATE_APP_SUMMARY);
                        this.snackBarCustom.openSnackBar(PromptsMessages.translate("MESSAGE_TO_BROKER_SENT", this.languageService.getLanguage()));
                    });
                });
            });
        });
    }

    getDealNotes() {
        return this.originatorNotes == undefined || this.originatorNotes == null ? false : this.originatorNotes;
    }

    getInsurer(insurer: any){
        let languageBasedInsurer = this.getLanguageBasedValue(insurer, false); 
        if(isNaN(languageBasedInsurer)){
            return languageBasedInsurer;
        }

        return InsurerSettings.insurer[this.languageService.getLanguage().toLowerCase()][languageBasedInsurer];
    }

    getApplicationDecision(decision: any) {
        if (this.languageService.getLanguage() === "fr") {
            return this.mapStatusesEnglishFrench.get(decision);
        }
        return decision;
    }
}
