export class PropertyAddressReportCSVDTO {
    ReceivedLoanID: string;
    Date: Date;
    LenderCode: string;
    LenderName: string;
    PropertyAddress: string

    constructor(applicationId: string, date: Date, lenderCode: string, lenderName: string, propertyAddress: string) {
        this.ReceivedLoanID = applicationId;
        this.Date = date;
        this.LenderCode = lenderCode;
        this.LenderName = lenderName;
        this.PropertyAddress = propertyAddress;
    }

}