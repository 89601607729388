import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { CookieService } from 'ngx-cookie-service';
import { GeneralPromptComponent } from 'src/app/components/common/general-prompt/general-prompt.component';
import { PromptModel } from 'src/app/models/prompt.model';
import { ErrorService } from '../error/error.service';
import { AuthGuardService } from '../security/auth-guard.service';
import { AuthService } from '../security/auth.service';
import { Observable } from 'rxjs/internal/Observable';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';

@Injectable({
  providedIn: 'root'
})
export class SessionExtensionService {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  constructor( private errorService: ErrorService, private dialog: MatDialog, 
    private router: Router, private authGuardService: AuthGuardService,private authService: AuthService,
    private idle: Idle,private keepalive: Keepalive,private cookiesService: CookieService) { }

  onIdle(){
    //Session extension handling
    var idleTime = 1500; //PROD value: 25 min
    var timeoutTime = 300; //PROD value: 5 min
    // sets the idle timeout.
    this.idle.setIdle(idleTime);
    // sets a timeout period. after the inactivity period, the user will be considered timed out.
    this.idle.setTimeout(timeoutTime);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.'
        this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        this.timedOut = true;
        this.authService.profileDetails = null;
        this.authService.signOut();
    });

      this.idle.onIdleStart.subscribe(() => {
      let source$: Observable<Event>;
      //Session extension prompt should not appear when in login page
      if(this.router.url != "/login"){
        this.idleState = 'You\'ve gone idle!';
        let promptInfo = new PromptModel(null, true, false, '/', '', '', false, true, false, false, false);
        promptInfo.isRedirectedViaHref = false;
        promptInfo.route = null;
        let dialogRef = this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
        let source$: Observable<Event>;
        source$ = fromEvent(window, 'storage');
        source$.subscribe(
            (ldata: StorageEvent) => {
                if (ldata.key == 'ACTION_NAME' && ldata.newValue.startsWith("SessionExtensionState")) {
                    dialogRef.close();
                }
            }
        );
          dialogRef.afterOpened().subscribe(() => {
            dialogRef.componentInstance.onOk.subscribe(() => {
              localStorage.setItem('ACTION_NAME', "SessionExtensionState_" + new Date().getTime());
            if (this.cookiesService.get('IdToken') != null) {
                this.authService.renewToken();
            }
            this.reset();
            });
          });

        setTimeout(() => { dialogRef.close() }, timeoutTime*1000);
      }
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;       
}
}
