import { Injectable, ErrorHandler } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class AddressApiService {

    url = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3ex.ws?Key=zy72-zk67-zm25-gb73';

    constructor(private http: HttpClient, private handleError: ErrorHandler ) {
    }

    getAddressComplete(term: string): Observable<any[]> {

        let paramsOptions = new HttpParams().set('SearchTerm', term);
        paramsOptions = paramsOptions.set('LastId', '');
        paramsOptions = paramsOptions.set('SearchFor', 'Everything');
        paramsOptions = paramsOptions.set('Country', 'CAN');
        paramsOptions = paramsOptions.set('LanguagePreference', 'en, fr');
        paramsOptions = paramsOptions.set('MaxSuggestions', '10');
        paramsOptions = paramsOptions.set('MaxResults', '10');

        let searchURL = `${this.url}&${paramsOptions.toString()}?`;
    
        return this.http.get<any[]>(searchURL);
    }

    getAddresses(lastId: string): Observable<any[]> {

        let paramsOptions = new HttpParams().set('SearchTerm', '');
        paramsOptions = paramsOptions.set('LastId', lastId);
        paramsOptions = paramsOptions.set('SearchFor', 'Everything');
        paramsOptions = paramsOptions.set('Country', 'CAN');
        paramsOptions = paramsOptions.set('LanguagePreference', 'en, fr');
        paramsOptions = paramsOptions.set('MaxSuggestions', '30');
        paramsOptions = paramsOptions.set('MaxResults', '30');

        let searchURL = `${this.url}&${paramsOptions.toString()}?`;

        return this.http.get<any[]>(searchURL);
    }
   
}
