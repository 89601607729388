import { EventEmitter, Injectable, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PromptsMessages } from "src/app/components/common/constants/promptsMessages";
import { GeneralPromptComponent } from "src/app/components/common/general-prompt/general-prompt.component";
import { LenderLanguage } from "src/app/models/lender-language";
import { LenderInfoDTO } from "src/app/models/LenderInfoDTO.model";
import { ProfileRole } from "src/app/models/profile-role.model";
import { PromptModel } from "src/app/models/prompt.model";
import { LenderService } from "../api/lender.service";
import { LanguageService } from "../language-service/language.service";
import { AuthService } from "../security/auth.service";

@Injectable({
  providedIn: "root",
})
export class VideoService {
  constructor(
    private authService: AuthService,
    private languageService: LanguageService,
    public dialog: MatDialog,
    private lenderService: LenderService
  ) {}
  @Output() sendPromptText: EventEmitter<any> = new EventEmitter<any>();

  updateLoginNumber() {
    if (
      this.authService.profileDetails.profileRole.includes(
        ProfileRole.LENDER_USER
      ) &&
      (this.authService.profileDetails.language ==
        LenderLanguage.EN.toUpperCase() ||
        this.authService.profileDetails.language == null)
    ) {
      if (
        this.authService.profileDetails.loginNumber == null ||
        this.authService.profileDetails.loginNumber == 0 ||
        this.authService.profileDetails.loginNumber == undefined
      ) {
        let promptInfo = new PromptModel(
          [
            {
              content: PromptsMessages.translate(
                "VIDEO_MESSAGE",
                this.languageService.getLanguage()
              ),
              isTextBold: false,
            },
          ],
          true,
          true,
          "",
          PromptsMessages.translate("WATCH_NOW", this.languageService.getLanguage()),
          PromptsMessages.translate("REMIND_ME_LATER", this.languageService.getLanguage()),
          false,
          false,
          false,
          false,
          true
        );
        this.sendPromptText.emit(promptInfo);
        let dialogRef = this.dialog.open(GeneralPromptComponent, {
          autoFocus: false,
          data: promptInfo,
          disableClose: true,
        });
        dialogRef.afterOpened().subscribe(() => {
          dialogRef.componentInstance.onOk.subscribe(() => {
            this.openVideo();
          });
        });
        this.lenderService
          .updateLenderUser(
            new LenderInfoDTO(
              null,
              this.authService.profileDetails.id,
              null,
              1,
              null,
              null,
              null,
              null,
              null,
              null
            )
          )
          .subscribe();
      } else if (this.authService.profileDetails.loginNumber == 1) {
        let promptInfo = new PromptModel(
          [
            {
              content: PromptsMessages.translate(
                "VIDEO_MESSAGE",
                this.languageService.getLanguage()
              ),
              isTextBold: false,
            },
          ],
          true,
          true,
          "",
          PromptsMessages.translate("WATCH_NOW", this.languageService.getLanguage()),
          PromptsMessages.translate("REMIND_ME_LATER", this.languageService.getLanguage()),
          false,
          false,
          false,
          false,
          true
        );
        this.sendPromptText.emit(promptInfo);
        let dialogRef = this.dialog.open(GeneralPromptComponent, {
          autoFocus: false,
          data: promptInfo,
          disableClose: true,
        });
        dialogRef.afterOpened().subscribe(() => {
          dialogRef.componentInstance.onOk.subscribe(() => {
            this.openVideo();
          });
        });
        this.lenderService
          .updateLenderUser(
            new LenderInfoDTO(
              null,
              this.authService.profileDetails.id,
              null,
              2,
              null,
              null,
              null,
              null,
              null,
              null
            )
          )
          .subscribe();
      }
    }
  }

  async openVideo() {
    var uri = await this.getVideoUri();
    setTimeout(() => {
      var promptInfo = new PromptModel(
        [{ content: uri, isTextBold: false }],
        true,
        false,
        "",
        "",
        "",
        false,
        false,
        false,
        true,
        false
      );
      this.sendPromptText.emit(promptInfo);
      this.dialog.open(GeneralPromptComponent, {
        autoFocus: false,
        data: promptInfo,
        disableClose: true,
      });
    }, 100);
  }

  // Temporary commented until we get french video
  async getVideoUri() {
    // if (this.languageService.getLanguage() == LenderLanguage.FR) {
    //   return "https://lendergateway-videos.s3.eu-west-3.amazonaws.com/lenderGatewayPresentationVideoEN.mp4";
    // }
    return "https://lendergateway-public-resources.s3.ca-central-1.amazonaws.com/FN_1036_LenderGateway_Demo_EN_R4.mp4";
  }
}
