/// <reference types="@types/googlemaps" />
// tslint:disable-next-line:max-line-length
import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { MortgageApplication } from '../../models/lenderview.model';
import { FormBuilder } from '@angular/forms';
import { LoansService } from '../../services/api/loans.service';
import { MatDialog } from '@angular/material/dialog';
import { DTOLoanApplication } from '../../models/DTOLoanApplication';
import { PromptModel } from '../../models/prompt.model';
import { GeneralPromptComponent } from '../common/general-prompt/general-prompt.component';
import { PromptsMessages } from '../common/constants/promptsMessages';
import { DecisionPromptComponent } from '../common/decision-prompt/decision-prompt.component';
import { LoanDecision } from '../../models/loan-decision.model';
import { AppConfigService } from 'src/app/services/config-service/app-config.service';
import { IsLoadingService } from '../../services/is-loading.service';
import { LenderService } from 'src/app/services/api/lender.service';
import { LanguageService } from 'src/app/services/language-service/language.service';
import { LenderLanguage } from 'src/app/models/lender-language';
import { MultiTabEvents, MultiTabNotification } from "../../services/mutli-tab-notification.service";
import { AuthService } from "../../services/security/auth.service";
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatChipEvent } from "@angular/material/chips";

interface Size {
  value: number;
  viewValue: number;
}

@Component({
  selector: "app-lenderview",
  templateUrl: "./lenderview.component.html",
  styleUrls: ["./lenderview.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LenderviewComponent implements OnInit {
  @ViewChildren("selects") selects: QueryList<MatSelect>;
  @ContentChildren("myDealsStatus") myDealsStatus: QueryList<ElementRef>;

  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild("storyDrawer") storyDrawer: MatDrawer;
  @Output() sendPromptText: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendDialogueBoxText: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  page: EventEmitter<PageEvent>;
  pageSizeLeads = 5;
  pageSizeMyDeals = 5;

  public dataSourceLeads: MatTableDataSource<MortgageApplication>;
  public dataSourceMyDeals: MatTableDataSource<MortgageApplication>;
  public selectedIndex = 0;
  public noLeadsMessage = false;
  public activePageLeads = 0;
  public activePageMyDeals = 0;
  public loanList: MortgageApplication[];
  public leadList: MortgageApplication[];
  public myDealsList: MortgageApplication[];
  public displayCreditScore = true;
  public hidePaginatorLeads = true;
  public leadsNumberOfPages: number;
  public myDealsNumberOfPages: number;
  public displayCreditBureau = true;
  public hidePaginatorMyDeals = true;
  public searchOption;
  public searchInput;
  public filterStatus;
  public noSearchedLeadsMessage = false;
  public noLeadsWhenSearchBySourceName = true;
  public clearSearchFieldRestart = false;
  public noMyDeals = false;
  public noLeads = false;
  public isSearchedForLeads = false;
  public isSearchedForMyDeals = false;
  public optionsReset: any;
  public filterFlagForStatus: boolean;
  public options: any[] = [
    { value: "Application ID", displayValue: "Application ID" },
    { value: "Client name", displayValue: "Client name" },
    { value: "Source name", displayValue: "Source name" },
    { value: "Property Address", displayValue: "Property Address" },
  ];
  public optionsForLeads: any[] = [
    { value: "Source name", displayValue: "Source name" },
    { value: "Property Address", displayValue: "Property Address" },
  ];
  public optionsFrench: any[] = [
    { value: "Application ID", displayValue: "Numéro de Demande" },
    { value: "Client name", displayValue: "Nom du Client" },
    { value: "Source name", displayValue: "Nom de la Source" },
    { value: "Property Address", displayValue: "Adresse de la Propriété" },
  ];
  public optionsForLeadsFrench: any[] = [
    { value: "Source name", displayValue: "Nom de la Source" },
    { value: "Property Address", displayValue: "Adresse de la Propriété" },
  ];
  public pageSizes: Size[] = [
    { value: 5, viewValue: 5 },
    { value: 10, viewValue: 10 },
    { value: 25, viewValue: 25 },
    { value: 100, viewValue: 100 },
  ];
  public isMyDealsFilterActive = false;
  public isLeadsFilterActive = false;
  private myDealsActiveStatusFilters: string[] = [];
  private leadsActiveStatusFilters: string[] = [];
  private myDealsNumberOfResultsPerpage: number;
  private leadsNumberOfResultsPerpage: number;
  public countApprovedStatusClicks: number = 0;
  public statusList = new Map<string, any[]>();
  public statusListById: any[] = [];
  public documentPDF: any;
  public pdfName: string;
  public storyAddress: string;
  public storyContent: string;
  public allDataFetched: boolean = false;
  public selectedStatusByKey: boolean = false;
  public checkIfBothLeadsAndMyDealsListAreEmpty = 0;
  public checkIfNoLeads: boolean = true;
  public frenchVersionSelected: boolean = false;
  public englishNameOfTheLender: string;
  public selectedStatusValue = null;
  public selectedStatus = null;
  private file: any;
  public leadsStatusFiltersChips = {
    en: [
      { name: "New", state: false },
      { name: "Rejected", state: false },
    ],
    fr: [
      { name: "Nouvelle", state: false },
      { name: "Rejetée", state: false },
    ],
  };

  public myDealsStatusChips = {
    en: [
      { name: "In Review", state: false },
      { name: "Approved", state: false },
      { name: "Funded", state: false },
      { name: "Declined", state: false },
    ],
    fr: [
      { name: "En revue", state: false },
      { name: "Approuvée", state: false },
      { name: "Fonds déboursés", state: false },
      { name: "Refusée", state: false },
    ],
  };

  public mapStatusesFrenchEnglish = new Map<string, string>();
  public mapStatusesEnglishFrench = new Map<string, string>();
  public mapMonthsEnglishFrench = new Map<string, string>();
  public mapStatusToStatusActionEnglish = new Map<string, string>();
  public mapStatusToStatusActionFrench = new Map<string, string>();

  private displayedLeadColumns: string[] = [
    "received",
    "sourceName",
    "propertyAddress",
    "ltv",
    "totalLoan",
    "charge",
    "occupancy",
    "propertyType",
    "creditScore",
    "status",
  ];

  private displayedLeadColumnsResized: string[] = ["received"];

  private displayedMyDealsColumns: string[] = [
    "received",
    "applicationId",
    "sourceName",
    "clientName",
    "propertyAddress",
    "applicationSummary",
    "commitmentLetter",
    "myLeadsStatus",
  ];

  private displayedMyDealsColumnsResized: string[] = ["received"];

  @Input() childMessage: any = {
    applicationStatus: "",
  };

  @Output() messageEvent = new EventEmitter<string>();

  public search: string;
  public userEnglishName: string;

  constructor(
    private languageService: LanguageService,
    private loansService: LoansService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private isLoadingService: IsLoadingService,
    private lenderService: LenderService,
    private multiTabNotification: MultiTabNotification,
    private authService: AuthService
  ) {
    this.userEnglishName = this.authService.profileDetails["lenderName"];
    const that = this;
    this.multiTabNotification.subscribe(
      MultiTabEvents.LOAN_DECISION_UPDATE_APP_SUMMARY,
      () => {
        that.getMyDealsList();
        that.getAllLeads();
      }
    );
    this.fb.array([]);
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  getDisplayInOneColumn() {
    if (this.innerWidth < 1500) {
      return true;
    }
    return false;
  }

  public innerWidth: any;

  ngOnInit() {
    this.selectedStatus = null;

    this.innerWidth = window.innerWidth;
    this.lenderService
      .getProfileDetails(this.authService.profileDetails.fk_LenderAccount)
      .subscribe((data) => {
        this.englishNameOfTheLender = data.lenderAccount.lenderName;
      });

    this.languageService.init().then((data) => {
      this.setFilterFlagForStatus();
      this.getAllLeads();
      this.getMyDealsList();
      this.getLeads(0, 5);
    });

    this.allDataFetched = false;

    this.mapStatusesFrenchEnglish.set("Nouvelle", "New");
    this.mapStatusesFrenchEnglish.set("Réviser", "Review");
    this.mapStatusesFrenchEnglish.set("Rejeter", "Reject");
    this.mapStatusesFrenchEnglish.set("Rejetée", "Rejected");
    this.mapStatusesFrenchEnglish.set("En revue", "In Review");
    this.mapStatusesFrenchEnglish.set("Refuser", "Decline");
    this.mapStatusesFrenchEnglish.set("Refusée", "Declined");
    this.mapStatusesFrenchEnglish.set("Fonds déboursés", "Funded");
    this.mapStatusesFrenchEnglish.set("Approuver", "Approve");
    this.mapStatusesFrenchEnglish.set("Approuvée", "Approved");
    this.mapStatusesFrenchEnglish.set("Eliminer", "Remove");
    this.mapStatusesFrenchEnglish.set("Eliminé", "Removed");

    this.mapStatusesEnglishFrench.set("New", "Nouvelle");
    this.mapStatusesEnglishFrench.set("Review", "Réviser");
    this.mapStatusesEnglishFrench.set("Reject", "Rejeter");
    this.mapStatusesEnglishFrench.set("In Review", "En revue");
    this.mapStatusesEnglishFrench.set("Decline", "Refuser");
    this.mapStatusesEnglishFrench.set("Declined", "Refusée");
    this.mapStatusesEnglishFrench.set("Funded", "Fonds déboursés");
    this.mapStatusesEnglishFrench.set("Approved", "Approuvée");
    this.mapStatusesEnglishFrench.set("Remove", "Eliminer");
    this.mapStatusesEnglishFrench.set("Rejected", "Rejetée");
    this.mapStatusesEnglishFrench.set("Removed", "Eliminé");

    this.mapMonthsEnglishFrench.set("Jan", "janv");
    this.mapMonthsEnglishFrench.set("Feb", "févr");
    this.mapMonthsEnglishFrench.set("Mar", "mars");
    this.mapMonthsEnglishFrench.set("Apr", "avril");
    this.mapMonthsEnglishFrench.set("May", "mai");
    this.mapMonthsEnglishFrench.set("Jun", "juin");
    this.mapMonthsEnglishFrench.set("Jul", "juil");
    this.mapMonthsEnglishFrench.set("Aug", "août");
    this.mapMonthsEnglishFrench.set("Sep", "sept");
    this.mapMonthsEnglishFrench.set("Oct", "oct");
    this.mapMonthsEnglishFrench.set("Nov", "nov");
    this.mapMonthsEnglishFrench.set("Dec", "déc");

    this.mapStatusToStatusActionEnglish.set("In Review", "Review");
    this.mapStatusToStatusActionEnglish.set("Rejected", "Reject");
    this.mapStatusToStatusActionEnglish.set("Approved", "Approve");
    this.mapStatusToStatusActionEnglish.set("Funded", "Fund");
    this.mapStatusToStatusActionEnglish.set("Declined", "Decline");
    this.mapStatusToStatusActionEnglish.set("Removed", "Remove");

    this.mapStatusToStatusActionFrench.set("En revue", "Réviser");
    this.mapStatusToStatusActionFrench.set("Rejetée", "Rejeter");
    this.mapStatusToStatusActionFrench.set("Approuvée", "Approuver");
    this.mapStatusToStatusActionFrench.set(
      "Fonds déboursés",
      "Fonds déboursés"
    );
    this.mapStatusToStatusActionFrench.set("Refusée", "Refuser");
    this.mapStatusToStatusActionFrench.set("Eliminé", "Eliminer");
  }

  getLeadsStatusFiltersChips() {
    return this.leadsStatusFiltersChips[this.languageService.getLanguage()];
  }

  getMyDealsStatusChips() {
    return this.myDealsStatusChips[this.languageService.getLanguage()];
  }

  getOptionsForLeads() {
    if (this.languageService.getLanguage() == "fr") {
      return this.optionsForLeadsFrench;
    } else {
      return this.optionsForLeads;
    }
  }

  getOptions() {
    if (this.languageService.getLanguage() == "fr") {
      return this.optionsFrench;
    } else {
      return this.options;
    }
  }

  public setFilterFlagForStatus() {
    this.filterFlagForStatus = false;
  }

  public getAllLeads() {
    if (this.languageService.getLanguage() == "fr") {
      this.frenchVersionSelected = true;
    }
    this.dataSourceLeads = new MatTableDataSource();
    this.loansService
      .getLeads(
        this.leadsActiveStatusFilters,
        this.activePageLeads,
        this.pageSizeLeads
      )
      .subscribe((data) => {
        this.noLeadsMessage = data.objectList.length == 0;
        this.leadList = data.objectList;
        if (this.leadList.length > 0) {
          this.checkIfBothLeadsAndMyDealsListAreEmpty =
            this.checkIfBothLeadsAndMyDealsListAreEmpty + 1;
        }
        this.leadsNumberOfPages = data.nrOfPages;
        this.checkForNews();
        this.displayCreditScore = this.shouldDisplayCreditScore(this.leadList);
        this.dataSourceLeads.data = this.leadList;
        if (this.dataSourceLeads.data.length > 0) {
          this.checkIfNoLeads = false;
        }
        this.pageSizeLeads = 5;
        for (let i = 0; i < this.leadList.length; i++) {
          var statusList = this.setLocalStatusList(
            this.leadList[i].possibleStatuses
          );
          this.statusListById.push({
            id: this.leadList[i].id,
            statuses: statusList,
          });
        }
        if (data.nrOfPages > 1) {
          this.hidePaginatorLeads = false;
        } else {
          this.hidePaginatorLeads = !(this.leadList.length > 5);
        }
      });
  }

  public getMyDealsList() {
    this.dataSourceMyDeals = new MatTableDataSource();
    this.loansService
      .getMyDeals(
        this.myDealsActiveStatusFilters,
        this.activePageMyDeals,
        this.pageSizeMyDeals
      )
      .subscribe((data) => {
        this.myDealsList = data.objectList;
        if (this.myDealsList.length > 0) {
          this.checkIfBothLeadsAndMyDealsListAreEmpty =
            this.checkIfBothLeadsAndMyDealsListAreEmpty + 1;
        }
        this.myDealsNumberOfPages = data.nrOfPages;
        this.checkForNews();
        this.displayCreditBureau = this.shouldDisplayCommitmentLetter(
          this.myDealsList
        );
        this.dataSourceMyDeals.data = this.myDealsList;
        this.noMyDeals = data.objectList.length == 0;
        this.pageSizeMyDeals = 5;
        for (let i = 0; i < this.myDealsList.length; i++) {
          var statusList = this.setLocalStatusList(
            this.myDealsList[i].possibleStatuses
          );
          this.statusListById.push({
            id: this.myDealsList[i].id,
            statuses: statusList,
          });
        }
        if (data.nrOfPages > 1) {
          this.hidePaginatorMyDeals = false;
        } else {
          this.hidePaginatorMyDeals = !(this.myDealsList.length > 5);
        }
      });
    this.allDataFetched = true;
    this.isLoadingService.remove();
  }

  private setLocalStatusList(possibleStatuses: string[]) {
    var statusListLocal: any[] = [];
    possibleStatuses.forEach((ps) => {
      statusListLocal.push({
        value: this.getLanguageBasedStatusValue(ps),
        displayValue: ps,
      });
    });

    return statusListLocal;
  }

  getLanguageBasedStatusValue(ps: string): string {
    return this.languageService.getLanguage() == "en"
      ? this.mapStatusToStatusActionEnglish.get(ps)
      : this.mapStatusToStatusActionFrench.get(ps);
  }

  public getStatusListById(elem: any, id: string) {
    var searchedStatusList = [];
    for (let i = 0; i < this.statusListById.length; i++) {
      if (this.statusListById[i].id == id) {
        searchedStatusList = this.statusListById[i].statuses;
      }
    }
    return searchedStatusList;
  }

  public getSentToExpert() {
    if (this.authService.profileDetails)
      return this.authService.profileDetails["sentToExpert"];
  }

  public setIndex() {
    if (this.selectedIndex == 0) {
      this.selectedIndex = -1;
    } else if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
  }

  public setNotifyNewLoan(state: boolean) {
    this.getLeadsForHeader(0, 5);
  }

  private getMyDeals(pageNumber: number, nrOfResultsPerPage: number) {
    this.loansService
      .getMyDeals(
        this.myDealsActiveStatusFilters,
        pageNumber,
        nrOfResultsPerPage
      )
      .subscribe((data) => {
        this.myDealsList = data.objectList;
        this.myDealsNumberOfPages = data.nrOfPages;
        this.displayCreditBureau = this.shouldDisplayCommitmentLetter(
          this.myDealsList
        );
        this.dataSourceMyDeals = new MatTableDataSource(this.myDealsList);
        this.noMyDeals = data.objectList.length == 0;
        for (let i = 0; i < this.myDealsList.length; i++) {
          var statusList = this.setLocalStatusList(
            this.myDealsList[i].possibleStatuses
          );
          this.statusListById.push({
            id: this.myDealsList[i].id,
            statuses: statusList,
          });
        }
        if (data.nrOfPages > 1) {
          this.hidePaginatorMyDeals = false;
        } else {
          this.hidePaginatorMyDeals = !(this.myDealsList.length > 5);
        }
      });
  }

  setLeadsNumberOfPages(numberOfPages: number) {
    this.leadsNumberOfPages = numberOfPages;
  }

  private getLeads(pageNumber: number, nrOfResultsPerPage: number) {
    this.activePageLeads = pageNumber;
    this.pageSizeLeads = nrOfResultsPerPage;
    this.loansService
      .getLeads(this.leadsActiveStatusFilters, pageNumber, nrOfResultsPerPage)
      .subscribe((data) => {
        this.noLeadsMessage = data.objectList.length == 0;
        this.noLeads = data.objectList.length == 0;
        this.leadList = data.objectList;
        this.setLeadsNumberOfPages(data.nrOfPages);
        this.displayCreditScore = this.shouldDisplayCreditScore(this.leadList);
        this.dataSourceLeads = new MatTableDataSource(this.leadList);
        if (this.dataSourceLeads.data.length > 0) {
          this.checkIfNoLeads = false;
        }
        for (let i = 0; i < this.leadList.length; i++) {
          var statusList = this.setLocalStatusList(
            this.leadList[i].possibleStatuses
          );
          this.statusListById.push({
            id: this.leadList[i].id,
            statuses: statusList,
          });
        }
        if (data.nrOfPages > 1) {
          this.hidePaginatorLeads = false;
        } else {
          this.hidePaginatorLeads = !(this.leadList.length > 5);
        }
        this.checkForNews();
      });
  }

  public getLeadsForHeader(pageNumber: number, nrOfResultsPerPage: number) {
    this.leadsActiveStatusFilters = [];
    return this.getLeads(pageNumber, nrOfResultsPerPage);
  }

  private checkForNews() {
    var count = 0;
    if (this.leadList) {
      this.leadList.forEach((element) => {
        if (element.status == "New" || element.status == "Nouvelle") {
          count++;
        }
      });
    }

    if (count > 0) {
      this.selectedIndex = 0;
    } else {
      if (this.leadList != undefined && this.myDealsList != undefined) {
        if (
          this.leadList.length > 0 &&
          this.myDealsList.length > 0 &&
          this.leadsNumberOfPages == 0 &&
          this.filterFlagForStatus == false
        ) {
          this.selectedIndex = 1;
        } else if (this.leadList.length == 0 && this.myDealsList.length == 0) {
          this.noLeadsMessage = true;
        } else if (
          this.leadList.length == 0 &&
          this.myDealsList.length > 0 &&
          this.leadsNumberOfPages == 0 &&
          this.filterFlagForStatus == false
        ) {
          this.selectedIndex = 1;
        }
      }
    }
  }

  private shouldDisplayCreditScore(loanList: MortgageApplication[]): boolean {
    return !loanList.every((loan) => loan.creditScore === "N/A");
  }

  private shouldDisplayCommitmentLetter(myDealsList: any[]): boolean {
    var shouldDisplay = false;
    myDealsList.forEach((element) => {
      if (element.activeLoanDecision != null) {
        shouldDisplay = true;
      }
    });
    return shouldDisplay;
  }

  //logic necessary for displaying or hiding the CreditScore column if the lender is or not a TU/Equifax member
  public getColumnsToDisplay(): string[] {
    if (!this.displayCreditScore) {
      return this.displayedLeadColumns.filter(
        (column) => column !== "creditScore"
      );
    } else {
      return this.displayedLeadColumns;
    }
  }

  public getColumnsToDisplayResized(): string[] {
    return this.displayedLeadColumnsResized;
  }

  //logic necessary for displaying or hiding the CreditBureau column
  public getColumnsToDisplayMyDeals(): string[] {
    if (!this.displayCreditBureau) {
      return this.displayedMyDealsColumns.filter(
        (column) => column !== "commitmentLetter"
      );
    } else {
      return this.displayedMyDealsColumns;
    }
  }

  public getColumnsToDisplayMyDealsResized(): string[] {
    return this.displayedMyDealsColumnsResized;
  }

  public updateLeadsStatuses(element: any) {
    this.multiTabNotification.trigger(
      MultiTabEvents.LOAN_DECISION_UPDATE_LENDERS_VIEW
    );
    element.status = this.selectedStatus;
    if (!this.isSearchedForLeads) {
      this.getLeads(this.activePageLeads, this.pageSizeLeads);
    } else if (this.isSearchedForLeads) {
      switch (this.searchOption) {
        case "Source name": {
          this.getSearchedLeadsBySourceName(
            this.activePageMyDeals,
            this.pageSizeMyDeals
          );
          break;
        }
        case "Property Address": {
          this.getSearchedLeadsByPropertyAddress(
            this.activePageMyDeals,
            this.pageSizeMyDeals
          );
          break;
        }
      }
    }
  }

  public updateMyDealsStatuses(element: any) {
    this.multiTabNotification.trigger(
      MultiTabEvents.LOAN_DECISION_UPDATE_LENDERS_VIEW
    );
    element.status = this.selectedStatus;
    if (!this.isSearchedForMyDeals) {
      this.getMyDeals(this.activePageMyDeals, this.pageSizeMyDeals);
    } else if (this.isSearchedForMyDeals) {
      switch (this.searchOption) {
        case "Application ID": {
          this.getSearchedDealsByApplicationId(
            this.activePageMyDeals,
            this.pageSizeMyDeals
          );
          break;
        }
        case "Client name": {
          this.getSearchedDealsByClientName(
            this.activePageMyDeals,
            this.pageSizeMyDeals
          );
          break;
        }
        case "Source name": {
          this.getSearchedDealsBySourceName(
            this.activePageMyDeals,
            this.pageSizeMyDeals
          );
          break;
        }
        case "Property Address": {
          this.getSearchedDealsByPropertyAddress(
            this.activePageMyDeals,
            this.pageSizeMyDeals
          );
          break;
        }
      }
    }
  }

  getDisplayStatus(status) {
    return status;
  }

  formatLTV(ltv, decimals: number = 2) {
    const factor = Math.pow(10, decimals);
    return Math.trunc(100 * ltv * factor) / factor;
  }

  public async onChangeStatus(element: any) {
    if (this.selectedStatusValue) {
      this.selectedStatus = this.selectedStatusValue;
      if (
        !element.isPayed &&
        (this.selectedStatus == "In Review" ||
          this.mapStatusesFrenchEnglish.get(this.selectedStatus) == "In Review")
      ) {
        let promptInfoReview: PromptModel;
        promptInfoReview = new PromptModel(
          [
            {
              content:
                PromptsMessages.translate(
                  "MSG_1_REVIEW_LEADS",
                  this.languageService.getLanguage()
                ) +
                AppConfigService.settings.fee +
                PromptsMessages.translate(
                  "MSG_4_REVIEW_LEADS",
                  this.languageService.getLanguage()
                ),
              isTextBold: false,
            },
            {
              content: PromptsMessages.translate(
                "MSG_3_REVIEW_LEADS",
                this.languageService.getLanguage()
              ),
              isTextBold: false,
            },
          ],
          true,
          true,
          "",
          PromptsMessages.translate(
            "DIALOGUE_BOX_BUTTON_REVIEW",
            this.languageService.getLanguage()
          ),
          PromptsMessages.translate(
            "DIALOGUE_BOX_BUTTON_CANCEL",
            this.languageService.getLanguage()
          ),
          true,
          false,
          false,
          false,
          false
        );
        promptInfoReview.isRedirectedViaHref = false;
        this.sendPromptText.emit(promptInfoReview);
        let loanApp = await this.loansService
          .getLoanById(element.id)
          .toPromise();

        let profileDetails = await this.lenderService
          .getProfileDetails(
            loanApp.loanApplication.mortgage.requested.lenderSubmission
              .lenderProfile.lenderCode
          )
          .toPromise();
        let dialogRef = this.dialog.open(GeneralPromptComponent, {
          autoFocus: false,
          data: promptInfoReview,
        });
        dialogRef.afterOpened().subscribe(() => {
          dialogRef.componentInstance.onOk.subscribe(() => {
            if (!loanApp.isPayed) {
              window.open(
                "pay?id=" +
                  element.id +
                  "&loanApplicationId=" +
                  element.loanApplicationId +
                  "&activeLoanDecision=" +
                  element.activeLoanDecision +
                  "&applicationDecision=" +
                  element.status +
                  "&previousStatus=" +
                  element.previousStatus +
                  "&clientName=" +
                  element.clientName +
                  "&profileName=" +
                  profileDetails.lenderAccount.contactDetails.lastName +
                  " " +
                  profileDetails.lenderAccount.contactDetails.firstName +
                  "&lenderName=" +
                  this.authService.profileDetails.lenderName,
                " _blank"
              );
            } else {
              var promptAlreadyPayed = new PromptModel(
                [
                  {
                    content: PromptsMessages.ALREADY_PAYED_LOAN,
                    isTextBold: false,
                  },
                ],
                true,
                false,
                "",
                PromptsMessages.DIALOGUE_BOX_BUTTON_OK,
                "",
                true,
                false,
                false,
                false,
                false
              );
              this.sendPromptText.emit(promptAlreadyPayed);
              this.dialog.open(GeneralPromptComponent, {
                autoFocus: false,
                data: promptAlreadyPayed,
              });
            }
          });

          dialogRef.componentInstance.onCancel.subscribe(() => {});
        });
      } else if (
        this.selectedStatus == "Rejected" ||
        this.mapStatusesFrenchEnglish.get(this.selectedStatus) == "Rejected"
      ) {
        let dialogueInfoRejected = new PromptModel(
          [
            {
              loanStatus: this.selectedStatus,
              dialogBoxTitle: PromptsMessages.translate(
                "DECLINE_MYDEALS_TITLE",
                this.languageService.getLanguage()
              ),
              isAttachedDocument: false,
              attachedDocumentText: PromptsMessages.translate(
                "DIALOGUE_BOX_ATTACH_DOCUMENT",
                this.languageService.getLanguage()
              ),
              isAttachedLetter: false,
              attachedLetterText: PromptsMessages.translate(
                "DIALOGUE_BOX_ATTACH_LETTER",
                this.languageService.getLanguage()
              ),
              attachedNotesText: PromptsMessages.translate(
                "DIALOGUE_BOX_ATTACH_NOTES",
                this.languageService.getLanguage()
              ),
              fileExtensionError: PromptsMessages.translate(
                "DIALOGUE_BOX_FILE_EXTENSION_ERROR",
                this.languageService.getLanguage()
              ),
              pdfSizeError: PromptsMessages.translate(
                "DIALOGUE_BOX_PDF_SIZE_ERROR",
                this.languageService.getLanguage()
              ),
              notesLengthError: PromptsMessages.translate(
                "DIALOGUE_BOX_NOTES_LENGTH_ERROR",
                this.languageService.getLanguage()
              ),
              pdf: "",
              listDropDownForDecline: true,
            },
          ],
          true,
          true,
          "",
          PromptsMessages.translate(
            "DIALOGUE_BOX_BUTTON_CANCEL",
            this.languageService.getLanguage()
          ),
          PromptsMessages.translate(
            "DIALOGUE_BOX_BUTTON_SUBMIT",
            this.languageService.getLanguage()
          ),
          false,
          false,
          false,
          false,
          false
        );

        this.sendDialogueBoxText.emit(dialogueInfoRejected);
        let dialogueBoxRef = this.dialog.open(DecisionPromptComponent, {
          autoFocus: false,
          data: dialogueInfoRejected,
        });
        dialogueBoxRef.afterOpened().subscribe(() => {
          dialogueBoxRef.componentInstance.onApprove.subscribe(() => {
            element.status = this.selectedStatus;
            dialogueBoxRef.afterClosed().subscribe((data) => {
              this.loansService
                .addLoanDecision(
                  data.file,
                  new LoanDecision(
                    element.id,
                    element.loanApplicationId,
                    element.status,
                    data.notes,
                    "",
                    data.pdfName,
                    data.denialReason,
                    false
                  )
                )
                .subscribe((result) => {
                  this.updateLeadsStatuses(element);
                });
            });
          });

          dialogueBoxRef.componentInstance.onCancel.subscribe(() => {});
        });
      } else if (
        this.selectedStatus == "Removed" ||
        this.mapStatusesFrenchEnglish.get(this.selectedStatus) == "Removed"
      ) {
        let promptInfo = new PromptModel(
          [
            {
              content: PromptsMessages.translate(
                "REMOVE_MYDEALS",
                this.languageService.getLanguage()
              ),
              isTextBold: false,
            },
          ],
          true,
          true,
          "",
          PromptsMessages.translate(
            "DIALOGUE_BOX_BUTTON_REMOVE",
            this.languageService.getLanguage()
          ),
          PromptsMessages.translate(
            "CANCEL_BUTTON",
            this.languageService.getLanguage()
          ),
          true,
          false,
          false,
          false,
          false
        );
        this.sendPromptText.emit(promptInfo);
        let dialogRef = this.dialog.open(GeneralPromptComponent, {
          autoFocus: false,
          data: promptInfo,
        });
        dialogRef.afterOpened().subscribe(() => {
          dialogRef.componentInstance.onOk.subscribe(() => {
            if (this.myDealsList.length == 1) {
              this.activePageMyDeals = this.activePageMyDeals - 1;
            }
            this.myDealsList.splice(this.myDealsList.indexOf(element), 1);
            this.dataSourceMyDeals = new MatTableDataSource(this.myDealsList);
            var newLoanApplication = new DTOLoanApplication(
              element.id,
              element.loanApplicationId,
              "Removed",
              this.authService.profileDetails.fk_LenderAccount,
              true
            );
            this.loansService.updateLoan(newLoanApplication).subscribe(() => {
              this.updateMyDealsStatuses(element);
            });
            this.resetFilters(false);
            this.checkForNews();
          });

          dialogRef.componentInstance.onCancel.subscribe(() => {});
        });
      } else {
        let promptInfo: PromptModel;
        if (
          this.selectedStatus == "Approved" ||
          this.mapStatusesFrenchEnglish.get(this.selectedStatus) ==
            "Approved" ||
          this.selectedStatus == "Declined" ||
          this.mapStatusesFrenchEnglish.get(this.selectedStatus) == "Declined"
        ) {
          let dialogueInfo;
          if (
            this.selectedStatus == "Approved" ||
            this.mapStatusesFrenchEnglish.get(this.selectedStatus) == "Approved"
          ) {
            if (element.activeLoanDecision) {
              var loanDecisionResponse = await this.getLoanDecision(element);
              this.pdfName = loanDecisionResponse.pdfName;
              var uri = await this.getPdfUri(this.pdfName);
              this.documentPDF = uri["uri"];
            } else {
              this.documentPDF = "";
            }

            dialogueInfo = new PromptModel(
              [
                {
                  loanStatus: this.selectedStatus,
                  dialogBoxTitle: PromptsMessages.translate(
                    "APPROVED_MYDEALS_TITLE",
                    this.languageService.getLanguage()
                  ),
                  isAttachedDocument: true,
                  attachedDocumentText: PromptsMessages.translate(
                    "DIALOGUE_BOX_ATTACH_DOCUMENT",
                    this.languageService.getLanguage()
                  ),
                  isAttachedLetter: true,
                  attachedLetterText: PromptsMessages.translate(
                    "DIALOGUE_BOX_ATTACH_LETTER",
                    this.languageService.getLanguage()
                  ),
                  attachedNotesText: PromptsMessages.translate(
                    "DIALOGUE_BOX_ATTACH_NOTES",
                    this.languageService.getLanguage()
                  ),
                  fileExtensionError: PromptsMessages.translate(
                    "DIALOGUE_BOX_FILE_EXTENSION_ERROR",
                    this.languageService.getLanguage()
                  ),
                  pdfSizeError: PromptsMessages.translate(
                    "DIALOGUE_BOX_PDF_SIZE_ERROR",
                    this.languageService.getLanguage()
                  ),
                  notesLengthError: PromptsMessages.translate(
                    "DIALOGUE_BOX_NOTES_LENGTH_ERROR",
                    this.languageService.getLanguage()
                  ),
                  pdf: this.documentPDF,
                  pdfName: this.pdfName,
                },
              ],
              true,
              true,
              "",
              PromptsMessages.translate(
                "DIALOGUE_BOX_BUTTON_CANCEL",
                this.languageService.getLanguage()
              ),
              PromptsMessages.translate(
                "DIALOGUE_BOX_BUTTON_APPROVE",
                this.languageService.getLanguage()
              ),
              false,
              false,
              false,
              false,
              false
            );
          } else if (
            this.selectedStatus == "Declined" ||
            this.mapStatusesFrenchEnglish.get(this.selectedStatus) == "Declined"
          ) {
            dialogueInfo = new PromptModel(
              [
                {
                  loanStatus: this.selectedStatus,
                  dialogBoxTitle: PromptsMessages.translate(
                    "DECLINE_MYDEALS_TITLE",
                    this.languageService.getLanguage()
                  ),
                  isAttachedDocument: false,
                  attachedDocumentText: PromptsMessages.translate(
                    "DIALOGUE_BOX_ATTACH_DOCUMENT",
                    this.languageService.getLanguage()
                  ),
                  isAttachedLetter: false,
                  attachedLetterText: PromptsMessages.translate(
                    "DIALOGUE_BOX_ATTACH_LETTER",
                    this.languageService.getLanguage()
                  ),
                  attachedNotesText: PromptsMessages.translate(
                    "DIALOGUE_BOX_ATTACH_NOTES",
                    this.languageService.getLanguage()
                  ),
                  fileExtensionError: PromptsMessages.translate(
                    "DIALOGUE_BOX_FILE_EXTENSION_ERROR",
                    this.languageService.getLanguage()
                  ),
                  pdfSizeError: PromptsMessages.translate(
                    "DIALOGUE_BOX_PDF_SIZE_ERROR",
                    this.languageService.getLanguage()
                  ),
                  notesLengthError: PromptsMessages.translate(
                    "DIALOGUE_BOX_NOTES_LENGTH_ERROR",
                    this.languageService.getLanguage()
                  ),
                  pdf: "",
                  listDropDownForDecline: true,
                },
              ],
              true,
              true,
              "",
              PromptsMessages.translate(
                "DIALOGUE_BOX_BUTTON_CANCEL",
                this.languageService.getLanguage()
              ),
              PromptsMessages.translate(
                "DIALOGUE_BOX_BUTTON_DECLINE",
                this.languageService.getLanguage()
              ),
              false,
              false,
              false,
              false,
              false
            );
          }
          this.sendDialogueBoxText.emit(dialogueInfo);
          let dialogueBoxRef = this.dialog.open(DecisionPromptComponent, {
            autoFocus: false,
            data: dialogueInfo,
          });
          dialogueBoxRef.afterOpened().subscribe(() => {
            dialogueBoxRef.componentInstance.onApprove.subscribe(() => {
              if (
                this.selectedStatus == "Approved" ||
                this.mapStatusesFrenchEnglish.get(this.selectedStatus) ==
                  "Approved"
              ) {
                dialogueBoxRef.afterClosed().subscribe((data) => {
                  var base64PDF = data.pdf.replace(
                    "data:application/pdf;base64,",
                    ""
                  );
                  if (element.activeLoanDecision == null) {
                    this.loansService
                      .addLoanDecision(
                        data.file,
                        new LoanDecision(
                          element.id,
                          element.loanApplicationId,
                          "Approved",
                          data.notes,
                          "",
                          data.pdfName,
                          null,
                          false
                        )
                      )
                      .subscribe(() => {
                        this.updateMyDealsStatuses(element);
                      });
                  } else {
                    this.loansService
                      .updateLoanDecision(
                        data.file,
                        new LoanDecision(
                          element.id,
                          element.loanApplicationId,
                          "Approved",
                          data.notes,
                          "",
                          data.pdfName,
                          null,
                          false
                        )
                      )
                      .subscribe(() => {
                        this.updateMyDealsStatuses(element);
                      });
                  }
                });
              } else if (
                this.selectedStatus == "Declined" ||
                this.mapStatusesFrenchEnglish.get(this.selectedStatus) ==
                  "Declined"
              ) {
                dialogueBoxRef.afterClosed().subscribe((data) => {
                  this.loansService
                    .addLoanDecision(
                      data.file,
                      new LoanDecision(
                        element.id,
                        element.loanApplicationId,
                        "Declined",
                        data.notes,
                        "",
                        data.pdfName,
                        data.denialReason,
                        false
                      )
                    )
                    .subscribe(() => {
                      this.updateMyDealsStatuses(element);
                    });
                });
              }
            });
          });
        }

        if (
          this.selectedStatus == "Funded" ||
          this.mapStatusesFrenchEnglish.get(this.selectedStatus) == "Funded" ||
          this.selectedStatus == "In Review" ||
          this.mapStatusesFrenchEnglish.get(this.selectedStatus) == "In Review"
        ) {
          if (
            this.selectedStatus == "Funded" ||
            this.mapStatusesFrenchEnglish.get(this.selectedStatus) == "Funded"
          ) {
            promptInfo = new PromptModel(
              [
                {
                  content: PromptsMessages.translate(
                    "FUNDED_MYDEALS",
                    this.languageService.getLanguage()
                  ),
                  isTextBold: false,
                },
              ],
              true,
              true,
              "",
              PromptsMessages.translate(
                "YES",
                this.languageService.getLanguage()
              ),
              PromptsMessages.translate(
                "NO",
                this.languageService.getLanguage()
              ),
              false,
              false,
              false,
              false,
              false
            );
          } else if (
            this.selectedStatus == "In Review" ||
            this.mapStatusesFrenchEnglish.get(this.selectedStatus) ==
              "In Review"
          ) {
            promptInfo = new PromptModel(
              [
                {
                  content: PromptsMessages.translate(
                    "IN_REVIEW_MYDEALS",
                    this.languageService.getLanguage()
                  ),
                  isTextBold: false,
                },
              ],
              true,
              true,
              "",
              PromptsMessages.translate(
                "YES",
                this.languageService.getLanguage()
              ),
              PromptsMessages.translate(
                "NO",
                this.languageService.getLanguage()
              ),
              false,
              false,
              false,
              false,
              false
            );
          }
          this.sendPromptText.emit(promptInfo);
          let dialogRef = this.dialog.open(GeneralPromptComponent, {
            autoFocus: false,
            data: promptInfo,
          });
          dialogRef.afterOpened().subscribe(() => {
            dialogRef.componentInstance.onOk.subscribe(() => {
              var newLoanApplication = new DTOLoanApplication(
                element.id,
                element.loanApplicationId,
                this.languageService.getLanguage() == "fr"
                  ? this.mapStatusesFrenchEnglish.get(this.selectedStatus)
                  : this.selectedStatus,
                this.authService.profileDetails.fk_LenderAccount,
                element.isPayed
              );
              this.loansService.updateLoan(newLoanApplication).subscribe(() => {
                if (
                  this.selectedStatus != "Funded" &&
                  this.mapStatusesFrenchEnglish.get(this.selectedStatus) !=
                    "Funded"
                ) {
                  if (this.languageService.getLanguage() == "en") {
                    this.loansService
                      .addLoanDecision(
                        null,
                        new LoanDecision(
                          element.id,
                          element.loanApplicationId,
                          this.selectedStatus,
                          PromptsMessages.IN_REVIEW_NOTES +
                            " " +
                            this.englishNameOfTheLender,
                          "",
                          "",
                          "",
                          false
                        )
                      )
                      .subscribe(() => {
                        this.updateMyDealsStatuses(element);
                      });
                  } else {
                    this.loansService
                      .addLoanDecision(
                        null,
                        new LoanDecision(
                          element.id,
                          element.loanApplicationId,
                          this.mapStatusesFrenchEnglish.get(
                            this.selectedStatus
                          ),
                          PromptsMessages.IN_REVIEW_NOTES +
                            " " +
                            this.englishNameOfTheLender,
                          "",
                          "",
                          "",
                          false
                        )
                      )
                      .subscribe(() => {
                        this.updateMyDealsStatuses(element);
                      });
                  }
                } else {
                  this.updateMyDealsStatuses(element);
                }
              });
            });
          });
        }
      }
    }
    this.selectedStatusValue = null;
  }

  async getPdfUri(element: string) {
    return await this.loansService.getPdfUri(element).toPromise();
  }

  async getLoanDecision(element) {
    return await this.loansService
      .getLoanDecisionById(element.activeLoanDecision)
      .toPromise();
  }

  public onTabChange(event: any) {
    this.isSearchedForLeads = false;
    this.isSearchedForMyDeals = false;
    this.optionsReset = "";
    if (<HTMLInputElement>document.getElementById("search-input")) {
      (<HTMLInputElement>document.getElementById("search-input")).value = "";
      this.searchOption = null;
    }
    if (event.index === 1) {
      this.activePageMyDeals = 0;
      this.getMyDeals(this.activePageMyDeals, this.pageSizeMyDeals);
    }
    if (event.index === 0) {
      this.activePageLeads = 0;
      this.getLeads(this.activePageLeads, this.pageSizeLeads);
    }
  }

  public onLeadsPageChange(event) {
    if (this.isSearchedForLeads) {
      switch (this.searchOption) {
        case "Source name": {
          if (event.pageSize != this.pageSizeLeads && this.isSearchedForLeads) {
            this.pageSizeLeads = event.pageSize;
            this.getSearchedLeadsBySourceName(0, event.pageSize);
          } else if (this.isSearchedForLeads) {
            this.getSearchedLeadsBySourceName(event.activePage, event.pageSize);
          }
          break;
        }

        case "Property Address": {
          if (event.pageSize != this.pageSizeLeads && this.isSearchedForLeads) {
            this.pageSizeLeads = event.pageSize;
            this.getSearchedLeadsByPropertyAddress(0, event.pageSize);
          } else if (this.isSearchedForLeads) {
            this.getSearchedLeadsByPropertyAddress(
              event.activePage,
              event.pageSize
            );
          }
          break;
        }
      }
    }

    if (event.pageSize != this.pageSizeLeads && !this.isSearchedForLeads) {
      this.pageSizeLeads = event.pageSize;
      this.getLeads(0, event.pageSize);
    } else if (!this.isSearchedForLeads) {
      this.getLeads(event.activePage, event.pageSize);
    }

    if (event.activePage == this.activePageLeads + 1) {
      this.activePageLeads = event.activePage;
    }

    this.leadsNumberOfResultsPerpage = event.pageSize;
  }

  public onMyDealsPageChange(event) {
    if (this.isSearchedForMyDeals) {
      switch (this.searchOption) {
        case "Application ID": {
          if (event.pageSize != this.pageSizeMyDeals) {
            this.pageSizeMyDeals = event.pageSize;
            this.getSearchedDealsByApplicationId(0, event.pageSize);
          } else {
            this.getSearchedDealsByApplicationId(
              event.activePage,
              event.pageSize
            );
          }
          break;
        }
        case "Client name": {
          if (event.pageSize != this.pageSizeMyDeals) {
            this.pageSizeMyDeals = event.pageSize;
            this.getSearchedDealsByClientName(0, event.pageSize);
          } else {
            this.getSearchedDealsByClientName(event.activePage, event.pageSize);
          }
          break;
        }
        case "Source name": {
          if (event.pageSize != this.pageSizeMyDeals) {
            this.pageSizeMyDeals = event.pageSize;
            this.getSearchedDealsBySourceName(0, event.pageSize);
          } else {
            this.getSearchedDealsBySourceName(event.activePage, event.pageSize);
          }
          break;
        }
        case "Property Address": {
          if (event.pageSize != this.pageSizeMyDeals) {
            this.pageSizeMyDeals = event.pageSize;
            this.getSearchedDealsByPropertyAddress(0, event.pageSize);
          } else {
            this.getSearchedDealsByPropertyAddress(
              event.activePage,
              event.pageSize
            );
          }
          break;
        }
      }
    }

    if (event.pageSize != this.pageSizeMyDeals && !this.isSearchedForMyDeals) {
      this.pageSizeMyDeals = event.pageSize;
      this.getMyDeals(0, event.pageSize);
    } else if (
      event.pageSize == this.pageSizeMyDeals &&
      !this.isSearchedForMyDeals
    ) {
      this.getMyDeals(event.activePage, event.pageSize);
    }
    if (event.activePage == this.activePageMyDeals + 1) {
      this.activePageMyDeals = event.activePage;
    }

    this.myDealsNumberOfResultsPerpage = event.pageSize;
  }

  public checkCreditBureau(elem: any): boolean {
    return elem.hasCreditBureau;
  }

  public getNoLeadsMessage() {
    return !this.noLeadsMessage;
  }

  public openApplicationSummary(event: any, index: number) {
    var time = new Date();
    var id = this.dataSourceMyDeals.data[index].id;
    var activeLoanDecision =
      this.dataSourceMyDeals.data[index].activeLoanDecision;
    var applicationDecision = this.dataSourceMyDeals.data[index].status;
    var previousStatus = this.dataSourceMyDeals.data[index].previousStatus;
    var isEligibleForRemove =
      this.dataSourceMyDeals.data[index].status === "Funded"
        ? false
        : this.dataSourceMyDeals.data[index].isEligibleForRemove;
    window.open(
      "applicationSummary?id=" +
        id +
        "&activeLoanDecision=" +
        activeLoanDecision +
        "&applicationDecision=" +
        applicationDecision +
        "&previousStatus=" +
        previousStatus +
        "&isEligibleForRemove=" +
        isEligibleForRemove,
      time.getTime().toString()
    );
  }

  public openApplicationSummaryResized(event: any, index: any) {
    var time = new Date();
    var id =
      this.dataSourceMyDeals.data[this.dataSourceMyDeals.data.indexOf(index)]
        .id;
    var activeLoanDecision =
      this.dataSourceMyDeals.data[this.dataSourceMyDeals.data.indexOf(index)]
        .activeLoanDecision;
    var applicationDecision =
      this.dataSourceMyDeals.data[this.dataSourceMyDeals.data.indexOf(index)]
        .status;
    var previousStatus =
      this.dataSourceMyDeals.data[this.dataSourceMyDeals.data.indexOf(index)]
        .previousStatus;
    var isEligibleForRemove =
      this.dataSourceMyDeals.data[this.dataSourceMyDeals.data.indexOf(index)]
        .status === "Funded"
        ? false
        : this.dataSourceMyDeals.data[
            this.dataSourceMyDeals.data.indexOf(index)
          ].isEligibleForRemove;
    window.open(
      "applicationSummary?id=" +
        id +
        "&activeLoanDecision=" +
        activeLoanDecision +
        "&applicationDecision=" +
        applicationDecision +
        "&previousStatus=" +
        previousStatus +
        "&isEligibleForRemove=" +
        isEligibleForRemove,
      time.getTime().toString()
    );
  }

  public openCreditBureau(element) {
    window.open(
      "creditBureau?id=" +
        element.id +
        "_" +
        element.loanApplicationId +
        "_" +
        element.clientName,
      "_blank"
    );
  }

  getCommitmentLetter(element) {
    let result: boolean = false;
    if (element.activeLoanDecision != null) {
      result = true;
    } else {
      result = false;
    }
    return result;
  }

  async downloadCommitmentLetter(element) {
    var loanDecisionResponse = await this.getLoanDecision(element);
    var uri = await this.getPdfUri(loanDecisionResponse.pdfName);
    (<HTMLLinkElement>document.getElementById("download-link")).href =
      uri["uri"];
    (<HTMLLinkElement>document.getElementById("download-link")).target =
      "_blank";
    (<HTMLLinkElement>document.getElementById("download-link")).click();
  }

  public checkPaginatorLeads() {
    return this.hidePaginatorLeads;
  }

  public checkPaginatorMyDeals() {
    return this.hidePaginatorMyDeals;
  }

  public getLeadsNumberOfPages() {
    return this.leadsNumberOfPages;
  }

  public getMyDealsNumberOfPages() {
    return this.myDealsNumberOfPages;
  }

  public getActivePageLeads() {
    return this.activePageLeads;
  }

  public getActivePageMyDeals() {
    return this.activePageMyDeals;
  }

  private getSearchedLeadsByPropertyAddress(
    pageNumber: number,
    nrOfResultsPerPage
  ) {
    this.loansService
      .getLeadsByPropertyAddress(
        this.searchInput,
        this.leadsActiveStatusFilters,
        pageNumber,
        nrOfResultsPerPage
      )
      .subscribe((data) => {
        this.noLeadsMessage = data.objectList.length == 0;
        this.noSearchedLeadsMessage = data.objectList.length == 0;
        this.noLeads = data.objectList.length == 0;
        this.leadList = data.objectList;
        this.leadsNumberOfPages = data.nrOfPages;
        this.displayCreditScore = this.shouldDisplayCreditScore(this.leadList);
        this.dataSourceLeads = new MatTableDataSource(this.leadList);
        if (data.nrOfPages > 1) {
          this.hidePaginatorLeads = false;
        } else {
          this.hidePaginatorLeads = !(this.leadList.length > 5);
        }
      });
  }

  public onLeadsSearchInput(event: any) {
    this.clearSearchFieldRestart = false;
    this.searchInput = event.target.value;
    this.isSearchedForLeads = true;
    this.resetFilters(false);
    this.activePageLeads = 0;

    if (this.searchOption == "Source name") {
      this.searchInput = event.target.value;
      this.getSearchedLeadsBySourceName(0, this.pageSizeLeads);
    } else if (this.searchOption == "Property Address") {
      this.searchInput = event.target.value;
      this.getSearchedLeadsByPropertyAddress(0, this.pageSizeLeads);
    }
  }

  private getSearchedDealsByApplicationId(
    pageNumber: number,
    nrOfResultsPerPage
  ) {
    this.loansService
      .getLoanByApplicationId(
        this.searchInput,
        this.myDealsActiveStatusFilters,
        pageNumber,
        nrOfResultsPerPage
      )
      .subscribe((data) => {
        this.populateMyDealsSearch(data);
      });
  }

  private getSearchedDealsByClientName(pageNumber: number, nrOfResultsPerPage) {
    this.loansService
      .getLoanByClientName(
        this.searchInput,
        this.myDealsActiveStatusFilters,
        pageNumber,
        nrOfResultsPerPage
      )
      .subscribe((data) => {
        this.populateMyDealsSearch(data);
      });
  }

  private getSearchedDealsBySourceName(pageNumber: number, nrOfResultsPerPage) {
    this.loansService
      .getLoanBySourceName(
        this.searchInput,
        this.myDealsActiveStatusFilters,
        pageNumber,
        nrOfResultsPerPage
      )
      .subscribe((data) => {
        this.populateMyDealsSearch(data);
      });
  }

  private getSearchedLeadsBySourceName(pageNumber: number, nrOfResultsPerPage) {
    this.loansService
      .getLeadsBySourceName(
        this.searchInput,
        this.leadsActiveStatusFilters,
        pageNumber,
        nrOfResultsPerPage
      )
      .subscribe((data) => {
        this.populateLeadsSearch(data);
      });
  }

  private getSearchedDealsByPropertyAddress(
    pageNumber: number,
    nrOfResultsPerPage
  ) {
    this.loansService
      .getMyDealsByPropertyAddress(
        this.searchInput,
        this.myDealsActiveStatusFilters,
        pageNumber,
        nrOfResultsPerPage
      )
      .subscribe((data) => {
        this.populateMyDealsSearch(data);
      });
  }

  public changeSearchOption(event: any) {
    this.searchOption = event.value;
    this.noLeadsWhenSearchBySourceName = true;
  }

  public changeSearchOptionForLeads(event: any) {
    this.searchOption = event.value;
    this.noLeadsWhenSearchBySourceName = true;
  }

  public onMyDealsApplicationIdSearchInput(event: any) {
    this.isSearchedForMyDeals = true;
    this.resetFilters(false);
    this.activePageMyDeals = 0;

    if (this.searchOption == "Application ID") {
      this.searchInput = event.target.value;
      this.getSearchedDealsByApplicationId(0, this.pageSizeMyDeals);
    } else if (this.searchOption == "Client name") {
      this.searchInput = event.target.value;
      this.getSearchedDealsByClientName(0, this.pageSizeMyDeals);
    } else if (this.searchOption == "Source name") {
      this.searchInput = event.target.value;
      this.getSearchedDealsBySourceName(0, this.pageSizeMyDeals);
    } else if (this.searchOption == "Property Address") {
      this.searchInput = event.target.value;
      /*
       * Logic that permit the entering of consecutiv spaces between words and the retrieval with success of loans
       */
      this.searchInput = this.searchInput.replace(/,/g, "");
      this.searchInput = this.searchInput.replace(/\s+/g, " ");
      this.getSearchedDealsByPropertyAddress(0, this.pageSizeMyDeals);
    }
  }

  private resetFilters(isFromLeads: boolean) {
    if (isFromLeads) {
      this.leadsActiveStatusFilters = [];
      this.leadsStatusFiltersChips[this.languageService.getLanguage()].map(
        (c) => (c.state = false)
      );
    } else {
      this.myDealsActiveStatusFilters = [];
      this.myDealsStatusChips[this.languageService.getLanguage()].map(
        (c) => (c.state = false)
      );
    }
  }

  public populateMyDealsSearch(data) {
    this.myDealsList = data.objectList;
    this.myDealsNumberOfPages = data.nrOfPages;
    this.noMyDeals = data.objectList.length == 0;
    this.displayCreditBureau = this.shouldDisplayCommitmentLetter(
      this.myDealsList
    );
    this.dataSourceMyDeals = new MatTableDataSource(this.myDealsList);
    for (let i = 0; i < this.myDealsList.length; i++) {
      var statusList = this.setLocalStatusList(
        this.myDealsList[i].possibleStatuses
      );
      this.statusListById.push({
        id: this.myDealsList[i].id,
        statuses: statusList,
      });
    }
    if (data.nrOfPages > 1) {
      this.hidePaginatorMyDeals = false;
    } else {
      this.hidePaginatorMyDeals = !(this.myDealsList.length > 5);
    }
  }

  public populateLeadsSearch(data) {
    this.leadList = data.objectList;
    if (this.leadList.length == 0) {
      this.noLeadsWhenSearchBySourceName = false;
    } else if (this.leadList.length > 0) {
      this.noLeadsWhenSearchBySourceName = true;
    }
    this.leadsNumberOfPages = data.nrOfPages;
    this.noLeads = data.objectList.length == 0;
    this.dataSourceLeads = new MatTableDataSource(this.leadList);
    for (let i = 0; i < this.leadList.length; i++) {
      var statusList = this.setLocalStatusList(
        this.leadList[i].possibleStatuses
      );
      this.statusListById.push({
        id: this.leadList[i].id,
        statuses: statusList,
      });
    }
    if (data.nrOfPages > 1) {
      this.hidePaginatorLeads = false;
    } else {
      this.hidePaginatorLeads = !(this.leadList.length > 5);
    }
  }

  public getPageSizeOptions() {
    return this.pageSizes;
  }

  public getNoLeadsWhenSearchMessage() {
    return !this.noSearchedLeadsMessage;
  }

  public getNoMyDeals() {
    return !this.noMyDeals;
  }

  public getNoLeads() {
    return !this.noLeads;
  }

  public getStatus(elem) {
    return elem.status;
  }

  public getStatusList(elem: any) {
    return this.statusList.get(elem.status);
  }

  public getClass(element) {
    if (
      element.status == "New" ||
      element.status == "Approved" ||
      element.status == "Nouvelle" ||
      element.status == "Approuvée"
    ) {
      return "classGreen";
    } else if (
      element.status == "Rejected" ||
      element.status == "Declined" ||
      element.status == "Rejetée" ||
      element.status == "Refusée"
    ) {
      return "classRed";
    } else if (
      element.status == "Funded" ||
      element.status == "Fonds déboursés"
    ) {
      return "classFunded";
    }
  }

  public onDeletingSearchAndSelection() {
    this.searchInput = null;
    this.searchOption = null;
    this.onMyDealsApplicationIdSearchInput;
  }

  public sortDataLeads(sort: MatSort) {
    if (sort.active && sort.direction !== "") {
      this.dataSourceLeads.data = this.dataSourceLeads.data.sort((a, b) => {
        const isAsc = sort.direction === "asc";
        return this.compare(a.receivedDate, b.receivedDate, isAsc);
      });
    }
  }

  public sortDataMyDeals(sort: MatSort) {
    if (sort.active && sort.direction !== "") {
      this.dataSourceMyDeals.data = this.dataSourceMyDeals.data.sort((a, b) => {
        const isAsc = sort.direction === "asc";
        return this.compare(a.receivedDate, b.receivedDate, isAsc);
      });
    }
  }

  private compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public onMyDealsFilterChange(e: MatChipEvent) {
    this.myDealsActiveStatusFilters = [];
    this.activePageMyDeals = 0;

    for (var chip of this.getMyDealsStatusChips()) {
      if (chip.state) {
        this.myDealsActiveStatusFilters.push(
          this.languageService.getLanguage() == LenderLanguage.FR
            ? this.mapStatusesFrenchEnglish.get(chip.name)
            : chip.name
        );
      }
    }
    this.myDealsActiveStatusFilters.length > 0
      ? (this.isMyDealsFilterActive = true)
      : (this.isMyDealsFilterActive = false);

    if (this.isSearchedForMyDeals) {
      switch (this.searchOption) {
        case "Application ID": {
          this.getSearchedDealsByApplicationId(
            this.activePageMyDeals,
            this.myDealsNumberOfResultsPerpage == undefined
              ? 5
              : this.myDealsNumberOfResultsPerpage
          );
          break;
        }
        case "Client name": {
          this.getSearchedDealsByClientName(
            this.activePageMyDeals,
            this.myDealsNumberOfResultsPerpage == undefined
              ? 5
              : this.myDealsNumberOfResultsPerpage
          );
          break;
        }
        case "Source name": {
          this.getSearchedDealsBySourceName(
            this.activePageMyDeals,
            this.myDealsNumberOfResultsPerpage == undefined
              ? 5
              : this.myDealsNumberOfResultsPerpage
          );
          break;
        }
        case "Property Address": {
          this.getSearchedDealsByPropertyAddress(
            this.activePageMyDeals,
            this.myDealsNumberOfResultsPerpage == undefined
              ? 5
              : this.myDealsNumberOfResultsPerpage
          );
          break;
        }
      }
    } else {
      this.getMyDeals(
        this.activePageMyDeals,
        this.myDealsNumberOfResultsPerpage == undefined
          ? 5
          : this.myDealsNumberOfResultsPerpage
      );
    }
  }

  public onLeadsFilterChange(e: MatChipEvent) {
    this.leadsActiveStatusFilters = [];
    this.activePageLeads = 0;
    this.filterFlagForStatus = true;

    for (var chip of this.leadsStatusFiltersChips[
      this.languageService.getLanguage()
    ]) {
      if (chip.state) {
        this.leadsActiveStatusFilters.push(
          this.languageService.getLanguage() == LenderLanguage.FR
            ? this.mapStatusesFrenchEnglish.get(chip.name)
            : chip.name
        );
      }
    }
    this.leadsActiveStatusFilters.length > 0
      ? (this.isLeadsFilterActive = true)
      : (this.isLeadsFilterActive = false);

    if (this.isSearchedForLeads) {
      switch (this.searchOption) {
        case "Property Address": {
          this.getSearchedLeadsByPropertyAddress(
            this.activePageLeads,
            this.leadsNumberOfResultsPerpage == undefined
              ? 5
              : this.leadsNumberOfResultsPerpage
          );
          break;
        }
        case "Source name": {
          this.getSearchedLeadsBySourceName(
            this.activePageLeads,
            this.leadsNumberOfResultsPerpage == undefined
              ? 5
              : this.leadsNumberOfResultsPerpage
          );
          break;
        }
      }
    } else {
      this.getLeads(
        this.activePageLeads,
        this.leadsNumberOfResultsPerpage == undefined
          ? 5
          : this.leadsNumberOfResultsPerpage
      );
    }
  }

  public openMap(element: any) {
    window.open(
      "map?streetNumber=" +
        element.subjectProperty.streetNumber +
        "&streetName=" +
        element.subjectProperty.streetName +
        "&city=" +
        element.subjectProperty.city +
        "&postalCode=" +
        element.subjectProperty.postalFsa +
        element.subjectProperty.postalLdu +
        "&province=" +
        element.subjectProperty.province,
      "_blank"
    );
  }

  repopulateLeads(event: any) {
    this.noLeadsWhenSearchBySourceName = true;
    this.activePageLeads = 0;
    this.setFilterFlagForStatus();
    this.getLeads(0, this.pageSizeLeads);
    this.isSearchedForLeads = false;
    this.clearSearchFieldRestart = true;
  }

  repopulateMyDeals(event: any) {
    this.activePageMyDeals = 0;
    this.setFilterFlagForStatus();
    this.getMyDeals(0, this.pageSizeMyDeals);
    this.isSearchedForMyDeals = false;
  }

  onStoryClick(event: any, element: any, originatorNotes: any) {
    if (element.applicationType == "Pre-Approval") {
      this.storyAddress = element.loanApplicationId;
    } else {
      this.storyAddress =
        element.subjectProperty.streetNumber +
        " " +
        element.subjectProperty.streetName +
        "," +
        element.subjectProperty.city +
        ", " +
        element.subjectProperty.province +
        " " +
        element.subjectProperty.postalFsa +
        " " +
        element.subjectProperty.postalLdu;
    }
    this.storyContent = originatorNotes;
    this.storyDrawer.toggle();
  }

  keyDownStatus(event: any, n) {
    if (event.key == "Tab") {
      this.selectedStatusByKey = true;
      document
        .getElementById("leads-select-" + n)
        .classList.add("focus-status");
    }
  }

  keyDownGoogleButton(n) {
    if (n > 0) {
      document
        .getElementById("leads-select-" + (n - 1))
        .classList.remove("focus-status");
    }
  }

  keyUpRemoveFocus() {
    document
      .getElementById("leads-select-" + (this.pageSizeLeads - 1))
      .classList.remove("focus-status");
  }

  getLeadsChip(index: number) {
    return this.leadsStatusFiltersChips[index];
  }

  getLeadsChipState(index: number) {
    return this.leadsStatusFiltersChips[index].state;
  }

  getLeadsChipName(index: number) {
    return this.leadsStatusFiltersChips[index].name;
  }

  hideGoogleMapsButton(element: any) {
    return (
      (element.applicationType == "Pre-Approval" &&
        (element.subjectProperty.streetNumber == null ||
          element.subjectProperty.streetNumber == "N/A" ||
          element.subjectProperty.streetNumber == "")) ||
      element.subjectProperty.streetName == null ||
      element.subjectProperty.streetName == "N/A" ||
      element.subjectProperty.streetName == "" ||
      element.subjectProperty.city == null ||
      element.subjectProperty.city == "N/A" ||
      element.subjectProperty.city == "" ||
      element.subjectProperty.postalFsa == null ||
      element.subjectProperty.postalFsa == "N/A" ||
      element.subjectProperty.postalFsa == "" ||
      element.subjectProperty.postalLdu == null ||
      element.subjectProperty.postalLdu == "N/A" ||
      element.subjectProperty.postalLdu == "" ||
      element.subjectProperty.province == null ||
      element.subjectProperty.province == "N/A" ||
      element.subjectProperty.province == ""
    );
  }

  getPreApprovalProvince(element: any) {
    if (
      element.subjectProperty.province != null &&
      element.subjectProperty.province != undefined &&
      element.subjectProperty.province != "N/A" &&
      element.subjectProperty.province != ""
    ) {
      return element.subjectProperty.province;
    }
    return "N/A";
  }

  getAddress(element: any) {
    let result = "";
    result +=
      element.subjectProperty.streetNumber != "" &&
      element.subjectProperty.streetNumber != "N/A"
        ? element.subjectProperty.streetNumber + " "
        : "";
    result +=
      element.subjectProperty.streetName != "" &&
      element.subjectProperty.streetName != "N/A"
        ? element.subjectProperty.streetName + ",<br />"
        : "";
    result +=
      element.subjectProperty.city != "" &&
      element.subjectProperty.city != "N/A"
        ? element.subjectProperty.city + ", "
        : "";
    result +=
      element.subjectProperty.province != null &&
      element.subjectProperty.province != "" &&
      element.subjectProperty.province != "N/A"
        ? element.subjectProperty.province + "<br />"
        : "";
    result +=
      element.subjectProperty.postalFsa != "" &&
      element.subjectProperty.postalFsa != "N/A"
        ? element.subjectProperty.postalFsa + " "
        : "";
    result +=
      element.subjectProperty.postalLdu != "" &&
      element.subjectProperty.postalLdu != "N/A"
        ? element.subjectProperty.postalLdu
        : "";
    result +=
      result == ""
        ? "N/A " +
          PromptsMessages.translate(
            "PRE_APPROVAL",
            this.languageService.getLanguage()
          )
        : element.applicationType == "Pre-Approval"
        ? "<br > " +
          PromptsMessages.translate(
            "PRE_APPROVAL",
            this.languageService.getLanguage()
          )
        : "";

    return result;
  }

  getAddressSecond(element: any) {
    let result = "";
    result +=
      element.subjectProperty.streetNumber != "" &&
      element.subjectProperty.streetNumber != "N/A"
        ? element.subjectProperty.streetNumber + " "
        : "";
    result +=
      element.subjectProperty.streetName != "" &&
      element.subjectProperty.streetName != "N/A"
        ? element.subjectProperty.streetName + ","
        : "";
    result +=
      element.subjectProperty.city != "" &&
      element.subjectProperty.city != "N/A"
        ? element.subjectProperty.city + ", "
        : "";
    result +=
      element.subjectProperty.province != null &&
      element.subjectProperty.province != "" &&
      element.subjectProperty.province != "N/A"
        ? element.subjectProperty.province + " "
        : "";
    result +=
      element.subjectProperty.postalFsa != "" &&
      element.subjectProperty.postalFsa != "N/A"
        ? element.subjectProperty.postalFsa + " "
        : "";
    result +=
      element.subjectProperty.postalLdu != "" &&
      element.subjectProperty.postalLdu != "N/A"
        ? element.subjectProperty.postalLdu
        : "";
    result +=
      result == ""
        ? "N/A " +
          PromptsMessages.translate(
            "PRE_APPROVAL",
            this.languageService.getLanguage()
          )
        : element.applicationType == "Pre-Approval"
        ? " " +
          PromptsMessages.translate(
            "PRE_APPROVAL",
            this.languageService.getLanguage()
          )
        : "";

    return result;
  }
}
