<title>{{'creditBureauRaport' | translate}}</title>

<div *ngFor="let creditBureau of getCreditBureaus(); let i = index">

        <div class="bureauHeader">
            <div *ngIf="i == 0" class="creditBureauNameDb">
                <div>{{getCreditBureauNameDd(creditBureau)}}</div>
                <div  class="creditBureauAppId">{{getApplicationId()}}</div>
            </div>
            <div *ngIf="i != 0" class="creditBureauNameDbSecond">
                <div>{{getCreditBureauNameDd(creditBureau)}}</div>
            </div>
            <h1 *ngIf="i == 0" style="width: 16vw;">{{'creditBureauView' | translate}}</h1>
            <div class="creditBureauDatePulled">{{getDatePulled(creditBureau)}}</div>
        </div>
   
    
    <div style="width: 55vw;height: 3.5px;background-color: black;margin-left: 2%;margin-bottom: 20px;"></div>
    
    <div class="creditBureauBody" id="main-content">
        <h2 *ngIf="i == 0" style="padding:2%; font-weight: 400;">{{'creditBureauRaport' | translate}}</h2>
        <div class="bureauBody">
            {{getCreditBureauReportText(creditBureau)}}
        </div>
    </div>
</div>


<div [hidden]=true>
    <div id="creditBureauBody" >
        <div *ngFor="let creditBureau of getCreditBureaus(); let j = index">
            <div class="bureauHeaderHidden">
                <div *ngIf="j == 0" class="creditBureauNameDb">
                    <div>{{getCreditBureauNameDd(creditBureau)}}</div>
                    <div  class="creditBureauAppId">{{getApplicationId()}}</div>
                </div>
                <div *ngIf="j != 0" class="creditBureauNameDbSecond">
                    <div>{{getCreditBureauNameDd(creditBureau)}}</div>
                </div>
                <h1 *ngIf="j == 0" class="creditBureauTitle">{{'creditBureauView' | translate}}</h1>
                <div class="creditBureauDatePulled">{{getDatePulled(creditBureau)}}</div>
            </div>

            <div *ngIf="j == 0" class="creditBureauSubtitle">
                {{'creditBureauRaport' | translate}}
            </div>
            <div class="bureauBody">
                {{getCreditBureauReportText(creditBureau)}}
            </div>
            <br><br>
        </div>
    </div>
</div>