<mat-dialog-content id="review-dialog-lenderview" class="col-sm-12">
    <div class="col-sm-15 mt-3 d-flex" id="approve-deal-div">{{data.textBody[0].dialogBoxTitle}}</div> 
    <div class="col-sm-15 mt-3 mb-2" id="please-attach-div" *ngIf="data.textBody[0].isAttachedDocument">{{data.textBody[0].attachedDocumentText}}</div>
    <div  *ngIf = "listDropDown" style="margin-top: 20px; margin-bottom: 20px;">
        <div style="margin-bottom: 10px;">{{'denialRaesons' | translate}}</div>
        <mat-form-field appearance="fill" style="width: 350px;">
            
        <mat-label>{{'selectReason' | translate}}</mat-label>
            <mat-select required tabindex="0" #select (selectionChange)="setOption(select.selected)" style="text-align-last: center!important;">
                <label></label>
                <mat-option value="Loan to Value" >{{'denialReason1' | translate}}</mat-option>
                <mat-option value="Credit/Capacity/GDS/TDS" >{{'denialReason2' | translate}}</mat-option>
                <mat-option value="Character" >{{'denialReason3' | translate}}</mat-option>
                <mat-option value="Property/Location" >{{'denialReason4' | translate}}</mat-option>
                <mat-option value="Rate/terms/fees not acceptable" >{{'denialReason5' | translate}}</mat-option>
                <mat-option value="Deal size (outside min/max)" >{{'denialReason6' | translate}}</mat-option>
                <mat-option value="Suspicious transaction" >{{'denialReason7' | translate}}</mat-option>
                <mat-option value="Duplicate transaction" >{{'denialReason8' | translate}}</mat-option>
                <mat-option value="Turned down by broker/client" >{{'denialReason9' | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div style="margin-bottom: -15px; color:#b9170c; font-size: 15.5px;margin-top: 10px;">{{'denialReasonNotSentToExpert' | translate}}</div>
    </div>
    <div *ngIf="uploadNewFile && data.textBody[0].isAttachedLetter">
        <div class="col-sm-15 mt-4 required-element" id="attach-letter-div">
            {{data.textBody[0].attachedLetterText}}
        </div>
        <div class="col-sm-15 drag-drop-panel mt-2" *ngIf="!isUploaded" appDragAndDrop (onFileDropped)="loadFile($event)">
            <div>
                <div class="h-25 d-flex justify-content-center align-items-center mt-1">
                    {{'dragAndDrop' | translate}}
                </div>
                <div class="h-25 d-flex justify-content-center align-items-center">
                    {{'or' | translate}}
                </div>
                <div class="h-25 d-flex justify-content-center align-items-center mt-2">
                    <input type="file" accept="application/pdf" id="files" (change)="loadFile($event.target.files)"hidden />
                    <label class="browse-button" for="files">{{'browse' | translate}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-15" *ngIf="isUploaded" id="output">
        <div *ngIf="incorrectExtension" id="incorrect-file-div" class="col-sm-8 d-flex">
            {{data.textBody[0].fileExtensionError}}
            <div>
                <i class="material-icons delete-icon" id="delete-icon" (click)="reload($event)">attachment</i>
            </div>
        </div>
        <div *ngIf="tooManyFiles || biggerThan10MB || zeroSizeFile" id="many-files-div" class="col-sm-8 d-flex">
            {{data.textBody[0].pdfSizeError}}
            <div>
                <i class="material-icons delete-icon" (click)="reload($event)">attachment</i>
            </div>
        </div>
        <div *ngFor="let file of fileNames; let i = index" class="col-sm-10 d-flex">
            <a id="download-link" download={{file}} href={{downloadLink}}> {{file}}</a>
            <div>
                <i class="material-icons delete-icon" (click)="deleteFile($event, i)">delete</i>
            </div>
        </div>
    </div>
    <div class="col-sm-15 mt-3" aria-label="additional-notes-div" id="additional-notes-div">
        {{data.textBody[0].attachedNotesText}}
    </div>
    <div class="col-sm-15  mt-2 text-area">
        
        <textarea aria-label="additional-notes-text" #notesArea class="notes-panel" name="Text1" maxlength="4001" cols="65" id="additional-notes-text" (keyup)="changeNotesText($event)"></textarea>
        <span style="color:red" *ngIf="limitHasBeenReached==true">
            {{data.textBody[0].notesLengthError}}
      </span> 

    </div>
    <div class="row mt-2 button-div">
        <div class="col-sm-6 mr-4"> </div>
        <div class="col-sm-4  d-flex">
            <div *ngIf="data.isCancelBtn">
                <button class="cancel-button float-right mr-2" mat-stroked-button color="accent" (click)="cancel()">
                    {{data.textFirstButton}}
                </button>
            </div>
            <div *ngIf="data.isOkBtn">
                <button [disabled]="(isApproved && !isUploaded) || incorrectUpload || limitHasBeenReached || notesLengthError || denialReasonNotSelected || isApproved || sendMessageToBroker" class="approve-button-prompt float-right" mat-flat-button color="primary" (click)="approve()">
                    {{data.textSecondButton}}
                </button>
            </div>
        </div>
    </div>
    <div class="col-sm-15 mt-3">
    </div>
</mat-dialog-content>
