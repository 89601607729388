export class PromptsMessages{
    public static CANCEL_MESSAGE = 'Are you sure you want to discard these changes?';
    public static SAVE_MESSAGE_ACC_DET_LENDER = 'Are you sure you want to change this information?';
    public static SAVE_AFTER_PUBLISHED_TO_EXPERT = 'The changes have been saved!';
    public static MSG_1_REVIEW_LEADS = 'You are about to review this lead, which will move the application into your "My Deals" dashboard.  You will be charged $';
    public static MSG_2_REVIEW_LEADS = 'Please select Ok to proceed or Cancel to close this window.';
    public static MSG_3_REVIEW_LEADS = 'Please select Pay & Review to proceed or Cancel to close this window.';
    public static REJECT_LEADS = 'By rejecting this lead, the broker will be advised of your decision.';
    public static SAVE_LENDER_WITH_EXISTING_EMAIL_ADDRESS = 'A lender account with this e-mail address already exists under lender name: ';
    public static SAVE_LENDER_WITH_EXISTING_EMAIL_ADDRESS_AND_LENGTH_GREATER_THAN_ONE = 'Lender accounts with this e-mail address already exists under lender names: ';
    public static MSG1_PUBLISHED_TO_EXPERT = 'The request to publish this lender in Expert has been successfully submitted.';
    public static MSG2_PUBLISHED_TO_EXPERT = 'A TA will process the request as soon as possible.';
    public static SAVE_BTN_LR_INCOMPLETE_FORM = 'Please make sure both contracts and vetting conditions are marked as Yes prior to publishing the lender to Expert Broker.';
    public static SAVED_BUT_NOT_PUBLISHED ='The lender has been added to Filogix Private Lender Gateway, but has not been published to Expert'
    public static MSG1_RADIO_BUTTONS_PROMPT = 'Please address the following issue before saving the form:'
    public static MSG2_RADIO_BUTTONS_PROMPT = 'If you provided a credential number, then the credit bureau radio button should be selected as `Yes`. Click `OK` to remove the membership number or `Cancel` to leave the option marked as Yes.'
    public static FUNDED_MYDEALS = 'You are about to change the status of this loan application to "Funded". Do you want to continue?';
    public static APPROVED_MYDEALS_TITLE = 'Approve Deal';
    public static DECLINE_MYDEALS_TITLE = 'Decline Deal';
    public static IN_REVIEW_MYDEALS = 'You are about to change the status of this loan application to "In Review" and notify the broker accordingly. Do you want to continue?';
    public static REMOVE_MYDEALS = 'By removing this loan application you will no longer see it in your dashboard. Are you sure you want to remove it?';
    public static DIALOGUE_BOX_ATTACH_DOCUMENT = 'Please attach the required document and provide any additional comments.';
    public static DIALOGUE_BOX_ATTACH_LETTER = 'Attach commitment letter';
    public static DIALOGUE_BOX_ATTACH_NOTES = 'Additional notes for the broker';
    public static DIALOGUE_BOX_ATTACH_NOTES_MESSAGE = 'Please input your notes below';
    public static DIALOGUE_BOX_FILE_EXTENSION_ERROR = 'Incorrect file extension! Only PDF allowed.';
    public static DIALOGUE_BOX_PDF_SIZE_ERROR = 'Only one PDF of size greater than 0 and at most 1.3 MB is maximum allowed!';
    public static DIALOGUE_BOX_NOTES_LENGTH_ERROR = '4000 characters limit reached!';
    public static DIALOGUE_BOX_BUTTON_OK = 'Ok';
    public static DIALOGUE_BOX_BUTTON_APPROVE = 'Approve';
    public static DIALOGUE_BOX_BUTTON_CANCEL = 'Cancel';
    public static DIALOGUE_BOX_BUTTON_SUBMIT = 'Submit';
    public static DIALOGUE_BOX_BUTTON_DECLINE = 'Decline';
    public static DIALOGUE_BOX_BUTTON_RUN = 'Run Report';
    public static CANCELLED_MYDEALS = 'By selecting this status you will cancel the loan application and notify the broker accordingly. Do you want to continue?';
    public static DECLINED_MYDEALS = 'Please select a reason and provide additional comments.';
    public static DIALOGUE_BOX_BUTTON_REVIEW = 'Pay & Review';
    public static IN_REVIEW_APP_SUMMARY = "Send message to broker";
    public static DIALOGUE_BOX_BUTTON_REMOVE = 'Remove';
    public static DIALOGUE_BOX_BUTTON_SEND_MESSAGE = 'Send';
    public static USER_DIALOGUE_SAVE_MESSAGE_ACCOUNT_DETAILS = 'Please ensure you click Save in the Account details page to complete the user creation process.';
    public static USER_DIALOGUE_SAVE_MESSAGE_LENDER_REGISTRATION = 'Please ensure you click Save in the lender Registration page to complete the user creation process.';
    public static USER_DIALOGUE_ADD_TO_AD = 'You will also need to add the newly created user in AWS Cognito';
    public static DELETE_USER_LENDER = 'Do you confirm?';
    public static DELETE_MESSAGE ='You are about to delete ';
    public static REMOVE_CARD_MSG='Are you sure you want to remove this payment method?'
    public static EDIT_CARD_MSG='Are you sure you want to edit this payment method?'
    public static REMOVE_CARD_WARNING='You are about to remove your Primary Card. Make sure you have another card selected.'
    public static PAYMENT_WARNING_NO_DEFAULT_CARD='You need to set a Primary Card in order to continue the payment process!'
    public static PAYMENT_FAILED_LINE1='There was an error processing your payment.'
    public static PAYMENT_FAILED_LINE2='Please check your card details or contact your bank.'
    public static CARD_FORM_INCOMPLETE='Please fill in all card details in order to Save a Card.'
    public static PAYMENT_ACCEPT_1ST_PART='Are you sure you want to make the payment of'
    public static PAYMENT_ACCEPT_2ND_PART = 'to review this lead?'
    public static USER_WARNING_LOGOUT = 'You will be logged out of Private Lender Gateway in 5:00 minutes. Please acknowledge this message by clicking "Ok",  or inside the page to continue working in Private Lender Gateway and avoid losing unsaved data.'
    public static ALREADY_PAYED_LOAN = 'You already paid for this loan application and you can access it from "My Deals" tab.';
    public static IN_REVIEW_NOTES = 'The loan application is being reviewed by';
    public static LENDER_USER_EMAIL_ALREADY_EXISTS = 'E-mail address already in use.';
    public static YES = 'Yes';
    public static NO = 'No';
    public static REPORTING_TYPE_1 = "Statuses Insights";
    public static REPORTING_TYPE_2 = "Credit Bureau Insights";
    public static REPORTING_TYPE_3 = "Property Address Report";
    public static REPORTING_TYPE_4 = "Denial Reasons Report";
    public static REPORTING_TYPE_5 = "Lender Code - Lender Name Report";
    public static REPORTING_TYPE_6 = "Paid Loans Overview";
    
    public static messages = {
        en:{
            APP_TITLE:'Private Lender Gateway Web Page',
            CANCEL_MESSAGE : 'Are you sure you want to discard these changes?',
            ACCOUNT_DETAILS: 'Account Details',
            APPLICATION_SUMMARY:'Mortgage Application',
            REVIEW_LEAD:'Review Lead',
            MSG_1_REVIEW_LEADS : 'You are about to review this lead, which will move the application into your "My Deals" dashboard.  You will be charged $',
            MSG_3_REVIEW_LEADS : 'Please select Pay & Review to proceed or Cancel to close this window.',
            MSG_4_REVIEW_LEADS: '.',
            DIALOGUE_BOX_BUTTON_REVIEW : 'Pay & Review',
            DIALOGUE_BOX_BUTTON_CANCEL : 'Cancel',
            APPROVED_MYDEALS_TITLE : 'Approve Deal',
            DIALOGUE_BOX_ATTACH_DOCUMENT : 'Please attach the required document and provide any additional comments.',
            DIALOGUE_BOX_ATTACH_LETTER : 'Attach commitment letter',
            DIALOGUE_BOX_ATTACH_NOTES : 'Additional notes for the broker',
            DIALOGUE_BOX_FILE_EXTENSION_ERROR : 'Incorrectf file extension! Only PDF allowed.',
            DIALOGUE_BOX_PDF_SIZE_ERROR : 'Only one PDF of size greater than 0 and at most 1.3 MB is maximum allowed!',
            DIALOGUE_BOX_NOTES_LENGTH_ERROR : '4000 characters limit reached!',
            DIALOGUE_BOX_BUTTON_APPROVE : 'Approve',
            DECLINE_MYDEALS_TITLE : 'Decline Deal',
            DIALOGUE_BOX_BUTTON_DECLINE : 'Decline',
            DIALOGUE_BOX_BUTTON_SUBMIT : 'Submit',
            FUNDED_MYDEALS : 'You are about to change the status of this loan application to "Funded". Do you want to continue?',
            IN_REVIEW_MYDEALS : 'You are about to change the status of this loan application to "In Review" and notify the broker accordingly. Do you want to continue?',
            PAYMENT_WARNING_NO_DEFAULT_CARD : 'You need to set a Primary Card in order to continue the payment process!',
            PAYMENT_ACCEPT_1ST_PART : 'Are you sure you want to make the payment of',
            PAYMENT_ACCEPT_2ND_PART : 'to review this lead?',
            REMOVE_CARD_WARNING : 'You are about to remove your Primary Card. Make sure you have another card selected.',
            REMOVE_CARD_MSG :'Are you sure you want to remove this payment method?',
            ESTIMATED: 'Estimated',
            USING: ' using ',
            QUESTION_MARK_RESUBMITED_1:"There is more than one submission for Application ID \n",
            QUESTION_MARK_RESUBMITED_2:". You can remove this version of the \n application if you are proceeding with another version",
            IN_REVIEW_APP_SUMMARY : "Send message to broker",
            DIALOGUE_BOX_ATTACH_NOTES_MESSAGE : 'Please input your notes below',
            DIALOGUE_BOX_BUTTON_SEND_MESSAGE : 'Send',
            MESSAGE_TO_BROKER_SENT:"Your message was sent",
            REMOVE_MYDEALS : 'By removing this loan application you will no longer see it in your dashboard. Are you sure you want to remove it?',
            DIALOGUE_BOX_BUTTON_REMOVE : 'Remove',
            APPLICATION_WAS:"Application was ",
            YEARS:" years",
            YEAR:" year",
            MONTHS:" months",
            MONTH:" month",
            AND:" and ",
            CANCEL_BUTTON:"Cancel",
            SAVE_MESSAGE_ACC_DET_LENDER : 'Are you sure you want to change this information?',
            YES : 'Yes',
            NO : 'No',
            DASHBOARD:'Dashboard',
            VIDEO_MESSAGE_TITLE : "Welcome to Filogix Private Lender Gateway!",
            VIDEO_MESSAGE : "Before getting started, we recommend you view our quick 3-minute demo video, in order to get a glimpse at the main features of the application. If you need to watch it again, this demo video will be available at all times in our Help section within the application.",
            WATCH_NOW: 'Watch Now',
            REMIND_ME_LATER: 'Remind me later',
            DELETE_MESSAGE : 'You are about to delete ',
            DELETE_USER_LENDER : 'Do you confirm?',
            PRE_APPROVAL: "(pre-approval)"
        },
        fr: {
            APP_TITLE:'Passerelle Filogix pour les prêteurs privés',
            CANCEL_MESSAGE : 'Voulez-vous vraiment annuler ces modifications ?',
            ACCOUNT_DETAILS: 'Renseignements sur le compte',
            APPLICATION_SUMMARY:'Demande de prêt hypothécaire',
            REVIEW_LEAD:'Examiner la piste de client éventuel',
            MSG_1_REVIEW_LEADS : 'Vous êtes sur le point d’examiner cette piste de client éventuel, ce qui transférera la demande à la section "Mes transactions" de votre tableau de bord. Des frais de $',
            MSG_3_REVIEW_LEADS: 'Veuillez sélectionner Payer et examiner pour continuer ou sur Annuler pour fermer cette fenêtre.',
            MSG_4_REVIEW_LEADS: ' vous seront facturés.',
            DIALOGUE_BOX_BUTTON_REVIEW : 'Payer et examiner',
            DIALOGUE_BOX_BUTTON_CANCEL :'Annuler',
            APPROVED_MYDEALS_TITLE : 'Approuver la demande',
            DIALOGUE_BOX_ATTACH_DOCUMENT : 'Veuillez joindre le document requis et indiquer tout commentaire supplémentaire.',
            DIALOGUE_BOX_ATTACH_LETTER : 'Joignez la lettre d’engagement',
            DIALOGUE_BOX_ATTACH_NOTES : 'Remarques supplémentaires à l’intention du courtier',
            DIALOGUE_BOX_FILE_EXTENSION_ERROR : 'Extension de fichier incorrecte ! Seul le PDF est autorisé.',
            DIALOGUE_BOX_PDF_SIZE_ERROR : 'Un seul PDF de taille supérieure à 0 et de 1,3 Mo maximum est autorisé au maximum !',
            DIALOGUE_BOX_NOTES_LENGTH_ERROR : 'Limite de 4000 caractères atteinte !',
            DIALOGUE_BOX_BUTTON_APPROVE : 'Approuver',
            DECLINE_MYDEALS_TITLE : 'Refuser la demande',
            DIALOGUE_BOX_BUTTON_DECLINE : 'Refuser',
            DIALOGUE_BOX_BUTTON_SUBMIT : 'Soumettre',
            FUNDED_MYDEALS : 'Vous êtes sur le point de changer l’état de cette demande de prêt pour « Fonds déboursés ». Voulez-vous continuer ?',
            IN_REVIEW_MYDEALS : 'Vous êtes sur le point de changer l’état de cette demande de prêt pour « En cours d’examen » et d’en aviser le courtier. Voulez-vous continuer ?',
            PAYMENT_WARNING_NO_DEFAULT_CARD : 'You need to set a Primary Card in order to continue the payment process!',
            PAYMENT_ACCEPT_1ST_PART : 'Êtes-vous sûr de vouloir effectuer le paiement de',
            PAYMENT_ACCEPT_2ND_PART : 'pour examiner ce prospect ?',
            REMOVE_CARD_WARNING : "Vous êtes sur le point de retirer votre carte principale. Assurez-vous d'avoir sélectionné une autre carte.",
            REMOVE_CARD_MSG : 'Voulez-vous vraiment supprimer ce mode de paiement ?',
            ESTIMATED: 'Estimé',
            USING: ' en utilisant ',
            QUESTION_MARK_RESUBMITED_1:"La demande ",
            QUESTION_MARK_RESUBMITED_2:" a été envoyée plus d’une fois. \n Vous pouvez supprimer cette version de la demande\n si vous continuez avec une autre version.",
            IN_REVIEW_APP_SUMMARY : "Envoyez un message au courtier",
            DIALOGUE_BOX_ATTACH_NOTES_MESSAGE : 'Veuillez entrer vos notes ci-dessous',
            DIALOGUE_BOX_BUTTON_SEND_MESSAGE : 'Envoyer',
            MESSAGE_TO_BROKER_SENT:"Votre message a été envoyé",
            REMOVE_MYDEALS : 'En supprimant cette demande de prêt, vous ne la verrez plus à votre tableau de bord. Voulez-vous vraiment la supprimer ?',
            DIALOGUE_BOX_BUTTON_REMOVE : 'Supprimer',
            APPLICATION_WAS:"La demande était ",
            YEARS:" années",
            YEAR:" an",
            MONTHS:" mois",
            MONTH:" mois",
            AND:" et ",
            CANCEL_BUTTON:"Annuler",
            SAVE_MESSAGE_ACC_DET_LENDER : 'Voulez-vous vraiment modifier ces renseignements ?',
            YES : 'Oui',
            NO : 'Non',
            DASHBOARD:'Tableau de bord',
            VIDEO_MESSAGE_TITLE : "Welcome to Filogix Private Lender Gateway!",
            VIDEO_MESSAGE : "Before getting started, we recommend you view our quick 3-minute demo video, in order to get a glimpse at the main features of the application. If you need to watch it again, this demo video will be available at all times in our Help section within the application.",
            WATCH_NOW: 'Watch Now',
            REMIND_ME_LATER: 'Remind me later',
            DELETE_MESSAGE : 'Vous êtes sur le point de supprimer ',
            DELETE_USER_LENDER : 'Voulez-vous continuer ?',
            PRE_APPROVAL: "(pré-approbation)"
        }   
    }

    public static translate(messageId, language) {
        return this.messages[language][messageId];
    }
}
