<body>
    <div class="errorTitle">
        {{'page_not_found' | translate }}
    </div>
    <div class="iconBox">
        <img class="errorIcon" src="../../assets/Icon-1.svg" />
    </div>
    <div class="errorMessage">
        {{'misspelled_url' | translate }}
    </div>
    <div class="buttonBox">
        <button id="payButton" mat-stroked-button dense class="buttonBack" (click)="exit()"> 
            {{buttonText | translate}}
        </button>
    </div>
    <div class="supportBox">
        <a href="mailto: abc@example.com" class="supportLink">{{'Support' | translate}}</a>
    </div>
</body>
