import { Component, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { LenderService } from '../../services/api/lender.service';
import { Router } from '@angular/router';
import { ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ProfileDetails } from '../../models/profile-details.model';
import { MatTableDataSource } from "@angular/material/table";

interface Size {
  value: number;
  viewValue: number;
}

@Component({
  selector: 'app-lenders-list',
  templateUrl: './lenders-list.component.html',
  styleUrls: ['./lenders-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LendersListComponent implements OnInit {
  @ViewChildren('buttons') buttons: QueryList<ElementRef>;
  @ViewChildren(MatPaginator, { read: true }) paginator = new QueryList<MatPaginator>();

  public lenderCode: string;
  public pageNumber = 0;
  public searchInput;
  public dataSource: MatTableDataSource<ProfileDetails>;
  public search: string;
  public displayedColumns: string[] = [
    'lenderName',
    'primaryContact',
    'id',
    'isActive',
    'details'
  ]
  public paginatorPageSize = 10;
  public totalNumberOfPages: number;
  public stringForEventSearch: string[];
  public pageSizes: Size[] = [
    { value: 10, viewValue: 10 },
    { value: 20, viewValue: 20 },
    { value: 50, viewValue: 50 },
    { value: 100, viewValue: 100 },
  ];
  public isSearchedForLendersList = false;

  constructor(private router: Router, private lenderService: LenderService, private cdr: ChangeDetectorRef) { }
  public lendersList: ProfileDetails[];
  public noLenders = false;

  ngOnInit() {
    this.getAllLenders();
  }

  public getAllLenders() {
    this.refresh();
    this.dataSource = new MatTableDataSource();
    this.lenderService.getLendersWithoutParameters()
      .subscribe(resp => {
        this.lendersList = resp.objectList;
        for (let i = 0; i < this.lendersList.length; i++) {
          this.lendersList[i].contactDetails.firstName = decodeURI(this.lendersList[i].contactDetails.firstName);
          this.lendersList[i].contactDetails.lastName = decodeURI(this.lendersList[i].contactDetails.lastName);
        }
        this.totalNumberOfPages = resp.nrOfPages;
        this.dataSource.data = this.lendersList;
        if (this.totalNumberOfPages < 1 && this.lendersList.length == 0) {
          this.noLenders = true;
        }
      });
    this.cdr.markForCheck();
  }

  getSearchedLendersListByLenderCodeOrLenderName(pageNumber: number, nrOfResultsPerPage) {
    this.refresh();
    this.lenderService.getLenderByLenderCodeOrLenderName(this.searchInput, pageNumber, nrOfResultsPerPage)
      .subscribe(resp => {
        this.lendersList = resp.objectList;
        this.totalNumberOfPages = resp.nrOfPages;
        this.dataSource = new MatTableDataSource(this.lendersList);
        this.refresh();
      });
  }

  getLenders(pageNumber: number, nrOfResultsPerPage: number) {
    this.lenderService.getLenders(pageNumber, nrOfResultsPerPage)
      .subscribe(resp => {
        this.lendersList = resp.objectList;
        this.totalNumberOfPages = resp.nrOfPages;
        this.dataSource.data = this.lendersList;
      });
  }
  viewLender(event: any, i: number) {
    var lenderCode = this.dataSource.data[i].lenderCode;
    var id = this.dataSource.data[i].id;
    this.router.navigate(['accountDetailsCS/'], { queryParams: { lenderCode: lenderCode, id: id } });
  }

  refresh() {
    this.cdr.detectChanges();
  }

  public getNumberOfPages() {
    return this.totalNumberOfPages;
  }

  public onPageChange(event) {
    if (this.isSearchedForLendersList) {
      if (event.pageSize != this.paginatorPageSize) {
        this.paginatorPageSize = event.pageSize;
        this.getSearchedLendersListByLenderCodeOrLenderName(0, event.pageSize);
      }
      else {
        this.getSearchedLendersListByLenderCodeOrLenderName(event.activePage, event.pageSize);
      }
    }

    if (event.pageSize != this.paginatorPageSize && !this.isSearchedForLendersList) {
      this.paginatorPageSize = event.pageSize;
      this.getLenders(0, event.pageSize);
    } else if (event.pageSize == this.paginatorPageSize && !this.isSearchedForLendersList) {
      this.getLenders(event.activePage, event.pageSize);
    }

    if (event.activePage == (this.pageNumber + 1)) {
      this.pageNumber = event.activePage;
    }
  }

  preventDefault(event: any) {
    event.preventDefault();
  }

  public displayPaginator() {
    if (this.dataSource) {
      if (this.totalNumberOfPages > 1) {
        return false;
      } else {
        return !(this.dataSource.data.length > 10);
      }
    }
    return false;
  }

  public searchByLenderCodeOrLenderName(event: any) {
    this.refresh();
    this.stringForEventSearch = event.target.value;
    if (this.stringForEventSearch[0] == " " || event.target.value == "") {
      this.isSearchedForLendersList = false;
      this.repopulateLendersList(event);
    }
    else {
      this.refresh();
      this.isSearchedForLendersList = true;
      this.searchInput = event.target.value;
      this.pageNumber = 0;
      this.getSearchedLendersListByLenderCodeOrLenderName(0, this.paginatorPageSize);
    }
  }

  public getPageSizeOptions() {
    return this.pageSizes;
  }

  public getNoLenders() {
    return !this.noLenders;
  }

  public repopulateLendersList(event: any) {
    this.isSearchedForLendersList = false;
    this.refresh();
    this.getLenders(this.pageNumber, this.paginatorPageSize)
  }

  public getActivePage() {
    return this.pageNumber;
  }
}