<div>
    <div class="col-sm-9" id="sign-in-div">
        <div id="logo-div">
            <h1>
                <img tabindex="0" alt ="Filogix logo" id="logo-img" src="{{'logoSrc' | translate}}" />
            </h1>
        </div>
        <div>
            <div tabindex="0" id="product-title-div">
                {{'loginLenderGateway' | translate}}
            </div>
        </div>
        <div class="row">
            <div tabindex="0" id="register-div">
                {{'login' | translate}}
            </div>
        </div>
        <div tabindex="0" class="row mt-3">
            <div id="register-details-div">
                {{'loginEnterUsername' | translate}}
            </div>
        </div>
        <div id="form-div">
            <form [formGroup]="loginForm" id="signin-form">
                <div class="col-sm-12 mt-4">
                    <mat-form-field>
                        <img alt ="profile-icon" class="prefix-icons" id="profile-icon" role="main" src="../../assets/profile.png" matPrefix />
                        <input matInput id="email-input" (input)="changeFieldNoSpaces($event, 'emailInput')" type="text" formControlName="emailInput" placeholder="{{'loginUsername' | translate}}" />
                    </mat-form-field>
                </div>
                <div style="height:20px">

                </div>
                <div class="col-sm-12  mt-2">
                    <mat-form-field>
                        <img alt="lock-icon" id="lock-icon" class="prefix-icons" src="../../assets/lock.png" matPrefix />
                        <input matInput id="password-input" (input)="changeFieldNoSpaces($event, 'password')" [type]="fieldTextType ? 'text' : 'password'" formControlName="password" placeholder="{{'loginPassword' | translate}}" />
                        <i id="eye-icon" tabindex="0" class="fa" [ngClass]="{
                          'fa-eye-slash': !fieldTextType,
                          'fa-eye': fieldTextType
                        }" (click)="toggleFieldTextType()"></i>
                    </mat-form-field>
                </div>

                <div class="row mt-3">
                    <a id="forgot-password-link" routerLink="/forgot-password" routerLinkActive="active">{{'loginForgotPassword' | translate}}</a>
                </div>
                <div class="row mt-3">
                    <div tabindex="0" id="terms-of-service-div">
                        {{'loginTerms' | translate}} 
                        <a href="https://www.finastra.com/privacy-policy" style="color: #493694"> {{'loginPrivacyPolicy' | translate}} </a>
                    </div>
                </div>
                <div style="height:30px">

                </div>
                <div id="button-div">
                    <button mat-flat-button color="primary" dense id="register-btn" (click)="login()">
                        <i id="arrow-icon" class="fa fa-long-arrow-right"></i> {{'login' | translate}}
                    </button>
                </div>
                <div style="height:20px">

                </div>
                <div tabindex="0" *ngIf="!validCredentials" id="invalid-cred-div" class="col-sm-12 mt-4">
                    {{'loginInvalidCredentials' | translate}}
                </div>
            </form>


        </div>
    </div>

</div>
