export class DTOLoanApplication {
    id: string
    loanApplicationId: string
    status: string
    lenderCode: string
    isPayed: boolean

    constructor(id: string, loanApplication: string, status: string, lenderCode: string, isPayed: boolean) {
        this.id = id
        this.loanApplicationId = loanApplication
        this.status = status
        this.lenderCode = lenderCode;
        this.isPayed = isPayed
    }
}