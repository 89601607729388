import { Directive, HostListener, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../../services/security/auth.service';
//import { PromptsMessages } from '../common/constants/promptsMessages';
import { PromptModel } from '../../models/prompt.model';
import { MatDialog } from '@angular/material/dialog';
import { GeneralPromptComponent } from '../../components/common/general-prompt/general-prompt.component';
import { Router } from '@angular/router';
import { PromptsMessages } from '../../components/common/constants/promptsMessages';
import { Observable } from 'rxjs';
import { Auth } from 'aws-amplify';



@Directive({
    selector: '[appActivityEvents]'
})
export class ActivityEventsDirective {

    private timeLastActivity: any = new Date();



    constructor(private authService: AuthService) { }


        @HostListener('mouseenter')
        @HostListener('mouseover')
        @HostListener('mouseout')
        @HostListener('mouseup')
        @HostListener('mousedown')
        @HostListener('mouseleave')
        @HostListener('click')
        @HostListener('drag')
        @HostListener('dragover')
        @HostListener('drop')
        @HostListener('document:keypress')
        @HostListener('document:keydown')
        @HostListener('document:focus')
        @HostListener('document:blur')
        @HostListener('document:submit')
        @HostListener('document:scroll')
    
    checkActivity() {
        let currentTime: any = new Date();
        let difference: any = (currentTime - this.timeLastActivity) / (60 * 1000);
        


        //reset token every 30 minutes if user is active
        if(difference > 30 && difference < 50){
            this.timeLastActivity = new Date();
            this.authService.renewToken();
        }

    }
}