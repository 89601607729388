<title>{{'creditBureauRaport' | translate}}</title>
<div>
    <button mat-flat-button color="primary" dense id="to-pdf-button" aria-label="Download document" (click)="convertToPDF()">
        <mat-icon style="margin-right: 4px; margin-left: -4px;" name="Download icon" aria-label="Download document icon">vertical_align_bottom</mat-icon>
        {{'downloadPdfButton' | translate}}
    </button>
</div>

<div *ngFor="let creditBureau of getCreditBureaus(); let i = index">

        <div class="bureauHeader">
            <div *ngIf="i == 0" class="creditBureauNameDb">
                <div>{{getCreditBureauNameDd(creditBureau)}}</div>
                <div  class="creditBureauAppId">{{getApplicationId()}}</div>
            </div>
            <div *ngIf="i != 0" class="creditBureauNameDbSecond">
                <div>{{getCreditBureauNameDd(creditBureau)}}</div>
            </div>
            <h1 *ngIf="i == 0" style="width: 16vw;">{{'creditBureauView' | translate}}</h1>
            <div class="creditBureauDatePulled">{{getDatePulled(creditBureau)}}</div>
        </div>
   
    
    <div style="width: 55vw;height: 3.5px;background-color: black;margin-left: 15%;margin-bottom: 20px;"></div>
    
    <div class="creditBureauBody" id="main-content">
        <h2 *ngIf="i == 0" style="padding:2%; font-weight: 400;">{{'creditBureauRaport' | translate}}</h2>
        <div class="bureauBody">
            {{getCreditBureauReportText(creditBureau)}}
        </div>
    </div>
</div>