import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/security/auth.service";
import { Router } from "@angular/router";
import { ProfileRole } from "../../models/profile-role.model";
import { VideoService } from "src/app/services/video-service/video.service";

@Component({
  selector: "app-auth-callback",
  templateUrl: "./auth-callback.component.html",
  styleUrls: ["./auth-callback.component.scss"],
})
export class AuthCallbackComponent implements OnInit {
  constructor(
    private videoService: VideoService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authService
      .completeAuthentication()
      .then(() => {
        if (
          this.authService.profileDetails.profileRole.includes(
            ProfileRole.SYSTEM_ADMIN
          )
        ) {
          this.router.navigateByUrl("/SystemAccess");
          return;
        } else if (
          this.authService.profileDetails.profileRole.includes(
            ProfileRole.REPORTING
          )
        ) {
          this.router.navigateByUrl("/reports");
          return;
        }
        this.router.navigateByUrl("");
        setTimeout(() => {
          this.videoService.updateLoginNumber();
        }, 1500);
      })
      .catch(() => {
        this.router.navigateByUrl("");
      });
  }
}
