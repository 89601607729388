
<title>Address Map view</title>

<div class="split left">
    <div id="map"></div>
</div>
<div class="split right">
    <div id="pano"></div>
</div>


