<app-header></app-header>

<div class="row d-flex" class="paymentPage">

    <div id="payment-block" style="min-width: 55%; max-width: 55%;height: fit-content;">
        <div class="paymentInfoTitle">
            {{'paymentInformation' | translate}}
        </div>

        <div class="paymentInfoDescription">
            {{'paymentText' | translate}}
        </div>

        <div class="errorMessageBox">
            <div *ngIf="getIsErrorPayment() == true" class="errorMessageText">
                {{getErrorPayment()}}
            </div>
            <div *ngIf="getIsErrorCard() == true" class="errorMessageText">
                {{getErrorCard()}}
            </div>
        </div>

        <div *ngIf="editOrAddCard">
            <form class="checkout" [formGroup]="paymentForm" class="addCardForm">
                <div class="addCardFields">
                    <div id="form-field" class="mt-4">
                        <div id="card-info" #cardInfo style="max-width: 95%;min-width: 95%;"></div>

                        <mat-error id="card-errors" role="alert" *ngIf="cardError">
                            <mat-icon class="cardError">{{'cancel' | translate}}</mat-icon>
                            &nbsp;{{ cardError }}
                        </mat-error>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 mt-4">
                            <mat-form-field class="nameField">
                                <input id="name-input" placeholder="{{'name' | translate}}" matInput formControlName="name"
                                    (input)="changeFieldNoSpaces($event,'name')">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" style="width: 630px;max-width: 100%;min-width: 100%;">
                        <div class="col-sm-6 mt-4">
                            <mat-form-field class="address1Field">
                                <input matInput placeholder="{{'address' | translate}}" formControlName="addressLine1"
                                    (input)="changeFieldNoSpaces($event,'addressLine1')">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 mt-4">
                            <mat-form-field class="address2Field">
                                <input #addressInput id="address-input" placeholder="{{'apSuite' | translate}}" matInput
                                    formControlName="addressLine2" (input)="changeFieldNoSpaces($event,'addressLine2')">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 mt-4">
                            <mat-form-field class="cityField">
                                <input #cityInput id="city-input" placeholder="{{'city' | translate}}" matInput formControlName="city"
                                    (input)="changeFieldNoSpaces($event,'city')">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" style="width: 630px;max-width: 100%;min-width: 100%;">
                        <div class="col-sm-6 mt-4">
                            <mat-form-field class="postalCodeField">
                                <input #postalCodeInput id="postalCode-input" placeholder="{{'postalCode' | translate}}" matInput
                                    formControlName="postalCode" (input)="changeFieldNoSpaces($event,'postalCode')">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 mt-4">
                            <mat-form-field class="prvinceField">
                                <mat-label>{{'province' | translate}}</mat-label>
                                <mat-select formControlName="province" aria-label="{{'province' | translate}}">
                                    <mat-option *ngFor="let elem of provinces" [value]="elem.name">
                                        {{elem.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="stripeAlignTerms">
                    <div class="stripe1">
                        <div>Powered by</div>
                        <b style="margin-left: 5px;margin-right: 10px;font-size: 14px;">stripe</b> | 
                        <div style="margin-left: 10px;">Terms</div>
                        <div style="margin-left: 10px;">Privacy</div>
                    </div>

                    <div class="d-flex" class="buttonsSize2">
                        <button id="cancel-card" class="mt-2 cancel-card" (click)="cancelEditOrAddCard()">
                            {{'cancel' | translate}}
                        </button>

                        <button [disabled]="getDisableSaveCard()" id="save-card" type="submit" class="mt-2 save-card"
                            (click)="addNewCard()">
                            {{'saveCard' | translate}}
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div *ngIf="editOrAddCard==false" class="addCardBox">
            <div style="min-height: 32rem;">
                <div *ngFor="let card of customerCards; let i=index" class="d-flex" style="min-width: 100%;max-width: 100%;">

                    <mat-form-field class="cardField">
                        <div style="display: inline-flex;">
                            <mat-icon class="creditCardIcon">credit_card</mat-icon>
                            <input id={{card+i}} value={{card.card.brand}}****{{card.card.last4}} matInput
                                   attr.aria-label="{{'cardNumber' | translate}}"
                                readonly="readonly"
                            >
                        </div>
                    </mat-form-field>

                    <div class="radioButtonsPrimary">
                            <div  *ngIf="currentPaymentMethod===card.id">
                                <mat-radio-button  id="primaryCard{{getCounter(card.id)}}"
                                                   aria-label="{{'primaryCard' | translate}}"
                                name="primaryCard" [checked]='true'></mat-radio-button>
                            </div>
                            <div *ngIf="currentPaymentMethod!==card.id">
                                <mat-radio-button  id="primaryCard{{getCounter(card.id)}}"
                                                   aria-label="{{'primaryCard' | translate}}"
                                                   (click)="setDefaultSourceExistingClient(card.id)"
                                name="primaryCard"></mat-radio-button>
                            </div>
                    </div>

                    <div  class="primaryBox">
                        <span [ngClass]="{'primaryCardPink':currentPaymentMethod===card.id, 'primaryCard':currentPaymentMethod!=card.id}" class="primaryCardPink" mat-stroked-button color="primary" dense>{{'primaryCard' | translate}}</span>
                    </div>

                    <i class="deleteCardButton" id="delete-icon" (click)="deleteCard($event,card.id )">delete</i>

                </div>

                <div class="mt-4">
                    <button id="add-card-button" mat-stroked-button color="primary" dense
                        (click)="addCardExistingClient($event)">
                        <span class="material-icons" style="font-size:20px; margin-right:8px; margin-bottom: 3px">
                            add
                        </span> {{'addCard' | translate}}
                    </button>
                </div>

            </div>
            <div class="stripeAlignTerms">
                <div class="stripe" style="min-height: 30px;max-height: 30px;"><div style="width: max-content;">Powered by</div> <b
                        style="margin-left: 5px;margin-right: 10px;font-size: 14px;">stripe</b> | <div
                        style="margin-left: 10px;">Terms</div>
                    <div style="margin-left: 10px;">Privacy</div>
                </div>

                <div class="buttonsSize2" style="display: inline-flex;float: right;margin-right: 2vw;">
                    <button (click)="exitPayment()" mat-stroked-button
                        style="margin-right: 10px;font-family: sans-serif;border: none;color: #755cd9;font-weight: 600;font-size: 14px;"
                        href="leads">{{'cancel' | translate}}</button>
                    <button [disabled]=getDisablePay() (click)="payHere()" id="payButton" mat-stroked-button dense
                        style="font-family: sans-serif; font-size: 15px; height: 35px; background-color:#694ED6; color:white; border-color: transparent;">
                        <img src="../../assets/gpp_good.svg" style="width: 20px;margin-top: -3px;" alt="GPP OK" />
                        {{'payAndReview' | translate}}</button>
                </div>
            </div>
        </div>
    </div>

    <div class="px-3 py-3 col-sm-3" id="order-block" style="height: fit-content;min-width: 25%; max-width: 25%;">

        <div class="yourOrder">
            {{'yourOrder' | translate}} {{clientName}}
        </div>

        <div class="applicationFee">
            {{'applicationReviewFee' | translate}}
        </div>

        <div class="billedText">
            {{'billedOneTime' | translate}}
        </div>

        <div class="promotionCode" style="min-width: 100%; max-width: 100%;">
            <mat-form-field class="promotionField" style="max-width: 14vw;min-width: 14vw;">
                <input id="promotion-code-input" placeholder="{{'promotionCode' | translate}}" matInput
                    (input)="getPromotionCode($event,'promotionCode')">
            </mat-form-field>

            <button id="apply-coupon-button" mat-stroked-button color="primary" dense [disabled]=getDisablePay() (click)="applyCoupon()"
                style="margin-left: 2vw;">
                <span class="material-icons" style="font-size:20px; margin-bottom: 3px">
                </span> {{'apply' | translate}}
            </button>
        </div>
        <div class="couponError">
            {{getCouponError()}}
        </div>

        <div class="totalBeforeTax">
            {{'totalBeforeTax' | translate}}: ${{amount}}
        </div>

        <div class="estimatedGstHstQst">
            {{getEstimatedGstHstQst()}}: ${{taxValue}}
        </div>

        <div class="totalAmount">
            {{'total' | translate}}: ${{totalAmount}}
        </div>
    </div>
</div>
