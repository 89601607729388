import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/security/auth.service';
import { ProfileRole } from '../../models/profile-role.model';
import { Router } from '@angular/router';
import { AuthGuardService } from '../../services/security/auth-guard.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    let redirectUrl = localStorage.getItem('redirectUrl');
    localStorage.removeItem('redirectUrl');
    if(redirectUrl == undefined || redirectUrl == null || redirectUrl == '/auth-callback' || redirectUrl == '/'){
      if(this.authService.profileDetails.profileRole.includes(ProfileRole.LENDER_USER)){
        if (this.authService.profileDetails['sentToExpert']) {
          redirectUrl = '/leads';
        }else {
          redirectUrl = '/accountDetailsLender?lenderCode=' + this.authService.profileDetails['fk_LenderAccount'];
        }
      }else if(this.authService.profileDetails.profileRole.includes(ProfileRole.CS_ADMIN)){
        redirectUrl = '/lendersList';
      }else{
        redirectUrl = '/errorPage';
      }
    }
    
    this.router.navigateByUrl(redirectUrl);
  }

}
