import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from "ngx-cookie-service";
import { LenderService } from "../api/lender.service";
import { TranslateService } from "@ngx-translate/core";
import { LenderLanguage } from "src/app/models/lender-language";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  constructor(
    private cookieService: CookieService,
    private lenderService: LenderService,
    public translateService: TranslateService
  ) {}
  public static LANGUAGE = "language";
  static ENG_LOWER = "en";
  static FR_LOWER = "fr";

  public language: string;
  public profileDetails: any;
  private jwtHelper = new JwtHelperService();

  async init() {
    const fromQueryParams = window.location.search.match("lang=([^&]+)");
    if (fromQueryParams) {
      this.translateService.setDefaultLang(fromQueryParams[1]);
      this.translateService.addLangs(["en", "fr"]);
      this.setLanguagePreference(fromQueryParams[1]);
      // @ts-ignore
      window.languageWasSet = true;
      return;
    }

    this.translateService.setDefaultLang("en");
    this.translateService.addLangs(["en", "fr"]);
    this.language = "en";

    const navLanguage = navigator.language
      ? navigator.language.substring(0, 2)
      : null;
    if (["en", "fr"].indexOf(navLanguage) !== -1) {
      this.setLanguagePreference(navLanguage);
    }

    const lang = this.cookieService.get(LanguageService.LANGUAGE);
    if (lang) {
      this.setLanguagePreference(lang);
      return;
    }

    const token = this.cookieService.get("IdToken");

    if (!token) {
      return;
    }
    const tokenData = this.jwtHelper.decodeToken(token);

    if (!this.profileDetails) {
      this.profileDetails = await this.lenderService
        .getProfileDetailsByEmail(tokenData.email.toLowerCase())
        .toPromise()
        .then((resp) => (resp.length > 0 ? resp[0] : undefined));
      if (this.profileDetails && this.profileDetails.language) {
        if (
          this.profileDetails.language === LenderLanguage.FR.toLocaleUpperCase()
        ) {
          this.setLanguagePreference(LanguageService.FR_LOWER);
          this.cookieService.set(
            LanguageService.LANGUAGE,
            LanguageService.FR_LOWER
          );
        } else {
          this.setLanguagePreference(LanguageService.ENG_LOWER);
          this.cookieService.set(
            LanguageService.LANGUAGE,
            LanguageService.ENG_LOWER
          );
        }
      }
    }
  }

  public setLanguagePreference(lang: string) {
    this.setLanguage(lang.toLowerCase());
    this.translateService.use(lang.toLowerCase());
  }

  getLanguage() {
    return this.language;
  }

  setLanguage(language) {
    this.language = language;
  }
}
