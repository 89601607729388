export class DenialReasonsCSVDTO {
    LoanID: string;
    Date: Date;
    LenderCode: string;
    LenderName: string;
    DenialReason: string;
    FirmName: string;

    constructor(loanId: string, date: Date, lenderCode: string, lenderName: string, denialReason: string, firmName: string) {
        this.LoanID = loanId;
        this.Date = date;
        this.LenderCode = lenderCode;
        this.LenderName = lenderName;
        this.DenialReason = denialReason;
        this.FirmName = firmName;
    }

}