import { Component} from '@angular/core';

import { Router } from '@angular/router';
import { EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromptModel } from '../../../models/prompt.model';
import { LanguageService } from 'src/app/services/language-service/language.service';
import { PromptsMessages } from '../constants/promptsMessages';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-general-prompt',
  templateUrl: './general-prompt.component.html',
  styleUrls: ['./general-prompt.component.scss']
})
export class GeneralPromptComponent{
    public promptInfo: PromptModel;
    public onOk = new EventEmitter();
    public onCancel = new EventEmitter();
    public textFirstButton = 'OK';
    public textSecondButton = 'Cancel';
    public reverse = true;
    public isRedirectedViaHref = false;
    public saveButtonHasBeenPressed : Boolean = false;

    constructor(public languageService: LanguageService, public dialog: MatDialog, private router: Router, public dialogRef: MatDialogRef<GeneralPromptComponent>,
                @Inject(MAT_DIALOG_DATA) public data: PromptModel) {

        if (this.languageService.getLanguage() == 'en') {
            this.textFirstButton = 'OK';
            this.textSecondButton = 'Cancel';
        } else {
            this.textFirstButton = 'OK';
            this.textSecondButton = 'Annuler';
        }

        if (data.textFirstButton) {
            this.textFirstButton = data.textFirstButton;
        }
        if (data.textSecondButton) {
            this.textSecondButton = data.textSecondButton;
        }
        data.reverse = this.reverse;
        this.isRedirectedViaHref = data.isRedirectedViaHref;

    }

    ngOnInit(){
        this.saveButtonHasBeenPressed = false;
    }
    public close() {
        this.dialogRef.close();
        this.onCancel.emit();
        }

    public ok() {
        if (this.data.route) {
            this.router.navigateByUrl(this.data.route);
        }
        this.dialog.closeAll();
        this.onOk.emit();
    }

    public getTextFirstButton() {
        return this.textFirstButton;
    }

    public getTextSecondButton() {
        return this.textSecondButton;
    }

    public getReverseFlag() {
        return this.reverse;
    }

    public getOkBtn() {
        this.saveButtonHasBeenPressed  = true;
        return this.data.isOkBtn;
    }

    public getCancelBtn() {
        if (this.saveButtonHasBeenPressed == true)
        return this.data.isCancelBtn;
    }

    public getIsRedirectedViaHref() {
        return this.isRedirectedViaHref;
    }

    public okForHref() {
        this.dialog.closeAll();
        this.onOk.emit();
    }

    public getIsRunReport() {
        return this.data.isRunReport;
    }

    public getReportType() {
        return this.data.textBody[0].content;
    }

    public getReportOption() {
        return this.data.textBody[1].content;
    }

    public getIsVideo() {
        return this.data.isVideo;
    }

    public getIsVideoPreview() {
        return this.data.isVideoPreview;
    }

    public getIsVideoTitle() {
        return PromptsMessages.translate('VIDEO_MESSAGE_TITLE', this.languageService.getLanguage());
    }

    public getVideoUri() {
        return this.data.textBody[0].content;
    }

    public restart() {
        let video: any = document.getElementById('videoId');
        video.currentTime = 0;
        video.play();
    }

}
