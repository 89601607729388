<form  [formGroup]="addressForm">
    <div class="row">
        <div class="col-sm-12 pb-4">
            <mat-form-field id="address-lookup-field">
                <input formControlName="addressLookup" (input)="changeAddressSearch($event)"  id="address-lookup-register" matInput placeholder="{{'addressLookup' | translate}}">
                <mat-hint *ngIf="!showAutocompleteBar">{{'startTyping' | translate}}</mat-hint>
                <mat-error *ngIf="addressForm.controls['addressLookup'].errors?.pattern"> Please make sure you typed-in the correct Address</mat-error>
            </mat-form-field>

            <div *ngIf="showAutocompleteBar"  class="wrap-container">
                <div class="address-container-dropdown" clickOutside (clickOutside)="closeOutsideSidenav()">
                    <div class="address-row" *ngFor="let option of autocompleteOptions" (click)="selectedOption(option)" style= "cursor:pointer">
                        {{option.address}}
                        <span *ngIf="option.arrow"><i class="fa fa-angle-right arrow-position"></i></span>
                    </div>
                </div>
                <div *ngIf="true" [ngClass]="{'active-panel-class': false, 'inactive-panel-class': false}" class="logo-div">
                    <img class="canada-logo" src="../../assets/canada_post_api_logo.jpg" />
                </div>
            </div>

        </div>
    </div>  
</form>