import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PromptModel } from 'src/app/models/prompt.model';
import { ReportingService } from 'src/app/services/reporting/reporting.service';
import { PromptsMessages } from '../common/constants/promptsMessages';
import { GeneralPromptComponent } from '../common/general-prompt/general-prompt.component';
import { FormControl, FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DateAdapter} from '@angular/material/core';
import moment from 'moment'

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit {
  public maxDate: Object =  new Date();
  public minDate: Object = new Date("2020-01-01");
  public disableButton1: boolean = true;
  public isIntervalSelected1: boolean = true;
  public disableButton2: boolean = true;
  public isIntervalSelected2: boolean = true;
  public disableButton3: boolean = true;
  public isIntervalSelected3: boolean = true;
  public disableButton4: boolean = true;
  public isIntervalSelected4: boolean = true;
  public disableButton5: boolean = true;
  public isIntervalSelected5: boolean = true;
  public disableButton6: boolean = true;
  public isIntervalSelected6: boolean = true;

  public incorrectInterval1: boolean = false;
  public incorrectInterval2: boolean = false;
  public incorrectInterval3: boolean = false;
  public incorrectInterval4: boolean = false;
  public incorrectInterval5: boolean = false;
  public incorrectInterval6: boolean = false;

  public range1 = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  public range2 = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  public range3 = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  public range4 = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  public range5 = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  public range6 = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  @Output() sendPromptText: EventEmitter<any> = new EventEmitter<any>();

  @Output() sendDialogueBoxText: EventEmitter<any> = new EventEmitter<any>();

  public reportOptionA : string;
  public reportOptionB : string;
  public reportOptionC : string;
  public reportOptionD : string;
  public reportOptionE : string;
  public reportOptionF : string;

  public stringInterval1 : string;
  public stringInterval2 : string;
  public stringInterval3 : string;
  public stringInterval4 : string;
  public stringInterval5 : string;
  public stringInterval6 : string;

  constructor(public datepipe: DatePipe, public dialog: MatDialog, private router: Router, private reportingService: ReportingService, private _adapter: DateAdapter<any>) { }

  ngOnInit() {
    this.reportOptionA = 'last month';
    this.reportOptionB = 'last month';
    this.reportOptionC = 'last month';
    this.reportOptionD = 'last month';
    this.reportOptionE = 'last month';
    this.reportOptionF = 'last month';
  }

  setOption(reportType, option) {
    switch (reportType) {
      case 'first': {
        this.range1 = new FormGroup({
          start: new FormControl(),
          end: new FormControl()
        });
        this.disableButton1 = true;
        this.reportOptionA = option.toLowerCase();

        switch (this.reportOptionA) {
          case 'last month': {
            this.range1.value.start = this.datepipe.transform(new Date().setMonth(new Date().getMonth() - 1), 'MM/dd/yyyy');
            this.range1.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton1 = false;
            this.stringInterval1 = "Last month";
            break;
          }
          case 'last 3 months': {
            this.range1.value.start = this.datepipe.transform(new Date().setMonth(new Date().getMonth() - 3), 'MM/dd/yyyy');
            this.range1.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton1 = false;
            this.stringInterval1 = "Last 3 months";
            break;
          }
          case 'last year': {
            this.range1.value.start = this.datepipe.transform(new Date().setFullYear(new Date().getFullYear() - 1), 'MM/dd/yyyy');
            this.range1.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton1 = false;
            this.stringInterval1 = "Last year";
            break;
          }
          default: {
            this.stringInterval1 = "Interval";
          }
        }
        break;
      }
      case 'second': {
        this.range2 = new FormGroup({
          start: new FormControl(),
          end: new FormControl()
        });
        this.reportOptionB = option.toLowerCase();
        this.disableButton2 = true;

        switch (this.reportOptionB) {
          case 'last month': {
            this.range2.value.start = this.datepipe.transform(new Date().setMonth(new Date().getMonth() - 1), 'MM/dd/yyyy');
            this.range2.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton2 = false;
            this.stringInterval2 = "Last month";
            break;
          }
          case 'last 3 months': {
            this.range2.value.start = this.datepipe.transform(new Date().setMonth(new Date().getMonth() - 3), 'MM/dd/yyyy');
            this.range2.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton2 = false;
            this.stringInterval2 = "Last 3 months";
            break;
          }
          case 'last year': {
            this.range2.value.start = this.datepipe.transform(new Date().setFullYear(new Date().getFullYear() - 1), 'MM/dd/yyyy');
            this.range2.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton2 = false;
            this.stringInterval2 = "Last year";
            break;
          }
          default: {
            this.stringInterval2 = "Interval";
          }
        }
        break;
      }
      case 'third': {
        this.range3 = new FormGroup({
          start: new FormControl(),
          end: new FormControl()
        });
        this.reportOptionC = option.toLowerCase();
        this.disableButton3 = true;

        switch (this.reportOptionC) {
          case 'last month': {
            this.range3.value.start = this.datepipe.transform(new Date().setMonth(new Date().getMonth() - 1), 'MM/dd/yyyy');
            this.range3.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton3 = false;
            this.stringInterval3 = "Last month";
            break;
          }
          case 'last 3 months': {
            this.range3.value.start = this.datepipe.transform(new Date().setMonth(new Date().getMonth() - 3), 'MM/dd/yyyy');
            this.range3.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton3 = false;
            this.stringInterval3 = "Last 3 months";
            break;
          }
          case 'last year': {
            this.range3.value.start = this.datepipe.transform(new Date().setFullYear(new Date().getFullYear() - 1), 'MM/dd/yyyy');
            this.range3.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton3 = false;
            this.stringInterval3 = "Last year";
            break;
          }
          default: {
            this.stringInterval3 = "Interval";
          }
        }
        break;
      }
      case 'forth': {
        this.range4 = new FormGroup({
          start: new FormControl(),
          end: new FormControl()
        });
        this.reportOptionD = option.toLowerCase();
        this.disableButton4 = true;

        switch (this.reportOptionD) {
          case 'last month': {
            this.range4.value.start = this.datepipe.transform(new Date().setMonth(new Date().getMonth() - 1), 'MM/dd/yyyy');
            this.range4.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton4 = false;
            this.stringInterval4 = "Last month";
            break;
          }
          case 'last 3 months': {
            this.range4.value.start = this.datepipe.transform(new Date().setMonth(new Date().getMonth() - 3), 'MM/dd/yyyy');
            this.range4.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton4 = false;
            this.stringInterval4 = "Last 3 months";
            break;
          }
          case 'last year': {
            this.range4.value.start = this.datepipe.transform(new Date().setFullYear(new Date().getFullYear() - 1), 'MM/dd/yyyy');
            this.range4.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton4 = false;
            this.stringInterval4 = "Last year";
            break;
          }
          default: {
            this.stringInterval4 = "Interval";
          }
        }
        break;
      }
      case 'fifth': {
        this.range5 = new FormGroup({
          start: new FormControl(),
          end: new FormControl()
        });
        this.reportOptionE = option.toLowerCase();
        this.disableButton5 = true;

        switch (this.reportOptionE) {
          case 'last month': {
            this.range5.value.start = this.datepipe.transform(new Date().setMonth(new Date().getMonth() - 1), 'MM/dd/yyyy');
            this.range5.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton5 = false;
            this.stringInterval5 = "Last month";
            break;
          }
          case 'last 3 months': {
            this.range5.value.start = this.datepipe.transform(new Date().setMonth(new Date().getMonth() - 3), 'MM/dd/yyyy');
            this.range5.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton5 = false;
            this.stringInterval5 = "Last 3 months";
            break;
          }
          case 'last year': {
            this.range5.value.start = this.datepipe.transform(new Date().setFullYear(new Date().getFullYear() - 1), 'MM/dd/yyyy');
            this.range5.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton5 = false;
            this.stringInterval5 = "Last year";
            break;
          }
          default: {
            this.stringInterval5 = "Interval";
          }
        }
        break;
      }
      case 'sixth': {
        this.range6 = new FormGroup({
          start: new FormControl(),
          end: new FormControl()
        });
        this.reportOptionF = option.toLowerCase();
        this.disableButton6 = true;

        switch (this.reportOptionF) {
          case 'last month': {
            this.range6.value.start = this.datepipe.transform(new Date().setMonth(new Date().getMonth() - 1), 'MM/dd/yyyy');
            this.range6.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton6 = false;
            this.stringInterval6 = "Last month";
            break;
          }
          case 'last 3 months': {
            this.range6.value.start = this.datepipe.transform(new Date().setMonth(new Date().getMonth() - 3), 'MM/dd/yyyy');
            this.range6.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton6 = false;
            this.stringInterval6 = "Last 3 months";
            break;
          }
          case 'last year': {
            this.range6.value.start = this.datepipe.transform(new Date().setFullYear(new Date().getFullYear() - 1), 'MM/dd/yyyy');
            this.range6.value.end = this.datepipe.transform(new Date().setDate(new Date().getDate()), 'MM/dd/yyyy');

            this.disableButton6 = false;
            this.stringInterval6 = "Last year";
            break;
          }
          default: {
            this.stringInterval6 = "Interval";
          }
        }
        break;
      }
    }
  }

  runReport(reportType) {
    switch (reportType) {
      case 'A': {
        this.reportingService.reportOption = this.stringInterval1;
        this.openPrompt(PromptsMessages.REPORTING_TYPE_1, this.range1);
        break;
      }
      case 'B': {
        this.reportingService.reportOption = this.stringInterval2;
        this.openPrompt(PromptsMessages.REPORTING_TYPE_2, this.range2);
        break;
      }
      case 'C': {
        this.reportingService.reportOption = this.stringInterval3;
        this.openPrompt(PromptsMessages.REPORTING_TYPE_3, this.range3);
        break;
      }
      case 'D': {
        this.reportingService.reportOption = this.stringInterval4;
        this.openPrompt(PromptsMessages.REPORTING_TYPE_4, this.range4);
        break;
      }
      case 'E': {
        this.reportingService.reportOption = this.stringInterval5;
        this.openPrompt(PromptsMessages.REPORTING_TYPE_5, this.range5);
        break;
      }
      case 'F': {
        this.reportingService.reportOption = this.stringInterval6;
        this.openPrompt(PromptsMessages.REPORTING_TYPE_6, this.range6);
        break;
      }
    }
  }

  private openPrompt(reportType, range) {
    let promptInfoReview: PromptModel;
    promptInfoReview = new PromptModel([{ content: reportType, isTextBold: false }, { content: `${this.datepipe.transform(range.value.start, 'MM/dd/yyyy') } - ${this.datepipe.transform(range.value.end, 'MM/dd/yyyy')}`, isTextBold: false }], true, true, '',
      PromptsMessages.DIALOGUE_BOX_BUTTON_RUN, PromptsMessages.DIALOGUE_BOX_BUTTON_CANCEL, false, false, true, false, false);
    promptInfoReview.isRedirectedViaHref = false;
    this.sendPromptText.emit(promptInfoReview);
    let dialogRef = this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfoReview });
    dialogRef.afterOpened().subscribe(() => {
      dialogRef.componentInstance.onOk.subscribe(() => {
        this.reportingService.reportType = reportType;
        let startDateAsString = this.datepipe.transform(range.value.start, 'MM/dd/yyyy');
        /*
        * One more day must be added to the end for otherwise it would not take into consideration the last day of the interval
        */
        let endDateAsString = this.datepipe.transform(new Date(range.value.end).setDate(new Date(range.value.end).getDate() + 1), 'MM/dd/yyyy');
        if(this.reportingService.reportOption == "Interval"){
          this.reportingService.reportOption = startDateAsString + " - " + endDateAsString;
        }
        this.router.navigate(['/report-details'], { queryParams: { reportType: reportType, start: startDateAsString, end: endDateAsString }});
      });
    });
  }

  checkInterval(report: string, event: MatDatepickerInputEvent<Date>) {
    switch (report){
      case 'first':{
        if(this.range1.value.start != null && this.range1.value.end != null){
            this.disableButton1 = !this.incorrectInterval1 ? false : true;
        }else{
          this.disableButton1 = true;
        }
        break;
      }
      case 'second':{
        if(this.range2.value.start != null && this.range2.value.end != null){
          this.disableButton2 = !this.incorrectInterval2 ? false : true;
        }else{
          this.disableButton2 = true;
        }
        break
      }
      case 'third':{
        if(this.range3.value.start != null && this.range3.value.end != null){
          this.disableButton3 = !this.incorrectInterval3 ? false : true;
        }else{
          this.disableButton3 = true;
        }
        break
      }
      case 'forth':{
        if(this.range4.value.start != null && this.range4.value.end != null){
          this.disableButton4 = !this.incorrectInterval4 ? false : true;
        }else{
          this.disableButton4 = true;
        }
        break
      }
      case 'fifth':{
        if(this.range5.value.start != null && this.range5.value.end != null){
          this.disableButton5 = !this.incorrectInterval5 ? false : true;
        }else{
          this.disableButton5 = true;
        }
        break
      }
      case 'sixth':{
        if(this.range6.value.start != null && this.range6.value.end != null){
          this.disableButton6 = !this.incorrectInterval6 ? false : true;
        }else{
          this.disableButton6 = true;
        }
        break
      }
    }  
  }  
  isDateIntervalIncorrect(range: FormGroup) {
    
    let rawStartDate = range.value.start != null ? range.value.start : "";
    let rawEndDate = range.value.end != null ? range.value.end : "";

    if( (moment(rawStartDate, "MM/DD/YYYY", true).isValid() || moment(rawStartDate, "M/DD/YYYY", true).isValid() || moment(rawStartDate, "M/D/YYYY", true).isValid() || moment(rawStartDate, "MM/D/YYYY", true).isValid())
    && (moment(rawEndDate, "MM/DD/YYYY", true).isValid() || moment(rawEndDate, "M/DD/YYYY", true).isValid() || moment(rawEndDate, "M/D/YYYY", true).isValid() || moment(rawEndDate, "MM/D/YYYY", true).isValid())){
      let startDate = new Date(range.value.start);
      let endDate = new Date(range.value.end);
        if ( startDate > new Date("01/01/2021") && endDate > new Date("01/01/2021")){
         
      if(startDate > endDate){
        return true;     
      }
    }
    if ( startDate > new Date("01/01/2021") && endDate <= new Date("01/01/2021")){
      return true;
    }

    if ( startDate >= new Date("01/01/2020") && endDate < new Date("01/01/2021")){
      if (startDate > endDate){
      return true;
    }
  }    
      return false;
  }
    return false;
  }

  checkIntervalRange(report:string, event:any){
    switch(report){
      case "Status": {
        this.incorrectInterval1 = this.isDateIntervalIncorrect(this.range1);
        break;
      }
      case "CreditBureau": {
        this.incorrectInterval2 = this.isDateIntervalIncorrect(this.range2);
      }
      case "PropertyAddr": {
        this.incorrectInterval3 = this.isDateIntervalIncorrect(this.range3);
      }
      case "DenialReasons": {
        this.incorrectInterval4 = this.isDateIntervalIncorrect(this.range4);
      }
      case "LenderCode": {
        this.incorrectInterval5 = this.isDateIntervalIncorrect(this.range5);
      }
      case "PaidLoans": {
        this.incorrectInterval6 = this.isDateIntervalIncorrect(this.range6);
      }
    }

  }
}
