import {mergeMap, retryWhen} from 'rxjs/internal/operators';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable, throwError, timer} from 'rxjs';
import {ErrorService} from '../services/error/error.service';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private errorService: ErrorService, private router: Router, private translateService: TranslateService) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {

        const maxRetryAttempts = 5;
        const excludedStatusCodes = [];
        const scalingDuration = 300;

        // @ts-ignore
        if (request.alreadyInPipe) {
            return next.handle(request);
        } else {
            // @ts-ignore
            request.alreadyInPipe = true;
            return next.handle(request).pipe(
                retryWhen(subject =>
                    subject.pipe(
                        mergeMap((error, i) => {

                            if (error.status === 500) {
                                this.router.navigateByUrl('/error_500');
                                return;
                            }

                            if (error.status === 400 && error.code === 5004 || error.status === 404 && error.code === 5005) {
                                this.router.navigateByUrl('/error_400_404');
                                return;
                            }

                            if (error.status === 401 || error.status === 403) {
                                this.router.navigateByUrl('/error_401_403');
                                return;
                            }

                            if (error.error && error.error.title !== undefined) {
                                this.translateService.get(error.error.service + error.error.code)
                                    .subscribe(translatedMessage => {
                                    if (error.error.arguments) {
                                        for (const arg of error.error.arguments) {
                                            translatedMessage = translatedMessage.replace('{}', arg);
                                        }
                                    }

                                    this.errorService.addErrors([translatedMessage]);
                                });

                                return throwError(error);
                            }

                            const retryAttempt = i + 1;
                            if (
                                retryAttempt > maxRetryAttempts ||
                                excludedStatusCodes.find(e => e === error.status)
                            ) {
                                this.translateService.get('network_error')
                                    .subscribe(translation => {
                                    this.errorService.addErrors([translation]);
                                });

                                return throwError(error);
                            }
                            console.log(
                                `Attempt ${retryAttempt}: retrying in ${retryAttempt *
                                scalingDuration}ms`
                            );
                            return timer(retryAttempt * scalingDuration);
                        })
                    )
                )
            );
        }
    }
}
