export class AddressInformation{
    addressOne: string;
    addressTwo: string;
    city: string;
    postalCode: string;
    province: string;

    constructor(addressOne: string, addressTwo: string, city: string, postalCode: string,
        province: string) {
        this.addressOne = addressOne;
        this.addressTwo = addressTwo;
        this.city = city;
        this.postalCode = postalCode;
        this.province = province;
    }

    get getAddressOne(): string {
        return this.addressOne;
    }

    get getAddressTwo(): string {
        return this.addressTwo;
    }


    get getCity(): string {
        return this.city;
    }

    get getPostalCode(): string {
        return this.postalCode;
    }

    get getProvince(): string {
        return this.province;
    }

}