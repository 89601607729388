<mat-dialog-content id="add-user-dialog">
    <div class="col-sm-12 mt-3 d-flex" id="add-user-div">{{'addUser' | translate}}</div>
    <form class="p-5 col-sm-12" [formGroup]="addUserForm" id="add-user-form">
        <div class="row">
            <div class="col-sm-6 mt-2">
                <mat-form-field id="first-name-field" [ngClass]="{'highlight-class': false}">
                    <input (input)="changeFieldNoSpacesAndDigits($event,'firstName')"
                        required formControlName="firstName" matInput placeholder="{{'firstName' | translate}}">
                    <mat-hint>Ex: John</mat-hint>
                    <mat-error *ngIf="addUserForm.controls['firstName'].errors?.required">{{'required' | translate}}</mat-error>
                    <mat-error *ngIf="addUserForm.controls['firstName'].errors?.pattern">{{'invalidFirstNameFormat' | translate}}  
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6 mt-2 pl-0">
                <mat-form-field id="last-name-field" [ngClass]="{'highlight-class': false}">
                    <input (input)="changeFieldNoSpacesAndDigits($event,'lastName')"
                        required formControlName="lastName" matInput placeholder="{{'lastName' | translate}}">
                    <mat-hint>Ex: Doe</mat-hint>
                    <mat-error *ngIf="addUserForm.controls['lastName'].errors?.required">{{'required' | translate}}</mat-error>
                    <mat-error *ngIf="addUserForm.controls['lastName'].errors?.pattern">{{'invalidLastNameFormat' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-12 mt-4 mb-2 pl-0">
            <div class="full-row" style="margin-top: 10px; width: 420px!important">
                <mat-form-field id="email-field" [ngClass]="{'highlight-class': false}">
                    <input (input)="changeEmailField($event,'email')" required
                        type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$" matInput
                        formControlName="email" placeholder="{{'email' | translate}}">
                    <mat-hint>Ex: johndoe@email.com</mat-hint>
                    <mat-error *ngIf="addUserForm.controls['email'].errors?.required">{{'required' | translate}} Ex: johndoe@email.com</mat-error>
                    <mat-error *ngIf="addUserForm.controls['email'].errors?.email">{{'invalidFormatOfEmail' | translate}} Ex:johndoe@email.com</mat-error>
                    <mat-error *ngIf="getEmailError() == true">{{'e-mailAddressAlreadyInUseForLenderAccount' | translate}} {{getLenderNameForEmailError()}}</mat-error>
                    
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="full-row">
                <div class="col-sm-12" style="margin-top: 15px;">
                    <mat-radio-group formControlName="adminUserRadioGroup" id="adminUserRadioGroup" class="admin-user-radiogroup"
                        aria-label="Select an option" required>
                        <label [ngClass]="{'highlight-class': madminUserRadioGroup==true}" id="admin-user-label">{{'isThisUserAnAdmin' | translate}} </label>
                        <div id="admin-radio">
                            <mat-radio-button required #radioUserAdminYes  id="admin-yes-radio" name="userAdminYes" value="yes">{{'yes' | translate}}</mat-radio-button>
                            <mat-radio-button required #radioUserAdminNo  id="admin-no-radio" name="userAdminNo" value="no">{{'no' | translate}}</mat-radio-button>
                        </div>

                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: -10px;">
            <div class="col-sm-8 mt-2">
                <mat-form-field id="phone-field" [ngClass]="{'highlight-class': false}">
                    <input (keyup)="changePhone($event.target.value)"
                        (keydown)="keydownPhone($event)" #phone required maxlength="13" type="tel" autocomplete="tel"
                        formControlName="phone" matInput placeholder="{{'phoneNumber' | translate}}"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}">
                    <mat-hint>Format: 111-111-1111</mat-hint>
                    <mat-error *ngIf="addUserForm.get('phone').hasError('maxlength')">{{'maxNumberOfCharactersWasReachedPleaseMakeSureYouTypedInTheCorrectPhoneNumber' | translate}}</mat-error>
                    <mat-error *ngIf="addUserForm.controls['phone'].errors?.required">{{'required' | translate}} Ex: 111-111-1111
                    </mat-error>
                    <mat-error *ngIf="addUserForm.controls['phone'].errors?.pattern">{{'invalidFormat' | translate}} Ex: 111-111-1111
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-4 mt-2 pl-0">
                <mat-form-field id="ext-field" [ngClass]="{'highlight-class': false}">
                    <input (input)="changeContactField($event,'ext')" matInput
                        maxlength="6" pattern="[0-9]{5}" formControlName="ext" placeholder="{{'ext' | translate}}">
                    <mat-hint>{{'optional' | translate}} Ex:12345</mat-hint>
                    <mat-error *ngIf="addUserForm.get('ext').hasError('maxlength')">{{'maxNumberOfCharactersWasReached' | translate}} </mat-error>
                    <mat-error *ngIf="addUserForm.controls['ext'].errors?.pattern">{{'invalidFormat' | translate}} Ex:12345</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="space-div">

        </div>
        <div class="d-flex end">
            <div>
                <button id="cancel-button" (click)="cancel()" mat-stroked-button color="accent">
                    {{'cancel' | translate}}
                </button>
            </div>
            <div *ngIf="isEditDialogue" class="ml-3">
                <button id="delete-button" (click)="delete()" mat-stroked-button color="accent">
                    {{'delete' | translate}}
                </button>
            </div>
            <div class="ml-3 mr-4">
             
                <button #saveButton [disabled]="addUserForm.invalid" id="save-button" type="submit" (click)="save()"
                    mat-flat-button color="primary" dense>
                    <div *ngIf="sumbitButtonText === 'Save' ">
                        {{'submitButtonTextSave' | translate}}
                    </div>
                    <div *ngIf="sumbitButtonText === 'Add User' ">
                        {{'submitButtonTextAddUser' | translate}}
                    </div>
            </button>
        </div>
        </div>
        <div class="row mt-4">

        </div>

    </form>
</mat-dialog-content>