export class PromptModel {

    public textBody: any[];
    public isOkBtn: boolean;
    public isCancelBtn: boolean;
    public isSessionExtension: boolean;
    public route: string;
    public textFirstButton: string;
    public textSecondButton: string;
    public reverse: boolean;
    public isRedirectedViaHref: boolean = false;
    public isRunReport: boolean = false;
    public isVideo: boolean = false;
    public isVideoPreview: boolean = false;

    public constructor(textBody: any[],
        isOkBtn: boolean,
        isCancelBtn: boolean,
        route: string,
        textFirstButton: string,
        textSecondButton: string,
        reverse: boolean,
        isSessionExtension: boolean,
        isRunReport: boolean,
        isVideo: boolean,
        isVideoPreview: boolean) {
        this.textBody = textBody;
        this.isOkBtn = isOkBtn;
        this.isCancelBtn = isCancelBtn;
        this.route = route;
        this.textFirstButton = textFirstButton;
        this.textSecondButton = textSecondButton;
        this.reverse = reverse;
        this.isSessionExtension = isSessionExtension;
        this.isRedirectedViaHref = false;
        this.isRunReport = isRunReport;
        this.isVideo = isVideo;
        this.isVideoPreview = isVideoPreview;
    }

    public get getTextBody(): any[] {
        return this.textBody;
    }

    public get getIsOkBtn(): boolean {
        return this.isOkBtn;
    }

    public get getIsCancelBtn(): boolean {
        return this.isCancelBtn;
    }

    public get getRoute(): string {
        return this.route;
    }

    public redirectViaHref() {
        this.isRedirectedViaHref = true;
    }

    public getIsRunReport() {
        return this.isRunReport;
    }

}