<div>
    <div class="col-sm-9" id="sign-in-div">
        <div id="logo-div">
            <img alt ="Filogix Logo" id="logo-img" src="{{'logoSrc' | translate}}" />
        </div>
        <div class="row">
            <div id="new-pass-div">
                <br>
                {{'create_new_password' | translate}}
            </div>
        </div>
        <div class="row mt-3">
            <div id="new-pass-details-div">
                {{'create_new_password_text' | translate}}
            </div>
        </div>
        <div id="form-div">
            <form [formGroup]="changePassForm" id="signin-form">
                <div class="col-sm-12 mt-4" *ngIf="isFromForgotPassword">
                    <mat-form-field>
                        <input matInput id="code-input" [type]="text" formControlName="code" placeholder="Code" />
                    </mat-form-field>
                </div>

                <div class="col-sm-12 mt-4">
                    <mat-form-field>
                        <img alt="lock-icon" class="lock-icon" class="prefix-icons" src="../../assets/lock.png" matPrefix />
                        <input matInput id="email-input" [type]="fieldTextTypeNewPass ? 'text' : 'password'" (input)="newPass($event,'pass1')" formControlName="pass1" placeholder="{{'new_password' | translate}}" />
                        <mat-icon class="eye-icon" class="fa" [ngClass]="{
                          'fa-eye-slash': !fieldTextTypeNewPass,
                          'fa-eye': fieldTextTypeNewPass}" (click)="toggleNewPassType()"></mat-icon>
                    </mat-form-field>
                </div>
                <div style="height:20px">

                </div>
                <div class="col-sm-12  mt-2">
                    <mat-form-field>
                        <img alt="lock-icon" class="lock-icon" class="prefix-icons" src="../../assets/lock.png" matPrefix />
                        <input matInput id="password-input" [type]="fieldTextTypeReEnter ? 'text' : 'password'" (input)="newPass($event,'pass2')" formControlName="pass2" placeholder="{{'reenter_new_password' | translate}}" />
                        <mat-icon class="eye-icon" class="fa" [ngClass]="{
                          'fa-eye-slash': !fieldTextTypeReEnter,
                          'fa-eye': fieldTextTypeReEnter
                        }" (click)="toggleReEnterType()"></mat-icon>
                        <mat-error *ngIf="changePassForm.get('pass2').invalid">{{'error_create_password_1' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div style="height:40px">

                </div>
                <div id="button-div">
                    <button mat-flat-button color="primary" dense id="finish-btn" [disabled]="!passWordsMatch" (click)="changePassword()">
                        <i id="arrow-icon" class="fa fa-long-arrow-right"></i> {{'finish' | translate}}
                    </button>
                </div>
                <div style="height:40px">

                </div>
                <div *ngIf="entersNewPass" class="col-sm-12 mt-4">
                    <div class="d-flex">
                        <div *ngIf="!newPassValidation.hasLowerCase" class="validation-icon-position">
                            <i class="fas fa-times is-invalid"></i>
                        </div>
                        <div *ngIf="newPassValidation.hasLowerCase" class="validation-icon-position">
                            <i class="fas fa-check is-valid "></i>
                        </div>
                        <div [ngClass]="{
                          'is-valid': newPassValidation.hasLowerCase,
                          'is-invalid': !newPassValidation.hasLowerCase}">
                          {{'error_create_password_2' | translate}}
                        </div>
                    </div>
                    <div class="d-flex">
                        <div *ngIf="!newPassValidation.hasUpperCase" class="validation-icon-position">
                            <i class="fas fa-times is-invalid"></i>
                        </div>
                        <div *ngIf="newPassValidation.hasUpperCase" class="validation-icon-position">
                            <i class="fas fa-check is-valid "></i>
                        </div>
                        <div [ngClass]="{
                          'is-valid': newPassValidation.hasUpperCase,
                          'is-invalid': !newPassValidation.hasUpperCase}">
                          {{'error_create_password_3' | translate}}
                        </div>
                    </div>
                    <div class="d-flex">
                        <div *ngIf="!newPassValidation.hasSpecialCharacter" class="validation-icon-position">
                            <i class="fas fa-times is-invalid"></i>
                        </div>
                        <div *ngIf="newPassValidation.hasSpecialCharacter" class="validation-icon-position">
                            <i class="fas fa-check is-valid "></i>
                        </div>
                        <div [ngClass]="{
                          'is-valid': newPassValidation.hasSpecialCharacter,
                          'is-invalid': !newPassValidation.hasSpecialCharacter}">
                          {{'error_create_password_4' | translate}}
                        </div>
                    </div>
                    <div class="d-flex">
                        <div *ngIf="!newPassValidation.hasNumber" class="validation-icon-position">
                            <i class="fas fa-times is-invalid"></i>
                        </div>
                        <div *ngIf="newPassValidation.hasNumber" class="validation-icon-position">
                            <i class="fas fa-check is-valid "></i>
                        </div>
                        <div [ngClass]="{
                          'is-valid': newPassValidation.hasNumber,
                          'is-invalid': !newPassValidation.hasNumber}">
                          {{'error_create_password_5' | translate}}
                        </div>
                    </div>
                    <div class="d-flex">
                        <div *ngIf="!newPassValidation.hasEightLetters" class="validation-icon-position">
                            <i class="fas fa-times is-invalid"></i>
                        </div>
                        <div *ngIf="newPassValidation.hasEightLetters" class="validation-icon-position">
                            <i class="fas fa-check is-valid "></i>
                        </div>
                        <div [ngClass]="{
                          'is-valid': newPassValidation.hasEightLetters,
                          'is-invalid': !newPassValidation.hasEightLetters}">
                          {{'error_create_password_6' | translate}}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>
