import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuardService } from 'src/app/services/security/auth-guard.service';
import { ComponentsMessages } from '../../constants/componentsMessages';
import {AuthService} from "../../../../services/security/auth.service";

@Component({
  selector: 'app-error-server',
  templateUrl: './error-server.component.html',
  styleUrls: ['./error-server.component.scss']
})
export class ErrorServerComponent implements OnInit {

  constructor(private elementRef: ElementRef, private router: Router, private authGuardService: AuthGuardService,
    private authService: AuthService) { }
  buttonText: any;
  ngOnInit() {
    this.buttonText = this.authService.profileDetails.profileRole[0] === 'CS_ADMIN' ? ComponentsMessages.BACK_TO_LENDERSLIST : ComponentsMessages.BACK_TO_DASHBOARD;
  }

  ngAfterViewInit(){
    this.elementRef.nativeElement.ownerDocument.body.style.background = "url('../assets/login-backgroung.png') center center fixed";
    this.elementRef.nativeElement.ownerDocument.body.style.width='100%'
    this.elementRef.nativeElement.ownerDocument.body.style.height='auto'
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundRepeat='no-repeat'
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundSize='cover'
 }

 public exit() {
  let route = this.authService.profileDetails.profileRole[0] === 'CS_ADMIN' ? '/lendersList' : '/leads';
  this.router.navigateByUrl(route);
 }

}
