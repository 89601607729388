<app-header></app-header>
<div class="vertical-align container px-3 py-3 col-md-8 col-sm-8">
    <div>
        <mat-select style="max-width: 100px" [(ngModel)]="filterColumn" name="type_list" (selectionChange)="filter()" placeholder="Search By">
            <mat-option *ngFor="let type of searchBy" [value]="type">{{type}}</mat-option>
        </mat-select>
        <mat-form-field>
            <input matInput [(ngModel)]="filterValue" (keyup)="filter()" placeholder="Type here">
        </mat-form-field>

        <button (click)="addUser()" mat-flat-button color="primary"
                [autofocus]="false">Add User</button>
    </div>
    <mat-table class="mat-elevation-z0 overflow-hidden"
               [dataSource]="matUsers">
        <ng-container matColumnDef="Email" alt="Email">
            <mat-header-cell *matHeaderCellDef
                             arrowPosition="before">Email
            </mat-header-cell>
            <mat-cell *matCellDef="let user">
                {{user.email}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Name" alt="Name">
            <mat-header-cell *matHeaderCellDef
                             arrowPosition="before"> Name </mat-header-cell>
            <mat-cell *matCellDef="let user">
                {{user.name}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Roles" alt="Roles">
            <mat-header-cell *matHeaderCellDef
                             arrowPosition="before"> Roles </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <mat-select [(ngModel)]="user.roles" multiple name="type_list" (selectionChange)="update(user)">
                    <mat-option *ngFor="let type of roles" [value]="type.id">{{type.description}}</mat-option>
                </mat-select>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Actions" alt="Actions">
            <mat-header-cell *matHeaderCellDef
                             arrowPosition="before"> Actions </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <button *ngIf="user.isActive" (click)="deactivate(user)" mat-flat-button color="primary"
                        [autofocus]="false">Deactivate</button>
                <button *ngIf="!user.isActive" (click)="activate(user)" mat-flat-button color="primary"
                        [autofocus]="false">Activate</button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let user; columns: columns;"></mat-row>
    </mat-table>
</div>
