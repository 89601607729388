import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit {

  private cordinates: google.maps.LatLng;
  private point: google.maps.LatLng;
  private panorama : google.maps.StreetViewPanorama

  private city;
  private streetName;
  private streetNumber;
  private pageRoute;
  private province;
  private postalCode;
  private map: google.maps.Map;

  constructor(private router : Router) { }

  ngOnInit() {
    if (this.router.url != "/") {
      this.pageRoute=this.router.url;
      this.streetNumber = this.pageRoute.split("streetNumber=").pop().split('&')[0]
      this.streetName = decodeURIComponent(this.pageRoute.split('streetNumber=').pop().split('&')[1].split('streetName=')[1])
      this.city = decodeURIComponent(this.pageRoute.split('streetName=').pop().split('&')[1].split('city=')[1])
      this.postalCode = decodeURIComponent(this.pageRoute.split('city=').pop().split('&')[1].split('postalCode=')[1])
      this.province = decodeURIComponent(this.pageRoute.split('&province=').pop())

      this.initMap();
    }
  }

  private updateView() {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: this.cordinates,
      zoom: 17
    });

    this.point = new google.maps.LatLng(Number(this.cordinates.lat), Number(this.cordinates.lng));
    var streetViewService = new google.maps.StreetViewService();
    this.panorama = new google.maps.StreetViewPanorama(
      document.getElementById('pano'), {
        position: this.cordinates,
        pov: {
          heading: 0,
          pitch: 0
        }
      });

      this.map.setStreetView(this.panorama);
      var markerPt = this.cordinates;

      var streetViewService = new google.maps.StreetViewService();
      var STREETVIEW_MAX_DISTANCE = 15;
      streetViewService.getPanoramaByLocation(this.cordinates, STREETVIEW_MAX_DISTANCE,(panorama, status) => {
        if (status == google.maps.StreetViewStatus.OK) {
          var oldPoint = this.cordinates;
          this.point = panorama.location.latLng;
          markerPt = this.point;

          var heading = google.maps.geometry.spherical.computeHeading(this.point, oldPoint);

          this.panorama = new google.maps.StreetViewPanorama(
            document.getElementById('pano'), {
              position: this.point,
              pov: {
                heading: heading,
                pitch: 0
              }
            });
          this.map.setStreetView(this.panorama);
        }
        var marker = new google.maps.Marker({
          position: markerPt,
          map:this.map
        });
      });
  }

  private geocodeAddress(geocoder : google.maps.Geocoder, address : string) {
    geocoder.geocode({'address':address}, (result, status) => {
      if (status == "OK") {
        this.cordinates = result[0].geometry.location
        this.updateView();
      } else {
        switch(status) {
          case 'ZERO_RESULTS':
	        case 'INVALID_REQUEST':
	          alert('No result found');
	          break;
	        default:
	          alert('Service unavailable');
	          break;
        }
      }
    })
  }

  private initMap() {
    var geoCoder = new google.maps.Geocoder();
    this.geocodeAddress(geoCoder, this.streetNumber + " " + this.streetName + " " + this.city + " " +  this.province+ " " + this.postalCode)
  }

}
