export class CreditBureauReportCSVDTO {
    ReceivedLoanID: string;
    Date: Date;
    LenderCode: string;
    LenderName: string;
    CreditBureau: string;
    EfxTu: string;

    constructor(applicationId: string, date: Date, lenderCode: string, lenderName: string, hasCreditBureau: string, isEfxTu: string) {
        this.ReceivedLoanID = applicationId;
        this.Date = date;
        this.LenderCode = lenderCode;
        this.LenderName = lenderName;
        this.CreditBureau = hasCreditBureau;
        this.EfxTu = isEfxTu;
    }

}