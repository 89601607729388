import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConfigService} from '../config-service/app-config.service';
import {AuthGuardService} from '../security/auth-guard.service';
import {AuthService} from '../security/auth.service';
import {CookieService} from 'ngx-cookie-service';
import {LanguageService} from '../language-service/language.service';

@Injectable({
    providedIn: 'root'
})
export class LGStripeService {

    private saveFirstCardPath = AppConfigService.settings.stripe_backend_endpoint + '/save-first-card';
    private saveCardsExistingCustomerPath = AppConfigService.settings.stripe_backend_endpoint + '/save-card-existing-customer';
    private getCustomerCardsPath = AppConfigService.settings.stripe_backend_endpoint + '/get-customer-cards';
    private CustomerPath = AppConfigService.settings.stripe_backend_endpoint + '/customer';
    private paymentMethod = AppConfigService.settings.stripe_backend_endpoint + '/payment-method';
    private payExistingCustomerPath = AppConfigService.settings.stripe_backend_endpoint + '/pay-existing-customer';
    private checkoutPath = AppConfigService.settings.stripe_backend_endpoint + '/checkout';
    private getCouponPath = AppConfigService.settings.stripe_backend_endpoint + '/coupons';
    private invoicePath = AppConfigService.settings.stripe_backend_endpoint + '/create-invoice';
    private invoicesPath = AppConfigService.settings.stripe_backend_endpoint + '/invoices';
    private invoiceByIdPath = AppConfigService.settings.stripe_backend_endpoint + '/invoice';
    private invoiceItemsPath = AppConfigService.settings.stripe_backend_endpoint + '/invoice-items';
    private finalizeInvoicePath = AppConfigService.settings.stripe_backend_endpoint + '/finalize-invoice';
    private updateInvoicePath = AppConfigService.settings.stripe_backend_endpoint + '/update-invoice';
    private deleteInvoicePath = AppConfigService.settings.stripe_backend_endpoint + '/delete-invoice';
    private payInvoicePath = AppConfigService.settings.stripe_backend_endpoint + '/pay-invoice';
    private paymentIntentPath = AppConfigService.settings.stripe_backend_endpoint + '/payment-intent';
    private defaultPaymentMethod = AppConfigService.settings.stripe_backend_endpoint + '/default-payment-method';

    constructor(private http: HttpClient, private authService: AuthService, private authGuardService: AuthGuardService,
                private cookieService: CookieService, private languageService: LanguageService) {
    }

    saveFirstCard(stripeObject: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.post(this.saveFirstCardPath, stripeObject, {
            headers
        });
    }

    saveCardsExistingCustomer(stripeObject: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.put(this.saveCardsExistingCustomerPath, stripeObject, {
            headers
        });
    }

    createCheckout(stripeObject: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.post(this.checkoutPath, stripeObject, {
            headers
        });
    }

    createInvoice(stripeObject: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.post(this.invoicePath, stripeObject, {
            headers
        });
    }

    createInvoiceItems(stripeObject: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.post(this.invoiceItemsPath, stripeObject, {
            headers
        });
    }

    finalizeInvoice(stripeObject: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.post(this.finalizeInvoicePath, stripeObject, {
            headers
        });
    }

    updateInvoice(stripeObject: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.post(this.updateInvoicePath, stripeObject, {
            headers
        });
    }

    payInvoice(stripeObject: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.post(this.payInvoicePath, stripeObject, {
            headers
        });
    }

    deleteInvoice(stripeObject: any): Observable<any> {
        const headers = this.getHeaders();
        const params = new HttpParams()
            .set('invoiceId', stripeObject.invoiceId.toString());
        return this.http.delete(this.deleteInvoicePath, {
            headers, params
        });
    }

    getCustomerInvoices(customerId: any): Observable<any> {
        const headers = this.getHeaders();
        const params = new HttpParams()
            .set('customerId', customerId.toString());
        return this.http.get(this.invoicesPath, {
            headers, params
        });
    }

    getInvoiceById(invoiceId: any): Observable<any> {
        const headers = this.getHeaders();
        const params = new HttpParams()
            .set('invoiceId', invoiceId.toString());
        return this.http.get(this.invoiceByIdPath, {
            headers, params
        });
    }

    updateCustomer(stripeObject: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.put(this.CustomerPath, stripeObject, {
            headers
        });
    }

    deleteCardByCustomer(stripeObject: any): Observable<any> {
        const headers = this.getHeaders();
        const params = new HttpParams()
            .set('paymentMethodId', stripeObject.paymentMethod_id.toString());
        return this.http.delete(this.paymentMethod, {
            headers, params
        });
    }

    payExistingCustomer(stripeObject: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.post(this.payExistingCustomerPath, stripeObject, {
            headers
        });
    }

    getCustomerCards(customerId: any): Observable<any> {
        const headers = this.getHeaders();
        const params = new HttpParams()
            .set('customerId', customerId.toString());
        return this.http.get(this.getCustomerCardsPath, {
            headers, params
        });
    }

    getCustomerById(customerId: any): Observable<any> {
        const headers = this.getHeaders();
        const params = new HttpParams()
            .set('customerId', customerId);
        return this.http.get(this.CustomerPath, {
            headers, params
        });
    }

    getPaymentMethodById(paymentMethodId: any): Observable<any> {
        const headers = this.getHeaders();
        const params = new HttpParams()
            .set('payment_method_id', paymentMethodId);
        return this.http.get(this.paymentMethod, {
            headers, params
        });
    }

    getCoupon(coupon: any): Observable<any> {
        const headers = this.getHeaders();
        const params = new HttpParams()
            .set('coupon', coupon.toString());
        return this.http.get(this.getCouponPath, {
            headers, params
        });
    }

    private getLanguage(): string {
        const lang = this.languageService.getLanguage();
        return  lang ?
            lang.substring(0, 2) : 'en';
    }

    updatePaymentIntent(stripeObject: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.post(this.paymentIntentPath, stripeObject, {
            headers
        });
    }

    getDefaultPaymentMethod(stripeObject: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.get(this.defaultPaymentMethod + '?customerId=' + stripeObject.customerId, {
            headers
        });
    }

    private getHeaders(): HttpHeaders {
        const lang = this.getLanguage();
        const headers = new HttpHeaders()
            .set('authorizationtoken', this.cookieService.get('IdToken'))
            .set('Content-Type', 'application/json')
            .set('Accept-Language', lang);
        return headers;
    }
}
