<app-header></app-header>

<div style="padding-bottom: 40px;height: 100%;max-width: 100%;min-width: 100%;display: flex;flex-wrap: wrap;">
    <div class="box">
        <div class="box-title">Statuses Insights</div>
        <div class="box-subtitle">Provides an overview of the status changes accross all leads/deals belonging to all
            active lenders from Private Lender Gateway.</div>
        <mat-form-field appearance="fill" style="width: 290px;">
            <mat-select placeholder="Last month">
                <mat-option value="Last month" (click)="setOption('first', 'Last month')">Last month</mat-option>
                <mat-option value="Last 3 months" (click)="setOption('first', 'Last 3 months')">Last 3 months
                </mat-option>
                <mat-option value="Last year" (click)="setOption('first', 'Last year')">Last year</mat-option>
                <mat-option value="Interval" (click)="setOption('first', 'Interval')">Choose an interval</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="reportOptionA == 'interval'" appearance="fill" [formGroup]="range1"
            style="width: 290px;">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker1" [min]="minDate" [max]='maxDate'>
                <input matStartDate formControlName="start" placeholder="mm/dd/yyyy" 
                    (dateChange)="checkInterval('first', $event)" (dateInput)="checkIntervalRange('Status',$event)">
                <input matEndDate formControlName="end" placeholder="mm/dd/yyyy"
                    (dateChange)="checkInterval('first', $event)" (dateInput)="checkIntervalRange('Status',$event)">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-date-range-picker #picker1></mat-date-range-picker>
        </mat-form-field>        
        <div class="incorrectInterval" *ngIf="incorrectInterval1">
            The start date cannot be higher than the end date
        </div>
        <button class="mr-2" (mousedown)="runReport('A')" style="margin-left: 130px;margin-top: 10px;"
            [disabled]="disableButton1 && isIntervalSelected1" mat-flat-button color="primary">
            Run Report
        </button>
    </div>

    <div class="box">
        <div class="box-title">Credit Bureau Insights</div>
        <div class="box-subtitle">Provides information regarding the existence of credit reports per each loan received
            on Private Lender Gateway.</div>
        <mat-form-field appearance="fill" style="width: 290px;">
            <mat-select placeholder="Last month">
                <mat-option value="Last month" (click)="setOption('second', 'Last month')">Last month</mat-option>
                <mat-option value="Last 3 months" (click)="setOption('second', 'Last 3 months')">Last 3 months
                </mat-option>
                <mat-option value="Last year" (click)="setOption('second', 'Last year')">Last year</mat-option>
                <mat-option value="Interval" (click)="setOption('second', 'Interval')">Choose an interval</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="reportOptionB == 'interval'" appearance="fill" [formGroup]="range2"
            style="width: 290px;">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker2" [min]="minDate" [max]='maxDate'>
                <input matStartDate formControlName="start" placeholder="mm/dd/yyyy"
                    (dateChange)="checkInterval('second', $event)" (dateInput)="checkIntervalRange('CreditBureau',$event)">
                <input matEndDate formControlName="end" placeholder="mm/dd/yyyy"
                    (dateChange)="checkInterval('second', $event)" (dateInput)="checkIntervalRange('CreditBureau',$event)">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-date-range-picker #picker2></mat-date-range-picker>
        </mat-form-field>
        <div class="incorrectInterval" *ngIf="incorrectInterval2">
            The start date cannot be higher than the end date
        </div>
        <button class="mr-2" (mousedown)="runReport('B')" style="margin-left: 130px;margin-top: 10px;"
            [disabled]="disableButton2 && isIntervalSelected2" mat-flat-button color="primary">
            Run Report
        </button>
    </div>

    <div class="box">
        <div class="box-title">Property Address Report</div>
        <div class="box-subtitle">Provides high-level information regarding the property addresses of the loans that are
            sent to Private Lender Gateway.</div>
        <mat-form-field appearance="fill" style="width: 290px;">
            <mat-select placeholder="Last month">
                <mat-option value="Last month" (click)="setOption('third', 'Last month')">Last month</mat-option>
                <mat-option value="Last 3 months" (click)="setOption('third', 'Last 3 months')">Last 3 months
                </mat-option>
                <mat-option value="Last year" (click)="setOption('third', 'Last year')">Last year</mat-option>
                <mat-option value="Interval" (click)="setOption('third', 'Interval')">Choose an interval</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="reportOptionC == 'interval'" appearance="fill" [formGroup]="range3"
            style="width: 290px;">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker3" [min]="minDate" [max]='maxDate'>
                <input matStartDate formControlName="start" placeholder="mm/dd/yyyy"
                    (dateChange)="checkInterval('third', $event)" (dateInput)="checkIntervalRange('PropertyAddr',$event)">
                <input matEndDate formControlName="end" placeholder="mm/dd/yyyy"
                    (dateChange)="checkInterval('third', $event)" (dateInput)="checkIntervalRange('PropertyAddr',$event)">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-date-range-picker #picker3></mat-date-range-picker>
        </mat-form-field>
        <div class="incorrectInterval" *ngIf="incorrectInterval3">
            The start date cannot be higher than the end date
        </div>
        <button class="mr-2" (mousedown)="runReport('C')" style="margin-left: 130px;margin-top: 10px;"
            [disabled]="disableButton3 && isIntervalSelected3" mat-flat-button color="primary">
            Run Report
        </button>
    </div>

    <div class="box">
        <div class="box-title">Denial Reasons Report</div>
        <div class="box-subtitle">Provides the denial reasons for each of the loans that were declined or rejected,
            including the agent profile (name of the agent + brokerage).</div>
        <mat-form-field appearance="fill" style="width: 290px;">
            <mat-select placeholder="Last month">
                <mat-option value="Last month" (click)="setOption('forth', 'Last month')">Last month</mat-option>
                <mat-option value="Last 3 months" (click)="setOption('forth', 'Last 3 months')">Last 3 months
                </mat-option>
                <mat-option value="Last year" (click)="setOption('forth', 'Last year')">Last year</mat-option>
                <mat-option value="Interval" (click)="setOption('forth', 'Interval')">Choose an interval</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="reportOptionD == 'interval'" appearance="fill" [formGroup]="range4"
            style="width: 290px;">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker4" [min]="minDate" [max]='maxDate'>
                <input matStartDate formControlName="start" placeholder="mm/dd/yyyy"
                    (dateChange)="checkInterval('forth', $event)" (dateInput)="checkIntervalRange('DenialReasons',$event)">
                <input matEndDate formControlName="end" placeholder="mm/dd/yyyy"
                    (dateChange)="checkInterval('forth', $event)" (dateInput)="checkIntervalRange('DenialReasons',$event)">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-date-range-picker #picker4></mat-date-range-picker>
        </mat-form-field>
        <div class="incorrectInterval" *ngIf="incorrectInterval4">
            The start date cannot be higher than the end date
        </div>
        <button class="mr-2" (mousedown)="runReport('D')" style="margin-left: 130px;margin-top: 10px;"
            [disabled]="disableButton4 && isIntervalSelected4" mat-flat-button color="primary">
            Run Report
        </button>
    </div>

    <div class="box">
        <div class="box-title">Lender Code - Lender Name Report</div>
        <div class="box-subtitle">A list of all the lender accounts (active/inactive) and their respective lender codes.
        </div>
        <mat-form-field appearance="fill" style="width: 290px;">
            <mat-select placeholder="Last month">
                <mat-option value="Last month" (click)="setOption('fifth', 'Last month')">Last month</mat-option>
                <mat-option value="Last 3 months" (click)="setOption('fifth', 'Last 3 months')">Last 3 months
                </mat-option>
                <mat-option value="Last year" (click)="setOption('fifth', 'Last year')">Last year</mat-option>
                <mat-option value="Interval" (click)="setOption('fifth', 'Interval')">Choose an interval</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="reportOptionE == 'interval'" appearance="fill" [formGroup]="range5"
            style="width: 290px;">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker5" [min]="minDate" [max]='maxDate'>
                <input matStartDate formControlName="start" placeholder="mm/dd/yyyy"
                    (dateChange)="checkInterval('fifth', $event)" (dateInput)="checkIntervalRange('LenderCode',$event)">
                <input matEndDate formControlName="end" placeholder="mm/dd/yyyy"
                    (dateChange)="checkInterval('fifth', $event)" (dateInput)="checkIntervalRange('LenderCode',$event)">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
            <mat-date-range-picker #picker5></mat-date-range-picker>
        </mat-form-field>
        <div class="incorrectInterval" *ngIf="incorrectInterval5">
            The start date cannot be higher than the end date
        </div>
        <button class="mr-2" (mousedown)="runReport('E')" style="margin-left: 130px;margin-top: 10px;"
            [disabled]="disableButton5 && isIntervalSelected5" mat-flat-button color="primary">
            Run Report
        </button>
    </div>

    <div class="box">
        <div class="box-title">Paid Loans Overview</div>
        <div class="box-subtitle">Provides insights on all the paid loans received on Private Lender Gateway along with
            the lender account that made that payment.</div>
        <mat-form-field appearance="fill" style="width: 290px;">
            <mat-select placeholder="Last month">
                <mat-option value="Last month" (click)="setOption('sixth', 'Last month')">Last month</mat-option>
                <mat-option value="Last 3 months" (click)="setOption('sixth', 'Last 3 months')">Last 3 months
                </mat-option>
                <mat-option value="Last year" (click)="setOption('sixth', 'Last year')">Last year</mat-option>
                <mat-option value="Interval" (click)="setOption('sixth', 'Interval')">Choose an interval</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="reportOptionF == 'interval'" appearance="fill" [formGroup]="range6"
            style="width: 290px;">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker6" [min]="minDate" [max]='maxDate'>
                <input matStartDate formControlName="start" placeholder="mm/dd/yyyy"
                    (dateChange)="checkInterval('sixth', $event)" (dateInput)="checkIntervalRange('PaidLoans',$event)">
                <input matEndDate formControlName="end" placeholder="mm/dd/yyyy"
                    (dateChange)="checkInterval('sixth', $event)" (dateInput)="checkIntervalRange('PaidLoans',$event)">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
            <mat-date-range-picker #picker6></mat-date-range-picker>
        </mat-form-field>
        <div class="incorrectInterval" *ngIf="incorrectInterval6">
            The start date cannot be higher than the end date
        </div>
        <button class="mr-2" (mousedown)="runReport('F')" style="margin-left: 130px;margin-top: 10px;"
            [disabled]="disableButton6 && isIntervalSelected6" mat-flat-button color="primary">
            Run Report
        </button>
    </div>

</div>