import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuardService } from '../../services/security/auth-guard.service';
import { CookieService } from 'ngx-cookie-service';
import { ProfileType } from '../../models/profile-type.model';
import { ProfileRole } from 'src/app/models/profile-role.model';
import { Auth } from 'aws-amplify';
import { AuthService } from 'src/app/services/security/auth.service';
import { LanguageService } from 'src/app/services/language-service/language.service';


@Component({
    selector: 'app-dropdown-menu',
    templateUrl: './dropdown-menu.component.html',
    styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnInit {

    public isPublishedToExpert: boolean = this.authService.profileDetails["sentToExpert"];
    public profileType = this.authService.profileDetails["profileType"];
    public userNameInitial = this.authService.profileDetails["lenderName"] ?
        this.authService.profileDetails["lenderName"].charAt(0) : '';
    public userEnglishName = this.authService.profileDetails["lenderName"];
    public userEmail = this.authService.profileDetails["contactDetails"].email;
    public isLender: boolean;

    constructor(private languageService: LanguageService, private router: Router, private authService: AuthService) { }

    ngOnInit() {
        this.isUserLender();
    }

    isLenderUser() {
        return this.hasRole(ProfileRole.LENDER_USER);
    }

    isCsAdmin() {
        return this.hasRole(ProfileRole.CS_ADMIN);
    }

    isReporting() {
        return this.hasRole(ProfileRole.REPORTING);
    }

    isSystemAdmin() {
        return this.hasRole(ProfileRole.SYSTEM_ADMIN);
    }

    singleRole() {
        return this.authService.profileDetails["profileRole"].length === 1;
    }

    hasRole(role) {
        return this.authService.profileDetails["profileRole"].indexOf(role) != -1;
    }

    public viewAccountDetails() {
        this.inMenu = false;
        if (this.authService.profileDetails["profileRole"].includes(ProfileRole.LENDER_USER) || this.authService.profileDetails["profileRole"].includes(ProfileRole.CS_ADMIN) || this.authService.profileDetails["profileRole"].includes(ProfileRole.LENDER_ADMIN)) {
            var accountDetailsUrl = 'accountDetailsLender?lenderCode=' + this.authService.profileDetails["fk_LenderAccount"];
            this.router.navigateByUrl(accountDetailsUrl);
        }
    }

    public async goto(page) {
        this.router.navigate([page]);
    }
    public async viewDashboard() {
        //To be used again in the future if the a href redirection is not used anymore
        //this.inMenu = false;
        //if (this.isPublishedToExpert) {
        //    await this.router.navigate(['leads/']).then(async () => {
        //        await window.location.reload();
        //    });
        //}
        ////In the future, if a lender is not published, it will redirect on a different page
        //else if (!this.isPublishedToExpert) {
        //    await this.router.navigate(['leads/']).then(async () => {
        //        await window.location.reload();
        //    });
        //}
    }

    public logout() {
        this.inMenu = false;
        this.authService.profileDetails = null;
        this.authService.signOut();
    }

    public isUserLender() {
        this.isLender = this.profileType == ProfileType.LENDER;
    }

    private timedOutCloserButton;
    private timedOutCloserMenu;
    private inMenu = true;

    private keepTrigger;

    public mouseEnterIcon(trigger) {
        this.inMenu = true;
        if (this.timedOutCloserButton) {
            clearTimeout(this.timedOutCloserButton);
        }
        this.keepTrigger = trigger;
        trigger.openMenu();
    }

    public mouseLeaveIcon(trigger) {
        this.timedOutCloserButton = setTimeout(() => {
            if (!this.inMenu)
                trigger.closeMenu();
        }, 300);
    }

    public mouseEnterMenu() {
        this.inMenu = true;
    }

    public mouseLeaveMenu() {
        this.inMenu = false;
        this.timedOutCloserMenu = setTimeout(() => {
            this.keepTrigger.closeMenu();
        }, 300);
    }

    openHelp() {
        if (this.languageService.getLanguage() == 'en') {
            window.open('help/index.htm', '_blank');
        } else {
            window.open('help-fr/index.htm', '_blank');
        }
        return false;
    }
}
