import { DatePipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { StatusesReportLoanData } from 'src/app/models/statuses-report-loan-data';
import { LanguageService } from 'src/app/services/language-service/language.service';
import { ReportingService } from 'src/app/services/reporting/reporting.service';
import { PromptsMessages } from '../common/constants/promptsMessages';
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: 'app-report-details-print',
  templateUrl: './report-details-print.component.html',
  styleUrls: ['./report-details-print.component.scss']
})
export class ReportDetailsPrintComponent implements OnInit {

  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  public dataSource: MatTableDataSource<any>;
  public dataSourcePDF: MatTableDataSource<any>;
  public statusReportDataSource: MatTableDataSource<any>;
  public statusReportDataSourcePDF: MatTableDataSource<any>;
  public dataSourceCSVPDF: MatTableDataSource<any>;
  private dataAvailable = true;
  public startDate = null;
  public endDate = null;
  public isStatusInsights: boolean;
  public isLenderCodeLenderNameReportDisplayed: boolean = false;

  constructor(private reportingService: ReportingService, private route: ActivatedRoute, public datepipe: DatePipe, private languageService: LanguageService) { 
    const fromQueryParams = window.location.search.match('lang=([^&]+)');
    const defLang = fromQueryParams ? fromQueryParams[1] : 'en';
    this.languageService.setLanguagePreference(defLang);
  }

  private displayedColumns: string[] = [
    'date',
    'lenderCode',
    'lenderName',
    'receivedLead',
    'paidLead',
    'approvedDeal',
    'declinedDeal',
    'fundedDeal',
    'loanId',
    'applicationId',
    'hasCreditBureau',
    'isEfxTu',
    'propertyAddress',
    'denialReason',
    'agentNameBrokerage',
    'status'
  ];

  public reportType;
  public reportOption;

  ngOnInit() {
    this.isLenderCodeLenderNameReportDisplayed = false;
    this.isStatusInsights = false;
    this.dataAvailable = true;

    this.route.queryParams
      .subscribe(params => {
        this.reportType = params['reportType'];
        this.startDate = params['start'];
        this.endDate = params['end'];
        this.reportingService.reportOption = this.reportingService.reportOption != "Last month" && this.reportingService.reportOption != "Last 3 months"
                                            && this.reportingService.reportOption != "Last year" ?
        this.startDate + " - " + this.datepipe.transform(new Date(this.endDate).setDate(new Date(this.endDate).getDate() - 1), 'MM/dd/yyyy') : this.reportingService.reportOption;
      });

    this.startDate = this.datepipe.transform(new Date(this.startDate), 'yyyy-MM-dd');
    this.endDate = this.datepipe.transform(new Date(this.endDate), 'yyyy-MM-dd')

    this.reportingService.reportType = this.reportType;

    this.dataSource = new MatTableDataSource();
    this.statusReportDataSource = new MatTableDataSource();
    this.dataSourceCSVPDF = new MatTableDataSource();

    this.getDataForPDF();
  }

  public getColumnsToDisplay() {
    switch (this.reportingService.getReportType()) {
      case PromptsMessages.REPORTING_TYPE_1: {
        this.displayedColumns = [
          'lenderCode&lenderName', 'loanData'
        ];
        break;
      }
      case PromptsMessages.REPORTING_TYPE_2: {
        this.displayedColumns = [
          'date', 'lenderCode', 'lenderName', 'applicationId', 'hasCreditBureau', 'isEfxTu'
        ];
        break;
      }
      case PromptsMessages.REPORTING_TYPE_3: {
        this.displayedColumns = [
          'date', 'lenderCode', 'lenderName', 'applicationId', 'propertyAddress'
        ];
        break;
      }
      case PromptsMessages.REPORTING_TYPE_4: {
        this.displayedColumns = [
          'date', 'lenderCode', 'lenderName', 'loanId', 'denialReason', 'firmName'
        ];
        break;
      }
      case PromptsMessages.REPORTING_TYPE_5: {
        this.displayedColumns = [
          'date', 'lenderCode', 'lenderName', 'status'
        ];
        this.isLenderCodeLenderNameReportDisplayed = true;
        break;
      }
      case PromptsMessages.REPORTING_TYPE_6: {
        this.displayedColumns = [
          'date', 'lenderCode', 'lenderName', 'paidLead'
        ];
        break;
      }
    }
    return this.displayedColumns;
  }

  public getColumnsToDisplayForSecondTable() {
    return ['status', 'loanData'];
  }

  public getColumnsToDisplayForThirdTable() {
    return ['loanId', 'date'];
  }

  public getTitleForPdf() {
    return this.reportType + " - " + this.reportingService.getReportOption();
  }

  public getDataForPDF() {
    if (this.reportType === PromptsMessages.REPORTING_TYPE_1) {
      this.isStatusInsights = true;
      this.statusReportDataSourcePDF = new MatTableDataSource();

      this.reportingService.getStatusesReporting(this.startDate, this.endDate, -1, -1).subscribe(data => {
        this.statusReportDataSourcePDF.data = data.objectList;
        if (data == null || data.objectList.length == 0) {
          this.dataAvailable = false;
        }
      });
    } else if (this.reportingService.getReportType() == PromptsMessages.REPORTING_TYPE_2) {
      this.dataSourcePDF = new MatTableDataSource();

      this.reportingService.getCreditBureausReport(this.startDate, this.endDate, -1, -1).subscribe(data => {
        this.dataSourcePDF.data = data.objectList;
        if (data == null || data.objectList.length == 0) {
          this.dataAvailable = false;
        }
      });
    } else if (this.reportingService.getReportType() == PromptsMessages.REPORTING_TYPE_3) {
      this.dataSourcePDF = new MatTableDataSource();

      this.reportingService.getPropertyAddressReport(this.startDate, this.endDate, -1, -1).subscribe(data => {
        this.dataSourcePDF.data = data.objectList;
        if (data == null || data.objectList.length == 0) {
          this.dataAvailable = false;
        }
      });
    } else if (this.reportingService.getReportType() == PromptsMessages.REPORTING_TYPE_4) {
      this.dataSourcePDF = new MatTableDataSource();

      this.reportingService.getDenialReasonsReport(this.startDate, this.endDate, -1, -1).subscribe(data => {
        this.dataSourcePDF.data = data.objectList;
        if (data == null || data.objectList.length == 0) {
          this.dataAvailable = false;
        }
      });
    } else if (this.reportingService.getReportType() == PromptsMessages.REPORTING_TYPE_5) {
      this.dataSourcePDF = new MatTableDataSource();

      this.reportingService.getLenderCodeLenderNameReport(this.startDate, this.endDate, -1, -1).subscribe(data => {
        this.dataSourcePDF.data = data.objectList;
        if (data == null || data.objectList.length == 0) {
          this.dataAvailable = false;
        }
      });
    } else if (this.reportingService.getReportType() == PromptsMessages.REPORTING_TYPE_6) {
      this.dataSourcePDF = new MatTableDataSource();

      this.reportingService.getPaidLoansReport(this.startDate, this.endDate, -1, -1).subscribe(data => {
        this.dataSourcePDF.data = data.objectList;
        if (data == null || data.objectList.length == 0) {
          this.dataAvailable = false;
        }
      });
    }
  }

  updateDates() {
    this.startDate = null;
    this.endDate = null;
    switch (this.reportOption) {
      case 'last month': {
        this.startDate = this.datepipe.transform(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM-dd');
        this.endDate = this.datepipe.transform(new Date().setDate(new Date().getDate() + 1), 'yyyy-MM-dd');
        break;
      }
      case 'last 3 months': {
        this.startDate = this.datepipe.transform(new Date().setMonth(new Date().getMonth() - 3), 'yyyy-MM-dd');
        this.endDate = this.datepipe.transform(new Date().setDate(new Date().getDate() + 1), 'yyyy-MM-dd');
        break;
      }
      case 'last year': {
        this.startDate = this.datepipe.transform(new Date().setFullYear(new Date().getFullYear() - 1), 'yyyy-MM-dd');
        this.endDate = this.datepipe.transform(new Date().setDate(new Date().getDate() + 1), 'yyyy-MM-dd');
        break;
      }
    }
  }

  public isDataAvailable() {
    return this.dataAvailable;
  }

  public sortData(sort: MatSort) {
    if (sort.active && sort.direction !== '') {
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        return this.compare(a.date, b.date, isAsc);
      });
    }
  }

  private compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  isDataAvailableForStatusReportTable() {
    if (this.statusReportDataSourcePDF.data.length > 0) {
      return true;
    }
    return false;
  }

  extractDatasourceForSecondTable(loanData: StatusesReportLoanData[]) {
    let dataSourceForSecondTable: MatTableDataSource<any> = new MatTableDataSource();
    let objList: any[] = [];

    let receivedLeads: any = {};
    receivedLeads.status = "New";
    receivedLeads.loans = loanData.filter((ld) => { return ld.status === "New" });
    if (receivedLeads.loans != null && receivedLeads.loans != undefined && receivedLeads.loans.length != 0) {
      objList.push(receivedLeads);
    }


    let payedLeads: any = {};
    payedLeads.status = "In Review";
    payedLeads.loans = loanData.filter((ld) => { return ld.status === "In Review" });
    if (payedLeads.loans != null && payedLeads.loans != undefined && payedLeads.loans.length != 0) {
      objList.push(payedLeads);
    }

    let rejectedLeads: any = {};
    rejectedLeads.status = "Rejected";
    rejectedLeads.loans = loanData.filter((ld) => { return ld.status === "Rejected" });
    if (rejectedLeads.loans != null && rejectedLeads.loans != undefined && rejectedLeads.loans.length != 0) {
      objList.push(rejectedLeads);
    }

    let approvedDeal: any = {};
    approvedDeal.status = "Approved";
    approvedDeal.loans = loanData.filter((ld) => { return ld.status === "Approved" });
    if (approvedDeal.loans != null && approvedDeal.loans != undefined && approvedDeal.loans.length != 0) {
      objList.push(approvedDeal);
    }

    let declinedDeals: any = {};
    declinedDeals.status = "Declined";
    declinedDeals.loans = loanData.filter((ld) => { return (ld.status === "Declined") });
    if (declinedDeals.loans != null && declinedDeals.loans != undefined && declinedDeals.loans.length != 0) {
      objList.push(declinedDeals);
    }

    let fundedDeals: any = {};
    fundedDeals.status = "Funded";
    fundedDeals.loans = loanData.filter((ld) => { return ld.status === "Funded" });
    if (fundedDeals.loans != null && fundedDeals.loans != undefined && fundedDeals.loans.length != 0) {
      objList.push(fundedDeals);
    }

    dataSourceForSecondTable.data = objList;
    return dataSourceForSecondTable;
  }

  extractDatasourceForThirdTable(loans: any[]) {
    let dataSourceForSecondTable: MatTableDataSource<any> = new MatTableDataSource();
    dataSourceForSecondTable.data = loans;
    return dataSourceForSecondTable;
  }

}
