import { Component, OnInit, Inject, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromptModel } from '../../../models/prompt.model';
import { Validation } from '../constants/validation';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-decision-prompt',
    templateUrl: './decision-prompt.component.html',
    styleUrls: ['./decision-prompt.component.scss']
})

export class DecisionPromptComponent implements OnInit {
    public isUploaded: boolean;
    public incorrectExtension: boolean;
    public tooManyFiles: boolean;
    public biggerThan10MB: boolean;
    public zeroSizeFile: boolean;
    public isApproved: boolean;
    public initialIsApproved: boolean;
    public isDeclined: boolean;
    public incorrectUpload: boolean;
    public notesLengthError: boolean;
    public fileNames: string[] = []
    public onApprove = new EventEmitter();
    public onCancel = new EventEmitter();
    public base64PDF: any = "";
    public bufferPDF: any = "";
    public downloadLink;
    public denialOption: String;
    public denialReasonNotSelected:boolean = true;
    public isMessageToBroker: boolean;
    public listDropDown: boolean = false;
    public sendMessageToBroker: boolean;
    public uploadNewFile: boolean = false;
    private file: any;

    @ViewChild('notesArea') notesArea: any;

    constructor(private domSanitizer: DomSanitizer,public dialog: MatDialog, public dialogRef: MatDialogRef<PromptModel>,
        @Inject(MAT_DIALOG_DATA) public data: PromptModel) {
        this.isApproved = (data.textBody[0].loanStatus == "Approved" || data.textBody[0].loanStatus == "Approuvée");
        this.initialIsApproved = this.isApproved;
        this.isDeclined = (data.textBody[0].loanStatus == "Declined" || data.textBody[0].loanStatus == "Refusée") || (data.textBody[0].loanStatus == "Rejected" || data.textBody[0].loanStatus == "Rejeté");
        this.incorrectUpload = this.isApproved;
        this.downloadLink = data.textBody[0].pdf;
        
        if (this.downloadLink != "" && this.isApproved) {
            this.fileNames = [data.textBody[0].pdfName];
            setTimeout(()=>{
                (<HTMLLinkElement>document.getElementById('download-link')).href = this.downloadLink;
            },100);
            this.incorrectUpload = false;
            this.denialReasonNotSelected = false;
            this.isUploaded = true;
            this.isApproved = false;
        } else if(this.downloadLink == "" && this.isApproved){
            this.uploadNewFile=true;
        }
        if (data.textBody[0].listDropDownForDecline) {
            this.listDropDown = data.textBody[0].listDropDownForDecline;
        }
        if (data.textBody[0].messageToBroker != undefined) {
            if (data.textBody[0].messageToBroker)
                this.isMessageToBroker = true;
        }
    }

    ngOnInit() { }
    
    public async loadFile(event: any) {
        if (!event.length){
            return
        }
        this.denialReasonNotSelected = false;
        this.isApproved=false;
        this.isUploaded=true;
        if (event.length > 1) {
            this.tooManyFiles = true;
            this.incorrectUpload = true;
        } else {
            for (let index = 0; index < event.length; index++) {
                const file = event[index];
                let fileSize = file.size / 1024 / 1024
                if (fileSize > 1.3) {
                    this.incorrectUpload = true;
                    this.biggerThan10MB = true;
                    this.fileNames.splice(index, 1);
                } else if (fileSize == 0) {
                    this.incorrectUpload = true;
                    this.zeroSizeFile = true;
                    this.fileNames.splice(index, 1);
                } else if (!(file.name.substr(file.name.length - 4) === '.pdf')) {
                    this.incorrectExtension = true;
                    this.incorrectUpload = true;
                } else {
                    this.fileNames.push(file.name);
                    this.file = file;
                    this.base64PDF = await this.getBase64(file);
                    this.convertbase64ToLink(this.base64PDF);
                    this.incorrectUpload = false;
                }
            }
        }
    }

    convertbase64ToLink(base64PDF:any){
        base64PDF = base64PDF.replace("data:application/pdf;base64,", "");
        let arrBuffer;
        try {
            arrBuffer = this.base64ToArrayBuffer(base64PDF);
        }catch(e){
            return;
        }
        this.bufferPDF = arrBuffer;
        let newBlob = new Blob([arrBuffer], { type: "application/pdf" });
        let data = window.URL.createObjectURL(newBlob);
        setTimeout(()=>{
            (<HTMLLinkElement>document.getElementById('download-link')).href = data;
          },100)

    }

    base64ToArrayBuffer(data) {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    public async getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    public deleteFile(event: any, index: number) {
        this.fileNames.splice(index, 1);
        this.incorrectUpload = true;
        this.isUploaded = !(this.fileNames.length == 0)
        this.uploadNewFile = true;
    }

    public reload(event: any) {
        this.isUploaded = this.incorrectExtension = this.tooManyFiles = this.biggerThan10MB = this.incorrectUpload = false;
        this.isApproved = this.initialIsApproved;
    }

  public limitHasBeenReached: boolean = false;

    public changeNotesText(event: any) {
        var dialogBoxNotes = ((document.getElementById("additional-notes-text") as HTMLInputElement).value);
        dialogBoxNotes = this.eliminateExtraSpaces(dialogBoxNotes);
        (document.getElementById("additional-notes-text") as HTMLInputElement).value = dialogBoxNotes;
        if (this.isDeclined == true) {
        this.isDeclined = dialogBoxNotes.length > 0 && !dialogBoxNotes.match(/^\s* {1}$/) ? false : true;
        }
        this.notesLengthError = dialogBoxNotes.length == Validation.DIALOG_BOX_NOTES_LENGHT;
        if (this.isMessageToBroker) {
            this.incorrectUpload = this.notesLengthError = this.denialReasonNotSelected = this.isApproved = false;
            this.sendMessageToBroker = dialogBoxNotes.length > 0 && !dialogBoxNotes.match(/^\s* {1}$/) ? false : true;
            this.incorrectUpload = false;
        }
      
        if (dialogBoxNotes.length == Validation.DIALOG_BOX_NOTES_LENGHT){
            this.limitHasBeenReached = true;
        }
        else if(dialogBoxNotes.length < Validation.DIALOG_BOX_NOTES_LENGHT ){
            this.limitHasBeenReached = false;
        }
    }

    eliminateExtraSpaces(value) {
        if (value.match(/^\s* {1}$/)) {
            value = value.substring(0, value.length - 1);
        } else if (value.length > 2 && value.substring(value.length - 2, value.length).match(/^\s* {2}$/)) {
            value = value.substring(0, value.length - 1);
        }

        return value
    }

    public cancel() {
        this.dialog.closeAll();
        this.onCancel.emit();
    }

    public approve() {
        this.dialogRef.close({ notes: this.notesArea.nativeElement.value, file: this.file, pdf: this.base64PDF, pdfName: this.fileNames[0], denialReason: this.denialOption });
        this.onApprove.emit(); 
    }

    public setOption(selected: any) {
        this.denialReasonNotSelected = false;
        this.denialOption = selected?.value;
    }
}
