import {HttpClient} from '@angular/common/http';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LGStripeService} from '../../services/stripe/l-g-stripe.service';
import {AuthGuardService} from '../../services/security/auth-guard.service';
import {LenderService} from 'src/app/services/api/lender.service';
import {PromptModel} from '../../models/prompt.model';
import {PromptsMessages} from '../common/constants/promptsMessages';
import {GeneralPromptComponent} from '../common/general-prompt/general-prompt.component';
import {ProvinceService} from '../../services/province-service/province-service.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LenderInfoDTO} from 'src/app/models/LenderInfoDTO.model';
import {ActivatedRoute, Router} from '@angular/router';
import {DTOLoanApplication} from 'src/app/models/DTOLoanApplication';
import {LoansService} from 'src/app/services/api/loans.service';
import {LoanDecision} from 'src/app/models/loan-decision.model';
import {AppConfigService} from 'src/app/services/config-service/app-config.service';
import {LanguageService} from 'src/app/services/language-service/language.service';
import {TranslateService} from "@ngx-translate/core";
import {StripeService} from "ngx-stripe";
import {StripeCardElement} from "@stripe/stripe-js";
import {MultiTabEvents, MultiTabNotification} from "../../services/mutli-tab-notification.service";
import {AuthService} from "../../services/security/auth.service";

class Deferred {
    public promise;
    public resolve;
    public reject;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve;
        });
    }
}

@Component({
    selector: 'app-pay-page',
    templateUrl: './pay-page.component.html',
    styleUrls: ['./pay-page.component.scss']
})

export class PayPageComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('cardInfo') cardInfo: ElementRef;
    @ViewChild('nameInput') nameInput: ElementRef;
    @ViewChild('addressInput') addressInput: ElementRef;
    @ViewChild('cityInput') cityInput: ElementRef;
    @ViewChild('postalCodeInput') postalCodeInput: ElementRef;
    @ViewChild('provinceInput') provinceInput: ElementRef;
    @Output() sendPromptText: EventEmitter<any> = new EventEmitter<any>();
    public _totalAmount: number;
    public card: StripeCardElement;
    public cardHandler = this.onChange.bind(this);
    public cardError: string;
    public newPaymentMethod: any;
    public isNewCustomer: boolean;
    public addOrEditCard = true;
    public mapTaxes = new Map();
    public mapGstHstQst = new Map([
        ['AB', 'Estimated GST'],
        ['BC', 'Estimated GST'],
        ['MB', 'Estimated GST'],
        ['NB', 'Estimated HST'],
        ['NL', 'Estimated HST'],
        ['NT', 'Estimated GST'],
        ['NS', 'Estimated HST'],
        ['NU', 'Estimated GST'],
        ['ON', 'Estimated HST'],
        ['PE', 'Estimated HST'],
        ['QC', 'Estimated GST & QST'],
        ['SK', 'Estimated GST'],
        ['YT', 'Estimated GST'],
    ]);
    public mapGstHstQstFrench = new Map([
        ['AB', 'TPS estimatives'],
        ['BC', 'TPS estimatives'],
        ['MB', 'TPS estimatives'],
        ['NB', 'TVH estimatives'],
        ['NL', 'TVH estimatives'],
        ['NT', 'TPS estimatives'],
        ['NS', 'TVH estimatives'],
        ['NU', 'TPS estimatives'],
        ['ON', 'TVH estimatives'],
        ['PE', 'TVH estimatives'],
        ['QC', 'TPS & TVQ estimatives'],
        ['SK', 'TPS estimatives'],
        ['YT', 'TPS estimatives'],
    ]);
    public estimatedGstHstQst = 'Estimated ';
    public errorMessageCoupon: string;
    public errorMessagePayment: string;
    public isPayError: boolean;
    public disablePayButton = true;
    public disableSaveCardButton: boolean;
    public errorMessageCard: string;
    public isCardError: boolean;
    public currentCustomer: any;
    public currentPaymentMethod: any;
    public customerCards: any[];
    public editOrAddCard: boolean;
    public provinces = [];
    public paymentForm: FormGroup;
    public amount = Number(AppConfigService.settings.fee);
    public taxValue = 0;
    public totalAmount = this.amount;
    public id;
    public activeLoanDecision;
    public loanApplicationId;
    public applicationDecision;
    public previousStatus;
    public isEligibleForRemove;
    public clientName;
    public lenderName;
    public profileName;
    public taxObjects = [];
    private promotionCode: string;
    private paymentMethods: any;
    private csvProvincesList: any;
    public lenderNamePayPage: string;
    currentInvoiceId = '';
    currentInvoiceItemId = '';
    invoicePromise = new Deferred();

    constructor(private languageService: LanguageService,  private loansService: LoansService, 
                private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder,
                private provinceService: ProvinceService, private lenderService: LenderService, 
                private authService: AuthService, private cd: ChangeDetectorRef,
                private dialog: MatDialog, private http: HttpClient, private stripeService: LGStripeService,
                private translateService: TranslateService, private stripe: StripeService,
                private multiTabNotification: MultiTabNotification
    ) {

        // @ts-ignore
        this.stripe.setKey(AppConfigService.settings.stripePublicKey, { locale: this.languageService.getLanguage() });

        this.paymentForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            addressLine1: ['', [Validators.required]],
            addressLine2: [''],
            city: ['', [Validators.required]],
            postalCode: ['', [Validators.required]],
            province: ['', [Validators.required]]
        });

        const taxes = AppConfigService.settings.taxesId.split(',');
        this.taxObjects = [
            {region: 'QC', id: [taxes[0], taxes[1]]},
            {region: 'PE', id: [taxes[2]]},
            {region: 'NS', id: [taxes[3]]},
            {region: 'NL', id: [taxes[4]]},
            {region: 'ON', id: [taxes[5]]},
            {region: 'YT', id: [taxes[6]]},
            {region: 'SK', id: [taxes[7]]},
            {region: 'NU', id: [taxes[8]]},
            {region: 'NT', id: [taxes[9]]},
            {region: 'NB', id: [taxes[10]]},
            {region: 'MB', id: [taxes[11]]},
            {region: 'BC', id: [taxes[12]]},
            {region: 'AB', id: [taxes[13]]}
        ];

        this.route.queryParams
            .subscribe(params => {
                this.id = params.id;
                this.loanApplicationId = params.loanApplicationId;
                this.activeLoanDecision = params.activeLoanDecision;
                this.applicationDecision = params.applicationDecision;
                this.previousStatus = params.previousStatus;
                this.isEligibleForRemove = params.isEligibleForRemove;
                this.lenderName = params.lenderName;
                this.clientName = params.clientName;
                this.profileName = params.profileName;
            });

        this._totalAmount = 100;
        this.isNewCustomer = this.authService.profileDetails.stripe_id == null ? true : false;
        this.editOrAddCard = this.isNewCustomer ? true : false;
        this.provinceService.getProvinces().subscribe(data => {
            this.csvProvincesList = this.provinceService.CSV2JSON(data);
            this.csvProvincesList.splice(0, 1);
            let length = this.csvProvincesList.length;
            this.csvProvincesList.splice(length - 1, 1);
            for (const elem in this.csvProvincesList) {
                this.provinces.push({id: elem, name: this.csvProvincesList[elem][0]});
            }
        });

        this.isPayError = false;
        this.errorMessagePayment = '';
        this.isCardError = false;
        this.errorMessageCard = '';
        this.disableSaveCardButton = false;
    }

  async createNewCheckout(customerId) {
    if (!this.isNewCustomer) {
        const defaultPaymentMethodId = await this.stripeService.getDefaultPaymentMethod({customerId}).toPromise();
        if (!defaultPaymentMethodId) {
            return;
        }
        this.estimatedGstHstQst = this.mapTaxes.get(this.languageService.getLanguage()).get(
                defaultPaymentMethodId
                    .body.billing_details.address.state
            );

        let checkoutObject;

        if (this.promotionCode) {
            checkoutObject = {
                customerId,
                coupon: this.promotionCode.toUpperCase().toString(),
            };
        } else {
            checkoutObject = {
                customerId
            };
        }
        this.stripeService.createCheckout(checkoutObject).subscribe(newCheckout => {
            this.amount = (newCheckout.body.amount_subtotal - newCheckout.body.total_details.amount_discount) / 100;
            this.taxValue = newCheckout.body.total_details.amount_tax / 100;
            this.totalAmount = newCheckout.body.amount_total / 100;
        });
    }
  }

  getPromotionCode(event: any, name: string) {
    this.errorMessageCoupon = null;
    if (event) {
      this.promotionCode = event.target.value;
    }
  }

  async applyCoupon() {
    const customer = await this.getCurrentCustomer(this.authService.profileDetails.stripe_id);

    this.errorMessageCoupon = null;
    if (this.promotionCode) {
      this.stripeService.getCoupon(this.promotionCode.toUpperCase()).subscribe(async coupon => {
        if (coupon.errorType) {
          this.errorMessageCoupon = coupon.errorMessage
          this.errorMessageCoupon = this.errorMessageCoupon.indexOf('No such coupon') !== -1 ?
              await this.translateService.get('no_such_coupon').toPromise()
              : await this.translateService.get('invalid_coupon').toPromise();
        } else {
          if (customer.body.metadata.redeemed && customer.body.metadata.redeemed.includes(coupon.body.id)) {
            this.errorMessageCoupon = await this.translateService.get('coupon_already_used').toPromise();
          } else {
              this.invoicePromise.promise.then(
                  () => {
                      this.errorMessageCoupon = null;
                      const updates = {
                          invoiceId: this.currentInvoiceItemId,
                          discount: this.promotionCode.toUpperCase()
                      };
                      this.stripeService.updateInvoice(updates).subscribe(() => {
                          this.stripeService.getInvoiceById(this.currentInvoiceId).subscribe(invoice => {
                              this.amount = (invoice.body.subtotal) / 100;
                              this.taxValue = invoice.body.tax / 100;
                              this.totalAmount = invoice.body.total / 100;
                          });
                      });
                  }
              );
          }
        }
      });
    }
  }

  changeFieldNoSpaces(event: any, name: string): void {
    if (event) {
      let value = event.target.value;
      if (value.match(/^\s* {1}$/)) {
        value = value.substring(0, value.length - 1);
      } else if (value.length > 2 && value.substring(value.length - 2, value.length).match(/^\s* {2}$/)) {
        value = value.substring(0, value.length - 1);
      }
      this.paymentForm.get(name).setValue(value);
      this.isCardError = false;
      this.disableSaveCardButton = false;
      this.updateErrorMessageCard(null);
      this.cd.detectChanges();
    }
  }

    async ngOnInit() {
      this.estimatedGstHstQst = PromptsMessages.translate('ESTIMATED', this.languageService.getLanguage());

        this.lenderService.getProfileDetails(this.authService.profileDetails.fk_LenderAccount).subscribe(
            data => {
                this.lenderNamePayPage = data.lenderAccount.lenderName;
            }
        );
        this.mapTaxes.set('en', this.mapGstHstQst);
        this.mapTaxes.set('fr', this.mapGstHstQstFrench);

        this.amount = Number(AppConfigService.settings.fee);
        if (this.isNewCustomer) {
            this.customerCards = [];
            if (this.customerCards.length === 0) {
                setTimeout(() => {
                    this.initiateCardElement();
                }, 100);
            }
        } else {
            this.getPaymentMethods(this.authService.profileDetails.stripe_id);
            this.initializePayment(this.authService.profileDetails.stripe_id);
        }
        this.isPayError = false;
        this.disableSaveCardButton = false;
    }

    private initializePayment(customerId) {
        this.invoicePromise = new Deferred();
        this.disablePayButton = true;
        this.invoicePromise.promise.then((invoice) => {
            this.disablePayButton = false;
            return invoice;
        });

        this.stripeService.getCustomerById(customerId).subscribe(async currentCust => {
            this.currentCustomer = currentCust;

            this.currentPaymentMethod = this.currentCustomer.body.invoice_settings.default_payment_method;
            if (this.currentPaymentMethod) {
                this.estimatedGstHstQst = this.mapTaxes.get(this.languageService.getLanguage()).get(
                    (await this.stripeService.getPaymentMethodById(this.currentPaymentMethod).toPromise())
                        .body.billing_details.address.state
                );
                this.createInvoiceForCustomer(customerId);
            }
            if (this.customerCards.length === 0) {
                this.editOrAddCard = true;
                setTimeout(() => {
                    this.initiateCardElement();
                }, 100);
            }
        });
    }

    private createInvoiceForCustomer(customerId) {
        const invoiceItemObject = {
            customerId,
            loanApplicationId: this.loanApplicationId,
        };

        this.stripeService.createInvoiceItems(invoiceItemObject).subscribe(invoiceItem => {
            this.currentInvoiceItemId = invoiceItem.body.id;

            const invoiceObject = {
                customerId,
                loanApplicationId: this.loanApplicationId,
                lenderCode: this.authService.profileDetails.fk_LenderAccount
            };

            this.stripeService.createInvoice(invoiceObject).subscribe(invoice => {
                this.currentInvoiceId = invoice.body.id;
                this.amount = (invoice.body.subtotal) / 100
                this.taxValue = invoice.body.tax / 100
                this.totalAmount = invoice.body.total / 100;
                this.invoicePromise.resolve(invoice);
            }, this.failHandler());
        }, this.failHandler());
    }

    private  failHandler() {
        return async () => {
            const message = 'Failed to create invoice';
            console.log(message);
            this.invoicePromise.reject(message);
            const displayMessage = await this.translateService.get('temporary_server_problem').toPromise();
            const promptInfo = new PromptModel([{
                content: displayMessage,
                isTextBold: false
            }], true, false, '', '', '', false, false, false, false, false);
            this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
        };
    }

    async getCurrentCustomer(customerId) {
    const currentCustomer = await this.stripeService.getCustomerById(customerId).toPromise();
    return currentCustomer;
  }

  async getPaymentMethods(customerId) {
    this.isCardError = false;
    this.disableSaveCardButton = false;
    this.updateErrorMessageCard(null);
    this.paymentMethods = await this.getAllPaymentMethods(customerId);
    this.customerCards = this.paymentMethods.body.data;
    this.currentCustomer = await this.getCurrentCustomer(customerId);
    this.currentPaymentMethod = this.currentCustomer.body.invoice_settings.default_payment_method;
    if (this.currentPaymentMethod == null) {
      setTimeout(() => {
        if (this.customerCards !== undefined) {
          if (this.customerCards.length > 0) {
            this.setDefaultSourceExistingClient(this.customerCards[0].id);
          }
        }
      }, 100);
    }
    if (this.customerCards.length === 0) {
      this.editOrAddCard = true;
      setTimeout(() => {
        this.initiateCardElement();
      }, 1000);
    }
  }

    destroyCard() {
        if (this.card) {
            this.card.off('change', this.cardHandler);
            this.card.destroy();
            this.card = undefined;
        }
        this.isCardError = false;
        this.disableSaveCardButton = false;
        this.updateErrorMessageCard(null);
    }

  ngOnDestroy() {
    this.destroyCard();
  }

    ngAfterViewInit() {
    }

    async initiateCardElement() {
        const cardStyle = {
            style: {
                base: {
                    color: '#32325d',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a',
                }
            }
        };

        const elements = await this.stripe.elements().toPromise();
        // @ts-ignore
        this.card = elements.create('card', {hidePostalCode: true, cardStyle});
        this.cardInfo.nativeElement.ariaLabel = await this.translateService.get('cardNumberL').toPromise();
        this.card.mount(this.cardInfo.nativeElement);
        this.card.on('change', this.cardHandler);
    }

  onChange({error}) {
    if (error) {
      this.cardError = error.message;
    } else {
      this.cardError = null;
    }
    this.isCardError = false;
    this.updateErrorMessageCard(null);
    this.isPayError = false;
    this.updateErrorMessagePayment(null);
    this.disableSaveCardButton = false;
    this.cd.detectChanges();
  }

  cancelEditOrAddCard() {
    if (this.customerCards.length === 0) {
      this.exitPayment();
    } else {
      this.editOrAddCard = false;
      this.destroyCard();
      this.getPaymentMethods(this.authService.profileDetails.stripe_id);
    }

    this.cardError = null;
    this.isCardError = false;
    this.updateErrorMessageCard(null);
  }

    async addNewCard() {
        this.disableSaveCardButton = true;
        this.disablePayButton = false;
        this.isCardError = true;
        this.isPayError = false;
        this.updateErrorMessagePayment(null);
        this.updateErrorMessageCard(null);
        this.cd.detectChanges();
        if (this.paymentForm.valid) {
            const name_value = this.paymentForm.get('name').value;
            const city_value = this.paymentForm.get('city').value;
            const address1_value = this.paymentForm.get('addressLine1').value;
            const address2_value = this.paymentForm.get('addressLine2').value;
            const postalCode_value = this.paymentForm.get('postalCode').value;
            const province_value = this.paymentForm.get('province').value;
            const newPaymentMethod = await this.stripe.createPaymentMethod({
                type: 'card',
                billing_details: {
                    address: {
                        city: city_value,
                        country: 'CD',
                        line1: address1_value,
                        line2: address2_value,
                        postal_code: postalCode_value,
                        state: province_value
                    },
                    name: name_value
                },
                card: this.card
            }).toPromise();
            const newCostumerObject = {
                paymentMethod: newPaymentMethod,
                costumer_email: this.authService.profileDetails.contactDetails.email,
                name: this.lenderName,
                description: this.profileName,
            };

      if (this.isNewCustomer) {
        const newCostumer = await this.stripeService.saveFirstCard(newCostumerObject).toPromise();
        const stripeInfoLender = new LenderInfoDTO(newCostumer.body.id, this.authService.profileDetails.id, null, null,null,null,null,null, null, null);
        this.lenderService.updateLenderUser(stripeInfoLender).subscribe(data => {
          // Logged in profile must be updated to contain the newly generated stripe id, required in the next stap of the payment flow (the actual payment)
          this.authService.profileDetails = data;
            this.setDefaultSource(newPaymentMethod.paymentMethod.id, newCostumer.body.id)
                .subscribe(() => {
                    this.getPaymentMethods(newCostumer.body.id);
                    this.initializePayment(newCostumer.body.id);
                    this.editOrAddCard = false;
                    this.isNewCustomer = false;
                    this.destroyCard();
                });
        });

      } else {
        const existingClientObject = {
          client_id: this.currentCustomer.body.id,
          paymentMethod: newPaymentMethod
        };
        this.stripeService.saveCardsExistingCustomer(existingClientObject).subscribe(async saveCardResponse => {
          if (saveCardResponse.errorType) {
            this.disableSaveCardButton = true;
              if (this.cardError == null || this.cardError == undefined || this.cardError == '') {
                  const genericErrorMessage = await this.translateService.get('check_card_or_contact_bank').toPromise();
                  const stripeErrorMessage = await this.translateService.get(saveCardResponse.errorMessage).toPromise();
                  this.updateErrorMessageCard(
                      stripeErrorMessage + ' ' +  genericErrorMessage);
              }
          } else {
            this.isCardError = false;
            this.editOrAddCard = false;
            if (this.customerCards.length == 0) {
              this.setDefaultSource(newPaymentMethod.paymentMethod.id, existingClientObject.client_id);
            }
            this.createNewCheckout(existingClientObject.client_id);

            setTimeout(() => {
              this.getPaymentMethods(existingClientObject.client_id);
              this.initializePayment(existingClientObject.client_id);
              this.destroyCard();
            }, 1000);
          }
        });
      }
    } else {
      const promptInfo = new PromptModel([{
        content: PromptsMessages.CARD_FORM_INCOMPLETE,
        isTextBold: false
      }], true, false, '', '', '', false, false, false, false, false);
      this.sendPromptText.emit(promptInfo);
      this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
    }
  }

  async addCardExistingClient(event: any) {
    this.editOrAddCard = true;
    this.currentCustomer = await this.getCurrentCustomer(this.authService.profileDetails.stripe_id);
    this.currentPaymentMethod = this.currentCustomer.body.invoice_settings.default_payment_method;
    setTimeout(() => {
      this.initiateCardElement();
    }, 100);
    this.isPayError = false;
    this.updateErrorMessagePayment(null);
    this.cd.detectChanges();
  }

  async getAllPaymentMethods(customerId) {
    if (customerId) {
      return await this.stripeService.getCustomerCards(customerId).toPromise();
    } else {
      return null;
    }
  }

  async deleteCard(event: any, paymentMethodId: string) {
    const deleteObject = {
      paymentMethod_id: paymentMethodId
    };
    if (paymentMethodId === this.currentPaymentMethod) {
      const promptInfoWarning = new PromptModel([{
        content: PromptsMessages.translate('REMOVE_CARD_WARNING', this.languageService.getLanguage()),
        isTextBold: false
      }], true, true, '', '', '', false, false, false, false, false);
      this.sendPromptText.emit(promptInfoWarning);
      const dialogRef = this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfoWarning });
      dialogRef.afterOpened().subscribe(() => {
        dialogRef.componentInstance.onOk.subscribe(async () => {
          this.taxValue = 0;
          this.totalAmount = this.amount + this.taxValue;
          this.currentPaymentMethod = null;
          this.disablePayButton = true;
          this.stripeService.deleteCardByCustomer(deleteObject).subscribe(() => {
            this.getPaymentMethods(this.authService.profileDetails.stripe_id);
          });
        });
      });
    } else {
      const promptInfoMessage = new PromptModel([{
        content: PromptsMessages.translate('REMOVE_CARD_MSG', this.languageService.getLanguage()),
        isTextBold: false
      }], true, true, '', '', '', false, false, false, false, false);
      this.sendPromptText.emit(promptInfoMessage);
      const dialogRef = this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfoMessage });
      dialogRef.afterOpened().subscribe(() => {
        dialogRef.componentInstance.onOk.subscribe(async () => {
          this.stripeService.deleteCardByCustomer(deleteObject).subscribe(() => {
            this.getPaymentMethods(this.authService.profileDetails.stripe_id);
          });
        });
      });
      this.currentCustomer = await this.getCurrentCustomer(this.authService.profileDetails.stripe_id);
      this.currentPaymentMethod = this.currentCustomer.body.invoice_settings.default_payment_method;
    }
    this.isPayError = false;
    this.updateErrorMessagePayment(null);
    this.cd.detectChanges();
  }

    setDefaultSource(paymentMethod_id: any, client_id) {
        const updateCustomer = {
            client_id,
            paymentMethod_id,
            redeemed: ''
        };
        const observable = this.stripeService.updateCustomer(updateCustomer);
        observable.subscribe(() => {
            this.createNewCheckout(client_id);
        });
        return observable;
    }

  async setDefaultSourceExistingClient(paymentMethod_id: any) {
    this.disablePayButton = true;
    const customer = await this.getCurrentCustomer(this.authService.profileDetails.stripe_id);
    let couponsRedeemed = '';
    if (customer.body.metadata.redeemed) {
      couponsRedeemed = customer.body.metadata.redeemed;
    }
    const updateCustomer = {
      client_id: this.authService.profileDetails.stripe_id,
      paymentMethod_id,
      redeemed: couponsRedeemed
    };

    this.stripeService.updateCustomer(updateCustomer).subscribe(async () => {
      this.createNewCheckout(this.authService.profileDetails.stripe_id);
      this.currentCustomer = await this.getCurrentCustomer(this.authService.profileDetails.stripe_id);
      this.currentPaymentMethod = this.currentCustomer.body.invoice_settings.default_payment_method;
      this.initializePayment(this.authService.profileDetails.stripe_id);
    });

    this.isPayError = false;
    this.updateErrorMessagePayment(null);
    this.disablePayButton = false;
    this.cd.detectChanges();
  }

  getDefaultCardDetails() {
    let result = '';
    this.customerCards.forEach(card => {
      if (card.id === this.currentPaymentMethod) {
        result = card.card.brand + ' ****' + card.card.last4;
      }
    });
    return result;
  }

  public getCoupon() {
    return this.promotionCode;
  }

  async payHere() {
    const customer = await this.getCurrentCustomer(this.authService.profileDetails.stripe_id);
    if (this.currentPaymentMethod == null) {
      const promptInfo = new PromptModel([{
        content: PromptsMessages.translate('PAYMENT_WARNING_NO_DEFAULT_CARD', this.languageService.getLanguage()),
        isTextBold: false
      }], true, false, '', '', '', false, false, false, false, false);
      this.sendPromptText.emit(promptInfo);
      this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
    } else {
        const paymentMessage = PromptsMessages.translate('PAYMENT_ACCEPT_1ST_PART', this.languageService.getLanguage()) + ' $' + this.totalAmount + PromptsMessages.translate('USING', this.languageService.getLanguage()) + this.getDefaultCardDetails() + ' ' + PromptsMessages.translate('PAYMENT_ACCEPT_2ND_PART', this.languageService.getLanguage());
        const promptInfo = new PromptModel([{
        content: paymentMessage,
        isTextBold: false
      }], true, true, '', '', '', false, false, false, false, false);
      this.sendPromptText.emit(promptInfo);
      const dialogRefPayment = this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
      dialogRefPayment.afterOpened().subscribe(() => {
        dialogRefPayment.componentInstance.onOk.subscribe(async () => {
          const id = this.id;
          const loanApplicationId = this.loanApplicationId;
          const applicationDecision = 'In Review';
          const previousStatus = this.previousStatus;
          const isEligibleForRemove = this.isEligibleForRemove;
          const loansService = this.loansService;
          const coupon = this.getCoupon();
          const service = this.stripeService;
          const auth = this.authService;
          const cards = this.customerCards;

          this.stripeService.getCustomerById(this.authService.profileDetails.stripe_id).subscribe(() => {
            const finalizeInvoice = {
              invoiceId: this.currentInvoiceId
            }
            this.stripeService.finalizeInvoice(finalizeInvoice).subscribe(async () => {
              this.stripeService.payInvoice(finalizeInvoice).subscribe(invoice => {
                if (coupon) {
                  service.getCoupon(coupon.toUpperCase()).subscribe(data => {
                    let couponsRedeemed: string = '';
                    if (customer.body.metadata.redeemed) {
                      couponsRedeemed = customer.body.metadata.redeemed;
                    }
                    let couponDuration = data.body.duration;
                    if (couponDuration == "once") {
                      couponsRedeemed += data.body.id;
                    }
                    const updateCustomer = {
                      client_id: auth.profileDetails.stripe_id,
                      paymentMethod_id: cards[0].id,
                      redeemed: couponsRedeemed
                    }
                    service.updateCustomer(updateCustomer).subscribe();
                  });
                }

                const paymentIntent = {
                  paymentIntentId: invoice.body.payment_intent,
                  loanApplicationId: loanApplicationId
                }

                this.stripeService.updatePaymentIntent(paymentIntent).subscribe(() => {
                    const newLoanApplication = new DTOLoanApplication(id, loanApplicationId, 'In Review', this.authService.profileDetails.fk_LenderAccount, true);
                    loansService.updateLoan(newLoanApplication).subscribe(() => {
                        loansService.addLoanDecision(null, new LoanDecision(id, loanApplicationId, 'In Review', PromptsMessages.IN_REVIEW_NOTES + " " + this.lenderNamePayPage, '', null, '', false)).subscribe(
                            loanDecisionResult => {
                                this.multiTabNotification.trigger(MultiTabEvents.LOAN_DECISION_UPDATE_APP_SUMMARY);
                                window.open('applicationSummary?id=' + id
                                  + '&activeLoanDecision=' + null
                                  + '&applicationDecision=' + encodeURIComponent(applicationDecision)
                                  + '&previousStatus=' + previousStatus
                                  + '&isEligibleForRemove=' + isEligibleForRemove, ' _blank');
                      }
                    );
                  }, this.failHandler());
                }, this.failHandler());
              }, this.failHandler());
            }, this.failHandler());
          });
        });
      });
    }
  }

  onError(error) {
    if (error.message) {
      this.cardError = error.message;
    }
  }

  getChecked(id: any) {
    if (this.currentPaymentMethod === id) {
      return true;
    } else {
      return false;
    }
  }

  exitPayment() {
    this.isCardError = false;
    this.disableSaveCardButton = false;
    this.updateErrorMessageCard(null);
    this.router.navigateByUrl('/leads')
  }

  getCounter(id: any) {
    return id;
  }

  getErrorPayment() {
    return this.errorMessagePayment;
  }

  getErrorCard() {
    return this.errorMessageCard;
  }

  getIsErrorPayment() {
    return this.isPayError;
  }

  getIsErrorCard() {
    return this.isCardError;
  }

  updateErrorMessageCard(error: string) {
    this.errorMessageCard = error;
  }

  updateErrorMessagePayment(error: string) {
    this.errorMessagePayment = error;
  }

  getCouponError() {
    return this.errorMessageCoupon;
  }

  getDisablePay() {
    return this.disablePayButton;
  }

  getDisableSaveCard() {
    return this.disableSaveCardButton;
  }

  getEstimatedGstHstQst() {
    return this.estimatedGstHstQst;
  }

}
