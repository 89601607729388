<!-- Footer -->


<footer class="main-footer page-footer" style="max-width: 100%; min-width: 100%">

    <!-- Footer Links -->
    <img tabindex="0" alt="Filogix Logo" id="logo" src="{{'logoSrc' | translate}}" alt="Filogix Logo" role="banner">

    <!-- Grid row -->
    <div class="rowFooter" py-3 role="navigation">

        <!-- Grid column -->
        <div class="column">
            <div id="column1">
                <div *ngIf="selectedLanguageEn === true">
                <a target="_blank" href="https://www.finastracanada.com/overview/">
                    <div aria-label="About us link" class="about-us-link" alt="About  Link">{{'aboutUs' | translate}}</div>
                </a>
                </div>
                <div *ngIf="selectedLanguageFr === true">
                    <a target="_blank" href="https://www.finastracanada.com/fr/technologie-de-pret-hypothecaire-2/ ">
                        <div aria-label="About us link" class="about-us-link" alt="About  Link">{{'aboutUs' | translate}}</div>
                    </a>
                    </div>
                    
            </div>
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="column">
            <div id="column3">
                <div *ngIf="selectedLanguageEn === true">
                <a target="_blank" href="https://www.finastra.com/privacy-policy">
                    <div aria-label="Privacy Policy link" class="privacy-policy-link" alt="Privacy Policy  Link">{{'privacyPolicy' | translate}}</div>
                </a>
                </div>
                <div *ngIf="selectedLanguageFr === true">
                    <a target="_blank" href="https://www.finastracanada.com/fr/page-politique-de-confidentialite/">
                        <div aria-label="Privacy Policy link" class="privacy-policy-link" alt="Privacy Policy  Link">{{'privacyPolicy' | translate}}</div>
                    </a>
                </div>
            </div>
        </div>

        <!-- Grid column -->
        <div class="column">
            <div id="column2">
                <div *ngIf="selectedLanguageEn === true">
                    <a target="_blank" href="https://www.finastracanada.com/contact-us/">
                        <div aria-label="Contact us link" class="contact-us-link"  alt="Contact us  Link">{{'contactUs' | translate}}</div>
                    </a>
                </div>
                <div *ngIf="selectedLanguageFr === true">
                    <a target="_blank" href="https://www.finastracanada.com/fr/communiquez-avec-nous/">
                        <div aria-label="Contact us link" class="contact-us-link"  alt="Contact us  Link">{{'contactUs' | translate}}</div>
                    </a>
                </div>
            </div>

        </div>
        <!-- Grid column -->

    </div>
    <!-- Grid row -->
    <div id="reference-div" aria-label="Reference area" py-5 class="text-center">
        <div id="column4">
            <a target="_blank" aria-label="Portal towards Filogix Website" id="reference-link" href="https://www.filogix.com/">
                <div id="filogix-com-link">www.filogix.com</div>
            </a>
        </div>
    </div>


</footer>
<!-- Footer -->
