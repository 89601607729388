<div *ngIf="allDataFetched">
    <mat-drawer-container [hasBackdrop]="true" id="drawer-div">
        <mat-drawer #storyDrawer class="story-sidenav" mode="over" position="end" [autoFocus]="false">
            <div class="col-sm-12" id="drawer-title-div">
                <div class="col-sm-11" style="padding-left: 0px;max-width: 85%;">
                    <h1 id="drawer-title">{{'storyFor' | translate}} {{storyAddress}}</h1>
                </div>
                <div class="col-sm-1 material-icons" id="drawer-close" (click)="storyDrawer.toggle()">
                    close
                </div>
            </div>
            <div class="col-sm-12" id="drawer-story-content">
                {{storyContent}}
            </div>
            <div class="test">

            </div>
            <div class="col-sm-11" id="drawer-close-button-div">
                <button (click)="storyDrawer.toggle()" style="float: right;" mat-flat-button color="primary"
                    [autofocus]="false">{{'closeButton' | translate}}</button>
            </div>

        </mat-drawer>
        <app-header (getIndex)="setIndex()" (notifyNewLoan)="setNotifyNewLoan($event)"></app-header>

        <div class="container px-3 py-3 col-md-8 col-sm-8" id="table-group-div">
            <div class="lender-name-div col-sm-12">&nbsp;</div>
            <div *ngIf="!getSentToExpert()" class="message">There are currently no applications available to review
                because
                you have not yet been published to Expert. Please contact your Client Service representative for more
                details</div>
            <mat-tab-group *ngIf="getSentToExpert()" class="mat-elevation-z0 overflow-hidden col-sm-12" #tabGroup
                animationDuration="0ms" (selectedTabChange)="onTabChange($event)" [selectedIndex]='selectedIndex'
                mat-stretch-tabs style="max-width: 100%; min-width: 100%;">

                    <mat-tab label="{{'leads' | translate}}" alt="Leads" role="tab">
                        <div *ngIf="getDisplayInOneColumn() == false">
                            <div class="flex-row mb-1 mt-1">
                                <div class="col-1 ml-4 mt-3" tabindex="0">
                                    <p class=" uxg-caption" id="filter-by-status" style="width: 100px;">{{'filterByStatus' |
                                        translate}}</p>
                                </div>
                                <div class="col-4 mt-2 d-flex">

                                    <div class="row">
                                        <mat-chip-list>
                                        </mat-chip-list>
                                        <div class="col-2 mr-3 leads-chips"
                                            *ngFor="let chip of getLeadsStatusFiltersChips()" tabindex="0">
                                            <mat-chip class="decision-chips" [selected]="chip.state"
                                                (selectionChange)="onLeadsFilterChange($event)"
                                                (click)="chip.state=!chip.state" (keydown.space)="(chip.state=!chip.state) && onLeadsFilterChange($event)" dense large>{{chip.name}}</mat-chip>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-3">
                                </div>

                                <mat-icon id="search-icon-leads" matPrefix aria-label="Search for Leads">search</mat-icon>
                                <div class="col-2" style="margin-left: 10px;margin-right: 10px;">
                                    <mat-form-field>
                                        <mat-select (selectionChange)="changeSearchOptionForLeads($event)" id="search-option"
                                            placeholder="{{'searchBy' | translate}}" [(ngModel)]="optionsReset"
                                            style="margin-left: 40px;min-width: 140px;margin-top: 3px;">
                                            <mat-option class="mat-option-decision"
                                                *ngFor="let option of getOptionsForLeads()" [value]="option.value">
                                                {{option.displayValue}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-2" style="margin-left: -10px;">
                                    <mat-form-field floatLabel="never" id="leads-search-field">
                                        <input class="search-input" id="search-input" type="search" matInput
                                            placeholder="{{'typeHere' | translate}}"
                                            (keyup.enter)="onLeadsSearchInput($event)" [(ngModel)]="search">
                                        <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear"
                                            (click)="repopulateLeads($event); search=''" style="margin-bottom: 15px;">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>

                            </div>
                            <mat-table class="mat-elevation-z0 overflow-hidden" matSort
                                (matSortChange)="sortDataLeads($event)" matSortActive="received" matSortDirection="desc"
                                matSortDisableClear #sort1="matSort" [dataSource]="dataSourceLeads">

                                <!-- Received Column -->
                                <ng-container matColumnDef="received" alt="Received">
                                    <mat-header-cell class="receivedColumn" *matHeaderCellDef mat-sort-header
                                        arrowPosition="before"> {{'received' | translate}} </mat-header-cell>
                                    <mat-cell class="receivedColumn" *matCellDef="let element">
                                        <div *ngIf="frenchVersionSelected==true">
                                            {{element.receivedDate | date: 'dd'}}
                                            {{mapMonthsEnglishFrench.get(element.receivedDate | date : 'MMM')}}
                                            {{element.receivedDate | date: 'yyyy'}}
                                            <br />
                                            {{element.receivedDate | date: '@ hh:mm a'}}
                                        </div>
                                        <div *ngIf="frenchVersionSelected==false">
                                            {{element.receivedDate | date: 'MMM dd, yyyy'}}
                                            <br />
                                            {{element.receivedDate | date: '@ hh:mm a'}}
                                        </div>
                                        <br />

                                    </mat-cell>
                                </ng-container>

                                <!-- SourceName Column -->
                                <ng-container matColumnDef="sourceName">
                                    <mat-header-cell class="sourceNameColumnLeads" *matHeaderCellDef> {{'sourceName' |
                                        translate}} </mat-header-cell>
                                    <mat-cell class="sourceNameColumnLeads" *matCellDef="let element">
                                        <div>
                                            <div>{{element.sourceName}}</div>

                                            <div id="source-name-div"
                                                (click)="onStoryClick($event, element, element.originatorNotes)">
                                                <div><span class="material-icons source-name-icon">
                                                        list_alt
                                                    </span></div>
                                                <div>{{'viewStory' | translate}}</div>
                                            </div>
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <!-- PropertyAddress Column -->
                                <ng-container matColumnDef="propertyAddress">
                                    <mat-header-cell class="propertyAddressColumn" *matHeaderCellDef> {{'propertyAddress' |
                                        translate}}
                                    </mat-header-cell>
                                    <mat-cell class="propertyAddressColumn" *matCellDef="let element; let n = index">
                                        <div style="min-width: 125px;" [innerHtml]=getAddress(element)></div>
                                        <div style="margin-left: 5px; cursor: pointer;min-width: 30px;" (click)="openMap(element)">
                                            <button [hidden]="hideGoogleMapsButton(element)" class="google-maps-icon" (keyup)="keyDownGoogleButton(n)">
                                                <img src="../../assets/google-maps-icon.svg" style="width: 35px;" alt="Google Maps" />
                                            </button>
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <!-- Ltv Column -->
                                <ng-container matColumnDef="ltv">
                                    <mat-header-cell class="LTVColumn" *matHeaderCellDef> {{'LTV' | translate}}
                                    </mat-header-cell>
                                    <mat-cell class="LTVColumn" *matCellDef="let element"> {{ formatLTV(element.ltv) + "%"}}
                                    </mat-cell>
                                </ng-container>

                                <!-- TotalLoan Column -->
                                <ng-container matColumnDef="totalLoan">
                                    <mat-header-cell class="totalColumn" *matHeaderCellDef> {{'totalLoan' | translate}}
                                    </mat-header-cell>
                                    <mat-cell class="totalColumn" *matCellDef="let element"> {{element.loanAmount |
                                        currency:'USD':'symbol':'4.0-2'}}
                                    </mat-cell>
                                </ng-container>

                                <!-- Charge Column -->
                                <ng-container matColumnDef="charge">
                                    <mat-header-cell class="chargeColumn" *matHeaderCellDef> {{'charge' | translate}}
                                    </mat-header-cell>
                                    <mat-cell class="chargeColumn" *matCellDef="let element"> {{element.charge}} </mat-cell>
                                </ng-container>

                                <!-- Occupancy Column -->
                                <ng-container matColumnDef="occupancy">
                                    <mat-header-cell class="occupancyColumn" *matHeaderCellDef> {{'occupancy' | translate}}
                                    </mat-header-cell>
                                    <mat-cell class="occupancyColumn" *matCellDef="let element"> {{element.occupancy}}
                                    </mat-cell>
                                </ng-container>

                                <!-- PropertyType Column -->
                                <ng-container matColumnDef="propertyType">
                                    <mat-header-cell class="propertyTypeColumn" *matHeaderCellDef> {{'propertyType' |
                                        translate}} </mat-header-cell>
                                    <mat-cell class="propertyTypeColumn" *matCellDef="let element"> {{element.propertyType}}
                                    </mat-cell>
                                </ng-container>

                                <!-- PrimaryCredit Column -->
                                <ng-container matColumnDef="creditScore" alt="Credit Score">
                                    <mat-header-cell class="creditScoreColumn" *matHeaderCellDef mat-sort-header
                                        arrowPosition="before"> {{'creditScore' | translate}} </mat-header-cell>
                                    <mat-cell class="creditScoreColumn" *matCellDef="let element"> {{element.creditScore}}
                                    </mat-cell>
                                </ng-container>

                                <!-- Status Column -->
                                <ng-container matColumnDef="status">
                                    <mat-header-cell class="statusColumn" *matHeaderCellDef> {{'status' | translate}}
                                    </mat-header-cell>
                                    <mat-cell class="statusColumn" *matCellDef="let element; let n = index">
                                        <mat-select class="{{getClass(element)}}" style="outline: 0; padding-left: 10px;"
                                            #selects name="change_status" placeholder="{{getStatus(element)}}"
                                            id="{{'leads-select-'+ n}}" (keyup)="keyDownStatus($event, n)"
                                            [(value)]="selectedStatusValue" #thisMatSelect (openedChange)="onChangeStatus(element)">
                                            <mat-select-trigger>{{getDisplayStatus(element.status)}}</mat-select-trigger>
                                            <mat-option class="mat-option-status"
                                                *ngFor="let elem of getStatusListById(element, element.id)"
                                                [(value)]="elem.displayValue">{{elem.value}}</mat-option>
                                        </mat-select>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="getColumnsToDisplay()"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: getColumnsToDisplay();"></mat-row>
                            </mat-table>

                            <div class="noLeadsMessage" [hidden]=getNoLeads()>

                                <div *ngIf="checkIfNoLeads==true && !isLeadsFilterActive">
                                    <p> {{'noAppWaitingForReview' | translate}} </p>
                                </div>

                                <div *ngIf="isLeadsFilterActive">
                                    <p> {{'noResultFilter' | translate}} </p>
                                </div>

                                <div
                                    *ngIf="isLeadsFilterActive==false && clearSearchFieldRestart==false && !checkIfNoLeads">
                                    <p> {{'noResultSearch' | translate}} </p>
                                </div>

                            </div>

                            <div [hidden]=checkPaginatorLeads() style="text-align-last: right; overflow: hidden;"
                                (keyup)="keyUpRemoveFocus()">

                                <app-paginator [pageSize]="5" [activePage]="getActivePageLeads()"
                                    [nrOfPages]="getLeadsNumberOfPages()" [pageSizeOptions]="getPageSizeOptions()"
                                    (onPageChange)="onLeadsPageChange($event)" style="width: 40em;">
                                </app-paginator>
                            </div>
                        </div>
                        <div *ngIf="getDisplayInOneColumn() == true">
                            <div class="flex-row mb-1 mt-1" style="max-height: unset;">
                                <div class="col-1 ml-4 mt-3" tabindex="0" style="min-width: max-content;">
                                    <p class=" uxg-caption" id="filter-by-status" style="width: 100px;">{{'filterByStatus' |
                                        translate}}</p>
                                </div>
                                <div class="col-4 mt-2 d-flex" style="margin-right: 5vw;">

                                    <div class="row">
                                        <mat-chip-list id="chip-list-filter">
                                        </mat-chip-list>
                                        <div class="col-2 mr-3 leads-chips"
                                             *ngFor="let chip of getLeadsStatusFiltersChips()" tabindex="0">
                                            <mat-chip class="decision-chips" [selected]="chip.state"
                                                      (selectionChange)="onLeadsFilterChange($event)"
                                                      (click)="chip.state=!chip.state" (keydown.space)="(chip.state=!chip.state) && onLeadsFilterChange($event)" dense large>{{chip.name}}</mat-chip>
                                        </div>

                                    </div>
                                </div>

                                <div style="max-width: fit-content;display: inline-flex;">
                                    <mat-icon id="search-icon-leads" matPrefix aria-label="Search for Leads"
                                              style="margin-left: 20px;">search</mat-icon>
                                    <div class="col-2" style="margin-left: 10px;margin-right: 10px;min-width: max-content;">
                                        <mat-form-field>
                                            <mat-select (selectionChange)="changeSearchOptionForLeads($event)"
                                                        placeholder="{{'searchBy' | translate}}" id="search-option"
                                                        [(ngModel)]="optionsReset"
                                                        style="margin-left: 40px;min-width: 140px;margin-top: 3px;">
                                                <mat-option class="mat-option-decision"
                                                            *ngFor="let option of getOptionsForLeads()" [value]="option.value">
                                                    {{option.displayValue}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-2" style="margin-left: -10px;min-width: max-content;">
                                        <mat-form-field floatLabel="never" id="leads-search-field">
                                            <input id="search-input" type="search" matInput
                                                   placeholder="{{'typeHere' | translate}}"
                                                   (keyup.enter)="onLeadsSearchInput($event)" [(ngModel)]="search">
                                            <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear"
                                                    (click)="repopulateLeads($event); search=''" style="margin-bottom: 15px;">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>
                            <mat-table class="mat-elevation-z0 overflow-hidden" matSort
                                       (matSortChange)="sortDataLeads($event)" matSortActive="received" matSortDirection="desc"
                                       matSortDisableClear #sort1="matSort" [dataSource]="dataSourceLeads">

                                <!-- Received Column -->
                                <ng-container matColumnDef="received" alt="Received">
                                    <mat-cell *matCellDef="let element; let n = index; "
                                              style="display: list-item;height: auto;padding-top: 12px;">
                                        <div style="display: inline-flex; min-height: 30px;">
                                            <div style="font-weight: 600;width: 150px;">
                                                {{'received' | translate}}
                                            </div>
                                            <div *ngIf="frenchVersionSelected==true">
                                                {{element.receivedDate | date: 'dd'}}
                                                {{mapMonthsEnglishFrench.get(element.receivedDate | date : 'MMM')}}
                                                {{element.receivedDate | date: 'yyyy'}}

                                                {{element.receivedDate | date: '@ hh:mm a'}}
                                            </div>
                                            <div *ngIf="frenchVersionSelected==false">
                                                {{element.receivedDate | date: 'MMM dd, yyyy'}}

                                                {{element.receivedDate | date: '@ hh:mm a'}}
                                            </div>
                                        </div>
                                        <br />

                                        <div style="display: inline-flex;">
                                            <div style="font-weight: 600;width: 150px;">
                                                {{'sourceName' | translate}}
                                            </div>
                                            <div style="display: inline-flex; min-height: 30px;">
                                                <div>{{element.sourceName}}</div>

                                                <div id="source-name-div-resized"
                                                     (click)="onStoryClick($event, element, element.originatorNotes)">
                                                    <div><span class="material-icons source-name-icon">
                                                        list_alt
                                                    </span></div>
                                                    <div>{{'viewStory' | translate}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        <div style="display: inline-flex;">
                                            <div style="font-weight: 600;width: 150px;">
                                                {{'propertyAddress' | translate}}
                                            </div>
                                            <div style="display: inline-flex; min-height: 30px;">
                                                <div style="min-width: 125px;" [innerHtml]=getAddressSecond(element)></div>
                                                <div style="margin-left: 5px; cursor: pointer;" (click)="openMap(element)">
                                                    <button [hidden]="hideGoogleMapsButton(element)" id="google-maps-icon-resized"
                                                        (keyup)="keyDownGoogleButton(n)">
                                                        <img src="../../assets/google-maps-icon.svg" style="width: 30px;" alt="Google Maps" />
                                                    </button>
                                        
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        <div style="display: inline-flex;">
                                            <div style="font-weight: 600;width: 150px;">
                                                {{'LTV' | translate}}
                                            </div>
                                            <div style="display: inline-flex; min-height: 30px;">
                                                {{100 * element.ltv + "%"}}
                                            </div>
                                        </div>
                                        <br />

                                        <div style="display: inline-flex;">
                                            <div style="font-weight: 600;width: 150px;">
                                                {{'totalLoan' | translate}}
                                            </div>
                                            <div style="display: inline-flex; min-height: 30px;">
                                                {{element.loanAmount | currency:'USD':'symbol':'4.0-2'}}
                                            </div>
                                        </div>
                                        <br />

                                        <div style="display: inline-flex;">
                                            <div style="font-weight: 600;width: 150px;">
                                                {{'charge' | translate}}
                                            </div>
                                            <div style="display: inline-flex; min-height: 30px;">
                                                {{element.charge}}
                                            </div>
                                        </div>
                                        <br />

                                        <div style="display: inline-flex;">
                                            <div style="font-weight: 600;width: 150px;">
                                                {{'occupancy' | translate}}
                                            </div>
                                            <div style="display: inline-flex; min-height: 30px;">
                                                {{element.occupancy}}
                                            </div>
                                        </div>
                                        <br />

                                        <div style="display: inline-flex;">
                                            <div style="font-weight: 600;width: 150px;">
                                                {{'propertyType' | translate}}
                                            </div>
                                            <div style="display: inline-flex; min-height: 30px;">
                                                {{element.propertyType}}
                                            </div>
                                        </div>
                                        <br />

                                        <div style="display: inline-flex;">
                                            <div style="font-weight: 600;width: 150px;">
                                                {{'status' | translate}}
                                            </div>
                                            <div style="display: inline-flex; min-height: 30px;margin-left: -10px;">
                                                <mat-select class="{{getClass(element)}}"
                                                            style="outline: 0; padding-left: 10px;" #selects name="change_status"
                                                            placeholder="{{getStatus(element)}}" id="{{'leads-select-'+ n}}"
                                                            (keyup)="keyDownStatus($event, n)" tabindex="0"
                                                            [(value)]="selectedStatusValue" #thisMatSelect (openedChange)="onChangeStatus(element)">
                                                    <mat-select-trigger>{{getDisplayStatus(element.status)}}
                                                    </mat-select-trigger>
                                                    <mat-option class="mat-option-status"
                                                                *ngFor="let elem of getStatusListById(element, element.id)"
                                                                [(value)]="elem.displayValue">{{elem.value}}
                                                    </mat-option>
                                                </mat-select>
                                            </div>
                                        </div>
                                        <br />

                                    </mat-cell>

                                </ng-container>
                                <mat-row *matRowDef="let row; columns: getColumnsToDisplayResized();" style="height: auto;">
                                </mat-row>
                            </mat-table>

                            <div class="noLeadsMessage" [hidden]=getNoLeads()>

                                <div *ngIf="checkIfNoLeads==true && !isLeadsFilterActive">
                                    <p> {{'noAppWaitingForReview' | translate}} </p>
                                </div>

                                <div *ngIf="isLeadsFilterActive">
                                    <p> {{'noResultFilter' | translate}} </p>
                                </div>

                                <div
                                        *ngIf="isLeadsFilterActive==false && clearSearchFieldRestart==false && !checkIfNoLeads">
                                    <p> {{'noResultSearch' | translate}} </p>
                                </div>

                            </div>

                            <div [hidden]=checkPaginatorLeads() style="text-align-last: right; overflow: hidden;"
                                 (keyup)="keyUpRemoveFocus()">

                                <app-paginator [pageSize]="5" [activePage]="getActivePageLeads()"
                                               [nrOfPages]="getLeadsNumberOfPages()" [pageSizeOptions]="getPageSizeOptions()"
                                               (onPageChange)="onLeadsPageChange($event)" style="width: 40em;">
                                </app-paginator>
                            </div>
                        </div>
                    </mat-tab>


                    <mat-tab class="overflow-hidden" label="{{'myDeals' | translate}}" alt="My Deals"
                        role="tab">
                        <div *ngIf="getDisplayInOneColumn() == false">
                            <div id="mydeals-content-div">
                                <div class="flex-row mb-1 mt-1 mat-elevation-z0 overflow-hidden">
                                    <div class="col-1 ml-4 mt-3">
                                        <p id="filter-by-decision" tabindex="0">{{'filterByStatus' | translate}}</p>
                                    </div>
                                    <div class="col-5 mt-2 ml-1">
                                        <div class="row">
                                            <mat-chip-list>
                                            </mat-chip-list>
                                            <div class="mr-2" *ngFor="let chip of getMyDealsStatusChips()" tabindex="0">
                                                <mat-chip id="decision-chip1" class="mr-2" [selected]="chip.state"
                                                    (selectionChange)="onMyDealsFilterChange($event)"
                                                    (click)="chip.state=!chip.state" (keydown.space)="(chip.state=!chip.state) && onMyDealsFilterChange($event)" dense large>{{chip.name}}</mat-chip>
                                            </div>
                                        </div>
                                    </div>

                                    <mat-icon id="search-icon-mydeals" matPrefix aria-label="search-icon-mydeals">search
                                    </mat-icon>
                                    <label>
                                        <div class="col-2" style="margin-left: 30px;">
                                            <label>

                                                <mat-form-field id="search-form">

                                                    <label>

                                                        <mat-select (selectionChange)="changeSearchOption($event)" id="search-option"
                                                            placeholder="{{'searchBy' | translate}}"
                                                            [(ngModel)]="optionsReset"
                                                            style="margin-left: 40px;min-width: 140px;margin-top: 3px;">
                                                            <label style="width: 100%;">

                                                                <mat-option id="search-mat-option"
                                                                    class="mat-option-decision"
                                                                    *ngFor="let option of getOptions()"
                                                                    [value]="option.value">
                                                                    {{option.displayValue}}
                                                                </mat-option>
                                                            </label>
                                                        </mat-select>

                                                    </label>

                                                </mat-form-field>
                                            </label>
                                        </div>
                                    </label>
                                    <div class="col-2">
                                        <mat-form-field floatLabel="never" id="mydeals-search-field">
                                            <input class="search-input" id="search-input" type="search" matInput
                                                placeholder="{{'typeHere' | translate}}"
                                                (keyup.enter)="onMyDealsApplicationIdSearchInput($event)"
                                                [(ngModel)]="search">
                                            <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear"
                                                (click)="repopulateMyDeals($event); search=''" style="margin-bottom: 15px;">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </div>

                                </div>

                            </div>

                            <div class="overflow-hidden">
                                <mat-table class="overflow-hidden" matSort #sort2="matSort"
                                    matSortActive="received" (matSortChange)="sortDataMyDeals($event)"
                                    matSortDirection="desc" matSortDisableClear [dataSource]="dataSourceMyDeals">
                                    <!-- Received Column -->
                                    <ng-container matColumnDef="received">
                                        <mat-header-cell class="receivedColumn" *matHeaderCellDef mat-sort-header
                                            arrowPosition="before"> {{'received' | translate}}</mat-header-cell>
                                        <mat-cell class="receivedColumn" *matCellDef="let element; let n = index; ">
                                            <div *ngIf="frenchVersionSelected==true">
                                                {{element.receivedDate | date: 'dd'}}
                                                {{mapMonthsEnglishFrench.get(element.receivedDate | date : 'MMM')}}
                                                {{element.receivedDate | date: 'yyyy'}}
                                                <br />
                                                {{element.receivedDate | date: '@ hh:mm a'}}
                                            </div>
                                            <div *ngIf="frenchVersionSelected==false">
                                                {{element.receivedDate | date: 'MMM dd, yyyy'}}
                                                <br />
                                                {{element.receivedDate | date: '@ hh:mm a'}}
                                            </div>
                                            <br />
                                        </mat-cell>
                                    </ng-container>

                                    <!-- ApplicationId Column -->
                                    <ng-container matColumnDef="applicationId">
                                        <mat-header-cell class="appIdColumnMyDeals" *matHeaderCellDef> {{'applicationId' |
                                            translate}} </mat-header-cell>
                                        <mat-cell class="appIdColumnMyDeals" *matCellDef="let element">
                                            {{element.loanApplicationId}} </mat-cell>
                                    </ng-container>

                                    <!-- SourceName Column -->
                                    <ng-container matColumnDef="sourceName">
                                        <mat-header-cell class="sourceNameColumn" *matHeaderCellDef> {{'sourceName' |
                                            translate}} </mat-header-cell>
                                        <mat-cell class="sourceNameColumn" *matCellDef="let element"> {{element.sourceName}}
                                        </mat-cell>
                                    </ng-container>

                                    <!-- ClientName Column -->
                                    <ng-container matColumnDef="clientName">
                                        <mat-header-cell class="clientNameColumn" *matHeaderCellDef> {{'clientName' |
                                            translate}} </mat-header-cell>
                                        <mat-cell class="clientNameColumn" *matCellDef="let element"> {{element.clientName}}
                                        </mat-cell>
                                    </ng-container>

                                    <!-- PropertyAddress Column -->
                                    <ng-container matColumnDef="propertyAddress">
                                        <mat-header-cell class="propertyAddressColumnMyDeals" *matHeaderCellDef>
                                            {{'propertyAddress' | translate}} </mat-header-cell>
                                        <mat-cell class="propertyAddressColumnMyDeals" *matCellDef="let element; let n = index"
                                            style="white-space: pre-wrap">
                                            <div style="min-width: 125px;" [innerHtml]=getAddress(element)></div>
                                            <div style="margin-left: 5px; cursor: pointer;min-width: 30px;" (click)="openMap(element)">
                                                <button [hidden]="hideGoogleMapsButton(element)" class="google-maps-icon" (keyup)="keyDownGoogleButton(n)">
                                                    <img src="../../assets/google-maps-icon.svg" style="width: 35px;" alt="Google Maps" />
                                                </button>
                                            </div>
                                        </mat-cell>
                                    </ng-container>

                                    <!-- ApplicationSummary Column -->
                                    <ng-container matColumnDef="applicationSummary">
                                        <mat-header-cell class="applicationSummaryColumnMyDeals" *matHeaderCellDef>
                                            {{'applicationSummary' | translate}} </mat-header-cell>
                                        <mat-cell class="applicationSummaryColumnMyDeals" *matCellDef="let element = index">
                                            <div
                                                style="display:flex; flex-direction: column; justify-content: center; align-items: center; cursor: pointer;">
                                                <button mat-flat-button color="primary" dense
                                                    (click)="openApplicationSummary($event, element)"
                                                    id="view-application-button">
                                                    {{'viewApplication' | translate}}
                                                </button>
                                            </div>
                                        </mat-cell>
                                    </ng-container>

                                <!-- CommitmentLetter Column -->
                                <ng-container matColumnDef="commitmentLetter">
                                    <mat-header-cell class="creditbureauColumnMyDeals" *matHeaderCellDef>
                                        {{'commitmentLetter' | translate}} </mat-header-cell>
                                    <mat-cell class="creditbureauColumnMyDeals" *matCellDef="let element">
                                        <div *ngIf="getCommitmentLetter(element); else toShow"
                                            style="display: -webkit-box;flex-direction: column; justify-content: center; align-items: center; cursor: pointer"
                                            tabindex="0">
                                            <a id="download-link"></a>
                                            <div (click)="downloadCommitmentLetter(element)" style="display: -webkit-box;">
                                                <img src="../../assets/Letter.svg"
                                                style="width: 20px;opacity: 60%;margin-top: 5px;margin-right: 5px;"
                                                alt="Commitment Letter" />
                                                <div style="margin-top: 7px;"><label id="download-label">{{'viewLetter' | translate}}</label></div>
                                            </div>
                                        </div>
                                        <ng-template #toShow>
                                            <b>N/A</b>
                                        </ng-template>
                                    </mat-cell>
                                </ng-container>

                                    <!-- Status Column -->
                                    <ng-container matColumnDef="myLeadsStatus">
                                        <mat-header-cell class="statusColumnMyDeals" *matHeaderCellDef> {{'status' |
                                            translate}} </mat-header-cell>
                                        <mat-cell class="statusColumnMyDeals" *matCellDef="let element; let n = index">
                                            <mat-select class="{{getClass(element)}}" style="outline: 0; min-width: 92px;"
                                                #myDealsStatus placeholder="{{getStatus(element)}}"
                                                id="{{'leads-select-'+ n}}" (keyup)="keyDownStatus($event, n)" tabindex="0"
                                                [(value)]="selectedStatusValue" #thisMatSelect (openedChange)="onChangeStatus(element)">
                                                <mat-select-trigger>{{getDisplayStatus(element.status)}}
                                                </mat-select-trigger>
                                                <mat-option class="mat-option-decision"
                                                    *ngFor="let elem of getStatusListById(element, element.id)"
                                                    [(value)]="elem.displayValue">
                                                    {{elem.value}} </mat-option>
                                            </mat-select>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="getColumnsToDisplayMyDeals()"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: getColumnsToDisplayMyDeals();"></mat-row>
                                </mat-table>

                                <div class="noLeadsMessage" [hidden]=getNoMyDeals()>
                                    <div *ngIf="isMyDealsFilterActive==false">
                                        <p> {{'noResultsFound' | translate}} </p>
                                    </div>
                                    <div *ngIf="isMyDealsFilterActive">
                                        <p> {{'noResultFilterMyDeals' | translate}} </p>
                                    </div>
                                </div>

                                <div [hidden]=checkPaginatorMyDeals()
                                    style="text-align-last: right; overflow: hidden; padding-bottom: 10px;"
                                    (keyup)="keyUpRemoveFocus()">
                                    <app-paginator [pageSize]="5" [activePage]="getActivePageMyDeals()"
                                        [nrOfPages]="getMyDealsNumberOfPages()" [pageSizeOptions]="getPageSizeOptions()"
                                        (onPageChange)="onMyDealsPageChange($event)" style="width: 40em;">
                                    </app-paginator>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="getDisplayInOneColumn() == true">
                            <div id="mydeals-content-div">
                                <div class="flex-row mb-1 mt-1 mat-elevation-z0 overflow-hidden"
                                     style="max-height: unset;">
                                    <div class="col-1 ml-4 mt-3" style="max-width: max-content;">
                                        <p id="filter-by-decision" tabindex="0">{{'filterByStatus' | translate}}</p>
                                    </div>
                                    <div class="col-5 mt-2 ml-1" style="min-width: max-content;">
                                        <div class="row">
                                            <mat-chip-list id="chip-list-filter">
                                            </mat-chip-list>
                                            <div class="mr-2" *ngFor="let chip of getMyDealsStatusChips()" tabindex="0">
                                                <mat-chip id="decision-chip1" class="mr-2" [selected]="chip.state"
                                                          (selectionChange)="onMyDealsFilterChange($event)"
                                                          (click)="chip.state=!chip.state" (keydown.space)="(chip.state=!chip.state) && onMyDealsFilterChange($event)" dense large>{{chip.name}}</mat-chip>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="display: inline-flex;margin-left: -105px;">
                                        <mat-icon id="search-icon-mydeals" matPrefix aria-label="search-icon-mydeals">search
                                        </mat-icon>
                                        <label>
                                            <div class="col-2" style="margin-left: 30px;">
                                                <label>

                                                    <mat-form-field id="search-form">

                                                        <label>

                                                            <mat-select (selectionChange)="changeSearchOption($event)"
                                                                        placeholder="{{'searchBy' | translate}}" id="search-option"
                                                                        [(ngModel)]="optionsReset"
                                                                        style="margin-left: 40px;min-width: 140px;margin-top: 3px;">
                                                                <label style="width: 100%;">

                                                                    <mat-option id="search-mat-option"
                                                                                class="mat-option-decision"
                                                                                *ngFor="let option of getOptions()"
                                                                                [value]="option.value">
                                                                        {{option.displayValue}}
                                                                    </mat-option>
                                                                </label>
                                                            </mat-select>

                                                        </label>

                                                    </mat-form-field>
                                                </label>
                                            </div>
                                        </label>
                                        <div class="col-2">
                                            <mat-form-field floatLabel="never" id="mydeals-search-field">
                                                <input id="search-input" type="search" matInput
                                                       placeholder="{{'typeHere' | translate}}"
                                                       (keyup.enter)="onMyDealsApplicationIdSearchInput($event)"
                                                       [(ngModel)]="search">
                                                <button mat-button *ngIf="search" matSuffix mat-icon-button
                                                        aria-label="Clear" (click)="repopulateMyDeals($event); search=''"
                                                        style="margin-bottom: 15px;">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div class="mat-elevation-z8 overflow-hidden">
                                <mat-table class="overflow-hidden" matSort #sort2="matSort"
                                           matSortActive="received" (matSortChange)="sortDataMyDeals($event)"
                                           matSortDirection="desc" matSortDisableClear [dataSource]="dataSourceMyDeals">
                                    <!-- Received Column -->
                                    <ng-container matColumnDef="received">
                                        <mat-cell *matCellDef="let element; let n = index; "
                                                  style="display: list-item;height: auto;padding-top: 12px;">
                                            <div style="display: inline-flex; min-height: 30px;">
                                                <div style="font-weight: 600;width: 150px;">
                                                    {{'received' | translate}}
                                                </div>
                                                <div *ngIf="frenchVersionSelected==true">
                                                    {{element.receivedDate | date: 'dd'}}
                                                    {{mapMonthsEnglishFrench.get(element.receivedDate | date : 'MMM')}}
                                                    {{element.receivedDate | date: 'yyyy'}}

                                                    {{element.receivedDate | date: '@ hh:mm a'}}
                                                </div>
                                                <div *ngIf="frenchVersionSelected==false">
                                                    {{element.receivedDate | date: 'MMM dd, yyyy'}}

                                                    {{element.receivedDate | date: '@ hh:mm a'}}
                                                </div>
                                            </div>
                                            <br />

                                            <div style="display: inline-flex; min-height: 30px;">
                                                <div style="font-weight: 600;width: 150px;">
                                                    {{'applicationId' | translate}}
                                                </div>
                                                <div style="display: inline-flex;">
                                                    {{element.loanApplicationId}}
                                                </div>
                                            </div>
                                            <br />

                                            <div style="display: inline-flex; min-height: 30px;">
                                                <div style="font-weight: 600;width: 150px;">
                                                    {{'sourceName' | translate}}
                                                </div>
                                                <div style="display: inline-flex;">
                                                    {{element.sourceName}}
                                                </div>
                                            </div>
                                            <br />

                                            <div style="display: inline-flex; min-height: 30px;">
                                                <div style="font-weight: 600;width: 150px;">
                                                    {{'clientName' | translate}}
                                                </div>
                                                <div style="display: inline-flex;">
                                                    {{element.clientName}}
                                                </div>
                                            </div>
                                            <br />

                                            <div style="display: inline-flex; min-height: 30px;">
                                                <div style="font-weight: 600;width: 150px;">
                                                    {{'propertyAddress' | translate}}
                                                </div>
                                                <div style="display: inline-flex;">
                                                    <div style="min-width: 125px;" [innerHtml]=getAddressSecond(element)></div>
                                                    <div style="cursor: pointer;" (click)="openMap(element)">
                                                        <button [hidden]="hideGoogleMapsButton(element)" id="google-maps-icon-resized"
                                                            (keyup)="keyDownGoogleButton(n)">
                                                            <img src="../../assets/google-maps-icon.svg" style="width: 30px;" alt="Google Maps" />
                                                        </button>
                                                    </div>
                                            
                                                </div>
                                            </div>
                                            <br />

                                        <div style="display: inline-flex; min-height: 30px;">
                                            <div style="font-weight: 600;width: 150px;">
                                                {{'applicationSummary' | translate}}
                                            </div>
                                            <div style="display: inline-flex;margin-top: -10px;">
                                                <div
                                                    style="display:flex; flex-direction: column; justify-content: center; align-items: center; cursor: pointer;">
                                                    <button mat-flat-button color="primary" dense
                                                        (click)="openApplicationSummaryResized($event, element)"
                                                        id="view-application-button">
                                                        {{'viewApplication' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        <div style="display: inline-flex; min-height: 30px;">
                                            <div style="font-weight: 600;width: 150px;">
                                                {{'commitmentLetter' | translate}}
                                            </div>
                                            <div style="display: inline-flex;">
                                                    <div *ngIf="getCommitmentLetter(element)"
                                                    style="margin-top: -5px;display: -webkit-box;flex-direction: column; justify-content: center; align-items: center; cursor: pointer"
                                                    tabindex="0">
                                                    <a id="download-link"></a>
                                                    <div (click)="downloadCommitmentLetter(element)" style="display: -webkit-box;">
                                                        <img src="../../assets/Letter.svg"
                                                        style="width: 20px;opacity: 60%;margin-top: 5px;margin-right: 5px;"
                                                        alt="Commitment Letter" />
                                                        <div style="margin-top: 7px;"><label id="download-label">{{'viewLetter' | translate}}</label></div>
                                                    </div>
                                                </div>
                                                <div *ngIf="!getCommitmentLetter(element)" style="margin-left: 20px;">
                                                    <b>N/A</b>
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                            <div style="display: inline-flex; min-height: 30px;">
                                                <div style="font-weight: 600;width: 140px;">
                                                    {{'status' | translate}}
                                                </div>
                                                <div style="display: inline-flex; margin-left: 10px;">
                                                    <mat-select class="{{getClass(element)}}"
                                                                style="outline: 0; min-width: 92px;" #myDealsStatus
                                                                placeholder="{{getStatus(element)}}" id="{{'leads-select-'+ n}}"
                                                                (keyup)="keyDownStatus($event, n)" tabindex="0"
                                                                [(value)]="selectedStatusValue" #thisMatSelect (openedChange)="onChangeStatus(element)">
                                                        <mat-select-trigger>{{getDisplayStatus(element.status)}}
                                                        </mat-select-trigger>
                                                        <mat-option class="mat-option-decision"
                                                                    *ngFor="let elem of getStatusListById(element, element.id)"
                                                                    [(value)]="elem.displayValue">{{elem.value}}</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                            <br />

                                        </mat-cell>
                                    </ng-container>

                                    <mat-row *matRowDef="let row; columns: getColumnsToDisplayMyDealsResized();"
                                             style="height: auto;"></mat-row>
                                </mat-table>

                                <div class="noLeadsMessage" [hidden]=getNoMyDeals()>
                                    <div *ngIf="isMyDealsFilterActive==false">
                                        <p> {{'noResultsFound' | translate}} </p>
                                    </div>
                                    <div *ngIf="isMyDealsFilterActive">
                                        <p> {{'noResultFilterMyDeals' | translate}} </p>
                                    </div>
                                </div>

                                <div [hidden]=checkPaginatorMyDeals()
                                     style="text-align-last: right; overflow: hidden; padding-bottom: 10px;"
                                     (keyup)="keyUpRemoveFocus()">
                                    <app-paginator [pageSize]="5" [activePage]="getActivePageMyDeals()"
                                                   [nrOfPages]="getMyDealsNumberOfPages()" [pageSizeOptions]="getPageSizeOptions()"
                                                   (onPageChange)="onMyDealsPageChange($event)" style="width: 40em;">
                                    </app-paginator>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
            </mat-tab-group>
        </div>
        <div id="space"></div>

        <app-footer></app-footer>

    </mat-drawer-container>
</div>
