import { ContactDetails } from "./contact-details.model";
import { ProfileDetails } from "./profile-details.model";
import { ProfileType } from "./profile-type.model";

export class LenderInfoDTO{
    public stripe_id: string;
    public lender_id:string;
    public lender_language:string;
    public loginNumber:number;
    public fk_LenderAccount: String;
    public contactDetails: ContactDetails;
    public isActive: Boolean;
    public profileType: ProfileType;
    public isUserAdmin: Boolean;
    public sentToExpert: Boolean;

    constructor(stripe_id: string, lender_id:string, lender_language:string, loginNumber: number, fk_LenderAccount: string, contactDetails: ContactDetails, isActive: Boolean, profileType: ProfileType, isUserAdmin: Boolean, sentToExpert: Boolean){
        this.stripe_id=stripe_id;
        this.lender_id=lender_id;
        this.lender_language=lender_language;
        this.loginNumber = loginNumber;
        this.fk_LenderAccount=fk_LenderAccount;
        this.contactDetails=contactDetails;
        this.isActive = isActive;
        this.profileType = profileType;
        this.isUserAdmin = isUserAdmin;
        this.sentToExpert = sentToExpert; 
    }

}