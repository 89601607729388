import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormControl, NgForm } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { AddressApiService } from '../../../services/address-service/address-api.service';
import { AddressInformation } from '../../../models/address-information.model';

@Component({
    selector: 'app-address-component',
    templateUrl: './address-component.component.html',
    styleUrls: ['./address-component.component.scss']
})

export class AddressComponent implements OnInit {

    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.invalid;
    }

    @Output() getSelectedItem: EventEmitter<any> = new EventEmitter<any>();

    public autocompleteOptions = [];
    public expandableList = []
    public addressForm: FormGroup;
    public completeAddressError: boolean;
    public showAutocompleteBar: boolean;

    constructor(private formBuilder: FormBuilder, private addressService: AddressApiService) { }

    public changeAddressSearch(fn: any): void {
        if (fn) {
            var value: string = fn.target.value;
            this.autocompleteOptions = [];
            if (value.replace(/\s/g, '').length) {
                this.callAddressAPI(value);
            }
            this.showAutocompleteBar = true;
        }
    }

    public getApiResponse(response) {
        var apiResponse = []
        var resultArray = Object.keys(response).map(function (addressIndex) {
            let address = response[addressIndex];
            return address;
        });

        resultArray.map(item => {
            for (let i = 0; i < item.length; i++) {
                var addressText = item[i].Description + " " + item[i].Text;
                var id = item[i].Id
                var isExpandable = addressText.includes('Addresses')
                var addressObject = { address: addressText, lastId: id, arrow: isExpandable }
                apiResponse.push(addressObject);

            }
        });
        return apiResponse;
    }

    public callAddressAPI(value) {
        this.showAutocompleteBar = true;
        this.addressService.getAddressComplete(value).subscribe(
            response => {
                this.autocompleteOptions = this.getApiResponse(response);
            });
    }

    public getAddresses(lastId) {
        this.addressService.getAddresses(lastId).subscribe(
            response => {
                this.autocompleteOptions = this.getApiResponse(response);
            }
        );
    }

    public selectedOption(option: any) {
        var addressOption = option.address;
        this.addressForm.get('addressLookup').setValue('');
        this.showAutocompleteBar = false;
        if (addressOption.includes('Addresses')) {
            this.getAddresses(option.lastId);
            this.showAutocompleteBar = true;
        }
        else {
            if (addressOption.match('[^,]+(?:,[^,]+){2,}')) {
                this.getFormControl('addressLookup').clearValidators();
                var city = addressOption.substring(0, addressOption.indexOf(","));
                var addressOption = addressOption.split(city + ",").pop()
                var province = addressOption.substring(1, addressOption.indexOf(","));
                var addressOption = addressOption.split(province + ",").pop()
                var pc1 = addressOption.indexOf(' ');
                var pc2 = addressOption.indexOf(' ', pc1 + 1);
                var postalCode1 = addressOption.substring(1, pc2);
                var addressOption = addressOption.split(postalCode1 + " ").pop()
                var postalCode2 = addressOption.substring(0, addressOption.indexOf(" "));
                var PC = postalCode1 + " " + postalCode2
                var addressOption = addressOption.split(postalCode2 + " ").pop()
                var strNb = addressOption.substring(0, addressOption.indexOf(" "));
                var street = addressOption.split(strNb + " ").pop()
                var addressInfo = new AddressInformation(street, strNb, city, PC, province);
                this.getSelectedItem.emit(addressInfo);
            }
        }
    }

    public closeOutsideSidenav() {
        this.showAutocompleteBar = false;
    }

    ngOnInit() {
        this.addressForm = this.formBuilder.group({
            addressLookup: [''],
        });
    }

    public getFormControl(name) {
        return this.addressForm.get(name);
    }
}
