import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { StatusesReports } from 'src/app/models/statuses-report';
import { AppConfigService } from '../config-service/app-config.service';
import { Settings } from '../../components/common/constants/settings';
import { LanguageService } from '../language-service/language.service';
import { CreditBureausReportList } from 'src/app/models/CreditBureausReportList';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(private languageService: LanguageService, private cookieService: CookieService, private http: HttpClient) { }

  public reportType = '';
  public reportOption;

  public getReportType() {
    return this.reportType;
  }

  public getReportOption() {
    return this.reportOption;
  }

  getStatusesReporting(startDate: string, endDate: string, pageNumber: number, nrOfResultsPerPage: number): Observable<StatusesReports>{
    return this.http.get<StatusesReports>(AppConfigService.settings.apiEndpoint_microserviceLoanApp + '/api/loan-apps/reporting/statuses', {
        params: new HttpParams()
            .set('startDate', startDate.toString())
            .set('endDate', endDate.toString())
            .set('pageNumber', pageNumber.toString())
            .set('nrOfResultsPerPage', nrOfResultsPerPage.toString()),
        headers: new HttpHeaders({
            'authorizationtoken':this.cookieService.get('IdToken')
        })
    });
  } 

  getDenialReasonsReport(startDate: string, endDate: string, pageNumber: number, nrOfResultsPerPage: number) {
    return this.http.get<any>(AppConfigService.settings.apiEndpoint_microserviceLoanApp + '/api/loan-apps/reporting/denial-reasons', {
        params: new HttpParams()
            .set('pageNumber', pageNumber || pageNumber === 0 ? pageNumber.toString() : null)
            .set('nrOfResultsPerPage', nrOfResultsPerPage ? nrOfResultsPerPage.toString() : null)
            .set('startDate', startDate ? startDate.toString() : null)
            .set('endDate', endDate ? endDate.toString() : null),
        headers: Settings.headers.set('authorizationtoken', this.cookieService.get('IdToken')).set('Accept-Language', this.languageService.getLanguage())
    });
}

getLenderCodeLenderNameReport(startDate: string, endDate: string, pageNumber: number, nrOfResultsPerPage: number) {
  return this.http.get<any>(AppConfigService.settings.apiEndpoint_microserviceLender + '/api/reporting/lenderCode-lenderName', {
      params: new HttpParams()
          .set('pageNumber', pageNumber || pageNumber === 0 ? pageNumber.toString() : null)
          .set('nrOfResultsPerPage', nrOfResultsPerPage ? nrOfResultsPerPage.toString() : null)
           .set('startDate', startDate ? startDate.toString() : null)
           .set('endDate', endDate ? endDate.toString() : null),
      headers: Settings.headers.set('authorizationtoken', this.cookieService.get('IdToken')).set('Accept-Language', this.languageService.getLanguage())
  });
}


  downloadFile(data, filename = 'data', columns) {
    let csvData = this.ConvertToCSV(data, columns);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in headerList) {
        let head = headerList[index];

        line += array[i][head] + ',';
      }
      str += line + '\r\n';
    }
    return str;
  }
  getCreditBureausReport(startDate: string, endDate: string, pageNumber: number, nrOfResultsPerPage: number) {
    return this.http.get<CreditBureausReportList>(AppConfigService.settings.apiEndpoint_microserviceLoanApp + '/api/loan-apps/reporting/credit-bureaus', {
        params: new HttpParams()
            .set('pageNumber', pageNumber || pageNumber === 0 ? pageNumber.toString() : null)
            .set('nrOfResultsPerPage', nrOfResultsPerPage ? nrOfResultsPerPage.toString() : null)
            .set('startDate', startDate ? startDate.toString() : null)
            .set('endDate', endDate ? endDate.toString() : null),
        headers: Settings.headers.set('authorizationtoken', this.cookieService.get('IdToken')).set('Accept-Language', this.languageService.getLanguage())
    });
  }

  getPropertyAddressReport(startDate: string, endDate: string, pageNumber: number, nrOfResultsPerPage: number) {
    return this.http.get<any>(AppConfigService.settings.apiEndpoint_microserviceLoanApp + '/api/loan-apps/reporting/property-address', {
        params: new HttpParams()
            .set('pageNumber', pageNumber || pageNumber === 0 ? pageNumber.toString() : null)
            .set('nrOfResultsPerPage', nrOfResultsPerPage ? nrOfResultsPerPage.toString() : null)
            .set('startDate', startDate ? startDate.toString() : null)
            .set('endDate', endDate ? endDate.toString() : null),
        headers: Settings.headers.set('authorizationtoken', this.cookieService.get('IdToken')).set('Accept-Language', this.languageService.getLanguage())
    });
  }

  getPaidLoansReport(startDate: string, endDate: string, pageNumber: number, nrOfResultsPerPage: number) {
    return this.http.get<any>(AppConfigService.settings.apiEndpoint_microserviceLoanApp + '/api/loan-apps/reporting/paid-loans', {
        params: new HttpParams()
            .set('pageNumber', pageNumber || pageNumber === 0 ? pageNumber.toString() : null)
            .set('nrOfResultsPerPage', nrOfResultsPerPage ? nrOfResultsPerPage.toString() : null)
            .set('startDate', startDate ? startDate.toString() : null)
            .set('endDate', endDate ? endDate.toString() : null),
        headers: Settings.headers.set('authorizationtoken', this.cookieService.get('IdToken')).set('Accept-Language', this.languageService.getLanguage())
    });
  }

}
