import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule, routing } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LenderviewComponent } from './components/lenderview/lenderview.component';
import 'hammerjs';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { LenderRegistrationComponent } from './components/lender-registration/lender-registration.component';
import { LendersListComponent } from './components/lenders-list/lenders-list.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { CdkTableModule } from '@angular/cdk/table';
import { ScrollToTopModule } from '@ffdc/uxg-angular-components/scroll-to-top';
import { HeaderComponent } from './components/header/header.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FooterComponent } from './components/footer/footer.component';
import { AgGridModule } from 'ag-grid-angular';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AccountDetailsCsComponent } from './components/account-details-cs/account-details-cs.component';
import { HttpErrorInterceptor } from './interceptors/HttpErrorInterceptor';
import { AccountDetailsLenderComponent } from './components/account-details-lender/account-details-lender.component';
import { AddressComponent } from './components/common/address-component/address-component.component';
import { GeneralPromptComponent } from './components/common/general-prompt/general-prompt.component';
import { PaginatorComponent } from './components/common/paginator/paginator.component';
import { ErrorService } from './services/error/error.service';
import { CreditBureauComponent } from './components/credit-bureau/credit-bureau.component';
import { ApplicationSummaryComponent } from './components/application-summary/application-summary.component';
import { ClickOutsideDirective } from './components/common/address-component/dropdown-directive';
import { AuthGuardService } from './services/security/auth-guard.service';
import { AuthService } from './services/security/auth.service';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { ApplicationInsightsService } from './services/applicationInsights/application-insights.service';
import { SignalRService } from './services/signalR/signal-r.service';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { HomeComponent } from './components/home/home.component';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { DecisionPromptComponent } from './components/common/decision-prompt/decision-prompt.component';
import { DragAndDropDirective } from './directives/drag-and-drop/drag-and-drop.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BlankPageComponent } from './components/blank-page/blank-page.component';
import { AppConfigService } from './services/config-service/app-config.service';
import { MapComponent } from './components/map/map.component';
import { ActivityEventsDirective } from './directives/activity-events/activity-events.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserPromptComponent } from './components/common/user-prompt/user-prompt.component';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { HttpInterceptorProviders } from './interceptors/interceptor-provider';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { PayPageComponent } from './components/pay-page/pay-page.component';
import { NgPaymentCardModule } from 'ng-payment-card';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { CreatePasswordComponent } from './components/forgot-password/create-password/create-password.component';
import { ErrorPageUnavailableComponent } from './components/common/error-pages/error-page-unavailable/error-page-unavailable.component';
import { ErrorPermissionsComponent } from './components/common/error-pages/error-permissions/error-permissions.component';
import { ErrorServerComponent } from './components/common/error-pages/error-server/error-server.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { CountdownGlobalConfig, CountdownModule } from 'ngx-countdown';
import { SnackbarComponent } from './components/common/snackbar/snackbar.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from "./services/language-service/language.service";
import { CookieService } from "ngx-cookie-service";
import { LenderService } from "./services/api/lender.service";
import { NgxStripeModule } from "ngx-stripe";
import { LGStripeService } from "./services/stripe/l-g-stripe.service";
import { MultiTabNotification } from "./services/mutli-tab-notification.service";
import { ReportingComponent } from './components/reporting/reporting.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ReportDetailsComponent } from './components/report-details/report-details.component';
import { InternalUsersComponent } from "./users/internal-users.component";
import { UserService } from "./users/user-service";
import { AddUserComponent } from "./users/add-user.component";
import { ReportingService } from './services/reporting/reporting.service';
import { CommonModule, DatePipe } from '@angular/common';
import { ApplicationSummaryPrintComponent } from './components/application-summary-print/application-summary-print.component';
import { CreditBureauPrintComponent } from './components/credit-bureau-print/credit-bureau-print.component';
import { ReportDetailsPrintComponent } from './components/report-details-print/report-details-print.component';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import 'moment/locale/en-gb';
  

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

const appInitializerFn = (appConfig: AppConfigService, languageService: LanguageService) => {
    return () => {
        return appConfig.loadAppConfig().then(() => {
            return languageService.init();
        });
    };
};

@NgModule({
    declarations: [
        AppComponent,
        LenderRegistrationComponent,
        LendersListComponent,
        HeaderComponent,
        LenderviewComponent,
        BreadcrumbComponent,
        FooterComponent,
        AccountDetailsCsComponent,
        AccountDetailsLenderComponent,
        AddressComponent,
        GeneralPromptComponent,
        PaginatorComponent,
        CreditBureauComponent,
        ApplicationSummaryComponent,
        ApplicationSummaryPrintComponent,
        ClickOutsideDirective,
        AuthCallbackComponent,
        ErrorPageComponent,
        HomeComponent,
        DropdownMenuComponent,
        DecisionPromptComponent,
        DragAndDropDirective,
        BlankPageComponent,
        MapComponent,
        ActivityEventsDirective,
        UserPromptComponent,
        SignInComponent,
        NewPasswordComponent,
        PayPageComponent,
        ForgotPasswordComponent,
        CreatePasswordComponent,
        ErrorPageUnavailableComponent,
        ErrorPermissionsComponent,
        ErrorServerComponent,
        SnackbarComponent,
        ReportingComponent,
        ReportDetailsComponent,
        InternalUsersComponent,
        AddUserComponent,
        CreditBureauPrintComponent,
        ReportDetailsPrintComponent
    ],
    entryComponents: [GeneralPromptComponent, AddUserComponent, DecisionPromptComponent, UserPromptComponent, SnackbarComponent],
    imports: [
        CommonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        BrowserModule,
        AppRoutingModule,
        NoopAnimationsModule,
        MatTableModule,
        CdkTableModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSortModule,
        MatSelectModule,
        MatPaginatorModule,
        MatTabsModule,
        MatChipsModule,
        HttpClientModule,
        MatInputModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        ScrollToTopModule,
        routing,
        AgGridModule.withComponents([]),
        MatRadioModule,
        MatAutocompleteModule,
        HttpClientJsonpModule,
        MatMenuModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatSidenavModule,
        AmplifyAngularModule,
        NgPaymentCardModule,
        CreditCardDirectivesModule,
        MatCheckboxModule,
        NgIdleKeepaliveModule.forRoot(),
        CountdownModule,
        MatSnackBarModule,
        NgxStripeModule.forRoot(''),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        AppConfigService,
        CookieService,
        LenderService,
        ReportingService,
        LanguageService,
        ApplicationInsightsService,
        DatePipe,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [AppConfigService, LanguageService]
        },        
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: {color: 'accent'},
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: ErrorService
        },
        {provide: CountdownGlobalConfig, useValue: undefined},
        AmplifyService,
        AuthGuardService,
        AuthService,
        SignalRService,
        HttpInterceptorProviders,
        LGStripeService,
        MultiTabNotification,
        UserService,
        DatePipe
    ],

    exports: [
        MatSortModule,
        MatDatepickerModule,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
