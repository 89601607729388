<div [hidden]="!disableButtons" class="loadingMessage">
    We are collecting data
    <br/><br/>
    <div *ngIf="displaySecondMessage" class="loadingSecondMessage">
        Your report may take longer to download due to the fact that it will retrieve a large amount of data.
    </div>
</div>
<app-header></app-header>
<div class="container px-3 py-3 col-md-8 col-sm-8" id="table-group-div" style="min-width: 85%;max-width: 85%;">

    <div class="buttons">
        <button [disabled]="isDataAvailable() == false || disableButtons == true" id="download-pdf-button" mat-flat-button color="primary" dense
            id="to-pdf-button" (click)="downloadPDF()">
            <img src="../../../assets/pdf.svg" class="downloadPdfIcon" aria-label="extract pdf button"
                role="button icon" />
            Download PDF
        </button>

        <button [disabled]="isDataAvailable() == false || disableButtons == true" id="download-csv-button" mat-flat-button color="primary" dense
            (click)="downloadCSV()" style="margin-left: 20px;">
            <img src="../../../assets/csv.svg" class="downloadCsvIcon" aria-label="extract csv button"
                role="button icon" />
            Download CSV
        </button>
    </div>

    <div *ngIf="!isStatusInsights">
        <mat-table *ngIf="isDataAvailable()" matSort class="table" matSortActive="date" matSortDirection="asc"
            [dataSource]="dataSource" (matSortChange)="sortData($event)" matSortDisableClear #sort1="matSort">

            <ng-container matColumnDef="date" alt="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" style="min-width: 14rem;">
                    <div class="headerTitle">Date</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" style="min-width: 14rem;">
                    <div *ngIf="isLenderCodeLenderNameReportDisplayed == true">
                        {{element.creationDate | date: 'MMM dd, yyyy'}}
                        {{element.creationDate | date: ', hh:mm a'}}
                    </div>
                    <div *ngIf="isLenderCodeLenderNameReportDisplayed == false">
                        {{element.date | date: 'MMM dd, yyyy'}}
                        {{element.date | date: ', hh:mm a'}}
                    </div>

                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lenderCode" alt="lender code">
                <mat-header-cell *matHeaderCellDef arrowPosition="before">
                    <div class="headerTitle">Lender Code</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.lenderCode}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lenderName" alt="lender name">
                <mat-header-cell *matHeaderCellDef arrowPosition="before" style="min-width: 15rem;">
                    <div class="headerTitle">Lender Name</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" style="min-width: 15rem;">
                    {{element.lenderName}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="receivedLead" alt="received lead ID">
                <mat-header-cell *matHeaderCellDef arrowPosition="before">
                    <div class="headerTitle">Received Lead</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.receivedLead}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="paidLead" alt="paid lead ID">
                <mat-header-cell *matHeaderCellDef arrowPosition="before">
                    <div class="headerTitle">Paid Lead</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.paidLeadId}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="approvedDeal" alt="approved deal ID">
                <mat-header-cell *matHeaderCellDef arrowPosition="before">
                    <div class="headerTitle">Approved deal</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.approvedDeal}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="declinedDeal" alt="declined deal ID">
                <mat-header-cell *matHeaderCellDef arrowPosition="before">
                    <div class="headerTitle">Declined deal</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.declinedDeal}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fundedDeal" alt="funded deal ID">
                <mat-header-cell *matHeaderCellDef arrowPosition="before">
                    <div class="headerTitle">Funded deal</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.fundedDeal}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="loanId" alt="loan id">
                <mat-header-cell *matHeaderCellDef arrowPosition="before">
                    <div class="headerTitle">Loan ID</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.loanId}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="applicationId" alt="received loan id">
                <mat-header-cell *matHeaderCellDef arrowPosition="before">
                    <div class="headerTitle">Received Loan</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.applicationId}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="hasCreditBureau" alt="credit bureau">
                <mat-header-cell *matHeaderCellDef arrowPosition="before">
                    <div class="headerTitle">Credit Bureau</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.hasCreditBureau == true? 'Yes':'No'}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="isEfxTu" alt="efx/tu">
                <mat-header-cell *matHeaderCellDef arrowPosition="before">
                    <div class="headerTitle">EFX/TU</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.isEfxTu? element.isEfxTu:'N/A'}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="propertyAddress" alt="property address">
                <mat-header-cell *matHeaderCellDef arrowPosition="before">
                    <div class="headerTitle">Property Address</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.propertyAddress.property.address.streetNumber + " " +
                    element.propertyAddress.property.address.streetName + ","}}
                    <br>
                    {{element.propertyAddress.property.address.city + ", " +
                    element.propertyAddress.property.address.provinceDd[0].text}}
                    <br>
                    {{element.propertyAddress.property.address.postalFsa + " " +
                    element.propertyAddress.property.address.postalLdu}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="denialReason" alt="denial reason">
                <mat-header-cell *matHeaderCellDef arrowPosition="before">
                    <div class="headerTitle">Denial Reason</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.denialReason}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="firmName" alt="agent name / brokerage">
                <mat-header-cell *matHeaderCellDef arrowPosition="before">
                    <div class="headerTitle">Agent Name / Brokerage</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.firmName}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status" alt="status">
                <mat-header-cell *matHeaderCellDef arrowPosition="before">
                    <div class="headerTitle">Status</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.isActive == true? 'Active':'Inactive'}}
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="getColumnsToDisplay()" class="matHeaderRow"></mat-header-row>
            <mat-row *matRowDef="let row; columns: getColumnsToDisplay();" class="matRow"></mat-row>

        </mat-table>
    </div>

    <div *ngIf="isStatusInsights">

        <div *ngIf="isDataAvailable()"
            style="display: inline-flex;margin-top: 20px;width: 95%;margin-left: 30px;margin-right: 40px;min-height: 45px;">
            <div style="width: 33%;font-weight: bolder;font-size: small;align-self: center; padding-left:2%">Lender Code
                / Lender Name</div>
            <div style="width: 37%;font-weight: bolder;font-size: small;align-self: center;">Status</div>
            <div style="width: 13.5%;font-weight: bolder;font-size: small;align-self: center;">Application Id</div>
            <div style="font-weight: bolder;font-size: small;align-self: center;">Date</div>
        </div>

        <mat-table *ngIf="isDataAvailableForStatusReportTable()" class="table" matSort matSortActive="date"
            matSortDirection="desc" style="margin-top: 0px;" [dataSource]="statusReportDataSource">

            <ng-container matColumnDef="lenderCode&lenderName" alt="lender code and lender name">
                <mat-cell *matCellDef="let element" class="lenderCodeAndNameColumn">
                    {{element.lenderCodeAndName}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="loanData" alt="loan data">
                <mat-cell *matCellDef="let element" style="padding-bottom: 0px !important; padding-right: 0px;">

                    <mat-table class="table" matSort matSortActive="date" matSortDirection="desc"
                        [dataSource]="extractDatasourceForSecondTable(element.loanData)" class="innerTableFirst">

                        <ng-container matColumnDef="status" alt="status">
                            <mat-cell *matCellDef="let element1">
                                {{element1.status}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="loanData" alt="status">
                            <mat-cell *matCellDef="let element1">

                                <mat-table class="table" matSort matSortActive="date" matSortDirection="desc"
                                    [dataSource]="extractDatasourceForThirdTable(element1.loans)" class="innerTables">

                                    <ng-container matColumnDef="loanId" alt="loan id">
                                        <mat-cell *matCellDef="let element2">
                                            {{element2.applicationId}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="date" alt="date">
                                        <mat-cell *matCellDef="let element2">
                                            {{element2.date | date: 'yyyy-MM-dd, h:mm:ss'}}
                                        </mat-cell>
                                    </ng-container>

                                    <mat-row *matRowDef="let row; columns: getColumnsToDisplayForThirdTable();"
                                        class="matRowStatusesLoanInfoRow"></mat-row>
                                </mat-table>

                            </mat-cell>
                        </ng-container>

                        <mat-row *matRowDef="let row; columns: getColumnsToDisplayForSecondTable();"
                            class="matRowStatusesSecondTable"></mat-row>
                    </mat-table>

                </mat-cell>
            </ng-container>

            <mat-row *matRowDef="let row; columns: getColumnsToDisplay();" class="matRowStatuses"
                style="padding-bottom: 0px !important;"></mat-row>

        </mat-table>
    </div>

    <div *ngIf="isDataAvailable()" [hidden]=checkPaginator() class="paginator">
        <app-paginator [pageSize]="getDefaultNrOfResultsPerPage()" [activePage]="getActivePage()"
            [nrOfPages]="getNumberOfPages()" [pageSizeOptions]="getPageSizeOptions()"
            (onPageChange)="onPageChange($event)" style="width: 40em;">
        </app-paginator>
    </div>

    <div *ngIf="!isDataAvailable()" class="message">
        There is currently no data available for this type of report.
    </div>

</div>

<footer style="height: 30px;"></footer>