import { DatePipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StatusesReportLoanData } from 'src/app/models/statuses-report-loan-data';
import { ReportingService } from 'src/app/services/reporting/reporting.service';
import { PromptsMessages } from '../common/constants/promptsMessages';
import { CreditBureauReportCSVDTO } from 'src/app/models/CreditBureauReportCSVDTO';
import { PropertyAddressReportCSVDTO } from 'src/app/models/PropertyAddressReportCSVDTO';
import { PaidLoansReportCSVDTO } from 'src/app/models/PaidLoansReportCSVDTO';
import { LenderCodeLenderNameReportCSVDTO } from 'src/app/models/LenderCodeLenderNameReportCSVDTO';
import { DenialReasonsCSVDTO } from 'src/app/models/DenialReasonsCSVDTO';
import { AppConfigService } from 'src/app/services/config-service/app-config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LanguageService } from 'src/app/services/language-service/language.service';
import { CookieService } from 'ngx-cookie-service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from "@angular/material/sort";
import { IsLoadingService } from 'src/app/services/is-loading.service';

interface Size {
  value: number;
  viewValue: number;
}

@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss']
})
export class ReportDetailsComponent implements OnInit {

  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  public dataSource: MatTableDataSource<any>;
  public dataSourcePDF: MatTableDataSource<any>;
  public statusReportDataSource: MatTableDataSource<any>;
  public statusReportDataSourcePDF: MatTableDataSource<any>;
  public dataSourceCSVPDF: MatTableDataSource<any>;
  private hidePaginator: boolean = false;
  private activePage = 0;
  private numberOfPages = 1;
  private pageSize = 10;
  private dataAvailable = true;
  public startDate = null;
  public endDate = null;
  public disableButtons = false;
  public displaySecondMessage = false;

  private pageSizes: Size[] = [
    { value: 10, viewValue: 10 },
    { value: 20, viewValue: 20 },
    { value: 50, viewValue: 50 },
    { value: 100, viewValue: 100 },
  ];

  private pageSizesForStatusInsights: Size[] = [
    { value: 5, viewValue: 5 },
    { value: 10, viewValue: 10 },
    { value: 30, viewValue: 30 },
    { value: 50, viewValue: 50 },
  ];
  public isStatusInsights: boolean;
  public isLenderCodeLenderNameReportDisplayed: boolean = false;
  public statusForLenderCodeLenderNameReport : string;

  constructor(private isLoadingService: IsLoadingService,private languageService: LanguageService, private cookieService: CookieService, private http: HttpClient,private reportingService: ReportingService, private route: ActivatedRoute, public datepipe: DatePipe) { }

  private displayedColumns: string[] = [
    'date',
    'lenderCode',
    'lenderName',
    'receivedLead',
    'paidLead',
    'approvedDeal',
    'declinedDeal',
    'fundedDeal',
    'loanId',
    'applicationId',
    'hasCreditBureau',
    'isEfxTu',
    'propertyAddress',
    'denialReason',
    'agentNameBrokerage',
    'status'
  ];

  public reportType;
  public reportOption;

  ngOnInit() {
    this.isLoadingService.remove();
    this.disableButtons = false;
    this.displaySecondMessage = false;
    this.isLenderCodeLenderNameReportDisplayed = false;
    this.isStatusInsights = false;
    this.activePage = 0;
    this.dataAvailable = true;

    this.route.queryParams
      .subscribe(params => {
        this.reportType = params['reportType'];
        this.startDate = params['start'];
        this.endDate = params['end'];
        this.reportingService.reportOption = this.reportingService.reportOption != "Last month" && this.reportingService.reportOption != "Last 3 months"
                                            && this.reportingService.reportOption != "Last year" ?
        this.startDate + " - " + this.datepipe.transform(new Date(this.endDate).setDate(new Date(this.endDate).getDate() - 1), 'MM/dd/yyyy') : this.reportingService.reportOption;
      });

    this.reportingService.reportType = this.reportType;

    this.startDate = this.datepipe.transform(new Date(this.startDate), 'yyyy-MM-dd');
    this.endDate = this.datepipe.transform(new Date(this.endDate), 'yyyy-MM-dd')

    this.dataSource = new MatTableDataSource();
    this.statusReportDataSource = new MatTableDataSource();
    this.dataSourceCSVPDF = new MatTableDataSource();

    /*
    * If report type is Status Insights then a table with different structure from the other report types will be displayed
    */
    this.checkReportType(this.reportType);
    this.fetchReportData(0, this.reportType === PromptsMessages.REPORTING_TYPE_1 ? 5 : 10);

  }

  public getColumnsToDisplay() {
    switch (this.reportingService.getReportType()) {
      case PromptsMessages.REPORTING_TYPE_1: {
        this.displayedColumns = [
          'lenderCode&lenderName', 'loanData'
        ];
        break;
      }
      case PromptsMessages.REPORTING_TYPE_2: {
        this.displayedColumns = [
          'date', 'lenderCode', 'lenderName', 'applicationId', 'hasCreditBureau', 'isEfxTu'
        ];
        break;
      }
      case PromptsMessages.REPORTING_TYPE_3: {
        this.displayedColumns = [
          'date', 'lenderCode', 'lenderName', 'applicationId', 'propertyAddress'
        ];
        break;
      }
      case PromptsMessages.REPORTING_TYPE_4: {
        this.displayedColumns = [
          'date', 'lenderCode', 'lenderName', 'loanId', 'denialReason', 'firmName'
        ];
        break;
      }
      case PromptsMessages.REPORTING_TYPE_5: {
        this.displayedColumns = [
          'date', 'lenderCode', 'lenderName', 'status'
        ];
        this.isLenderCodeLenderNameReportDisplayed = true;
        break;
      }
      case PromptsMessages.REPORTING_TYPE_6: {
        this.displayedColumns = [
          'date', 'lenderCode', 'lenderName', 'paidLead'
        ];
        break;
      }
    }
    return this.displayedColumns;
  }

  public getColumnsToDisplayForSecondTable() {
    return ['status', 'loanData'];
  }

  public getColumnsToDisplayForThirdTable() {
    return ['loanId', 'date'];
  }

  public getTitleForPdf() {
    return this.reportType + " - " + this.reportingService.getReportOption();
  }

  public downloadPDF() {
    this.disableButtons = true;
    this.isLoadingService.add();
    setTimeout(()=>{
      this.displaySecondMessage = true;
    },5000);
    const url = AppConfigService.settings.apiEndpoint_pdfService + '/api/generate-pdf?url=' +
      encodeURIComponent(`${window.location.href
        .replace('report-details', 'report-details-print')}&authorizationtoken=${this.cookieService.get('IdToken')}&lang=${this.languageService.getLanguage()}`);
    const mediaTypeDownloadPdf = 'application/pdf';
    this.http.get(url, {
      responseType: 'arraybuffer', headers: new HttpHeaders({
        'authorizationtoken': this.cookieService.get('IdToken'),
        'Accept': mediaTypeDownloadPdf
      })
    }
    ).subscribe(response => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const downloadUrl = window.URL.createObjectURL(blob);
      a.href = downloadUrl;
      a.download = `${this.getTitleForPdf()}.pdf`;
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
      this.isLoadingService.remove();
      this.disableButtons = false;
      this.displaySecondMessage = false;
    });
  }

  public downloadCSV() {
    this.disableButtons = true;
    this.isLoadingService.add();
    setTimeout(()=>{
      this.displaySecondMessage = true;
    },5000);

    if (this.reportType === PromptsMessages.REPORTING_TYPE_1) {
      this.reportingService.getStatusesReporting(this.startDate, this.endDate, -1, -1).subscribe(data => {
        this.reportingService.downloadFile(this.createCSVReadableDataSourceForStatusesreport(data.objectList), this.getTitleForPdf(), ['lenderCodeAndLenderName', 'status', "applicationId", "date"]);
        this.isLoadingService.remove();
        this.disableButtons = false;
        this.displaySecondMessage = false;
      });
      } else if (this.reportingService.getReportType() == PromptsMessages.REPORTING_TYPE_2) {
      let newDataSource: MatTableDataSource<CreditBureauReportCSVDTO>;
      newDataSource = new MatTableDataSource();

      this.reportingService.getCreditBureausReport(this.startDate, this.endDate, -1, -1).subscribe(data => {
        data.objectList.forEach(data => {
          let creditBureauCsvData: CreditBureauReportCSVDTO = new CreditBureauReportCSVDTO(data.applicationId, data.date, data.lenderCode, data.lenderName, data.hasCreditBureau ? "Yes" : "No", data.isEfxTu ? data.isEfxTu : "N/A");
          newDataSource.data.push(creditBureauCsvData);
        });
        this.reportingService.downloadFile(newDataSource.data, this.getTitleForPdf(), ["Date", "LenderCode", "LenderName", "ReceivedLoanID", "CreditBureau", "EfxTu"]);
        this.isLoadingService.remove();
        this.disableButtons = false;
        this.displaySecondMessage = false;
      });
    } else if (this.reportingService.getReportType() == PromptsMessages.REPORTING_TYPE_3) {
      let newDataSource: MatTableDataSource<PropertyAddressReportCSVDTO>;
      newDataSource = new MatTableDataSource();

      this.reportingService.getPropertyAddressReport(this.startDate, this.endDate, -1, -1).subscribe(data => {
        data.objectList.forEach(data => {
          let newSubjectProperty = data.propertyAddress.property.address.streetNumber + " " + data.propertyAddress.property.address.streetName + " " +
          data.propertyAddress.property.address.city + " " + data.propertyAddress.property.address.provinceDd[0].text + " " +
          data.propertyAddress.property.address.postalFsa + " " + data.propertyAddress.property.address.postalLdu;

          let propertyAddressCsvData: PropertyAddressReportCSVDTO = new PropertyAddressReportCSVDTO(data.applicationId, data.date, data.lenderCode, data.lenderName, newSubjectProperty);
          newDataSource.data.push(propertyAddressCsvData);
        });
        this.reportingService.downloadFile(newDataSource.data, this.getTitleForPdf(), ["Date", "LenderCode", "LenderName", "ReceivedLoanID", "PropertyAddress"]);
        this.isLoadingService.remove();
        this.disableButtons = false;
        this.displaySecondMessage = false;
      });
    } else if (this.reportingService.getReportType() == PromptsMessages.REPORTING_TYPE_4) {
      let newDataSource: MatTableDataSource<DenialReasonsCSVDTO>;
      newDataSource = new MatTableDataSource();

      this.reportingService.getDenialReasonsReport(this.startDate, this.endDate, -1, -1).subscribe(data => {
        data.objectList.forEach(data => {
          let denialReasonsCSVData: DenialReasonsCSVDTO = new DenialReasonsCSVDTO(data.loanId, data.date, data.lenderCode, data.lenderName, data.denialReason, data.firmName);
          newDataSource.data.push(denialReasonsCSVData);
        });
        this.reportingService.downloadFile(newDataSource.data, this.getTitleForPdf(), ["Date", "LenderCode", "LenderName", "LoanID", "DenialReason", "FirmName"]);
        this.isLoadingService.remove();
        this.disableButtons = false;
        this.displaySecondMessage = false;
      });
    } else if (this.reportingService.getReportType() == PromptsMessages.REPORTING_TYPE_5) {
      let newDataSource: MatTableDataSource<LenderCodeLenderNameReportCSVDTO>;
      newDataSource = new MatTableDataSource();

      this.reportingService.getLenderCodeLenderNameReport(this.startDate, this.endDate, -1, -1).subscribe(data => {
        data.objectList.forEach(data => {
          if (data.isActive == true){
            this.statusForLenderCodeLenderNameReport = "Active";
          }
          else{
           this.statusForLenderCodeLenderNameReport = "Inactive";
          }
          let lenderCodeLenderNameCSVData: LenderCodeLenderNameReportCSVDTO = new LenderCodeLenderNameReportCSVDTO(data.creationDate, data.lenderCode, data.lenderName, this.statusForLenderCodeLenderNameReport);
          newDataSource.data.push(lenderCodeLenderNameCSVData);
        });
        this.reportingService.downloadFile(newDataSource.data, this.getTitleForPdf(), ["Date", "LenderCode", "LenderName", "Status"]);
        this.isLoadingService.remove();
        this.disableButtons = false;
        this.displaySecondMessage = false;
      });
    } else if (this.reportingService.getReportType() == PromptsMessages.REPORTING_TYPE_6) {
      let newDataSource: MatTableDataSource<PaidLoansReportCSVDTO>;
      newDataSource = new MatTableDataSource();

      this.reportingService.getPaidLoansReport(this.startDate, this.endDate, -1, -1).subscribe(data => {
        data.objectList.forEach(data => {
          let paidLeadCsvData: PaidLoansReportCSVDTO = new PaidLoansReportCSVDTO(data.paidLeadId, data.date, data.lenderCode, data.lenderName);
          newDataSource.data.push(paidLeadCsvData);
        });
        this.reportingService.downloadFile(newDataSource.data, this.getTitleForPdf(), ["Date", "LenderCode", "LenderName", "PaidLeadID"]);
        this.isLoadingService.remove();
        this.disableButtons = false;
        this.displaySecondMessage = false;
      });
    } else {
      this.reportingService.downloadFile(this.dataSource.data, this.getTitleForPdf(), this.getColumnsToDisplay());
      this.isLoadingService.remove();
      this.disableButtons = false;
      this.displaySecondMessage = false;
    }
  }

  public checkPaginator() {
    return this.hidePaginator;
  }

  public getActivePage() {
    return this.activePage;
  }

  public getNumberOfPages() {
    return this.numberOfPages;
  }

  public getPageSizeOptions() {
    if (PromptsMessages.REPORTING_TYPE_1) {
      return this.pageSizesForStatusInsights
    }
    return this.pageSizes;
  }

  public onPageChange(event) {
    if (event.pageSize != this.pageSize) {
      this.pageSize = event.pageSize;
      this.fetchReportData(0, event.pageSize);
    } else {
      this.fetchReportData(event.activePage, this.pageSize);
    }

    if (event.activePage == (this.activePage + 1)) {
      this.activePage = event.activePage;
    }
  }

  public isDataAvailable() {
    return this.dataAvailable;
  }

  public getCreditBureausReportData(startDate: string, endDate: string, pageNumber: number, nrOfResultsPerPage: number) {
    this.reportingService.getCreditBureausReport(startDate, endDate, pageNumber, nrOfResultsPerPage).subscribe(data => {
      if (data == null || data.objectList.length == 0) {
        this.dataAvailable = false;
      }
      this.dataSource.data = data.objectList;
      this.numberOfPages = data.nrOfPages;
    })
  }

  public getPropertyAddressReportData(startDate: string, endDate: string, pageNumber: number, nrOfResultsPerPage: number) {
    this.reportingService.getPropertyAddressReport(startDate, endDate, pageNumber, nrOfResultsPerPage).subscribe(data=> {
      if (data == null || data.objectList.length == 0) {
        this.dataAvailable = false;
      }
      this.dataSource.data = data.objectList;
      this.numberOfPages = data.nrOfPages;
    })
  }

  public getPaidLoansReportData(startDate: string, endDate: string, pageNumber: number, nrOfResultsPerPage: number) {
    this.reportingService.getPaidLoansReport(startDate, endDate, pageNumber, nrOfResultsPerPage).subscribe(data=> {
      if (data == null || data.objectList.length == 0) {
        this.dataAvailable = false;
      }
      this.dataSource.data = data.objectList;
      this.numberOfPages = data.nrOfPages;
    })
  }

  public sortData(sort: MatSort) {
    if (this.reportingService.getReportType() == PromptsMessages.REPORTING_TYPE_5) {
      if (sort.active && sort.direction !== '') {
        this.dataSource.data = this.dataSource.data.sort((a, b) => {
          const isAsc = sort.direction === 'asc';
          return this.compare(a.creationDate, b.creationDate, isAsc);
        });
      }
    } else {
      if (sort.active && sort.direction !== '') {
        this.dataSource.data = this.dataSource.data.sort((a, b) => {
          const isAsc = sort.direction === 'asc';
          return this.compare(a.date, b.date, isAsc);
        });
      }
    }
  }

  private compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  isDataAvailableForStatusReportTable() {
    if (this.statusReportDataSource.data.length > 0) {
      return true;
    }
    return false;
  }

  checkReportType(reportType: any) {
    if (reportType === "Statuses Insights") {
      this.isStatusInsights = true;
      this.pageSize = 5;
    }
  }

  fetchReportData(pageNumber: number, nrOfResultsPerPage: number) {
    switch (this.reportType) {

      case PromptsMessages.REPORTING_TYPE_1: {
        this.getStatusReportData(this.startDate, this.endDate, pageNumber, nrOfResultsPerPage);
        break;
      }
      case PromptsMessages.REPORTING_TYPE_2: {
        this.getCreditBureausReportData(this.startDate, this.endDate, pageNumber, nrOfResultsPerPage);
        break;
      }
      case PromptsMessages.REPORTING_TYPE_3: {
        this.getPropertyAddressReportData(this.startDate, this.endDate, pageNumber, nrOfResultsPerPage);
        break;
      }
      case PromptsMessages.REPORTING_TYPE_4: {
        this.getDenialReasonsReportData(this.startDate, this.endDate, pageNumber, nrOfResultsPerPage);
        break;
      }
      case PromptsMessages.REPORTING_TYPE_5: {
        this.getLenderCodeLenderNameReportData(this.startDate, this.endDate, pageNumber, nrOfResultsPerPage);
        break;
      }
      case PromptsMessages.REPORTING_TYPE_6: {
        this.getPaidLoansReportData(this.startDate, this.endDate, pageNumber, nrOfResultsPerPage);
        break;
      }
    }
  }

  getDenialReasonsReportData(startDate: string, endDate: string, pageNumber: number, nrOfResultsPerPage: number) {
    this.reportingService.getDenialReasonsReport(startDate, endDate, pageNumber, nrOfResultsPerPage).subscribe(data => {
      if (data == null || data.objectList.length == 0) {
        this.dataAvailable = false;
      }
      this.dataSource.data = data.objectList;
      this.numberOfPages = data.nrOfPages;
    })
  }

  getLenderCodeLenderNameReportData(startDate: string, endDate: string, pageNumber: number, nrOfResultsPerPage: number) {
    this.reportingService.getLenderCodeLenderNameReport(startDate, endDate, pageNumber, nrOfResultsPerPage).subscribe(data => {
      if (data == null || data.objectList.length == 0) {
        this.dataAvailable = false;
      }
      this.dataSource.data = data.objectList;
      this.numberOfPages = data.nrOfPages;
    })
  }

  getStatusReportData(startDate: string, endDate: string, pageNumber: number, nrOfResultsPerPage: number) {
    this.reportingService.getStatusesReporting(startDate, endDate, pageNumber, nrOfResultsPerPage).subscribe(data => {
      if (data == null || data.objectList.length == 0) {
        this.dataAvailable = false;
      }
      this.statusReportDataSource.data = data.objectList;
      this.numberOfPages = data.nrOfPages;
    })
  }

  extractDatasourceForSecondTable(loanData: StatusesReportLoanData[]) {
    let dataSourceForSecondTable: MatTableDataSource<any> = new MatTableDataSource();
    let objList: any[] = [];

    let receivedLeads: any = {};
    receivedLeads.status = "New";
    receivedLeads.loans = loanData.filter((ld) => { return ld.status === "New" });
    if (receivedLeads.loans != null && receivedLeads.loans != undefined && receivedLeads.loans.length != 0) {
      objList.push(receivedLeads);
    }
  

    let payedLeads: any = {};
    payedLeads.status = "In Review";
    payedLeads.loans = loanData.filter((ld) => { return ld.status === "In Review" });
    if (payedLeads.loans != null && payedLeads.loans != undefined && payedLeads.loans.length != 0) {
      objList.push(payedLeads);
    }

    let rejectedLeads: any = {};
    rejectedLeads.status = "Rejected";
    rejectedLeads.loans = loanData.filter((ld) => { return ld.status === "Rejected" });
    if (rejectedLeads.loans != null && rejectedLeads.loans != undefined && rejectedLeads.loans.length != 0) {
      objList.push(rejectedLeads);
    }

    let approvedDeal: any = {};
    approvedDeal.status = "Approved";
    approvedDeal.loans = loanData.filter((ld) => { return ld.status === "Approved" });
    if (approvedDeal.loans != null && approvedDeal.loans != undefined && approvedDeal.loans.length != 0) {
      objList.push(approvedDeal);
    }

    let declinedDeals: any = {};
    declinedDeals.status = "Declined";
    declinedDeals.loans = loanData.filter((ld) => { return (ld.status === "Declined") });
    if (declinedDeals.loans != null && declinedDeals.loans != undefined && declinedDeals.loans.length != 0) {
      objList.push(declinedDeals);
    }

    let fundedDeals: any = {};
    fundedDeals.status = "Funded";
    fundedDeals.loans = loanData.filter((ld) => { return ld.status === "Funded" });
    if (fundedDeals.loans != null && fundedDeals.loans != undefined && fundedDeals.loans.length != 0) {
      objList.push(fundedDeals);
    }

    dataSourceForSecondTable.data = objList;

    return dataSourceForSecondTable;
  }

  extractDatasourceForThirdTable(loans: any[]) {
    let dataSourceForSecondTable: MatTableDataSource<any> = new MatTableDataSource();
    dataSourceForSecondTable.data = loans;

    return dataSourceForSecondTable;
  }

  getDefaultNrOfResultsPerPage() {
    if (this.reportType == PromptsMessages.REPORTING_TYPE_1) {
      return 5;
    }
    return 10;
  }

  createCSVReadableDataSourceForStatusesreport(data: any[]): any {
    let objList: any[] = [];

    for (let i = 0; i < data.length; i++) {
      let tempLoanData: any[] = [];
      tempLoanData = data[i].loanData;
      tempLoanData.forEach(tld => {
        tld.lenderCodeAndLenderName = data[i].lenderCodeAndName;
        objList.push(tld);
      });
    }
    return objList;
  }

}



