<body style="background-color: transparent;">
    <div class="errorTitle">
        {{ 'sorry' | translate }}
    </div>
    <div class="iconBox">
        <img class="errorIcon" src="../../assets/Icon-3.svg" />
    </div>
    <div class="errorMessage">
        {{ 'error_server' | translate }}
    </div>
    <div class="buttonBox">
        <button id="payButton" mat-stroked-button dense class="buttonBack" (click)="exit()"> 
            {{buttonText | translate}}</button>
    </div>
    <div class="supportBox">
        <a href="mailto: abc@example.com" class="supportLink">Support</a>
    </div>
</body>
