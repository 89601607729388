<div class="vertical-align container">
    <form #formControl="ngForm">
    <div>
        <mat-form-field>
            <input matInput required pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$" [(ngModel)]="user.email" name="email" placeholder="Email">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <input matInput required [(ngModel)]="user.name" name="name" placeholder="Name">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <mat-select required [id]="user.id" [(ngModel)]="user.roles" multiple name="roles" placeholder="Roles">
                <mat-option *ngFor="let type of roles" [value]="type.id">{{type.description}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="buttons">
        <button (click)="!formControl.invalid && add()" mat-flat-button color="primary"
                [autofocus]="false" [disabled]="formControl.invalid">Add User
        </button>
        <button (click)="cancel()" mat-flat-button color="primary"
                [autofocus]="false">Cancel
        </button>
    </div>
    </form>
</div>
