import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { add } from 'lodash';
import { AuthGuardService } from 'src/app/services/security/auth-guard.service';
import { AuthService } from 'src/app/services/security/auth.service';
import { ComparePassword } from '../../new-password/compare-pass';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {
  public changePassForm: FormGroup;
  public fieldTextTypeNewPass: boolean;
  public fieldTextTypeReEnter: boolean;
  public entersNewPass: boolean = false;
  public passWordsMatch: boolean = false;
  public isCodeMismatch: boolean = false;
  public isEmailInCorrect: boolean =false;
  public isLimitExceeded: boolean = false;
  public isCodeMismatchWhenUserException: boolean = false;
  public text: string;

  public newPassValidation: any = {
      hasLowerCase: false, hasUpperCase: false, hasSpecialCharacter: false, hasNumber: false, hasEightLetters: false
  }


  constructor(private formBuilder: FormBuilder, private authService: AuthService,  private elementRef: ElementRef, private route: ActivatedRoute) {
      this.changePassForm = this.formBuilder.group({
          emailInput: ['', [Validators.required,  Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$")]],
          pass1: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@!%*?&])[A-Za-z\d$@$!%*?&].{7,}$')]],
          pass2: ['', [Validators.required]],
          code: ['', [Validators.required]]
      },
      {
          validator: ComparePassword('pass1', 'pass2')
      });
  }

  changeFieldNoSpaces(event: any, name){
    if (event) {
        if(this.isEmailInCorrect){
            this.isEmailInCorrect = false;
        }
        var value = event.target.value;
        if (value.match(/^\s* {1}$/)) {
            value = value.substring(0, value.length - 1);
        }
        else if (value.length >= 2 && value.substring(value.length - 1, value.length).match(/^\s* {1}$/)) {
            value = value.substring(0, value.length - 1);
        }
        this.changePassForm.get(name).setValue(value);
        this.changePassForm.get(name).markAsTouched();
    }
}      

  ngAfterViewInit(){
      this.elementRef.nativeElement.ownerDocument.body.style.background = "url('../assets/login-backgroung.png') center center fixed";
      this.elementRef.nativeElement.ownerDocument.body.style.width='100%'
      this.elementRef.nativeElement.ownerDocument.body.style.height='auto'
      this.elementRef.nativeElement.ownerDocument.body.style.backgroundRepeat='no-repeat'
      this.elementRef.nativeElement.ownerDocument.body.style.backgroundSize='cover'
   }

  toggleNewPassType() {
      this.fieldTextTypeNewPass = !this.fieldTextTypeNewPass;
  }

  toggleReEnterType() {
      this.fieldTextTypeReEnter = !this.fieldTextTypeReEnter;
  }

  newPass(event: any, controlName) {
      if (event) {
          var value = event.target.value;
          if (value.match(/^\s* {1}$/)) {
              value = value.substring(0, value.length - 1);
          }
          else if (value.length >= 2 && value.substring(value.length - 1, value.length).match(/^\s* {1}$/)) {
              value = value.substring(0, value.length - 1);
          }
          this.changePassForm.get(controlName).setValue(value);
          this.changePassForm.get(controlName).markAsTouched();
          if (controlName == 'pass1') {
              this.entersNewPass = true;
              this.newPassValidation.hasLowerCase = this.changePassForm.get(controlName).value.match('.*[a-z].*') ? true : false;
              this.newPassValidation.hasUpperCase = this.changePassForm.get(controlName).value.match('.*[A-Z].*') ? true : false;
              this.newPassValidation.hasNumber = this.changePassForm.get(controlName).value.match('.*[0-9].*') ? true : false;
              this.newPassValidation.hasSpecialCharacter = this.changePassForm.get(controlName).value.match('[^A-Za-z0-9]') ? true : false;
              this.newPassValidation.hasEightLetters = this.changePassForm.get(controlName).value.length > 7? true : false;
          } else if (this.changePassForm.get(controlName).valid) {
              this.entersNewPass = false;
              this.passWordsMatch = this.changePassForm.get(controlName).value == this.changePassForm.get('pass1').value ? true : false;
          }
      }
  }

    async createNewPassword(){
    var response = await this.authService.forgotPasswordSecondStep(this.changePassForm.get('emailInput').value, this.changePassForm.get('code').value, this.changePassForm.get('pass2').value);

    if(response == "UserNotFoundException"){
        this.isEmailInCorrect = true;
        this.isCodeMismatchWhenUserException =true;
        let element = document.getElementById('username-mat-field');
        element.classList.add('mat-form-field-invalid');
        element = document.getElementById('code-mat-field');
        element.classList.add('mat-form-field-invalid');
    }
    
    if(response == "CodeMismatchException"){
        this.isCodeMismatch = true;
        let element = document.getElementById('code-mat-field');
        element.className = element.className + ' mat-form-field-invalid';
    }
    
    if(response == "LimitExceededException"){
        this.isLimitExceeded = true;
    }
    
  }

  resetUsernameError(){
      if(this.isEmailInCorrect){
        let element = document.getElementById('username-mat-field');
        element.classList.remove('mat-form-field-invalid');
        element = document.getElementById('code-mat-field');
        element.classList.remove('mat-form-field-invalid');
        this.isEmailInCorrect = false;
        this.isCodeMismatch = false;
      }
  }

  resetCodeError(){
    if(this.isCodeMismatch && !this.isEmailInCorrect){
      let element = document.getElementById('code-mat-field');
      element.classList.remove('mat-form-field-invalid');
      this.isCodeMismatch = false;
    }
    if(this.isEmailInCorrect && this.isCodeMismatchWhenUserException){
        let element = document.getElementById('username-mat-field');
        element.classList.remove('mat-form-field-invalid');
        element = document.getElementById('code-mat-field');
        element.classList.remove('mat-form-field-invalid');
        this.isEmailInCorrect = false;
        this.isCodeMismatch = false;
      }
}

resendCode(){
    var username=this.changePassForm.get("emailInput").value;
    this.authService.forgotPasswordFirstStep(username, true);
}

  ngOnInit() {}
}
