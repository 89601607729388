export class Validation {
    public static LENDER_CODE = 30;
    public static ENGLISH_NAME_LENGTH = 30;
    public static FRENCH_NAME_LENGTH = 90;
    public static MEMBER_NUMBER_LENGTH = 10;
    public static PHONE_LENGTH = 12;
    public static EXTENSION_LENGTH = 5;
    public static ADDRESS_LENGTH = 90;
    public static CITY_LENGTH = 30;
    public static POSTAL_CODE_LENGTH = 7;
    public static DIALOG_BOX_NOTES_LENGHT = 4001;
}