<app-header></app-header>
<div class="vertical-align">
    <div class="containerDetailes mt-n2 col-md-8 col-sm-8 ">
        <form [formGroup]="registerForm" class="p-5 col-sm-12">
            <h1 *ngIf="isLenderUserLoggedIn == false" id="page-title-paragraph">{{'accountDetails' | translate}}</h1>
            <br />
            <div *ngIf="isLenderUserLoggedIn == false" class="col-sm-12">
                <p>{{'accountDetailsText' | translate}}</p>
            </div>

            <div *ngIf="isLenderUserLoggedIn == true" style="font-size:large;" class="col-sm-12">
                <p>{{'userProfile' | translate}}</p>
            </div>

            <div class="form-group">
                <div class="col-sm-6">
                    <div class="row">
                        <div *ngIf="isLenderUserLoggedIn == false" class="col-sm-12 mt-4">
                            <mat-form-field>
                                <input [errorStateMatcher]="errorMatcher" [readonly]="true" type="text" maxlength="31"
                                    formControlName="englishName" class="large-input" id="english-name-input" matInput
                                    placeholder="{{'englishNameOfTheLender' | translate}}">
                                <mat-error class="error" *ngIf="registerForm.get('englishName').hasError('maxlength')">
                                    {{'error_nameOfTheLender' | translate}}</mat-error>
                                <mat-error class="error"
                                    *ngIf="registerForm.get('englishName').touched && registerForm.controls['englishName'].errors?.required">
                                    {{'required' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="isLenderUserLoggedIn == false" class="row">
                        <div class="col-sm-12 mt-4">
                            <mat-form-field>
                                <input [errorStateMatcher]="errorMatcher" [readonly]="true" maxlength="91"
                                    formControlName="frenchName" class="large-input" id="french-name-input" matInput
                                    placeholder="{{'frenchNameOfTheLender' | translate}}">
                                <mat-error class="error" *ngIf="registerForm.get('frenchName').hasError('maxlength')">
                                    {{'error_nameOfTheLender' | translate}}</mat-error>
                                <mat-error class="error"
                                    *ngIf="registerForm.get('frenchName').touched && registerForm.controls['frenchName'].errors?.required">
                                    {{'required' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="isLenderUserLoggedIn == false" class="row">
                        <div class="col-sm-12 mt-5">
                            <mat-radio-group formControlName="equifaxRadioGroup" aria-label="Select an option">
                                <label id="equifax-member-label">{{'equifaxBureaus' | translate}}</label>
                                <div>
                                    <mat-radio-button #radioEquifaxAnswerYes id="equifax-yes-radio"
                                        name="equifaxAnswerYes" value="yes">{{'yes' | translate}}</mat-radio-button>
                                    <mat-radio-button #radioEquifaxAnswerNo id="equifax-no-radio" name="equifaxAnswerNo"
                                        value="no">{{'no' | translate}}</mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div *ngIf="isDisabledCredentialEquifax && isLenderUserLoggedIn == false" class="row">
                        <div class="col-sm-12 mt-3">
                            <mat-form-field id="member-number-field">
                                <input [errorStateMatcher]="errorMatcher" [readonly]="true"
                                    pattern="[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{5}" [required]="isReqEquifax" maxlength="11"
                                    formControlName="credentialNumberEquifax" class="large-input" matInput
                                    placeholder="{{'credentialNumber' | translate}}">
                                <mat-error class="error"
                                    *ngIf="registerForm.get('credentialNumberEquifax').hasError('maxlength')">
                                    {{'error_memberId' | translate}}</mat-error>
                                <mat-error class="error"
                                    *ngIf="registerForm.controls['credentialNumberEquifax'].errors?.required">
                                    {{'required' | translate}} Ex:AAA11BBBBB</mat-error>
                                <mat-error class="error"
                                    *ngIf="registerForm.controls['credentialNumberEquifax'].errors?.pattern">
                                    {{'invalidFormat' | translate}} Ex:AAA11BBBBB</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="isLenderUserLoggedIn == false" class="row">
                        <div class="col-sm-12 mt-5">
                            <mat-radio-group formControlName="registeredTransUnionRadioGroup"
                                aria-label="Select an option">
                                <label id="transunion-member-label">{{'transUnionBureau' | translate}}</label>
                                <div>
                                    <mat-radio-button required #radioRegisteredTransUnionYes id="union-yes-radio"
                                        name="unionAnswerYes" value="yes">{{'yes' | translate}}</mat-radio-button>
                                    <mat-radio-button required #radioRegisteredTransUnionNo id="union-no-radio"
                                        name="unionAnswerNo" value="no">{{'no' | translate}}</mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div *ngIf="isDisabledCredentialTU && isLenderUserLoggedIn == false" class="row">
                        <div class="col-sm-12 mt-3">
                            <mat-form-field id="transunion-number-field">
                                <input [errorStateMatcher]="errorMatcher" [readonly]="true"
                                    pattern="[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{5}" [required]="isReqTU" maxlength="11"
                                    formControlName="credentialNumberTransUnion" class="large-input " matInput
                                    placeholder="{{'credentialNumber' | translate}}">
                                <mat-error class="error"
                                    *ngIf="registerForm.get('credentialNumberTransUnion').hasError('maxlength')">
                                    {{'error_memberId' | translate}}</mat-error>
                                <mat-error class="error"
                                    *ngIf="registerForm.controls['credentialNumberTransUnion'].errors?.required">
                                    {{'required' | translate}} Ex:AAA11BBBBB</mat-error>
                                <mat-error class="error"
                                    *ngIf="registerForm.controls['credentialNumberTransUnion'].errors?.pattern">
                                    {{'invalidFormat' | translate}} Ex:AAA11BBBBB</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="isLenderUserLoggedIn == false" class="row">
                        <div class="col-sm-12 mt-5">
                            <mat-radio-group formControlName="lenderAcceptTransUnionRadioGroup"
                                aria-label="Select an option">
                                <label id="transunion-member-label2">{{'lenderTransUnionBureau' | translate}} </label>
                                <div>
                                    <mat-radio-button required #radioLenderAcceptTransUnionYes id="union-yes-radio2"
                                        name="unionAnswerYes" value="yes">{{'yes' | translate}}</mat-radio-button>
                                    <mat-radio-button required #radioLenderAcceptTransUnionNo id="union-no-radio2"
                                        name="unionAnswerNo" value="no">{{'no' | translate}}</mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div *ngIf="isLenderUserLoggedIn == false" class="row mt-5 mb-2 ml-2">
                        <h1 id="contact-details-paragraph" class="required-label">{{'contactDetails' | translate}}</h1>
                    </div>
                    <div class="row">
                        <div *ngIf="isLenderUserLoggedIn == true" class="col-sm-6 mt-4">

                            <mat-form-field aria-disabled="true" id="first-name-field"
                                [ngClass]="{'highlight-class': mfirstName==true}">

                                <input [errorStateMatcher]="errorMatcher" id="first-name-field-lender-user" [readonly]="true"
                                    (input)="changeFieldNoSpaces($event,'firstName')" required
                                    formControlName="firstName" matInput placeholder="{{'firstName' | translate}}">
                                <mat-hint>Ex: John</mat-hint>
                                <mat-error class="error" *ngIf="registerForm.controls['firstName'].errors?.required">
                                    {{'required' | translate}}</mat-error>
                                <mat-error class="error" *ngIf="registerForm.controls['firstName'].errors?.pattern">
                                    {{'error_firstNameNumbers' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="isLenderUserLoggedIn == false" class="col-sm-6 mt-4">
                            <mat-form-field id="first-name-field" [ngClass]="{'highlight-class': mfirstName==true}">
                                <input [errorStateMatcher]="errorMatcher"
                                    (input)="changeFieldNoSpaces($event,'firstName')" required
                                    formControlName="firstName" matInput placeholder="{{'firstName' | translate}}">
                                <mat-hint>Ex: John</mat-hint>
                                <mat-error class="error" *ngIf="registerForm.controls['firstName'].errors?.required">
                                    {{'required' | translate}}</mat-error>
                                <mat-error class="error" *ngIf="registerForm.controls['firstName'].errors?.pattern">
                                    {{'error_firstNameNumbers' | translate}}</mat-error>
                            </mat-form-field>
                        </div>


                        <div *ngIf="isLenderUserLoggedIn == true" class="col-sm-6 mt-4">
                            <mat-form-field id="last-name-field-lender-user" [ngClass]="{'highlight-class': mlastName==true}">
                                <input [errorStateMatcher]="errorMatcher" [readonly]="true"
                                    (input)="changeFieldNoSpaces($event,'lastName')" required formControlName="lastName"
                                    matInput placeholder="{{'lastName' | translate}}">
                                <mat-hint>Ex: Doe</mat-hint>
                                <mat-error class="error" *ngIf="registerForm.controls['lastName'].errors?.required">
                                    {{'required' | translate}}</mat-error>
                                <mat-error class="error" *ngIf="registerForm.controls['lastName'].errors?.pattern">
                                    {{'error_lastNameNumbers' | translate}}</mat-error>
                            </mat-form-field>
                        </div>

                        <div *ngIf="isLenderUserLoggedIn == false" class="col-sm-6 mt-4">
                            <mat-form-field id="last-name-field" [ngClass]="{'highlight-class': mlastName==true}">
                                <input [errorStateMatcher]="errorMatcher"
                                    (input)="changeFieldNoSpaces($event,'lastName')" required formControlName="lastName"
                                    matInput placeholder="{{'lastName' | translate}}">
                                <mat-hint>Ex: Doe</mat-hint>
                                <mat-error class="error" *ngIf="registerForm.controls['lastName'].errors?.required">
                                    {{'required' | translate}}</mat-error>
                                <mat-error class="error" *ngIf="registerForm.controls['lastName'].errors?.pattern">
                                    {{'error_lastNameNumbers' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="isLenderUserLoggedIn == true" class="col-sm-12 mt-4 mb-2">
                            <mat-form-field id="email-field">
                                <input [errorStateMatcher]="errorMatcher" [readonly]="true" required type="email"
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$" matInput
                                    formControlName="email" placeholder="{{'email' | translate}}">
                                <mat-hint>Ex: johndoe@email.com</mat-hint>
                                <mat-error class="error" *ngIf="registerForm.controls['email'].errors?.required">
                                    {{'required' | translate}} Ex: johndoe@email.com</mat-error>
                                <mat-error class="error" *ngIf="registerForm.controls['email'].errors?.email">
                                    {{'invalidFormat' | translate}} Ex: johndoe@email.com</mat-error>
                            </mat-form-field>
                            <br>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-sm-6 mt-1">
                            <mat-form-field [ngClass]="{'highlight-class': mphone==true}">
                                <input [errorStateMatcher]="errorMatcher" (keyup)="changePhone($event.target.value)"
                                    (keydown)="keydownPhone($event)" #phone required maxlength="13" type="tel"
                                    autocomplete="tel" formControlName="phone" matInput
                                    placeholder="{{'phoneNumber' | translate}}" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}">
                                <mat-hint>Format: 111-111-1111</mat-hint>
                                <mat-error class="error" *ngIf="registerForm.get('phone').hasError('maxlength')">
                                    {{'error_phoneNumber' | translate}}</mat-error>
                                <mat-error class="error" *ngIf="registerForm.controls['phone'].errors?.required">
                                    {{'required' | translate}} Ex: 111-111-1111</mat-error>
                                <mat-error class="error" *ngIf="registerForm.controls['phone'].errors?.pattern">
                                    {{'invalidFormat' | translate}} Ex: 111-111-1111</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col col-sm-6 mt-1">
                            <mat-form-field [ngClass]="{'highlight-class': mext==true}">
                                <input [errorStateMatcher]="errorMatcher" (input)="changeExt($event)" matInput
                                    maxlength="6" pattern="[0-9]{5}" formControlName="ext"
                                    placeholder="{{'ext' | translate}}">
                                <mat-hint>{{'Optional' | translate}} Ex:12345</mat-hint>
                                <mat-error class="error" *ngIf="registerForm.get('ext').hasError('maxlength')">
                                    {{'error_maxNumberReached' | translate}}</mat-error>
                                <mat-error class="error" *ngIf="registerForm.controls['ext'].errors?.pattern">
                                    {{'invalidFormat' | translate}} Ex:12345</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div *ngIf="isLenderUserLoggedIn == false" class="col-sm-12 mt-4 mb-2">
                            <mat-form-field id="email-field">
                                <input [errorStateMatcher]="errorMatcher" [readonly]="true" required type="email"
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$" matInput
                                    formControlName="email" placeholder="{{'email' | translate}}">
                                <mat-hint>Ex: johndoe@email.com</mat-hint>
                                <mat-error class="error" *ngIf="registerForm.controls['email'].errors?.required">
                                    {{'required' | translate}} Ex: johndoe@email.com</mat-error>
                                <mat-error class="error" *ngIf="registerForm.controls['email'].errors?.email">
                                    {{'invalidFormat' | translate}} Ex: johndoe@email.com</mat-error>
                            </mat-form-field>
                        </div>
                    </div>


                    <div *ngIf="isLenderUserLoggedIn == false" class="row mt-5 mb-2 ml-2">
                        <h1 id="address-details-paragraph" class="required-label">{{'addressInformation' | translate}}
                        </h1>
                    </div>
                    <div class="row">
                        <div *ngIf="isLenderUserLoggedIn == false" class="col-sm-12 mt-3">
                            <app-address-component (getSelectedItem)="setAddressValues($event)">
                            </app-address-component>
                        </div>
                        <div *ngIf="isLenderUserLoggedIn == false" class="col-sm-12 mt-1">
                            <mat-form-field id="address-line1-field"
                                [ngClass]="{'highlight-class': maddressLine1==true}">
                                <input [errorStateMatcher]="errorMatcher" id="address1"
                                    (input)="changeFieldNoSpaces($event,'addressLine1')" required maxlength="91"
                                    matInput formControlName="addressLine1"
                                    placeholder="{{'addressLine1' | translate}}">
                                <mat-hint>{{'max90Chars' | translate}}</mat-hint>
                                <mat-error class="error" *ngIf="registerForm.get('addressLine1').hasError('maxlength')">
                                    {{'error_address' | translate}}</mat-error>
                                <mat-error class="error" *ngIf="registerForm.controls['addressLine1'].errors?.required">
                                    {{'required' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="isLenderUserLoggedIn == false" class="col-sm-12 mt-3">
                            <mat-form-field id="address-line2-field"
                                [ngClass]="{'highlight-class': maddressLine2==true}">
                                <input [errorStateMatcher]="errorMatcher" id="address2"
                                    (input)="changeFieldNoSpaces($event,'addressLine2')" required maxlength="91"
                                    matInput formControlName="addressLine2"
                                    placeholder="{{'addressLine2' | translate}}">
                                <mat-hint>{{'max90Chars' | translate}}</mat-hint>
                                <mat-error class="error" *ngIf="registerForm.get('addressLine2').hasError('maxlength')">
                                    {{'error_address' | translate}}</mat-error>
                                <mat-error class="error" *ngIf="registerForm.controls['addressLine2'].errors?.required">
                                    {{'required' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="isLenderUserLoggedIn == false" class="col-sm-6 mt-4">
                            <mat-form-field id="city-field" [ngClass]="{'highlight-class': mcity==true}">
                                <input [errorStateMatcher]="errorMatcher" id="city"
                                    (input)="changeFieldNoSpaces($event,'city')" required maxlength="91" matInput
                                    formControlName="city" placeholder="{{'city' | translate}}">
                                <mat-hint>{{'max30Chars' | translate}}</mat-hint>
                                <mat-error class="error" *ngIf="registerForm.get('city').hasError('maxlength')">
                                    {{'error_cityName' | translate}}</mat-error>
                                <mat-error class="error" *ngIf="registerForm.controls['city'].errors?.required">
                                    {{'required' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="isLenderUserLoggedIn == false" class="col-sm-6 mt-4">
                            <mat-form-field id="postal-code-field" [ngClass]="{'highlight-class': mpostalCode==true}">
                                <input [errorStateMatcher]="errorMatcher" id="postcode" (keyup)="changePC($event)"
                                    (keydown)="keydownPC($event)" required maxlength="8" matInput
                                    formControlName="postalCode"
                                    pattern="[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1} [0-9]{1}[a-zA-Z]{1}[0-9]{1}"
                                    placeholder="{{'postalCode' | translate}}">
                                <mat-hint>Ex: L1R 3M4 ({{'max7Chars' | translate}})</mat-hint>
                                <mat-error class="error" *ngIf="registerForm.controls['postalCode'].errors?.maxLength">
                                    {{'error_postalCode' | translate}}</mat-error>
                                <mat-error class="error" *ngIf="registerForm.controls['postalCode'].errors?.required">
                                    {{'required' | translate}} Ex: L1R 3M4 ({{'max7Chars' | translate}})</mat-error>
                                <mat-error class="error" *ngIf="registerForm.controls['postalCode'].errors?.pattern">
                                    {{'invalidFormat' | translate}} Ex: L1R 3M4 ({{'max7Chars' | translate}})
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row ">
                        <div *ngIf="isLenderUserLoggedIn == false" class="col-sm-6 mt-1">
                            <mat-form-field class="md-form" id="province-field"
                                [ngClass]="{'highlight-class': mprovince==true}">
                                <mat-label>{{'province' | translate}}</mat-label>
                                <mat-select required formControlName="province"
                                    (selectionChange)="changeProvince($event)">
                                    <mat-option *ngFor="let elem of provinces" [value]="elem.name">{{elem.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="isLenderUserLoggedIn == false" class="col-sm-6 mt-1">
                            <mat-form-field class="md-form" id="region-field"
                                [ngClass]="{'highlight-class': modifiedFields==true}">
                                <input id="country-input" matInput readonly placeholder="{{'country' | translate}}"
                                    value="Canada" />
                            </mat-form-field>
                        </div>
                    </div>

                </div>

                <div class="border-space">
                </div>

                <div class="mt-4">
                </div>

                <div class="d-inline-block">
                    <div *ngIf="existingLenderUsers && isLenderAdminLoggedIn == true" class="user-div d-flex mb-2 row">
                        <div *ngFor="let user of existingLenderUserList" class="user-details-div d-flex">
                            <div class="user-contactDetails-div">
                                {{user.contactDetails.firstName + " " + user.contactDetails.lastName + " | " +
                                user.contactDetails.email + " | " + user.contactDetails.phoneNumber + " | " +
                                user.profileType }}
                            </div>
                            <div id="edit-userbtn-div">
                                <button id="edit-user-button" mat-stroked-button color="primary" dense
                                    (click)="onEditExistingUser(user.id)">
                                    <span class="material-icons"
                                        style="font-size:16px; margin-right:8px; margin-bottom: 3px">
                                        edit
                                    </span> {{'edit' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="user-div d-flex mb-2 row">
                        <div *ngFor="let user of userLendersList" class="user-details-div d-flex">
                            <div class="user-contactDetails-div">
                                {{user.firstName + " " + user.lastName + " | " + user.email + " | " + user.phone + " | "
                                + user.profileType }}
                            </div>
                            <div id="edit-userbtn-div">
                                <button id="edit-user-button" mat-stroked-button color="primary" dense
                                    (click)="onEditExistingUser(user.id)">
                                    <span class="material-icons"
                                        style="font-size:16px; margin-right:8px; margin-bottom: 3px">
                                        edit
                                    </span> {{'edit' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="isLenderAdminLoggedIn == true" class="row col-sm-12">
                    <button id="add-user-button" mat-stroked-button color="primary" dense (click)="onAddUser()">
                        <span class="material-icons" style="font-size:20px; margin-right:8px; margin-bottom: 3px">
                            add
                        </span> {{'addUser' | translate}}
                    </button>
                </div>

                <div class="d-flex end">
                    <div class="col-sm-6"></div>
                    <div>
                        <button id="cancel-button" *ngIf="isNavigatingToDashboard" (click)="onCancel()"
                            mat-stroked-button
                            style="font-family: sans-serif; font-size: 13px; background-color: #C137A2; color:white; font-weight: bold">
                            {{'cancel' | translate}}
                        </button>
                        <a href="/leads" *ngIf="!isNavigatingToDashboard">
                            <button id="cancel-button" (click)="onCancel()" mat-stroked-button
                                style="font-family: sans-serif; font-size: 13px; background-color: #C137A2; color:white; font-weight: bold">
                                {{'cancel' | translate}}
                            </button>
                        </a>
                    </div>
                    <div class="col-sm-6">

                        <button *ngIf="isLenderUserLoggedIn === true" #submitButton
                            [disabled]="this.lenderUserValidationRegisterForm == false && this.testPhoneValid == false || this.testExtValid == false"
                            id="save-button" type="submit" (click)="onSubmit()" mat-stroked-button dense
                            style="font-family: sans-serif;font-weight: bold; font-size: 13px; background-color:#694ED6; color:white">
                            {{'save' | translate}}
                        </button>

                        <button *ngIf="isLenderUserLoggedIn === false" #submitButton [disabled]="registerForm.invalid"
                            id="save-button" type="submit" (click)="onSubmit()" mat-stroked-button dense
                            style="font-family: sans-serif;font-weight: bold; font-size: 13px; background-color:#694ED6; color:white">
                            {{'save' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

</div>
<app-footer>
</app-footer>