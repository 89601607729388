import { Component, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/security/auth.service';
import { Router } from '@angular/router';
import { SessionExtensionService } from 'src/app/services/session-extension-service/session-extension.service';
import { ErrorStateMatcher } from "@angular/material/core";
import { MatDialog } from '@angular/material/dialog';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.touched && control.invalid;
    }
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {

    public loginForm: FormGroup;
    public fieldTextType: boolean;
    public validCredentials: boolean = true;

    constructor(public dialog: MatDialog, private sessionExtensionsService: SessionExtensionService, private formBuilder: FormBuilder, private authService: AuthService, private router: Router, private elementRef: ElementRef) {
        this.loginForm = this.formBuilder.group({
            emailInput: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$")]],
            password: ['', [Validators.required]]
        });
    }

    ngAfterViewInit(){
        this.elementRef.nativeElement.ownerDocument.body.style.background = "url('../assets/login-backgroung.png') center center fixed";
        this.elementRef.nativeElement.ownerDocument.body.style.width='100%'
        this.elementRef.nativeElement.ownerDocument.body.style.height='auto'
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundRepeat='no-repeat'
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundSize='cover'
    }

    ngOnDestroy() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundSize='0px'
    }

    changeFieldNoSpaces(event: any, name){
        if (event) {
            var value = event.target.value;
            if (value.match(/^\s* {1}$/)) {
                value = value.substring(0, value.length - 1);
            }
            else if (value.length >= 2 && value.substring(value.length - 1, value.length).match(/^\s* {1}$/)) {
                value = value.substring(0, value.length - 1);
            }
            this.loginForm.get(name).setValue(value);
            this.loginForm.get(name).markAsTouched();
        }
    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    async login(){
        var username=this.loginForm.get("emailInput").value;
        var password=this.loginForm.get("password").value;
        var response = await this.authService.startAuthentication(username, password);
        if(response.accessKeyId=="User not found"){
            this.validCredentials= false;
        } else {
            this.validCredentials= true;
            this.sessionExtensionsService.onIdle();
            console.log("--------------LOGGING IN-------------");
        }
    }
}