<div class="rowsNumber" aria-label="Rows per page">{{'rowsPerPage' | translate}}:</div>

<mat-select class="pageSize page-number-class" [(value)]="selectedValue" name="pageSize"
    (selectionChange)="onChangeSize($event)" aria-label="Rows per page">
    <mat-option *ngFor="let size of pageSizes" [value]="size.value">
        <!-- This data is not expoitable beeing an object of tipe Size declared by us containing parameters of type number -->
        {{getSizeValue(size)}}
    </mat-option>
</mat-select>

<div class="pageNumber">Page: {{getCurrentPage()}} / {{getNumberOfPages()}}</div>
<button [ngClass]="{'paginator-button': isFocusedLeft == true}" class="paginator-button-div"
    (keydown)="arrowLeftEvent($event)">
    <img class="paginator-icon" [class.disabledClass]=checkPrev() src="../assets/back.svg" (click)="prev()"
        aria-label="Previous page">
</button>
<button [ngClass]="{'paginator-button': isFocusedRight == true}" class="paginator-button-div"
    (keydown)="arrowRightEvent($event)">
    <img class="paginator-icon" [class.disabledClass]=checkNext() src="../assets/next.svg" (click)="next()"
        aria-label="Next page">
</button>