import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoansService } from '../../services/api/loans.service';
import { AuthGuardService } from 'src/app/services/security/auth-guard.service';
import { AppConfigService } from 'src/app/services/config-service/app-config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from 'src/app/services/language-service/language.service';

@Component({
  selector: 'app-credit-bureau',
  templateUrl: './credit-bureau.component.html',
  styleUrls: ['./credit-bureau.component.scss']
})

export class CreditBureauComponent implements OnInit {

  constructor(private languageService: LanguageService, private cookieService: CookieService, private http: HttpClient,private router : Router, private loansService: LoansService, private authService: AuthGuardService) { }
  public creditBureauReportText: string;
  public datePulled: string;
  public creditBureauNameDd: string;
  public applicationId: string;
  public clientName: string;

  public creditBureaus: any;
  public isFirst: boolean;

  ngOnInit() {
    this.isFirst = true;
    if (this.router.url != "/") {
      this.loansService.getCreditBureauReportText(this.router.url.split('/creditBureau?id=')[1].split('_')[0]).subscribe(data => {
        this.creditBureaus = data;
      })
      this.applicationId = this.router.url.split('/creditBureau?id=')[1].split('_')[1];
      this.clientName = this.router.url.split('/creditBureau?id=')[1].split('_')[2].replace(/[^a-zA-Z]/g, '');  
    }
  }

  public getCreditBureaus() {
    if (this.creditBureaus) {
      return this.creditBureaus;
    }
  }

  public getApplicationId() {
    return this.applicationId;
  }

  public getCreditBureauReportText(creditBureau) {
    if (creditBureau != null && creditBureau != undefined) {
      this.creditBureauReportText = creditBureau.creditReport.creditReportText;
      var re = /MONTHLY PAYMENTS/gi;
      this.creditBureauReportText = this.creditBureauReportText.replace(re,"\nMONTHLY PAYMENTS");
      re = /TOTAL \nMONTHLY PAYMENTS/gi;
      this.creditBureauReportText = this.creditBureauReportText.replace(re,"TOTAL MONTHLY PAYMENTS");

      this.isFirst = false;
      return this.creditBureauReportText;
    }
  }

  public getDatePulled(creditBureau) {
    if (creditBureau != null && creditBureau != undefined) {
      var date = new Date(creditBureau.datePulled);
      if (date.toString() != "Invalid Date") {
        return date.toLocaleDateString().replace('/', '-').replace('/', '-') + " " + date.toLocaleTimeString();
      }
    }
  }

  public getCreditBureauNameDd(creditBureau) {
    if(creditBureau != null && creditBureau != undefined){
      if (creditBureau.creditBureauNameDd[0].text == "Equifax") {
        return "Equifax Canada Inc.";
      }else {
        return "TransUnion";
      }
    }
  }

  public convertToPDF() {
    const url = AppConfigService.settings.apiEndpoint_pdfService + '/api/generate-pdf?url=' +
      encodeURIComponent(`${window.location.href
        .replace('creditBureau', 'creditBureauPrint')}&authorizationtoken=${this.cookieService.get('IdToken')}&lang=${this.languageService.getLanguage()}`);
    const mediaTypeDownloadPdf = 'application/pdf';
    this.http.get(url, {
      responseType: 'arraybuffer', headers: new HttpHeaders({
        'authorizationtoken': this.cookieService.get('IdToken'),
        'Accept': mediaTypeDownloadPdf
      })
    }
    ).subscribe(response => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const downloadUrl = window.URL.createObjectURL(blob);
      a.href = downloadUrl;
      a.download = `CreditBureau-${this.clientName}.pdf`;
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
    });
  }
}
