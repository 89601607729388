<app-header></app-header>
<div class="vertical-align">
    <div class="container mt-n2 col-md-8 col-sm-8">
        <form class="p-5 col-sm-12" [formGroup]="registerForm">
            <div class="col-sm-6">
                <div class="row col-sm-12">
                    <h1 id="page-title-paragraph">Account Details</h1>
                </div>
                <div class="row">
                    <div class="col-sm-12 mt-4">
                        Lender Code: {{displayLenderCode}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mt-2">
                        <mat-form-field [ngClass]="{'highlight-class': menglishName==true}">
                            <input [errorStateMatcher]="errorMatcher" (input)="changeFieldNoSpaces($event,'englishName')" type="text" required maxlength="31" formControlName="englishName" id="english-name-input" matInput placeholder="English name of Lender">
                            <mat-hint>English (max 30 characters)</mat-hint>
                            <mat-error *ngIf="registerForm.get('englishName').hasError('maxlength')">Max number of characters was reached. Please make sure you typed-in the correct Name of the Lender.</mat-error>
                            <mat-error *ngIf="registerForm.get('englishName').touched && registerForm.controls['englishName'].errors?.required">Required</mat-error>
                            <mat-error *ngIf="registerForm.get('englishName').hasError('pattern')">Invalid format for English name</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mt-4">
                        <mat-form-field [ngClass]="{'highlight-class': mfrenchName==true}">
                            <input [errorStateMatcher]="errorMatcher" (input)="changeFieldNoSpaces($event,'frenchName')" required maxlength="91" formControlName="frenchName" id="french-name-input" matInput placeholder="French name of Lender">
                            <mat-hint>French (max 90 characters)</mat-hint>
                            <mat-error *ngIf="registerForm.get('frenchName').hasError('maxlength')">Max number of characters was reached. Please make sure you typed-in the correct Name of the Lender.</mat-error>
                            <mat-error *ngIf="registerForm.get('frenchName').touched && registerForm.controls['frenchName'].errors?.required">Required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mt-5">
                        <mat-radio-group required formControlName="equifaxRadioGroup" aria-label="Select an option">
                            <label [ngClass]="{'highlight-class': mequifaxRadioGroup==true}" id="equifax-member-label">Registered to receive Equifax bureaus?</label>
                            <div id="equifax-options-div">
                                <mat-radio-button #radioEquifaxAnswerYes id="equifax-yes-radio" name="equifaxAnswerYes" value="yes" (change)="onRegisteredEquifax()">Yes</mat-radio-button>
                                <mat-radio-button #radioEquifaxAnswerNo id="equifax-no-radio" name="equifaxAnswerNo" value="no" (change)="onNotRegisteredEquifax()">No</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12" *ngIf="registeredEquifax">
                        <mat-form-field id="member-number-field" [ngClass]="{'highlight-class': mcredentialNumber1==true}">
                            <input [errorStateMatcher]="errorMatcher" pattern="[0-9]{3}[a-zA-Z]{2}[0-9]{5}" (input)="changeCredential($event,'credentialNumberEquifax')" [required]="isReqEquifax" maxlength="11" formControlName="credentialNumberEquifax" matInput placeholder="Credential number">
                            <mat-error *ngIf="registerForm.get('credentialNumberEquifax').hasError('maxlength')">Max number of characters was reached. Please make sure you typed-in the correct Member Id.</mat-error>
                            <mat-error *ngIf="registerForm.controls['credentialNumberEquifax'].errors?.required">Required Ex:111AA22222</mat-error>
                            <mat-error *ngIf="registerForm.controls['credentialNumberEquifax'].errors?.pattern">Invalid format Ex:111AA22222</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mt-5">
                        <mat-radio-group formControlName="registeredTransUnionRadioGroup" aria-label="Select an option">
                            <label [ngClass]="{'highlight-class': mTURadioGroup==true}" id="transunion-member-label">Registered to receive TransUnion bureaus?</label>
                            <div id="union-options-div">
                                <mat-radio-button required #radioRegisteredTransUnionYes id="union-yes-radio" name="unionAnswerYes" value="yes" (change)="onRegisteredTransUnion()">Yes</mat-radio-button>
                                <mat-radio-button required #radioRegisteredTransUnionNo id="union-no-radio" name="unionAnswerNo" value="no" (change)="onNotRegisteredTransUnion()">No</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <mat-form-field id="transunion-number-field" [ngClass]="{'highlight-class': mcredentialNumber2==true}" *ngIf="registeredTransUnion">
                            <input [errorStateMatcher]="errorMatcher" pattern="[0-9]{3}[a-zA-Z]{2}[0-9]{5}" (input)="changeCredential($event,'credentialNumberTransUnion')" [required]="isReqTU" maxlength="11" formControlName="credentialNumberTransUnion" class="large-input " matInput placeholder="Credential number">
                            <mat-error *ngIf="registerForm.get('credentialNumberTransUnion').hasError('maxlength')">Max number of characters was reached. Please make sure you typed-in the correct Member Id.</mat-error>
                            <mat-error *ngIf="registerForm.controls['credentialNumberTransUnion'].errors?.required">Required Ex:111AA22222</mat-error>
                            <mat-error *ngIf="registerForm.controls['credentialNumberTransUnion'].errors?.pattern">Invalid format  Ex:111AA22222</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mt-5">
                        <mat-radio-group formControlName="lenderAcceptTransUnionRadioGroup" class="transunion-radio-group2" aria-label="Select an option">
                            <label [ngClass]="{'highlight-class': mTURadioGroup2==true}" id="transunion-member-label2">Does Lender accept TransUnion bureaus? </label>
                            <div id="union-options-div2">
                                <mat-radio-button required #radioLenderAcceptTransUnionYes id="union-yes-radio2" name="unionAnswerYes" value="yes">Yes</mat-radio-button>
                                <mat-radio-button required #radioLenderAcceptTransUnionNo id="union-no-radio2" name="unionAnswerNo" value="no">No</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="justify-content-start d-flex">
                    <div class="mt-4 mr-3">
                        <mat-radio-group formControlName="finastraRadioGroup" class="finastra-radio-group" aria-label="Select an option">
                            <label [ngClass]="{'highlight-class': mfinastraRadioGroup==true}" id="finastra-member-label">Finastra contracts completed?</label>
                            <div id="finastra-options-div">
                                <mat-radio-button #radioFinastraAnswerYes required id="finastra-yes-radio" name="finastraAnswerYes" value="yes">Yes</mat-radio-button>
                                <mat-radio-button #radioFinastraAnswerNo required id="finastra-no-radio" name="finastraAnswerNo" value="no">No</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                    <div class="mt-4">
                        <mat-radio-group formControlName="vettingRadioGroup" class="vetting-radio-group" aria-label="Select an option">
                            <label [ngClass]="{'highlight-class': mvettingRadioGroup==true}" id="vetting-member-label">Lender vetting completed?</label>
                            <div id="vetting-options-div">
                                <mat-radio-button #radioVettingAnswerYes required id="vetting-yes-radio" name="vettingAnswerYes" value="yes">Yes</mat-radio-button>
                                <mat-radio-button #radioVettingAnswerNo required id="vetting-no-radio" name="vettingAnswerNo" value="no">No</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="row mt-5 mb-2 ml-2">
                    <h1 id="contact-details-paragraph">Contact Details</h1>
                </div>
                <div class="row">
                    <div class="col-sm-6 mt-2">
                        <mat-form-field id="first-name-field" [ngClass]="{'highlight-class': mfirstName==true}">
                            <input [errorStateMatcher]="errorMatcher" (input)="changeFieldNoSpaces($event,'firstName')" required formControlName="firstName" matInput placeholder="First name">
                            <mat-hint>Ex: John</mat-hint>
                            <mat-error *ngIf="registerForm.controls['firstName'].errors?.required">Required</mat-error>
                            <mat-error *ngIf="registerForm.controls['firstName'].errors?.pattern">First name cannot contain numbers</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6 mt-2">
                        <mat-form-field id="last-name-field" [ngClass]="{'highlight-class': mlastName==true}">
                            <input [errorStateMatcher]="errorMatcher" (input)="changeFieldNoSpaces($event,'lastName')" required formControlName="lastName" matInput placeholder="Last name">
                            <mat-hint>Ex: Doe</mat-hint>
                            <mat-error *ngIf="registerForm.controls['lastName'].errors?.required">Required</mat-error>
                            <mat-error *ngIf="registerForm.controls['lastName'].errors?.pattern">Last name cannot contain numbers</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-8 mt-4">
                        <mat-form-field id="phone-field" [ngClass]="{'highlight-class': mphone==true}">
                            <input [errorStateMatcher]="errorMatcher" (keyup)="changePhone($event.target.value)" (keydown)="keydownPhone($event)" #phone required maxlength="13" type="tel" autocomplete="tel" formControlName="phone" matInput placeholder="Phone number" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}">
                            <mat-hint>Format: 111-111-1111</mat-hint>
                            <mat-error *ngIf="registerForm.get('phone').hasError('maxlength')">Max number of characters was reached. Please make sure you typed-in the correct Phone Number.</mat-error>
                            <mat-error *ngIf="registerForm.controls['phone'].errors?.required">Required Ex: 111-111-1111</mat-error>
                            <mat-error *ngIf="registerForm.controls['phone'].errors?.pattern">Invalid format Ex: 111-111-1111</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-4 mt-4">
                        <mat-form-field id="ext-field" [ngClass]="{'highlight-class': mext==true}">
                            <input [errorStateMatcher]="errorMatcher" (input)="changeContactField($event,'ext')" matInput maxlength="6" pattern="[0-9]{5}" formControlName="ext" placeholder="Ext.">
                            <mat-hint>Optional   Ex:12345</mat-hint>
                            <mat-error *ngIf="registerForm.get('ext').hasError('maxlength')">Max number of characters was reached. </mat-error>
                            <mat-error *ngIf="registerForm.controls['ext'].errors?.pattern">Invalid format Ex:12345</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mt-4 mb-2">
                        <mat-form-field id="email-field" [ngClass]="{'highlight-class': memail==true}">
                            <input [errorStateMatcher]="errorMatcher" (input)="changeContactField($event,'email')" required type="email" pattern="[a-zA-Zàâçéèêëîïôûùüÿñ0-9._%+-]+@[a-zA-Zàâçéèêëîïôûùüÿñ0-9.-]+[.]+[a-zA-Zàâçéèêëîïôûùüÿñ]{2,3}$" matInput formControlName="email" placeholder="Email">
                            <mat-hint>Ex: johndoe@email.com</mat-hint>
                            <mat-error *ngIf="registerForm.controls['email'].errors?.required">Required Ex: johndoe@email.com</mat-error>
                            <mat-error *ngIf="registerForm.controls['email'].errors?.email">Invalid format of email Ex: johndoe@email.com</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-5 mb-2 ml-2">
                    <h1 id="address-details-paragraph">Address information</h1>
                </div>
                <div>
                    <app-address-component (getSelectedItem)="setAddressValues($event)">
                    </app-address-component>
                </div>
                <div class="row">
                    <div class="col-sm-12 mt-1">
                        <mat-form-field id="address-line1-field" [ngClass]="{'highlight-class': maddressLine1==true}">
                            <input [errorStateMatcher]="errorMatcher" id="address1" (input)="changeFieldNoSpaces($event,'addressLine1')" required maxlength="91" matInput formControlName="addressLine1" placeholder="Address line 1">
                            <mat-hint>max 90 characters</mat-hint>
                            <mat-error *ngIf="registerForm.get('addressLine1').hasError('maxlength')">Max number of characters was reached. Please make sure you typed-in the correct Address.</mat-error>
                            <mat-error *ngIf="registerForm.controls['addressLine1'].errors?.required">Required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mt-4">
                        <mat-form-field id="address-line2-field" [ngClass]="{'highlight-class': maddressLine2==true}">
                            <input [errorStateMatcher]="errorMatcher" id="address2" (input)="changeFieldNoSpaces($event,'addressLine2')" maxlength="91" matInput formControlName="addressLine2" placeholder="Address line 2">
                            <mat-hint>max 90 characters</mat-hint>
                            <mat-error *ngIf="registerForm.get('addressLine2').hasError('maxlength')">Max number of characters was reached. Please make sure you typed-in the correct Address.</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 mt-4">
                        <mat-form-field id="city-field" [ngClass]="{'highlight-class': mcity==true}">
                            <input [errorStateMatcher]="errorMatcher" id="city" (input)="changeContactField($event,'city')" required maxlength="91" matInput formControlName="city" placeholder="City">
                            <mat-hint>max 30 characters</mat-hint>
                            <mat-error *ngIf="registerForm.get('city').hasError('maxlength')">Max number of characters was reached. Please make sure you typed-in the correct City name.</mat-error>
                            <mat-error *ngIf="registerForm.controls['city'].errors?.required">Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6 mt-4">
                        <mat-form-field id="postal-code-field" [ngClass]="{'highlight-class': mpostalCode==true}">
                            <input [errorStateMatcher]="errorMatcher" id="postcode" (keyup)="changePC($event.target.value)" (keydown)="keydownPC($event)" required maxlength="8" matInput formControlName="postalCode" pattern="[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1} [0-9]{1}[a-zA-Z]{1}[0-9]{1}" placeholder="Postal code">
                            <mat-hint>Ex:  L1R 3M4 (max 7 characters)</mat-hint>
                            <mat-error *ngIf="registerForm.controls['postalCode'].errors?.maxLength">Max number of characters was reached. Please make sure you typed-in the correct Postal Code.</mat-error>
                            <mat-error *ngIf="registerForm.controls['postalCode'].errors?.required">Required Ex:  L1R 3M4 (max 7 characters)</mat-error>
                            <mat-error *ngIf="registerForm.controls['postalCode'].errors?.pattern">Invalid format Ex:  L1R 3M4 (max 7 characters)</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 mt-4">
                        <mat-form-field id="province-field" [ngClass]="{'highlight-class': mprovince==true}">
                            <mat-label>Province</mat-label>
                            <mat-select required formControlName="province" (selectionChange)="changeProvince($event)">
                                <mat-option *ngFor="let elem of provinces" [value]="elem.name">{{elem.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6 mt-4">
                        <mat-form-field id="region-field" [ngClass]="{'highlight-class': false}">
                            <input id="country-input" matInput readonly placeholder="Country" value="Canada" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div style="margin-left: 20px;">
                <div class="row col-sm-12">
                    <p id="users-paragraph">Users</p>
                </div>
                <div class="d-inline-block">
                    <div *ngIf="existingLenderUsers" class="user-div d-flex row">
                        <div *ngFor="let user of existingLenderUserList" class="user-details-div d-flex">
                            <div class="user-contactDetails-div">
                                {{user.contactDetails.firstName + " " + user.contactDetails.lastName + " | " + user.contactDetails.email + " | " + user.contactDetails.phoneNumber  + " | " + user.profileType }}
                            </div>
                            <div id="edit-userbtn-div">
                                <button id="edit-user-button" mat-stroked-button color="primary" dense (click)="onEditExistingUser($event, user.id)">
                                    <span class="material-icons" style="font-size:16px; margin-right:8px; margin-bottom: 3px">
                                        edit
                                    </span> {{'edit' | translate}}
                                </button>
                            </div>
                        </div>
    
                    </div>
                </div>
                
                <div class="row col-sm-12">
                    <button id="add-user-button" mat-stroked-button color="primary" dense (click)="onAddUser($event)">
                        <span class="material-icons" style="font-size:20px; margin-right:8px; margin-bottom: 3px">
                            add
                        </span> {{'addUser' | translate}}
                    </button>
                </div>
            </div>

                <div class="border-space">
                </div>

                <div class="mt-4">
                </div>

                <div class="d-flex end">
                    <div>
                        <button id="cancel-button" (click)="onCancel()" mat-stroked-button  style="font-family: sans-serif; font-size: 13px; background-color: #C137A2; color:white">
                            Cancel
                        </button>
                    </div>
                    <div class="ml-3 mr-3">
                        <button #submitButton [disabled]="registerForm.invalid" id="save-button" type="submit" (click)="onSubmit()" mat-stroked-button  dense style="font-family: sans-serif; font-size: 13px; background-color:#694ED6; color:white; ">
                            Save
                        </button>
                    </div>
                    <div>
                        <button #publishButton *ngIf="!sentToExpertFromDb" [disabled]="registerForm.invalid || ((!finastraAnswerYes.checked && !vettingAnswerYes.checked) || (!finastraAnswerYes.checked && vettingAnswerYes.checked) || (finastraAnswerYes.checked && !vettingAnswerYes.checked)) || buttonDisabledP" id="publish-button" (click)="onPublish()" mat-flat-button color="primary" dense style="font-family: sans-serif; font-size: 13px;font-weight: bold">
                            <i id="arrow-icon" class="fa fa-long-arrow-right"></i>
                            Publish
                        </button>
                    </div>
                </div>
        
        </form>
      </div>
</div>