import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { Subject } from 'rxjs';
 
@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private subject = new Subject<any>();
  private hubConnection: signalR.HubConnection
 
  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
                            .withUrl('https://p01d24201506001.p01d24201501001.c01.dev.ess.dh.com/api')
                            .build(); 
 
    const subjectThat = this.subject;
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err));

      this.hubConnection.on('Send', (data1, data2) => {
        subjectThat.next({ text: data1 + ' ' + data2 });
      });
  }
  
  public getSubject() {
    return this.subject;
  }
}