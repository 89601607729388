<meta name="viewport" content="width=device-width, initial-scale=1">
<div id="whole-header" style="min-width: 100%!important;max-width: 100%!important;">
    <nav class="navbar navbar-expand-lg navbar-light bg-light" id="navbar" style="min-width: 100%!important;max-width: 100%!important;">
        <div tabindex="0" class="filogix-logo">
            <img alt="Filogix Logo" src="{{'logoSrc' | translate}}" id="filogix-logo" role="banner">
        </div>

        <div id="full-breadcrumb" style="min-width: 80%!important;max-width: 80%!important;">
            <ul style="min-width: 85%!important;max-width: 85%!important;">
                <li class="uxg-h6" id="page-route" style="min-width: 85%!important;max-width: 85%!important;">
                   <span aria-label="Go to Home Page" role="link" (click)="goToHomePage()"><a> {{'filogixPrivateLenderGateway' | translate}} </a></span> <div>/</div> {{href}}
                    <app-breadcrumb id="second-breadcrumb">
                    </app-breadcrumb>
                    </li>
            </ul>
        </div>

        <div id="symbols">
            <div *ngIf="getUser()" style="display: flex; margin-top: 9px; margin-right: 20px;">
                 <div tabindex="0" aria-label="english" (click)="switchEnglish()" [ngClass]="{'toggleLanguageSelected': languageService.getLanguage() == 'en', 'toggleLanguageNotSelected': languageService.getLanguage() != 'en'}"> 
                    <p alt="En" width="500" height="600"></p>
                         <p aria-readonly="true"></p> 
                 </div> 
                    <a style="color: #694ed6;" (click)="switchEnglish()" [ngClass]="{'toggleLanguageSelected': languageService.getLanguage() == 'en', 'toggleLanguageNotSelected': languageService.getLanguage() != 'en'}">En</a>   
               
                <div style="font-weight:600; color: #694ed6;">|</div>

                    <div tabindex="0" aria-label="french" (click)="switchFrench()" [ngClass]="{'toggleLanguageSelected': languageService.getLanguage() == 'fr', 'toggleLanguageNotSelected': languageService.getLanguage() != 'fr'}">
                        <p alt="Fr" width="500" height="600"></p>
                            <p aria-readonly="true"></p> 
                    </div>
                    <a style="color: #694ed6;" (click)="switchFrench()" [ngClass]="{'toggleLanguageSelected': languageService.getLanguage() == 'fr', 'toggleLanguageNotSelected': languageService.getLanguage() != 'fr'}">Fr</a>   
            </div>

            <header tabindex="0" *ngIf="showBell" id="alert-button">
                <i class="fa fa-bell" *ngIf="!newLoanData" style='font-size:19px'>
                </i>
                <i class="fa fa-bell notification" *ngIf="newLoanData" (click)="onAlert($event)" style='font-size:19px; position: relative'>
                </i>
            </header>
            <app-dropdown-menu></app-dropdown-menu>
        </div>
    </nav>

    <div id="colored-bar" style="max-width:100%;min-width:100%!important;">
        <h1 id="page-name" style="font-size:37px;">
            {{lenderName}}
            <app-breadcrumb id="breadcrumb-2">
            </app-breadcrumb>
        </h1>
    </div>
</div>


