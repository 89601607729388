export class PaidLoansReportCSVDTO {
    PaidLeadID: string;
    Date: Date;
    LenderCode: string;
    LenderName: string;

    constructor(applicationId: string, date: Date, lenderCode: string, lenderName: string) {
        this.PaidLeadID = applicationId;
        this.Date = date;
        this.LenderCode = lenderCode;
        this.LenderName = lenderName;
    }

}