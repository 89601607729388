import { Injectable } from '@angular/core';
import { AppConfigModel } from './app-config.model';
import { HttpClient } from '@angular/common/http';
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    static settings: AppConfigModel;
    static loaded = new Subject();
    constructor(private http: HttpClient) { }

    loadAppConfig() {
        const cacheBusterParam = (new Date()).getTime();
        const jsonFile = `./assets/config.json?nocache=${cacheBusterParam}`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise()
                .then((response: AppConfigModel) => {
                    AppConfigService.settings = <AppConfigModel>response;
                    AppConfigService.loaded.next();
                    resolve();
                }).catch((response: any) => {
                    reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
                });
        });
    }
} 
