import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class Settings{

    private static instance: Settings = null;
  
    // Settings
    public static TOKEN_TYPE = 'Bearer';

    constructor() {}

     public static headers = new HttpHeaders({
        'Accept': '*/*',
        'Content-Type': 'application/json'
    });

    public static httpOptions = {
        headers: Settings.headers.delete('Access-Control-Allow-Origin')
                                 .delete('Access-Control-Allow-Methods')
                                 .delete('Access-Control-Allow-Headers')
                                 .delete('Access-Control-Allow-Credentials')
    };
}
 