import {Injectable} from '@angular/core';
import {User} from "oidc-client";
import {Settings} from '../../components/common/constants/settings';
import {CookieService} from 'ngx-cookie-service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ApplicationInsightsService} from '../applicationInsights/application-insights.service';
import {Auth} from 'aws-amplify';
import {ICredentials} from '@aws-amplify/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NotificationService} from "../../notification/notificationService";
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AppConfigService} from "../config-service/app-config.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private jwtHelper = new JwtHelperService();
  private user: User = null;
  private renewTokenValue: string;

  private userLoggedIn = false;
  public profileDetails;

    constructor(private cookieService: CookieService, private applicationInsightsService: ApplicationInsightsService,
                private router: Router,
                private route: ActivatedRoute,
                private notificationService: NotificationService,
                private http: HttpClient) {
    }

    async isLoggedIn() {
        const token = this.cookieService.get('IdToken') ||
            this.getTokenFromUri();
        if (token && !this.profileDetails) {
            await this.getProfileDetails(token);
        }
        if (this.profileDetails) {
            return !this.jwtHelper.isTokenExpired(token);
        } else {
            return false;
        }
    }

    getTokenFromUri() {
        const authorizationTokenMatcher = window.location.search.match('authorizationtoken=([^&]+)');
        return authorizationTokenMatcher ? authorizationTokenMatcher[1] : null;
    }

    getProfileDetailsByEmail(email: string): Observable<any> {
        console.log( this.getAuthorizationHeaderValue())

        let params = new HttpParams()
            .set('email', email.toString())

        let headers = Settings.headers
            .set('authorizationtoken',this.cookieService.get('IdToken'))

        return this.http.get<any>(AppConfigService.settings.apiEndpoint_microserviceLender + '/api/lender/email', {
            params, headers
        });
    }

   async getAuthenticatedUser(){
    await Auth.currentAuthenticatedUser()
    .then(async () => {
        this.userLoggedIn = true;
        return true;
    })
    .catch(() => { 
      this.userLoggedIn = false;
      return false; });
  }

  getAuthorizationHeaderValue(): string {
    return Settings.TOKEN_TYPE + " " +  this.cookieService.get('IdToken');
  }

  async startAuthentication(username, password): Promise<ICredentials> {
     console.log('starting authentication');
    var response : any ={accessKeyId:''};

   await Auth.signIn(username, password)
    .then(user => {
      response.accessKeyId='User found';
      this.user=user;
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
            this.router.navigateByUrl('/new-password');
        } else {
          this.router.navigateByUrl('/auth-callback');
        }
    }).catch(e => {
      response.accessKeyId='User not found'
      console.log(e);
    });

    return response;
  }

  changePassFirstTime(newPass){
    Auth.completeNewPassword(
      this.user,           // the Cognito User Object
      newPass       // the new password
      ).then(user => {
       this.router.navigateByUrl('/auth-callback');
          // at this time the user is logged in if no MFA required
          console.log(user);
      }).catch(e => {
        console.log(e);
      });
  }

  async forgotPasswordFirstStep(username, fromCreatePasswordForm: boolean): Promise<any>{
    var response : any ={errorCode:''};

    await Auth.forgotPassword(username)
    .then(data =>{
      if(!fromCreatePasswordForm){
        console.log("user " + username + " has started the reset procedure. Incoming email");
      }else{
        console.log("user " + username + " has resent verification code. Incoming email");
      }      
    } )
    .catch(err =>{
      console.log(err)
      response.errorCode = err.code;
    });

    return response;
  }

  async forgotPasswordSecondStep(username, code, new_password): Promise<any>{
    var response : any ={errorCode:''};
    await Auth.forgotPasswordSubmit(username, code, new_password)
    .then(data =>{
      this.router.navigateByUrl('');
    } )
    .catch(err =>{
      response.errorCode = err.code;
    });

    return response.errorCode;
  }

    async completeAuthentication(): Promise<void> {
        let accessToken;
        let idToken;
        let userEmail;
        await Auth.currentSession().then(async res => {
            let rawAccessToken = res.getAccessToken()
            accessToken = rawAccessToken.getJwtToken()

            let rawIdToken = res.getIdToken();
            idToken = rawIdToken.getJwtToken();
            await this.getProfileDetails(idToken);

            this.jwtHelper.getTokenExpirationDate(idToken);
        })

        await Auth.currentUserInfo().then(userInfo => {
            userEmail = userInfo.attributes.email;
        });

        this.applicationInsightsService.logEvent("Login", {"event_type": "login", "username": userEmail});

        //Saving tokens
        this.cookieService.set('AuthToken', accessToken);
        this.cookieService.set('IdToken', idToken);

        return null;
    }

    private async getProfileDetails(idToken) {
        if (idToken) {
            const tokenData = this.jwtHelper.decodeToken(idToken);
            if (tokenData && tokenData.email) {
                if (!this.profileDetails) {
                    this.cookieService.set('IdToken', idToken);
                    this.profileDetails = await this.getProfileDetailsByEmail(tokenData.email.toLowerCase())
                        .toPromise()
                        .then(resp => resp.length > 0 ? resp[0] : undefined);
                    if (this.profileDetails) {
                        this.notificationService.lenderCode = this.profileDetails.fk_LenderAccount;
                        this.notificationService.connect(true);
                    }
                }
            }
        }
    }

    async signOut(){
    this.cookieService.delete('AuthToken');
    this.cookieService.delete('IdToken');
    try {
      await Auth.signOut();
      console.log("Signed out");
    } catch (error) {
        console.log('error signing out: ', error);
    }
    this.router.navigate(['/login']);
  }

  async renewToken(){
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
          console.log('session', err, session);
        const { idToken, refreshToken, accessToken } = session;
        //new tokens generated and saved
          this.cookieService.set('AuthToken', accessToken.jwtToken);        
        this.cookieService.set('IdToken', idToken.jwtToken);
      });
    } catch (e) {
      console.log('Unable to refresh Token', e);
    }
  }
 }
