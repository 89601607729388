import {Injectable} from "@angular/core";
import {Observable} from "rxjs/internal/Observable";
import {fromEvent} from "rxjs/internal/observable/fromEvent";

export enum MultiTabEvents {
    LOAN_DECISION_UPDATE_APP_SUMMARY,
    LOAN_DECISION_UPDATE_LENDERS_VIEW,

}

@Injectable()
export class MultiTabNotification {
    public source$: Observable<Event> = fromEvent(window, 'storage');

    constructor() {
    }

    subscribe(actionName: MultiTabEvents, callback: () => void) {
        this.source$.subscribe(
            (ldata: StorageEvent) => {
                if (ldata.key === 'ACTION_NAME' && ldata.newValue.startsWith(actionName.toString())) {
                    callback();
                }
            }
        );
    }

    trigger(actionName: MultiTabEvents) {
        localStorage.setItem('ACTION_NAME', actionName.toString() + '_' + new Date().getTime());
    }

}
