import { Component, ChangeDetectorRef, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthGuardService } from '../../services/security/auth-guard.service';
import { NotificationService } from '../../notification/notificationService';
import { MatDialog } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import { LenderService } from 'src/app/services/api/lender.service';
import { LenderInfoDTO } from 'src/app/models/LenderInfoDTO.model';
import { LenderLanguage } from 'src/app/models/lender-language';
import { LanguageService } from 'src/app/services/language-service/language.service';
import { PromptsMessages } from '../common/constants/promptsMessages';
import {CookieService} from "ngx-cookie-service";
import { ReportingService } from 'src/app/services/reporting/reporting.service';
import {AuthService} from "../../services/security/auth.service";
import { ProfileRole } from "../../models/profile-role.model";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public showBell: boolean;
    public newLoanData: boolean;
    private profileType = this.authService.profileDetails.profileType;

    @Output() getIndex: EventEmitter<any> = new EventEmitter<any>();
    @Output() notifyNewLoan: EventEmitter<any> = new EventEmitter<any>();

    public onAlert(event: any) {
        this.getIndex.emit(-1);
        this.resetNewLoanData();
        this.cdr.detectChanges();
        this.notifyNewLoan.emit();
        this.router.navigate(['leads/']);
    }

    public href: string = "";
    public lenderName: string = "";
    subscription: Subscription;
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;

    constructor(private authGuardService: AuthGuardService,
        private dialog: MatDialog,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private notificationService: NotificationService,
        public translate: TranslateService,
        private lenderService: LenderService,
        public languageService: LanguageService,
                private cookieService: CookieService,
                private reportingService: ReportingService,
                private authService: AuthService) {
        this.subscribe();
        this.showBell = window.location.href.toString().includes("leads")
            || window.location.href.toString().includes("accountDetailsLender");
            this.lenderName = this.authService.profileDetails.lenderName;
    }

    subscribe(): void {
        this.notificationService.messages.subscribe(event => {
            if (event) {
                this.updateNewLoanData();
            }
        });
    }

    goToHomePage(){
        console.log(this.profileType)
        if(this.profileType=="LENDER"){
            this.router.navigateByUrl('/leads');
        } else {
            this.router.navigateByUrl('/lendersList');
        }  
    }

    updateNewLoanData() {
        if (!this.newLoanData) {
            this.newLoanData = true;
        }
    }

    resetNewLoanData() {
        this.newLoanData = false;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe;
        }
    }

    async ngOnInit() {
        if (this.languageService.getLanguage() == "en") {
            this.updateTitle("en");
            this.translate.use('en');

        } else {
            this.updateTitle("fr");
            this.translate.use('fr');
        }
    }

    updateTitle(language) {
        const urlPage = this.router.url;
        if (urlPage.includes("leads")) {
            this.href = PromptsMessages.translate("DASHBOARD", language)
        } else if (urlPage.includes("lendersList")) {
            this.href = "Lenders";
        } else if (urlPage.includes("lenderRegistration")) {
            this.href = "Lender Registration";
        } else if (urlPage.includes("accountDetailsLender")) {
            this.href = PromptsMessages.translate("ACCOUNT_DETAILS", language)
        } else if (urlPage.includes("accountDetailsCS")) {
            this.href = "Account Details";
        } else if (urlPage.includes("applicationSummary")) {
            this.href = PromptsMessages.translate("APPLICATION_SUMMARY", language)
        } else if (urlPage.includes("pay")) {
            this.href = PromptsMessages.translate("REVIEW_LEAD", language);
        } else if (urlPage.includes("report-details")) {
            this.href = "Reports/ Report Details";
            this.lenderName = this.reportingService.getReportType() + " - " + this.reportingService.getReportOption();
        } else {
            this.href = urlPage.substring(1, 2).toUpperCase() + urlPage.substring(2);
        }
    }

    switchEnglish() {
        this.cookieService.set(LanguageService.LANGUAGE, LenderLanguage.EN.toString());
        this.lenderService.updateLenderUser(new LenderInfoDTO(null, this.authService.profileDetails.id,
            LenderLanguage.EN.toString(), null,null,null,null,null, null, null)).subscribe(data => {
            window.location.reload();
        });
    }

    switchFrench() {
        this.cookieService.set(LanguageService.LANGUAGE, LenderLanguage.FR.toString());
        this.lenderService.updateLenderUser(new LenderInfoDTO(null, this.authService.profileDetails.id,
            LenderLanguage.FR.toString(), null,null,null,null,null, null, null)).subscribe(data => {
            window.location.reload();
        });
    }

    getUser() {
        if (this.authService.profileDetails.profileRole.indexOf(ProfileRole.LENDER_USER) != -1) {
            return true;
        }
        return false;
    }
} 
