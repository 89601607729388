import { Component} from '@angular/core';
import { Subject, Observable} from 'rxjs';
import { GeneralPromptComponent } from './components/common/general-prompt/general-prompt.component';
import { ErrorService } from './services/error/error.service';
import { takeUntil, filter} from 'rxjs/operators';
import { PromptModel } from './models/prompt.model';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { ApplicationInsightsService } from './services/applicationInsights/application-insights.service';
import { IsLoadingService } from './services/is-loading.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SessionExtensionService } from './services/session-extension-service/session-extension.service';
import {TranslateService} from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { LanguageService } from './services/language-service/language.service';
import { PromptsMessages } from './components/common/constants/promptsMessages';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  title = 'LenderGatewayUI';
  public showHeader = true;
  isLoading: Observable<boolean>;
    
  private jwtHelper = new JwtHelperService();
  
    constructor(private errorService: ErrorService,
      private dialog: MatDialog, 
      private router: Router, 
      private applicationInsigtsService: ApplicationInsightsService,
      private isLoadingService: IsLoadingService, 
      private sessionExtensionsService:SessionExtensionService,
      public translate: TranslateService,
      private titleService: Title,
      private languageService: LanguageService) {
        this.applicationInsigtsService.setup();
        this.initializeErrors();
        router.events.forEach((event) => {
          if (String(event['url']).includes('creditBureau')) {
            this.showHeader = false;
          }
        });
        this.sessionExtensionsService.onIdle();
        localStorage.setItem('ACTION_NAME', null);

        translate.addLangs(['en', 'klingon'])
        translate.addLangs(['fr', 'klingon'])
        translate.setDefaultLang('en');
        translate.use('en');

        this.titleService.setTitle(PromptsMessages.translate("APP_TITLE", this.languageService.getLanguage()))
    }

    private ngUnsubscribe = new Subject();
    
      ngOnDestroy()
      {
          this.ngUnsubscribe.next();
          this.ngUnsubscribe.complete();
      }
  
      private initializeErrors()
      {
          this.errorService
              .getErrors()
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((errors) =>
              {
                var promptInfo = new PromptModel([{ content: errors[0], isTextBold: false }], true, false, null, '', '', false, false, false, false, false);
                this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
              });
         
    }   

    ngOnInit() {
      this.isLoading = this.isLoadingService.isLoading$();

      this.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
      )
      .subscribe(event => {
        // If it's the start of navigation, `add()` a loading indicator
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          return;
        }

        // Else navigation has ended, so `remove()` a loading indicator
        if(event instanceof NavigationEnd && event.url.includes("applicationSummary")){
            return;
        }        

        this.isLoadingService.remove();
      });
    }
}
