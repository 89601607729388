import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/security/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup;
  public finishedRessetingPassword: boolean = false;
  public emailPlaceHolder: string = 'Someemail@gmail.com';
  public isUsernameIncorrect: boolean = false;

  constructor(private formBuilder: FormBuilder, private authService: AuthService) {
    this.resetPasswordForm = this.formBuilder.group({
      emailInput: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$")]]
    });
  }

  ngOnInit() {
  }

  changeFieldNoSpaces(event: any, name){
    if (event) {
        var value = event.target.value;
        if (value.match(/^\s* {1}$/)) {
            value = value.substring(0, value.length - 1);
        }
        else if (value.length >= 2 && value.substring(value.length - 1, value.length).match(/^\s* {1}$/)) {
            value = value.substring(0, value.length - 1);
        }
        this.resetPasswordForm.get(name).setValue(value);
        this.resetPasswordForm.get(name).markAsTouched();
    }
}

  async resetPassword(){
    var username=this.resetPasswordForm.get("emailInput").value;

    var response = await this.authService.forgotPasswordFirstStep(username, false);
    if(response != undefined && response.errorCode != "UserNotFoundException"){
      this.finishedRessetingPassword = true;
    }else if(response.errorCode == "UserNotFoundException"){
      this.isUsernameIncorrect = true;
    }

  }

  checkPlaceHolder() {
    var username=this.resetPasswordForm.get("emailInput").value;

    if(this.isUsernameIncorrect == true){
      this.isUsernameIncorrect = false;
    }

    if (this.emailPlaceHolder) {
      this.emailPlaceHolder = null
      return;
    } else {
      if(username == ''){
        this.emailPlaceHolder = 'Someemail@gmail.com'
        return;
      }

      return
    }
  }
}
