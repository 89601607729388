import { Component, ViewChild, OnInit, HostListener, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/compiler/src/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Button } from 'primeng';
import { Router, ActivatedRoute } from '@angular/router';
import { ProvinceService } from '../../services/province-service/province-service.service';
import { ProfileDetails } from '../../models/profile-details.model';
import { ContactDetails } from '../../models/contact-details.model';
import { AddressInformation } from '../../models/address-information.model';
import { AddressComponent } from '../common/address-component/address-component.component';
import { GeneralPromptComponent } from '../common/general-prompt/general-prompt.component';
import { PromptModel } from '../../models/prompt.model';
import { PromptsMessages } from '../common/constants/promptsMessages';
import { AsciiCodes } from '../common/constants/asciiCodes';
import { Validation } from '../common/constants/validation';
import { LenderService } from '../../services/api/lender.service';
import { AccountDetailsCsComponent } from '../account-details-cs/account-details-cs.component';
import { Observable } from 'rxjs';
import { Location } from "@angular/common";
import { ProfileDetailsDTO } from '../../models/dto-profile-details';
import { LanguageService } from 'src/app/services/language-service/language.service';
import { AuthService } from "../../services/security/auth.service";
import { ProfileRole } from 'src/app/models/profile-role.model';
import { LenderInfoDTO } from 'src/app/models/LenderInfoDTO.model';
import { ProfileType } from 'src/app/models/profile-type.model';
import { UserPromptComponent } from '../common/user-prompt/user-prompt.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatRadioButton } from '@angular/material/radio';
import { clone } from 'lodash';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return (control.dirty || control.touched) && control.invalid;
    }
}

@Component({
    selector: 'app-account-details-lender',
    templateUrl: './account-details-lender.component.html',
    styleUrls: ['./account-details-lender.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccountDetailsLenderComponent implements OnInit {
    key: number;
    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        this.key = event.keyCode;
        return event.keyCode;
    }
    public isReqEquifax = false;
    public isReqTU = false;
    public modifiedFields = false;
    public registerForm: FormGroup;
    public errorMatcher = new CrossFieldErrorMatcher();
    public isDisabledCredentialEquifax: boolean;
    public isDisabledCredentialTU: boolean;
    public accountDetails: ProfileDetails;
    private addressInformation: AddressInformation;
    private csvProvincesList: any;
    private lender: ProfileDetails;
    private lenderInfoDTO = new LenderInfoDTO(null,null,null,null,null,null,null,null,null, null);
    private lenderDTO = new ProfileDetailsDTO(null, null);
    private contactDetails: ContactDetails;
    private stripe_clientId: String;
    public mfirstName: boolean;
    public mlastName: boolean;
    public mphone: boolean;
    public mext: boolean;
    public memail: boolean;
    public maddressLine1: boolean;
    public maddressLine2: boolean;
    public mcity: boolean;
    public mpostalCode: boolean;
    public mprovince: boolean;
    public globalId;
    public globalLenderCode;
    public globalSentToExpert: boolean;
    public globalFinastraContractsCompleted: boolean;
    public globalVettingCompleted: boolean;
    public provinces = []
    public countries = [
        { "id": 1, "name": "Canada" }
    ]
    public countryS = this.countries[0].id;
    public sentToExpert: boolean;
    public isNavigatingToDashboard: boolean = false;
    public loggedInEmail: string;
    public accountDetailsLoggedInUser: string;
    public isLenderUserLoggedIn: boolean = false;

    
    public isLenderAdminLoggedIn: boolean = false;
    public userLendersList = [];
    public contactDetailsForLenderUser: ContactDetails[];
    public existingLenderUserList = [];
    public existingLenderUsers = false;
    public sentToExpertFromDb: boolean;
    public isUserDeletionAndUpdate = false;
    public lenderUsersToBeDeleted : ProfileDetails[];
    public isLenderUserAnAdminOrNot : Boolean = false;
    public lenderUserValidationRegisterForm : boolean = false;
    public testPhoneValid : boolean = false;
    public testExtValid : boolean = false;
    public saveLenderDTOLenderUsersBeforeSplice = [];

    @ViewChild('radioEquifaxAnswerYes') equifaxAnswerYes: MatRadioButton;
    @ViewChild('radioEquifaxAnswerNo') equifaxAnswerNo: MatRadioButton;
    @ViewChild('radioRegisteredTransUnionYes') radioRegisteredTransUnionYes: MatRadioButton;
    @ViewChild('radioRegisteredTransUnionNo') radioRegisteredTransUnionNo: MatRadioButton;
    @ViewChild('radioLenderAcceptTransUnionYes') radioLenderAcceptTransUnionYes: MatRadioButton;
    @ViewChild('radioLenderAcceptTransUnionNo') radioLenderAcceptTransUnionNo: MatRadioButton;
    @ViewChild('submitButton') submitButton: Button;
    @ViewChild('phone') phone: Input;
    @ViewChild(AddressComponent) addressComponent: AddressComponent;
    @Output() sendPromptText: EventEmitter<any> = new EventEmitter<any>();

    constructor(private authService: AuthService, public languageService: LanguageService, private provinceService: ProvinceService, private location: Location, private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private dialog: MatDialog, private lenderService: LenderService) {
        this.registerForm = this.formBuilder.group({
            englishName: ['', [Validators.required, Validators.maxLength(Validation.ENGLISH_NAME_LENGTH), Validators.pattern('^([A-Za-z&]+ )+[A-Za-z&]+$|^[A-Za-z&]+$')]],
            frenchName: ['', [Validators.required, Validators.maxLength(Validation.FRENCH_NAME_LENGTH), Validators.pattern('^[ a-zA-ZÃ Ã¢Ã¤Ã¨Ã©ÃªÃ«Ã®Ã¯Ã´Å“Ã¹Ã»Ã¼Ã¿Ã§Ã€Ã‚Ã„ÃˆÃ‰ÃŠÃ‹ÃŽÃ?Ã”Å’Ã™Ã›ÃœÅ¸Ã‡]+$')]],
            credentialNumberEquifax: [{ value: '' }, [Validators.maxLength(Validation.MEMBER_NUMBER_LENGTH), Validators.pattern("[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{5}")]],
            credentialNumberTransUnion: [{ value: '' }, [Validators.maxLength(Validation.MEMBER_NUMBER_LENGTH), Validators.pattern("[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{5}")]],
            firstName: ['', [Validators.required, Validators.pattern('^[ a-zA-ZÃ Ã¢Ã¤Ã¨Ã©ÃªÃ«Ã®Ã¯Ã´Å“Ã¹Ã»Ã¼Ã¿Ã§Ã€Ã‚Ã„ÃˆÃ‰ÃŠÃ‹ÃŽÃ?Ã”Å’Ã™Ã›ÃœÅ¸Ã‡]+$')]],
            lastName: ['', [Validators.required, Validators.pattern('^[ a-zA-ZÃ Ã¢Ã¤Ã¨Ã©ÃªÃ«Ã®Ã¯Ã´Å“Ã¹Ã»Ã¼Ã¿Ã§Ã€Ã‚Ã„ÃˆÃ‰ÃŠÃ‹ÃŽÃ?Ã”Å’Ã™Ã›ÃœÅ¸Ã‡]+$')]],
            phone: ['', [Validators.required, Validators.maxLength(Validation.PHONE_LENGTH), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]],
            ext: ['', [Validators.maxLength(Validation.EXTENSION_LENGTH), Validators.pattern("[0-9]{5}")]],
            email: ['', [Validators.required, Validators.email]],
            adressLookup: [''],
            addressLine1: ['', [Validators.required, Validators.maxLength(Validation.ADDRESS_LENGTH)]],
            addressLine2: ['', [Validators.required, Validators.maxLength(Validation.ADDRESS_LENGTH),]],
            city: ['', [Validators.required, Validators.maxLength(Validation.CITY_LENGTH), Validators.pattern('^[ a-zA-ZÃ Ã¢Ã¤Ã¨Ã©ÃªÃ«Ã®Ã¯Ã´Å“Ã¹Ã»Ã¼Ã¿Ã§Ã€Ã‚Ã„ÃˆÃ‰ÃŠÃ‹ÃŽÃ?Ã”Å’Ã™Ã›ÃœÅ¸Ã‡]+$')]],
            postalCode: ['', [Validators.required, Validators.maxLength(Validation.POSTAL_CODE_LENGTH), Validators.pattern("[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1} [0-9]{1}[a-zA-Z]{1}[0-9]{1}")]],
            equifaxRadioGroup: [{ value: '' }, [Validators.required]],
            registeredTransUnionRadioGroup: [{ value: '' }, [Validators.required]],
            lenderAcceptTransUnionRadioGroup: [{ value: '' }, [Validators.required]],
            province: ['', [Validators.required]]
        },
            {
                validators: this.inputValidator,
                updateOn: 'blur'
            });

        this.provinceService.getProvinces().subscribe(data => {
            this.csvProvincesList = this.provinceService.CSV2JSON(data);
            var length = this.csvProvincesList.length;
            this.csvProvincesList.splice(0, 1);
            length = this.csvProvincesList.length;
            this.csvProvincesList.splice(length - 1, 1);
            for (let elem in this.csvProvincesList) {
                this.provinces.push({ 'id': elem, 'name': this.csvProvincesList[elem][0] });
            }
        });
    }

    inputValidator(form: FormGroup) {
        const condition = form.get('englishName').value.length == Validation.ENGLISH_NAME_LENGTH;
        return condition ? { invalidInput: true } : null;
    }

    setFormValue(form: FormGroup, name: string, value: any) {
        form.get(name).setValue(value);
        form.get(name).setErrors(null);
    }

    ngOnInit() {

        this.lenderUserValidationRegisterForm = false;
        this.isLenderUserLoggedIn = false;
        this.isLenderAdminLoggedIn = false;
        this.loggedInEmail = this.authService.profileDetails.contactDetails.email;
        this.location.subscribe(() => {
            window.location.reload();
        });
        this.route.queryParams
            .subscribe(params => {
                this.globalLenderCode = params['lenderCode'];
            });

        var lenderListResponse = [];

        this.lenderService.getProfileDetails(this.globalLenderCode).subscribe(response => {
            lenderListResponse.push(response);
            this.lenderDTO.lenderUsers = response.lenderUsers;

            if (this.lenderDTO.lenderUsers.length > 0) {
                this.existingLenderUserList = this.lenderDTO.lenderUsers;
                this.existingLenderUsers = true;
            }else{
                this.existingLenderUsers = false;
            }

            if(this.authService.profileDetails.profileRole.includes(ProfileRole.LENDER_ADMIN)){
                this.isLenderAdminLoggedIn = true;
            }else{
                this.isLenderUserLoggedIn = true;
            }

            let accountDetailsCsInstance = new AccountDetailsCsComponent(this.dialog, this.provinceService, this.router, this.route, this.formBuilder, this.lenderService)
            
            if(this.isLenderAdminLoggedIn){
                this.accountDetails = accountDetailsCsInstance.createLenderFromDBResponse(response.lenderAccount);
            }else{
                this.accountDetails = accountDetailsCsInstance.createLenderFromDBResponse(this.authService.profileDetails);
            }
            
            
            var details = this.accountDetails;
            this.globalSentToExpert = details.sentToExpert;
            this.globalId = details.id;
            this.globalFinastraContractsCompleted = details.finastraContractsCompleted;
            this.globalVettingCompleted = details.lenderVettingCompleted;
            this.setFormValue(this.registerForm, 'englishName', details.getLenderName);
            this.registerForm.get('englishName').disable();
            this.registerForm.get('email').disable();
            this.setFormValue(this.registerForm, 'frenchName', details.getLenderFrenchName);
            this.registerForm.get('frenchName').disable();
            this.stripe_clientId = details.getStripe_clientId;

            if (details.getEquifaxMember) {
                this.equifaxAnswerYes.checked = true;
                this.isDisabledCredentialEquifax = true;
                this.setFormValue(this.registerForm, 'credentialNumberEquifax', details.getCredentialNumberEq);
                this.registerForm.get('equifaxRadioGroup').setErrors(null);
            }
            else {
                this.isDisabledCredentialEquifax = false;
                this.equifaxAnswerNo.checked = true;
                this.registerForm.get('equifaxRadioGroup').setErrors(null);
            }

            this.registerForm.get('credentialNumberEquifax').setErrors(null);
            this.registerForm.get('credentialNumberEquifax').disable();
            this.registerForm.get('equifaxRadioGroup').disable();

            if (details.getTuMemberLender == true) {
                this.radioRegisteredTransUnionYes.checked = true;
                this.isDisabledCredentialTU = true;
                this.setFormValue(this.registerForm, 'credentialNumberTransUnion', details.getCredentialNumberTu);
                this.registerForm.get('registeredTransUnionRadioGroup').setErrors(null);

            }
            else {
                this.radioRegisteredTransUnionNo.checked = true;
                this.isDisabledCredentialTU = false;
                this.registerForm.get('registeredTransUnionRadioGroup').setErrors(null);
            }

            this.registerForm.get('credentialNumberTransUnion').setErrors(null);
            this.registerForm.get('credentialNumberTransUnion').disable();
            this.registerForm.get('registeredTransUnionRadioGroup').disable();

            if (details.getAcceptTUBureaus == true) {
                this.radioLenderAcceptTransUnionYes.checked = true;
                this.registerForm.get('lenderAcceptTransUnionRadioGroup').setErrors(null);
            }
            else {
                this.radioLenderAcceptTransUnionNo.checked = true;
                this.registerForm.get('lenderAcceptTransUnionRadioGroup').setErrors(null);
            }

            this.registerForm.get('lenderAcceptTransUnionRadioGroup').disable();
            this.setFormValue(this.registerForm, 'firstName', details.contactDetails.firstName);
            this.setFormValue(this.registerForm, 'lastName', details.contactDetails.lastName);
            this.setFormValue(this.registerForm, 'phone', details.contactDetails.phoneNumber);
            this.setFormValue(this.registerForm, 'ext', details.contactDetails.extension);
            this.setFormValue(this.registerForm, 'email', details.contactDetails.email);
            this.setFormValue(this.registerForm, 'addressLine1', details.addressInformation.addressOne);
            this.setFormValue(this.registerForm, 'addressLine2', details.addressInformation.addressTwo);
            this.setFormValue(this.registerForm, 'city', details.addressInformation.city);
            this.setFormValue(this.registerForm, 'postalCode', details.addressInformation.postalCode);
            this.setFormValue(this.registerForm, 'province', details.addressInformation.province);

            this.sentToExpert = lenderListResponse[0].sentToExpert;
        });
        if (this.registerForm.get('phone').touched == false && this.registerForm.get('ext').touched == false){
            this.lenderUserValidationRegisterForm = true;
        }
        else{
            this.lenderUserValidationRegisterForm = false;
        }
        
        if (this.registerForm.controls.phone.status === "VALID"){
            this.testPhoneValid = true;
        }
        if (this.registerForm.controls.ext.status === "VALID"){
            this.testExtValid = true;
        }

    }

    ngAfterViewInit() {
        this.registerForm.addControl('addressLookup', this.addressComponent.addressForm);
        this.addressComponent.addressForm.setParent(this.registerForm);
    }

    onCancel() {
        if (this.registerForm.touched) {
            var promptInfo = new PromptModel([{ content: PromptsMessages.translate("CANCEL_MESSAGE", this.languageService.getLanguage()), isTextBold: false }], true, true, '/leads', '', '', false, false, false, false, false);
            promptInfo.redirectViaHref();
            this.sendPromptText.emit(promptInfo);
            this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
            this.mfirstName = this.registerForm.get('firstName').dirty;
            this.mlastName = this.registerForm.get('lastName').dirty;
            this.mphone = this.registerForm.get('phone').dirty;
            this.memail = this.registerForm.get('email').dirty;
            this.mext = this.registerForm.get('ext').dirty;
            this.maddressLine1 = this.registerForm.get('addressLine1').dirty;
            this.maddressLine2 = this.registerForm.get('addressLine2').dirty;
            this.mcity = this.registerForm.get('city').dirty;
            this.mpostalCode = this.registerForm.get('postalCode').dirty;
            this.mprovince = this.registerForm.get('province').dirty;

        } else {
            this.isNavigatingToDashboard = true;
        }
    }

    onSubmit() {
        if (this.isLenderUserLoggedIn == true) {
            this.sendLenderUserToDatabase(this.globalSentToExpert, false).subscribe(() => {
                var promptInfo = new PromptModel([{ content: PromptsMessages.translate("SAVE_MESSAGE_ACC_DET_LENDER", this.languageService.getLanguage()), isTextBold: false }], true, false, '/leads', '', '', false, false, false, false, false);
                this.sendPromptText.emit(promptInfo);
                promptInfo.redirectViaHref();
                this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
            }, () => { });
        }
        else {
            this.sendLenderToDatabase(this.globalSentToExpert).subscribe(() => {
                var promptInfo = new PromptModel([{ content: PromptsMessages.translate("SAVE_MESSAGE_ACC_DET_LENDER", this.languageService.getLanguage()), isTextBold: false }], true, false, '/leads', '', '', false, false, false, false, false);
                this.sendPromptText.emit(promptInfo);
                promptInfo.redirectViaHref();
                this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
            }, () => { });
        }
    };

    onAddUser() {
        var dialogueInfo = { accountDetails: true }
        this.sendPromptText.emit(dialogueInfo);
        let dialogueBoxRef = this.dialog.open(UserPromptComponent, {
            autoFocus: false, data: dialogueInfo,
            minHeight: 'calc(60vh - 90px)',
            height: 'auto'
        });

        dialogueBoxRef.afterOpened().subscribe(() => {
            dialogueBoxRef.componentInstance.onSave.subscribe(() => {
                dialogueBoxRef.afterClosed().subscribe(data => {

                    let lenderUserData = new LenderInfoDTO(null,null,null,null,null,null,null,null,null,null);
                    let lenderUserContactDetails = new ContactDetails(null,null,null,null,null);

                    lenderUserData.fk_LenderAccount = this.accountDetails.lenderCode;
                    lenderUserData.isUserAdmin = data.isAdmin;

                    lenderUserContactDetails.firstName = data.firstName;
                    lenderUserContactDetails.lastName = data.lastName;
                    lenderUserContactDetails.phoneNumber = data.phone;
                    lenderUserContactDetails.extension = data.ext;
                    lenderUserContactDetails.email = data.email;
                    
                    lenderUserData.contactDetails = lenderUserContactDetails;
                    this.createLenderUser(lenderUserData);
                });
            });
        });
    }

    sendLenderToDatabase(isPublishedToExpert: boolean): Observable<ProfileDetails> {
        var lenderName = this.registerForm.get("englishName").value;
        var lenderFrenchName = encodeURI(this.registerForm.get("frenchName").value.toString().replace(/%/g, '~~pct~~'));
        var equifaxMember = this.equifaxAnswerYes.checked;
        var equifaxMemberNumber = equifaxMember ? this.registerForm.get("credentialNumberEquifax").value : '';
        var transUnionMember = this.radioRegisteredTransUnionYes.checked;
        var transUnionMemberNumber = transUnionMember ? this.registerForm.get("credentialNumberTransUnion").value : '';
        var acceptTUBureaus = this.radioLenderAcceptTransUnionYes.checked;
        var firstName = encodeURI(this.registerForm.get("firstName").value.toString().replace(/%/g, '~~pct~~'));
        var lastName = encodeURI(this.registerForm.get("lastName").value.toString().replace(/%/g, '~~pct~~'));
        var phoneNumber = this.registerForm.get("phone").value;
        var extension = this.registerForm.get("ext").value;
        var email = this.registerForm.get("email").value.toLowerCase();
        this.contactDetails = new ContactDetails(firstName, lastName, phoneNumber, extension, email);
        var addressOne = encodeURI(this.registerForm.get("addressLine1").value.toString().replace(/%/g, '~~pct~~'));
        var addressTwo = encodeURI(this.registerForm.get("addressLine2").value.toString().replace(/%/g, '~~pct~~'));
        var city = encodeURI(this.registerForm.get("city").value.toString().replace(/%/g, '~~pct~~'));
        var postalCode = this.registerForm.get("postalCode").value;
        var province = this.registerForm.get("province").value;
        this.addressInformation = new AddressInformation(addressOne, addressTwo,
            city, postalCode, province);
        this.globalSentToExpert = isPublishedToExpert;
        var isActive = true;

        this.lender = new ProfileDetails(lenderName, lenderFrenchName, equifaxMember, equifaxMemberNumber,
            transUnionMember, transUnionMemberNumber, acceptTUBureaus, this.globalFinastraContractsCompleted,
            this.globalVettingCompleted, this.contactDetails, this.addressInformation, this.globalLenderCode, this.globalId, isActive, this.globalSentToExpert, null, this.stripe_clientId, 0, []);

        this.lenderDTO.lenderAccount = this.lender;
        return this.lenderService.updateLender(this.lenderDTO);
    }

    sendLenderUserToDatabase(isPublishedToExpert: boolean, deleteUser:boolean): Observable<ProfileDetails> {
        var userContactDetails: ContactDetails;
        let firstName = encodeURI(this.registerForm.get('firstName').value.toString().replace(/%/g, '~~pct~~'));
        let lastName = encodeURI(this.registerForm.get('lastName').value.toString().replace(/%/g, '~~pct~~'));
        let phoneNumber = this.registerForm.get('phone').value;
        let extension = this.registerForm.get('ext').value;
        let email = this.registerForm.get('email').value.toLowerCase();
        
        userContactDetails = new ContactDetails(firstName, lastName, phoneNumber, extension, email);
        this.lenderInfoDTO = new LenderInfoDTO(null, this.globalId, this.languageService.getLanguage(), null, this.globalLenderCode, userContactDetails, true, ProfileType.LENDER, this.isLenderUserAnAdminOrNot, this.sentToExpert);
         
        return this.lenderService.updateLenderUser(this.lenderInfoDTO);
    }

    onEditExistingUser(userId: string) {
        this.saveLenderDTOLenderUsersBeforeSplice = clone(this.lenderDTO.lenderUsers);

        var editUser;
        if (this.existingLenderUserList.length > 0) {
            for (let i = 0; i < this.existingLenderUserList.length; i++) {
                if (this.existingLenderUserList[i].id == userId) {
                    editUser = {
                        firstName: this.existingLenderUserList[i].contactDetails.firstName, lastName: this.existingLenderUserList[i].contactDetails.lastName,
                        email: this.existingLenderUserList[i].contactDetails.email, phone: this.existingLenderUserList[i].contactDetails.phoneNumber, ext: this.existingLenderUserList[i].contactDetails.extension,
                        id: this.existingLenderUserList[i].id, profileType: this.existingLenderUserList[i].profileType, editedUser: true,
                        isAdmin: this.existingLenderUserList[i].profileRole.indexOf('LENDER_ADMIN') > -1 ? 'yes' : 'no',

                    }
                }
            }

        }

        var dialogueInfo = {
            accountDetails: true, editData: editUser
        }
        this.sendPromptText.emit(dialogueInfo);
        let dialogueBoxRef = this.dialog.open(UserPromptComponent, {
            autoFocus: false, data: dialogueInfo,
            minHeight: 'calc(60vh - 90px)',
            height: 'auto'
        });

        dialogueBoxRef.afterOpened().subscribe(() => {
            dialogueBoxRef.componentInstance.onSave.subscribe(() => {
                dialogueBoxRef.afterClosed().subscribe(data => {

                    let lenderUserData = new LenderInfoDTO(null,null,null,null,null,null,null,null,null,null);
                    let lenderUserContactDetails = new ContactDetails(null,null,null,null,null);

                    lenderUserData.fk_LenderAccount = this.accountDetails.lenderCode;
                    lenderUserData.isUserAdmin = data.isAdmin;
                    lenderUserData.lender_id = data.id;
                    lenderUserData.isActive = data.isActive;

                    lenderUserContactDetails.firstName = data.firstName;
                    lenderUserContactDetails.lastName = data.lastName;
                    lenderUserContactDetails.phoneNumber = data.phone;
                    lenderUserContactDetails.extension = data.ext;
                    lenderUserContactDetails.email = data.email;
                    
                    lenderUserData.contactDetails = lenderUserContactDetails;

                    this.updateLenderUser(lenderUserData);
                });
            });

            dialogueBoxRef.componentInstance.onDelete.subscribe(() => {
                dialogueBoxRef.afterClosed().subscribe(data => {

                    let lenderUserData = new LenderInfoDTO(null,null,null,null,null,null,null,null,null,null);
                    let lenderUserContactDetails = new ContactDetails(null,null,null,null,null);

                    lenderUserData.lender_id = data.id;
                    lenderUserData.isActive = false;
                    
                    lenderUserData.contactDetails = lenderUserContactDetails;

                    this.deleteLenderUser(lenderUserData);
                });
            });
        });
    }

    createLenderUser(lenderUserData: LenderInfoDTO) {
        
        this.lenderService.createLenderUser(lenderUserData).subscribe(data => {
            /*
            *After a user is inserted we need to fetch it's db generated id for it is required in the orher metods(delete and update lender user),
            *hence the recall of getProfileDetails
            */
            if(data != null && data != undefined){
                this.reinitializeLenderUserList();
            }
        });
    }

    updateLenderUser(lenderUserData: LenderInfoDTO) {
        this.lenderService.updateLenderUser(lenderUserData).subscribe(data => {
            /*
            *After a user is updated we need to fetch it's updated details so we have the fe up to date with the DB
            */
            if(data != null && data != undefined){
                this.reinitializeLenderUserList();
            }
        });
    }

    deleteLenderUser(lenderUserData: LenderInfoDTO) {
        this.lenderService.updateLenderUser(lenderUserData).subscribe(data => {
            /*
            *After a user is deleted in order to remove it from the list we need to call the BE
            */
            if(data != null && data != undefined){
                this.reinitializeLenderUserList();
            }
        });
    }

    reinitializeLenderUserList() {
        this.lenderService.getProfileDetails(this.accountDetails.lenderCode).subscribe(response => {
            if (response != null && response != undefined) {
                let lenderUsers = response.lenderUsers;

                this.existingLenderUserList = lenderUsers;
            }
        });
    }

    eliminateExtraSpaces(value) {
        if (value.match(/^\s* {1}$/)) {
            value = value.substring(0, value.length - 1);
        }
        else {
            if (value.length > 2 && value.substring(value.length - 2, value.length).match(/^\s* {2}$/)) {
                value = value.substring(0, value.length - 1);
            }
        }
        return value
    }

    changeFieldNoSpaces(event: any, name: string): void {
        if (event) {
            var value = this.eliminateExtraSpaces(event.target.value);
            this.registerForm.get(name).setValue(value);
            this.registerForm.get(name).markAsTouched();
            this.isNavigatingToDashboard = true;
        }
    }

    changePhone(fn: string): void {
        if (fn) {
            var formatted = this.formatPhoneText(fn);
            this.registerForm.get('phone').setValue(formatted);
           
            if (this.registerForm.controls.phone.status === "VALID"){
                this.testPhoneValid = true;
                this.lenderUserValidationRegisterForm = true;
            }
            else{
                this.testPhoneValid = false;
                this.lenderUserValidationRegisterForm = false;
            }
            this.registerForm.get('phone').markAsTouched();
            this.isNavigatingToDashboard = true;
            
        }
    }

    formatPhoneText(value): string {
        value = value.replace(/[^\w\s]/gi, "");

        if (value.length > 3 && value.length <= 6)
            value = value.slice(0, 3) + "-" + value.slice(3);
        else if (value.length > 6)
            value = value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6);

        return value;
    }

    keydownPhone(fn: any): void {
        var filter = [];

        //add key codes for digits 0 - 9 into this filter
        for (var i = 0; i <= 9; i++) {
            filter.push(i + AsciiCodes.KEYPAD_ZERO);
            filter.push(i + AsciiCodes.NUMPAD_ZERO);
        }

        //or for editing the keyboard input
        filter.push(AsciiCodes.BACKSPACE_KEY);
        filter.push(AsciiCodes.TAB_KEY);
        filter.push(AsciiCodes.DELETE_KEY);
        filter.push(AsciiCodes.LEFT_ARROW_KEY);
        filter.push(AsciiCodes.RIGHT_ARROW_KEY);
        if (filter.indexOf(fn.keyCode) < 0) {
            fn.preventDefault();
        }
    }

    changeExt(fn: any): void {
        if (fn) {
            this.registerForm.get('ext').setValue(fn.target.value);
          
            
            if (this.registerForm.controls.ext.status === "VALID"){
                this.lenderUserValidationRegisterForm = true;
                this.testExtValid = true;
            }
            else{
                this.testExtValid = false;
                this.lenderUserValidationRegisterForm = false;
            }
            this.registerForm.get('ext').markAsTouched();
            this.isNavigatingToDashboard = true;
        }
    }

    changeEmail(fn: any): void {
        if (fn) {

            this.registerForm.get('email').setValue(fn.target.value);
            this.registerForm.get('email').markAsTouched();
            this.memail = true;

        }
    }

    setAddressValues(addressInfo: AddressInformation) {

        this.registerForm.get('addressLine1').setValue(addressInfo.addressTwo + " " + addressInfo.addressOne);
        this.registerForm.get('city').setValue(addressInfo.city);
        this.registerForm.get('postalCode').setValue(addressInfo.postalCode);
        this.registerForm.get('province').setValue(addressInfo.province);

    }

    keydownPC(fn: any): void {
        var filter = [];

        //add key codes for digits 0 - 9 into this filter
        for (var i = 0; i <= 9; i++) {
            filter.push(i + AsciiCodes.KEYPAD_ZERO);
            filter.push(i + AsciiCodes.NUMPAD_ZERO);
        }

        //add key codes for digits letters into this filter
        for (var i = 0; i <= 25; i++) {
            filter.push(i + AsciiCodes.ALPHA_UPPER);
            filter.push(i + AsciiCodes.ALPHA_LOWER);
        }

        //add other keys that might be needed for navigation
        //or for editing the keyboard input
        filter.push(AsciiCodes.BACKSPACE_KEY);
        filter.push(AsciiCodes.TAB_KEY);
        filter.push(AsciiCodes.DELETE_KEY);
        filter.push(AsciiCodes.LEFT_ARROW_KEY);
        filter.push(AsciiCodes.RIGHT_ARROW_KEY);
        if (filter.indexOf(fn.keyCode) < 0) {
            fn.preventDefault();
        }
    }

    formatPCText(value): string {
        value = value.replace(" ", "");
        value = value.replace(")", "");
        value = value.replace("(", "");

        if (value.length > 3 && value.length <= 6)
            value = value.slice(0, 3) + " " + value.slice(3);
        else if (value.length > 6)
            value = value.slice(0, 3) + " " + value.slice(3, 6) + value.slice(6);

        return value;
    }

    changePC(fn: any): void {
        if (fn) {
            var formatted = this.formatPCText(fn.target.value);
            this.registerForm.get('postalCode').setValue(formatted);
            this.registerForm.get('postalCode').markAsTouched();
            this.isNavigatingToDashboard = true;
        }
    }

    public changeProvince(fn: any) {
        if (fn) {
            this.registerForm.get('province').markAsTouched();
            this.isNavigatingToDashboard = true;
        }
    }


}
