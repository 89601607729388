<mat-dialog-content id="review-dialog" *ngIf="!getIsVideo()">

    <div *ngIf="getIsRunReport()" style="margin-top: 15px;">
        Run Report
    </div>

    <div *ngIf="getIsVideoPreview()" style="margin-top: 15px;font-size: 16px;font-weight: 400;font-family: Roboto;">
        <img src="../../../assets/Icon_Announcement.svg"
                        style="margin-right: 4px;margin-left: 0px;font-size: 10px;width: 40px;" aria-label="Fund button"
                        role="button icon" />
                        {{getIsVideoTitle()}}
    </div>

    <div *ngIf="!getIsRunReport()" id="instructions-div " class="row justify-content-center mt-1">
        <div *ngIf="!data.isSessionExtension">
            <div *ngFor="let elem of data.textBody">
                <p [ngClass]="{'bold-class': elem.isTextBold}" class="p1" id="p1" *ngIf="!getIsVideoPreview()">
                    {{elem.content}}
                </p>
                <p [ngClass]="{'bold-class': elem.isTextBold}" class="p2" id="p1" *ngIf="getIsVideoPreview()">
                    {{elem.content}}
                </p>
            </div>
        </div>

        <!--Logic added just for the session extension pop-up for it required a dynamic countdouwn-->
        <div *ngIf="data.isSessionExtension">
            <p class="p1" id="p1">{{'sessionExtension1' | translate}} <countdown [config]="{leftTime: 300, format: 'm:s' }">
                </countdown>
                {{'sessionExtension2' | translate}}</p>
        </div>

    </div>

    <div *ngIf="getIsRunReport()" style="margin-top: 15px;margin-bottom: 35px;">
        Are you sure you want to run the <b>{{getReportType()}}</b> for the <b>{{getReportOption()}}</b> ?
    </div>

    <div *ngIf="!getIsRunReport() && !getIsVideo()">
        <div *ngIf="getReverseFlag() == false" class="row  justify-content-center mt-2 mb-2">
            <button *ngIf="getOkBtn()" class="mr-2" (mousedown)="ok()" class="submit-btn" id="submit-btn" mat-flat-button color="primary">
                {{getTextFirstButton()}}
            </button>

            <button *ngIf="getCancelBtn()" (mousedown)="close()" class="cancel-btn" id="cancel-btn" mat-flat-button color="white">
                {{getTextSecondButton()}}
            </button>
        </div>

        <div *ngIf="getReverseFlag() == true" class="row  justify-content-center mt-2 mb-2">
             <button *ngIf="getCancelBtn()" (mousedown)="close()" class="cancel-btn" id="cancel-btn" mat-flat-button color="white">
                {{getTextSecondButton()}}
            </button>

            <button *ngIf="getOkBtn() && !getIsRedirectedViaHref()" class="mr-2" (mousedown)="ok()" class="submit-btn" id="submit-btn"
                style="margin-left: 10px;" mat-flat-button color="primary">
                {{getTextFirstButton()}}
            </button>
  
            <button *ngIf="getOkBtn() && getIsRedirectedViaHref()" class="mr-2" class="submit-btn" id="submit-btn" style="margin-left: 10px;" mat-flat-button color="primary">
                <a href="/leads">
                    <div style="width: 50px;">
                        {{getTextFirstButton()}}
                    </div>
                </a>
            </button>
        </div>
    </div>

    <div *ngIf="getIsRunReport()" style="text-align: right;margin-bottom: 20px;">
        <button *ngIf="getCancelBtn()" (mousedown)="close()" id="cancel-btn-report" mat-flat-button color="white">
            {{getTextSecondButton()}}
        </button>

        <button *ngIf="getOkBtn()" class="mr-2" (mousedown)="ok()" class="submit-btn" id="submit-btn"
            style="margin-left: 10px;" mat-flat-button color="primary">
            {{getTextFirstButton()}}
        </button>
    </div>

</mat-dialog-content>

<mat-dialog-content *ngIf="getIsVideo()" style="min-width: auto;min-height: 75%!important;max-height: 75%!important;
padding-top: 25px;">
    <div *ngIf="getIsVideo()">
        <video id="videoId" width="100%" class="video-js vjs-default-skin" controls preload="none"
        data-setup='{ "aspectRatio":"600:220", "playbackRates": [1, 1.5, 2] }' autoplay>
            <source src="{{getVideoUri()}}" type="video/mp4" id="videoId"/>
		    Browser not supported
        </video>
        <br>
        <button (click)="close()" mat-flat-button color="primary" style="float: right;">Close</button>
        <button (click)="restart()" mat-flat-button color="primary" style="float: right; margin-right: 20px;">Restart</button>
    </div>
</mat-dialog-content>