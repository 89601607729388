import { Component, ViewChild, OnInit, HostListener, ViewChildren, QueryList, ElementRef, Output } from '@angular/core';
import { Input } from '@angular/compiler/src/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Button} from 'primeng';
import { Router, ActivatedRoute } from '@angular/router';
import { ProvinceService } from '../../services/province-service/province-service.service';
import { ProfileDetails } from '../../models/profile-details.model';
import { ContactDetails } from '../../models/contact-details.model';
import { AddressInformation } from '../../models/address-information.model';
import { Observable } from 'rxjs';
import { LenderService } from '../../services/api/lender.service';
import { AddressComponent } from '../common/address-component/address-component.component';
import { AsciiCodes } from '../common/constants/asciiCodes';
import { Validation } from '../common/constants/validation';
import { GeneralPromptComponent } from '../common/general-prompt/general-prompt.component';
import { EventEmitter } from '@angular/core';
import { PromptModel } from '../../models/prompt.model';
import { PromptsMessages } from '../common/constants/promptsMessages';
import { ProfileDetailsDTO } from '../../models/dto-profile-details';
import { UserPromptComponent } from '../common/user-prompt/user-prompt.component';
import { ProfileRole } from 'src/app/models/profile-role.model';
import { LenderInfoDTO } from 'src/app/models/LenderInfoDTO.model';
import { ErrorStateMatcher } from "@angular/material/core";
import { MatRadioButton } from "@angular/material/radio";
class CrossFieldErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return (control.dirty || control.touched) && control.invalid;
    }
}

@Component({
    selector: 'app-account-details-cs',
    templateUrl: './account-details-cs.component.html',
    styleUrls: ['./account-details-cs.component.scss']
})

export class AccountDetailsCsComponent implements OnInit {
    key: number;
    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        this.key = event.keyCode;
        return event.keyCode;
    }

    private accountDetails: ProfileDetails;
    private lender: ProfileDetails;
    private lenderDTO = new ProfileDetailsDTO(null, null);
    private contactDetails: ContactDetails;
    private addressInformation: AddressInformation;
    private csvProvincesList: any;
    private globallenderCode = "";
    private globalId = "";
    private stripe_clientId:String;
    public menglishName: boolean;
    public mfrenchName: boolean;
    public mcredentialNumber1: boolean;
    public mcredentialNumber2: boolean;
    public mfirstName: boolean;
    public mlastName: boolean;
    public mphone: boolean;
    public mext: boolean;
    public memail: boolean;
    public maddressLine1: boolean;
    public maddressLine2: boolean;
    public mcity: boolean;
    public mpostalCode: boolean;
    public mprovince: boolean;
    public mequifaxRadioGroup: boolean;
    public mTURadioGroup: boolean;
    public mTURadioGroup2: boolean;
    public mfinastraRadioGroup: boolean;
    public mvettingRadioGroup: boolean;
    public displayLenderCode: any;
    public sentToExpertFromDb: boolean;
    public registeredEquifax: boolean;
    public registeredTransUnion: boolean;
    public registerForm: FormGroup;
    public errorMatcher = new CrossFieldErrorMatcher();
    public buttonDisabledP = true;
    public isReqEquifax: boolean;
    public isReqTU: boolean;
    public lenderNameToDisplayForAccountCs;
    public dataSizeForAccountsCs;
    public booleanFlagForEnglishName: boolean = true;
    public existingLenderUsers = false;
    public newAddedLenderUser = false;
    public promptComponentLenderUser = PromptsMessages.LENDER_USER_EMAIL_ALREADY_EXISTS;
    public provinces = [];
    public countries = [
        { "id": 1, "name": "Canada" }
    ];
    countryS = this.countries[0].id;
    public existingLenderUserList = []
    public testMessage = "<div> test<br><b>test</b></div>";

    @ViewChild('radioFinastraAnswerYes') finastraAnswerYes: MatRadioButton;
    @ViewChild('radioFinastraAnswerNo') finastraAnswerNo: MatRadioButton;
    @ViewChild('radioVettingAnswerYes') vettingAnswerYes: MatRadioButton;
    @ViewChild('radioVettingAnswerNo') vettingAnswerNo: MatRadioButton;
    @ViewChild('radioEquifaxAnswerYes') equifaxAnswerYes: MatRadioButton;
    @ViewChild('radioEquifaxAnswerNo') equifaxAnswerNo: MatRadioButton;
    @ViewChild('radioRegisteredTransUnionYes') registeredTransUnionYes: MatRadioButton;
    @ViewChild('radioRegisteredTransUnionNo') registeredTransUnionNo: MatRadioButton;
    @ViewChild('radioLenderAcceptTransUnionYes') lenderAcceptTransUnionYes: MatRadioButton;
    @ViewChild('radioLenderAcceptTransUnionNo') lenderAcceptTransUnionNo: MatRadioButton;
    @ViewChild('submitButton') submitButton: Button;
    @ViewChild('publishButton') publishButton: Button;
    @ViewChild('phone') phone: Input;
    @ViewChildren('field') fields: QueryList<ElementRef>;
    @ViewChild(AddressComponent) addressComponent: AddressComponent;
    @Output() sendPromptText = new EventEmitter();  
    constructor(private dialog: MatDialog, private provinceService: ProvinceService, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private lenderService: LenderService) {
        this.registerForm = this.formBuilder.group({
            lenderCode: ['', [Validators.required, Validators.maxLength(Validation.LENDER_CODE), Validators.pattern('^([A-Za-z&]+ )+[A-Za-z&]+$|^[A-Za-z&]+$')]],
            englishName: ['', Validators.compose([Validators.required, Validators.maxLength(Validation.ENGLISH_NAME_LENGTH),  Validators.pattern('^([a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ0-9& ])*[A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ0-9& ]*$')])],
            frenchName: ['', Validators.compose([Validators.required, Validators.maxLength(Validation.FRENCH_NAME_LENGTH), Validators.pattern('^([a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ0-9& ])*[A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ0-9& ]*$')])],
            credentialNumberEquifax: ['', [Validators.maxLength(Validation.MEMBER_NUMBER_LENGTH), Validators.pattern("[0-9]{3}[a-zA-Z]{2}[0-9]{5}")]],
            credentialNumberTransUnion: ['', [Validators.maxLength(Validation.MEMBER_NUMBER_LENGTH), Validators.pattern("[0-9]{3}[a-zA-Z]{2}[0-9]{5}")]],
            firstName: ['', [Validators.required, Validators.pattern('^[ a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$')]],
            lastName: ['', [Validators.required, Validators.pattern('^[ a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$')]],
            phone: ['', [Validators.required, Validators.maxLength(Validation.PHONE_LENGTH), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]],
            ext: ['', [Validators.maxLength(Validation.EXTENSION_LENGTH), Validators.pattern("[0-9]{5}")]],
            email: ['', [Validators.required]],
            addressLine1: ['', [Validators.required, Validators.maxLength(Validation.ADDRESS_LENGTH)]],
            addressLine2: ['', [Validators.maxLength(Validation.ADDRESS_LENGTH)]],
            city: ['', [Validators.required, Validators.maxLength(Validation.CITY_LENGTH)]],
            postalCode: ['', [Validators.required, Validators.maxLength(Validation.POSTAL_CODE_LENGTH), Validators.pattern("[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1} [0-9]{1}[a-zA-Z]{1}[0-9]{1}")]],
            finastraRadioGroup: ['', [Validators.required]],
            vettingRadioGroup: ['', [Validators.required]],
            equifaxRadioGroup: ['', [Validators.required]],
            registeredTransUnionRadioGroup: ['', [Validators.required]],
            lenderAcceptTransUnionRadioGroup: ['', [Validators.required]],
            province: ['', [Validators.required]]

        },
            {
                validators: this.inputValidator,
                updateOn: 'blur'
            });

        this.buttonDisabledP = true;
        this.registerForm.get('equifaxRadioGroup').valueChanges
            .subscribe(() => this.setMemberNumberEquifax());
        this.registerForm.get('registeredTransUnionRadioGroup').valueChanges
            .subscribe(() => this.setMemberNumberTU());
        this.registerForm.valueChanges
            .subscribe(() => this.disablePublishButton());
        this.provinceService.getProvinces().subscribe(data => {
            this.csvProvincesList = this.provinceService.CSV2JSON(data);
            var length = this.csvProvincesList.length;
            this.csvProvincesList.splice(0, 1);
            length = this.csvProvincesList.length;
            this.csvProvincesList.splice(length - 1, 1);
            for (let elem in this.csvProvincesList) {
                this.provinces.push({ 'id': elem, 'name': this.csvProvincesList[elem][0] });
            }
        });
    }

    setMemberNumberEquifax(): void {
        const memberNumberEquifax = this.registerForm.get('credentialNumberEquifax');
        if (this.equifaxAnswerYes.checked) {
            if (memberNumberEquifax.disabled) {
                memberNumberEquifax.enable();
            }
            memberNumberEquifax.setValidators([Validators.required, Validators.maxLength(10), Validators.pattern("[0-9]{3}[a-zA-Z]{2}[0-9]{5}")]);
            memberNumberEquifax.updateValueAndValidity();
            this.isReqEquifax = true;
        }

    }

    setMemberNumberTU(): void {
        const memberNumberTU = this.registerForm.get('credentialNumberTransUnion');
        if (this.registeredTransUnionYes.checked) {
            if (memberNumberTU.disabled) {
                memberNumberTU.enable();
            }
            memberNumberTU.setValidators([Validators.required, Validators.maxLength(10), Validators.pattern("[0-9]{3}[a-zA-Z]{2}[0-9]{5}")]);
            memberNumberTU.updateValueAndValidity();
            this.isReqTU = true;
        }

    }

    public promptInfo: any;

    onSubmit() {
        if ((this.finastraAnswerYes.checked && this.vettingAnswerNo.checked) ||
            (this.finastraAnswerNo.checked && this.vettingAnswerYes.checked) ||
            (this.finastraAnswerNo.checked && this.vettingAnswerNo.checked)) {
            var promptInfo;
            this.sendLenderToDatabase(false, true).subscribe(resp => {
                promptInfo = new PromptModel([{ content: PromptsMessages.SAVE_BTN_LR_INCOMPLETE_FORM, isTextBold: false }], true, false, '/lendersList', '', '', false, false, false, false, false);
                this.sendPromptText.emit(promptInfo);
                this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
            }, () => {
                if (promptInfo != null) {
                    this.router.navigateByUrl('/lendersList');
                }
            });
        }

        if (this.finastraAnswerYes.checked && this.vettingAnswerYes.checked) {
            if (this.sentToExpertFromDb) {
                this.sendLenderToDatabase(true, true).subscribe(resp => {
                    promptInfo = new PromptModel([{ content: PromptsMessages.SAVE_AFTER_PUBLISHED_TO_EXPERT, isTextBold: false }], true, false, '/lendersList', '', '', false, false, false, false, false);
                    this.sendPromptText.emit(promptInfo);
                    this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
                }, () => {
                    if (promptInfo != null) {
                        this.router.navigateByUrl('/lendersList');
                    }
                });
            }

            if (!this.sentToExpertFromDb) {
                this.sendLenderToDatabase(false, true).subscribe(resp => {
                    promptInfo = new PromptModel([{ content: PromptsMessages.SAVED_BUT_NOT_PUBLISHED, isTextBold: false }], true, false, '/lendersList', '', '', false, false, false, false, false);
                    this.sendPromptText.emit(promptInfo);
                    this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });

                }, () => {
                    if (promptInfo != null) {
                        this.router.navigateByUrl('/lendersList');
                    }
                });
            }
        }
    }

    createLenderFromDBResponse(item: any): ProfileDetails {
        if (!item.profileRole.includes(ProfileRole.LENDER_USER) || (item.profileRole.includes(ProfileRole.LENDER_USER) && item.profileRole.includes(ProfileRole.LENDER_ADMIN))){
        var decodedCity = decodeURI(item.addressInformation.city);
        decodedCity = decodedCity.toString().replace(/~~pct~~/g, '%');

        var decodedAddressOne = decodeURI(item.addressInformation.addressOne);
        decodedAddressOne = decodedAddressOne.toString().replace(/~~pct~~/g, '%');

        var decodedAddressTwo = decodeURI(item.addressInformation.addressTwo);
        decodedAddressTwo = decodedAddressTwo.toString().replace(/~~pct~~/g, '%');

        var decodedFrenchName = decodeURI(item.lenderFrenchName);
        decodedFrenchName = decodedFrenchName.toString().replace(/~~pct~~/g, '%');

        var decodedFirstName = decodeURI(item.contactDetails.firstName);
        decodedFirstName = decodedFirstName.toString().replace(/~~pct~~/g, '%');

        var decodedLastName = decodeURI(item.contactDetails.lastName);
        decodedLastName = decodedLastName.toString().replace(/~~pct~~/g, '%');

        var contactDetails = new ContactDetails(decodedFirstName, decodedLastName, item.contactDetails.phoneNumber, item.contactDetails.extension, item.contactDetails.email)
        var addressInformation = new AddressInformation(decodedAddressOne, decodedAddressTwo, decodedCity, item.addressInformation.postalCode, item.addressInformation.province)
        }
        else if (item.profileRole.includes(ProfileRole.LENDER_USER) && !item.profileRole.includes(ProfileRole.LENDER_ADMIN)){
            var decodedFrenchName = decodeURI(item.lenderFrenchName);
            decodedFrenchName = decodedFrenchName.toString().replace(/~~pct~~/g, '%');
    
            var decodedFirstName = decodeURI(item.contactDetails.firstName);
            decodedFirstName = decodedFirstName.toString().replace(/~~pct~~/g, '%');
    
            var decodedLastName = decodeURI(item.contactDetails.lastName);
            decodedLastName = decodedLastName.toString().replace(/~~pct~~/g, '%');
            var contactDetails = new ContactDetails(decodedFirstName, decodedLastName, item.contactDetails.phoneNumber, item.contactDetails.extension, item.contactDetails.email)

        }
        this.stripe_clientId = item.stripe_clientId;

        return new ProfileDetails(
            item.lenderName,
            decodedFrenchName,
            item.equifaxMember,
            item.equifaxCredentialNumber,
            item.transUnionMember,
            item.transUnionCredentialNumber,
            item.acceptTUBureaus,
            item.finastraContractsCompleted,
            item.lenderVettingCompleted,
            contactDetails,
            addressInformation,
            item.lenderCode,
            item.id,
            item.isActive,
            item.sentToExpert,
            item.creationDate,
            item.stripe_clientId,
            item.loan_price,
            item.profileRole
        )
    }

    setFormValue(form: FormGroup, name: string, value: any) {
        form.get(name).setValue(value);
        form.get(name).setErrors(null);
    }

    ngOnInit() {
        var lenderCode;
        var id;
        this.route.queryParams
            .subscribe(params => {
                lenderCode = params['lenderCode'];
                id = params['id'];
            });

        this.displayLenderCode = `${lenderCode}`;
        this.globallenderCode = lenderCode;
        this.globalId = id;
        this.lenderService.getProfileDetails(lenderCode).subscribe(response => {
            if (response.lenderUsers) {
                this.existingLenderUserList = response.lenderUsers;
            }
            
            this.existingLenderUsers = response.lenderUsers ? true : false;
            this.accountDetails = this.createLenderFromDBResponse(response.lenderAccount);
            var details = this.accountDetails;
            this.sentToExpertFromDb = details.sentToExpert;

            this.setFormValue(this.registerForm, 'lenderCode', details.getLenderCode);
            this.setFormValue(this.registerForm, 'englishName', details.getLenderName);
            this.setFormValue(this.registerForm, 'frenchName', details.getLenderFrenchName);

            if (details.getEquifaxMember) {
                this.equifaxAnswerYes.checked = true;
                this.registeredEquifax = true;
                this.setMemberNumberEquifax();
                this.setFormValue(this.registerForm, 'credentialNumberEquifax', details.getCredentialNumberEq);
                this.registerForm.get('equifaxRadioGroup').setErrors(null);
            } else {
                this.equifaxAnswerNo.checked = true;
                this.registeredEquifax = false;
                this.registerForm.get('credentialNumberEquifax').disable();
                this.registerForm.get('equifaxRadioGroup').setErrors(null);
            }

            if (details.getTuMemberLender) {
                this.registeredTransUnionYes.checked = true;
                this.registeredTransUnion = true;
                this.setMemberNumberTU();
                this.setFormValue(this.registerForm, 'credentialNumberTransUnion', details.getCredentialNumberTu);
                this.registerForm.get('registeredTransUnionRadioGroup').setErrors(null);
            } else {
                this.registeredTransUnionNo.checked = true;
                this.registeredTransUnion = false;
                this.registerForm.get('credentialNumberTransUnion').disable();
                this.registerForm.get('registeredTransUnionRadioGroup').setErrors(null);
            }

            if (details.getAcceptTUBureaus) {
                this.lenderAcceptTransUnionYes.checked = true;
                this.registerForm.get('lenderAcceptTransUnionRadioGroup').setErrors(null);
            } else {
                this.lenderAcceptTransUnionNo.checked = true;
                this.registerForm.get('lenderAcceptTransUnionRadioGroup').setErrors(null);
            }

            if (details.getFinastraContracts) {
                this.finastraAnswerYes.checked = true;
                this.registerForm.get('finastraRadioGroup').disable();
                this.registerForm.get('finastraRadioGroup').setErrors(null);
            } else {
                this.finastraAnswerNo.checked = true;
                this.registerForm.get('finastraRadioGroup').setErrors(null);
            }

            if (details.getLenderVetting) {
                this.vettingAnswerYes.checked = true;
                this.registerForm.get('vettingRadioGroup').disable();
                this.registerForm.get('vettingRadioGroup').setErrors(null);
            } else {
                this.vettingAnswerNo.checked = true;
                this.registerForm.get('vettingRadioGroup').setErrors(null);
            }

            this.setFormValue(this.registerForm, 'firstName', details.contactDetails.firstName);
            this.setFormValue(this.registerForm, 'lastName', details.contactDetails.lastName);
            this.setFormValue(this.registerForm, 'phone', details.contactDetails.phoneNumber);
            this.setFormValue(this.registerForm, 'ext', details.contactDetails.extension);
            this.setFormValue(this.registerForm, 'email', details.contactDetails.email);
            this.setFormValue(this.registerForm, 'addressLine1', details.addressInformation.addressOne);
            this.setFormValue(this.registerForm, 'addressLine2', details.addressInformation.addressTwo);
            this.setFormValue(this.registerForm, 'city', details.addressInformation.city);
            this.setFormValue(this.registerForm, 'postalCode', details.addressInformation.postalCode);
            this.setFormValue(this.registerForm, 'province', details.addressInformation.province);
        });
    }

    ngAfterViewInit() {
        this.registerForm.addControl('addressLookup', this.addressComponent.addressForm);
        this.addressComponent.addressForm.setParent(this.registerForm);
    }

    inputValidator(form: FormGroup) {
        const condition = form.get('englishName').value.length == Validation.ENGLISH_NAME_LENGTH;
        return condition ? { invalidInput: true } : null;
    }

    disablePublishButton() {
        if (this.sentToExpertFromDb)
            this.buttonDisabledP = true;
            
        if (this.registerForm.valid) {
            if (!this.sentToExpertFromDb) {
                this.buttonDisabledP = false;
            } else if ((this.finastraAnswerYes.checked && this.vettingAnswerNo.checked) ||
                (this.finastraAnswerNo.checked && this.vettingAnswerYes.checked) ||
                (this.finastraAnswerNo.checked && this.vettingAnswerNo.checked)) {
                this.buttonDisabledP = true;
            } else if (this.finastraAnswerYes.checked && this.vettingAnswerYes.checked && !this.sentToExpertFromDb) {
                this.buttonDisabledP = false;
            }
        }
        else {
            this.buttonDisabledP = true;
        }
    }

    onPublish() {
        this.buttonDisabledP = true;
        this.sendLenderToDatabase(true, true).subscribe(() => {
            var promptInfo = new PromptModel([{ content: PromptsMessages.MSG1_PUBLISHED_TO_EXPERT, isTextBold: false },
            { content: PromptsMessages.MSG2_PUBLISHED_TO_EXPERT, isTextBold: true }], true, false, '/lendersList', '', '', false, false, false, false, false)
            this.sendPromptText.emit(promptInfo);
            this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
        }, () => {
            if (this.promptInfo != null) {
                this.router.navigateByUrl('/lendersList');
            }
        });
    }

    onCancel() {
        if (this.registerForm.touched) {
            var promptInfo = new PromptModel([{ content: PromptsMessages.CANCEL_MESSAGE, isTextBold: false }], true, true, '/lendersList', '', '', false, false, false, false, false);
            this.sendPromptText.emit(promptInfo);
            this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });

            this.menglishName = this.registerForm.get('englishName').dirty;
            this.mfrenchName = this.registerForm.get('frenchName').dirty;
            this.mcredentialNumber1 = this.registerForm.get('credentialNumberEquifax').dirty;
            this.mcredentialNumber2 = this.registerForm.get('credentialNumberTransUnion').dirty;
            this.mfirstName = this.registerForm.get('firstName').dirty;
            this.mlastName = this.registerForm.get('lastName').dirty;
            this.mphone = this.registerForm.get('phone').dirty;
            this.memail = this.registerForm.get('email').dirty;
            this.mext = this.registerForm.get('ext').dirty;
            this.maddressLine1 = this.registerForm.get('addressLine1').dirty;
            this.maddressLine2 = this.registerForm.get('addressLine2').dirty;
            this.mcity = this.registerForm.get('city').dirty;
            this.mpostalCode = this.registerForm.get('postalCode').dirty;
            this.mprovince = this.registerForm.get('province').dirty;
            this.mequifaxRadioGroup = this.registerForm.get('equifaxRadioGroup').dirty;
            this.mTURadioGroup = this.registerForm.get('registeredTransUnionRadioGroup').dirty;
            this.mTURadioGroup2 = this.registerForm.get('lenderAcceptTransUnionRadioGroup').dirty;
            this.mfinastraRadioGroup = this.registerForm.get('finastraRadioGroup').dirty;
            this.mvettingRadioGroup = this.registerForm.get('vettingRadioGroup').dirty;
        }
        else {
            this.router.navigateByUrl('/lendersList');
        }
    }

    eliminateExtraSpaces(value) {
        if (value.match(/^\s* {1}$/)) {
            value = value.substring(0, value.length - 1);
        }
        else {
            if (value.length > 2 && value.substring(value.length - 2, value.length).match(/^\s* {2}$/)) {
                value = value.substring(0, value.length - 1);
            }
        }
        return value
    }
    changeFieldNoSpaces(event: any, name: string): void {
        if (event) {
            var value = event.target.value;
            if (value.match(/^\s* {1}$/)) {
                value = value.substring(0, value.length - 1);
            }else if (value.length > 2 && value.substring(value.length - 2, value.length).match(/^\s* {2}$/)) {
                value = value.substring(0, value.length - 1);
            }

            this.registerForm.get(name).setValue(value);
            this.registerForm.get(name).markAsTouched();

        }
    }
    changeCredential(event: any, name: string): void {
        if (event) {
            var value = event.target.value;
            if (value.match(/^\s* {1}$/)) {
                value = value.substring(0, value.length - 1);
            }
            this.registerForm.get(name).setValue(value);
            this.registerForm.get(name).markAsTouched();
        }
    }

    changePhone(fn: string): void {
        if (fn) {
            var formatted = this.formatPhoneText(fn);
            this.registerForm.get('phone').setValue(formatted);
            this.registerForm.get('phone').markAsTouched();
        }
    }

    formatPhoneText(value): string {
        value = value.replace(/[^\w\s]/gi, "");

        if (value.length > 3 && value.length <= 6)
            value = value.slice(0, 3) + "-" + value.slice(3);
        else if (value.length > 6)
            value = value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6);

        return value;
    }

    keydownPhone(fn: any): void {
        var filter = [];

        //add key codes for digits 0 - 9 into this filter
        for (var i = 0; i <= 9; i++) {
            filter.push(i + AsciiCodes.KEYPAD_ZERO);
            filter.push(i + AsciiCodes.NUMPAD_ZERO);
        }

        //or for editing the keyboard input
        filter.push(AsciiCodes.BACKSPACE_KEY);
        filter.push(AsciiCodes.TAB_KEY);
        filter.push(AsciiCodes.DELETE_KEY);
        filter.push(AsciiCodes.LEFT_ARROW_KEY);
        filter.push(AsciiCodes.RIGHT_ARROW_KEY);
        if (filter.indexOf(fn.keyCode) < 0) {
            fn.preventDefault();

        }
    }

    changeContactField(event: any, name: string) {
        if (event) {
            this.registerForm.get(name).setValue(event.target.value);
            this.registerForm.get(name).markAsTouched();
        }
    }

    changeProvince(fn: any) {
        if (fn) {
            this.registerForm.get('province').markAsTouched();
            this.registerForm.get('province').setValue(fn.value);
        }
    }

    setAddressValues(addressInfo: AddressInformation) {
        this.registerForm.get('addressLine1').setValue(addressInfo.addressTwo + " " + addressInfo.addressOne);
        this.registerForm.get('city').setValue(addressInfo.city);
        this.registerForm.get('postalCode').setValue(addressInfo.postalCode);
        this.registerForm.get('province').setValue(addressInfo.province);
    }

    keydownPC(fn: any): void {
        var filter = [];

        //add key codes for digits 0 - 9 into this filter
        for (var i = 0; i <= 9; i++) {
            filter.push(i + AsciiCodes.KEYPAD_ZERO);
            filter.push(i + AsciiCodes.NUMPAD_ZERO);
        }

        //add key codes for digits letters into this filter
        for (var i = 0; i <= 25; i++) {
            filter.push(i + AsciiCodes.ALPHA_UPPER);
            filter.push(i + AsciiCodes.ALPHA_LOWER);
        }

        //add other keys that might be needed for navigation
        //or for editing the keyboard input
        filter.push(AsciiCodes.BACKSPACE_KEY);
        filter.push(AsciiCodes.TAB_KEY);
        filter.push(AsciiCodes.DELETE_KEY);
        filter.push(AsciiCodes.LEFT_ARROW_KEY);
        filter.push(AsciiCodes.RIGHT_ARROW_KEY);
        if (filter.indexOf(fn.keyCode) < 0) {
            fn.preventDefault();

        }
    }

    formatPCText(value): string {
        value = value.replace(" ", "");
        value = value.replace(")", "");
        value = value.replace("(", "");

        if (value.length > 3 && value.length <= 6)
            value = value.slice(0, 3) + " " + value.slice(3);
        else if (value.length > 6)
            value = value.slice(0, 3) + " " + value.slice(3, 6) + value.slice(6);

        return value;
    }

    changePC(fn: string): void {
        if (fn) {
            var formatted = this.formatPCText(fn);
            this.registerForm.get('postalCode').setValue(formatted);
            this.registerForm.get('postalCode').markAsTouched();
        }
    }

    sendLenderToDatabase(isPublishedToExpert: boolean, isOnlyForLenderAccount: boolean): Observable<ProfileDetails> {
        var lenderName = this.registerForm.get("englishName").value;
        var lenderFrenchName = this.registerForm.get("frenchName").value;

        if (lenderName.substring(lenderName.length - 1, lenderName.length).match(/^\s* {1}$/)) {
            lenderName = lenderName.substring(0, lenderName.length - 1);
        }
        if (lenderFrenchName.substring(lenderFrenchName.length - 1, lenderFrenchName.length).match(/^\s* {1}$/)) {
            lenderFrenchName = lenderFrenchName.substring(0, lenderFrenchName.length - 1);
        }
         lenderFrenchName = encodeURI(lenderFrenchName.toString().replace(/%/g, '~~pct~~'));
        var equifaxMember = this.equifaxAnswerYes.checked;
        var equifaxMemberNumber = equifaxMember ? this.registerForm.get("credentialNumberEquifax").value : '';
        var transUnionMember = this.registeredTransUnionYes.checked;
        var transUnionMemberNumber = transUnionMember ? this.registerForm.get("credentialNumberTransUnion").value : '';
        var acceptTUBureaus = this.lenderAcceptTransUnionYes.checked;
        var finastraContractsCompleted = this.finastraAnswerYes.checked;
        var lenderVettingCompleted = this.vettingAnswerYes.checked;
        var firstName = encodeURI(this.registerForm.get("firstName").value.toString().replace(/%/g, '~~pct~~'));
        var lastName = encodeURI(this.registerForm.get("lastName").value.toString().replace(/%/g, '~~pct~~'));
        var phoneNumber = this.registerForm.get("phone").value;
        var extension = this.registerForm.get("ext").value;
        var email = this.registerForm.get("email").value;
        this.contactDetails = new ContactDetails(firstName, lastName, phoneNumber, extension, email.toLowerCase());
        var addressOne = encodeURI(this.registerForm.get("addressLine1").value.toString().replace(/%/g, '~~pct~~'));

        var addressTwo = encodeURI(this.registerForm.get("addressLine2").value.toString().replace(/%/g, '~~pct~~'));
        var city = encodeURI(this.registerForm.get("city").value.toString().replace(/%/g, '~~pct~~'));
        var postalCode = this.registerForm.get("postalCode").value;
        var province = this.registerForm.get("province").value;
        this.addressInformation = new AddressInformation(addressOne, addressTwo, city, postalCode, province);

        var lenderCode = this.globallenderCode;
        var id = this.globalId;
        var isActive = true;
        var publishedToExpert = isPublishedToExpert;
        this.lender = new ProfileDetails(lenderName, lenderFrenchName, equifaxMember, equifaxMemberNumber,
            transUnionMember, transUnionMemberNumber, acceptTUBureaus, finastraContractsCompleted,
            lenderVettingCompleted, this.contactDetails, this.addressInformation, lenderCode, id, isActive, publishedToExpert, null,this.stripe_clientId,0, []);
        this.lenderDTO.lenderAccount = this.lender;

        return this.lenderService.updateLender(this.lenderDTO);
    }

    onRegisteredEquifax(): void {
        this.registeredEquifax = true;
    }

    onNotRegisteredEquifax(): void {
        const memberNumberEquifax = this.registerForm.get('credentialNumberEquifax');

        if (!this.equifaxAnswerYes.checked && memberNumberEquifax.value != '') {
            var promptInfo = new PromptModel([{ content: PromptsMessages.MSG1_RADIO_BUTTONS_PROMPT, isTextBold: false }, { content: PromptsMessages.MSG2_RADIO_BUTTONS_PROMPT, isTextBold: false }], true, true, '', '', '', false, false, false, false, false);
            this.sendPromptText.emit(promptInfo);
            let dialogRef = this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });

            dialogRef.afterOpened().subscribe(() => {
                dialogRef.componentInstance.onCancel.subscribe(() => {
                    this.equifaxAnswerYes.checked = true;
                    memberNumberEquifax.enable();
                    this.registeredEquifax = true;
                });
                dialogRef.componentInstance.onOk.subscribe(() => {
                    this.registeredEquifax = false;
                    memberNumberEquifax.setValue('');
                    memberNumberEquifax.disable()
                });
            })            
        } else {
            this.registeredEquifax = false;
            memberNumberEquifax.disable()
        }
    }

    onRegisteredTransUnion(): void {
        this.registeredTransUnion = true;
    }

    onNotRegisteredTransUnion(): void {
        const memberNumberTU = this.registerForm.get('credentialNumberTransUnion');

        if (memberNumberTU.value != '') {
            var promptInfo = new PromptModel([{ content: PromptsMessages.MSG1_RADIO_BUTTONS_PROMPT, isTextBold: false }, { content: PromptsMessages.MSG2_RADIO_BUTTONS_PROMPT, isTextBold: false }], true, true, '', '', '', false, false, false, false, false);
            this.sendPromptText.emit(promptInfo);
            let dialogRef = this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
            
            dialogRef.afterOpened().subscribe(() => {
                dialogRef.componentInstance.onCancel.subscribe(() => {
                    this.registeredTransUnionYes.checked = true;
                    memberNumberTU.enable();
                    this.registeredTransUnion = true;
                });
                dialogRef.componentInstance.onOk.subscribe(() => {
    
                    memberNumberTU.setValue('');
                    this.registeredTransUnion = false;
                    memberNumberTU.disable()
                });
            });
            
        } else {
            this.registeredTransUnion = false;
            memberNumberTU.disable();
        }
    }

    onAddUser(event: any) {
        var dialogueInfo = { accountDetails: true }
        this.sendPromptText.emit(dialogueInfo);
        let dialogueBoxRef = this.dialog.open(UserPromptComponent, {
            autoFocus: false, data: dialogueInfo,
            minHeight: 'calc(60vh - 90px)',
            height: 'auto'
        });

        dialogueBoxRef.afterOpened().subscribe(() => {
            dialogueBoxRef.componentInstance.onSave.subscribe(() => {
                dialogueBoxRef.afterClosed().subscribe(data => {
                    
                    let lenderUserData = new LenderInfoDTO(null,null,null,null,null,null,null,null,null,null);
                    let lenderUserContactDetails = new ContactDetails(null,null,null,null,null);

                    lenderUserData.fk_LenderAccount = this.accountDetails.lenderCode;
                    lenderUserData.isUserAdmin = data.isAdmin;

                    lenderUserContactDetails.firstName = data.firstName;
                    lenderUserContactDetails.lastName = data.lastName;
                    lenderUserContactDetails.phoneNumber = data.phone;
                    lenderUserContactDetails.extension = data.ext;
                    lenderUserContactDetails.email = data.email;
                    
                    lenderUserData.contactDetails = lenderUserContactDetails;

                    this.createLenderUser(lenderUserData);
                });
            });
        });
    }

    onEditExistingUser(event: any, userId: string) {
        var editUser;
        if (this.existingLenderUserList.length > 0) {
            for (let i = 0; i < this.existingLenderUserList.length; i++) {
                if (this.existingLenderUserList[i].id == userId) {
                    editUser = {
                
                        firstName: this.existingLenderUserList[i].contactDetails.firstName, lastName: this.existingLenderUserList[i].contactDetails.lastName,
                        email: this.existingLenderUserList[i].contactDetails.email, phone: this.existingLenderUserList[i].contactDetails.phoneNumber, ext: this.existingLenderUserList[i].contactDetails.extension,
                        id: this.existingLenderUserList[i].id, profileType: this.existingLenderUserList[i].profileType, editedUser: true,
                        isAdmin: this.existingLenderUserList[i].profileRole.indexOf('LENDER_ADMIN') > -1 ? 'yes' : 'no'
                    }
                }
            }
        }

        var dialogueInfo = {
            accountDetails: true, editData: editUser
        }
        this.sendPromptText.emit(dialogueInfo);
        let dialogueBoxRef = this.dialog.open(UserPromptComponent, {
            autoFocus: false, data: dialogueInfo,
            minHeight: 'calc(60vh - 90px)',
            height: 'auto'
        });

        dialogueBoxRef.afterOpened().subscribe(() => {
            dialogueBoxRef.componentInstance.onSave.subscribe(() => {
                dialogueBoxRef.afterClosed().subscribe(data => {

                    let lenderUserData = new LenderInfoDTO(null,null,null,null,null,null,null,null,null,null);
                    let lenderUserContactDetails = new ContactDetails(null,null,null,null,null);

                    lenderUserData.fk_LenderAccount = this.accountDetails.lenderCode;
                    lenderUserData.isUserAdmin = data.isAdmin;
                    lenderUserData.lender_id = data.id;
                    lenderUserData.isActive = data.isActive;

                    lenderUserContactDetails.firstName = data.firstName;
                    lenderUserContactDetails.lastName = data.lastName;
                    lenderUserContactDetails.phoneNumber = data.phone;
                    lenderUserContactDetails.extension = data.ext;
                    lenderUserContactDetails.email = data.email;
                    
                    lenderUserData.contactDetails = lenderUserContactDetails;

                    this.updateLenderUser(lenderUserData);
                });
            });
    
            dialogueBoxRef.componentInstance.onDelete.subscribe(() => {
                dialogueBoxRef.afterClosed().subscribe(data => {
                    let lenderUserData = new LenderInfoDTO(null,null,null,null,null,null,null,null,null,null);
                    let lenderUserContactDetails = new ContactDetails(null,null,null,null,null);

                    lenderUserData.lender_id = data.id;
                    lenderUserData.isActive = false;
                    
                    lenderUserData.contactDetails = lenderUserContactDetails;

                    this.deleteLenderUser(lenderUserData);
                });
            });
        });
    }

    createLenderUser(lenderUserData: LenderInfoDTO) {
        
        this.lenderService.createLenderUser(lenderUserData).subscribe(data => {
            /*
            *After a user is inserted we need to fetch it's db generated id for it is required in the orher metods(delete and update lender user),
            *hence the recall of getProfileDetails
            */
            if(data != null && data != undefined){
                this.reinitializeLenderUserList();
            }
        });
    }

    updateLenderUser(lenderUserData: LenderInfoDTO) {
        this.lenderService.updateLenderUser(lenderUserData).subscribe(data => {
            /*
            *After a user is updated we need to fetch it's updated details so we have the fe up to date with the DB
            */
            if(data != null && data != undefined){
                this.reinitializeLenderUserList();
            }
        });
    }

    deleteLenderUser(lenderUserData: LenderInfoDTO) {
        this.lenderService.updateLenderUser(lenderUserData).subscribe(data => {
            /*
            *After a user is deleted in order to remove it from the list we need to call the BE
            */
            if(data != null && data != undefined){
                this.reinitializeLenderUserList();
            }
        });
    }

    reinitializeLenderUserList() {
        this.lenderService.getProfileDetails(this.accountDetails.lenderCode).subscribe(response => {
            if (response != null && response != undefined) {
                let lenderUsers = response.lenderUsers;

                this.existingLenderUserList = lenderUsers;
            }
        });
    }
}