<app-header></app-header>
<div id="table-background" style="min-width: 75%;max-width: 75%;">
    <br />
    <br />
    <div id="add_search">
        <button (click)="preventDefault($event)" mat-stroked-button color="primary" id="addButton" routerLink="/lenderRegistration" style="font-family: sans-serif;">
            <mat-icon style="margin-bottom: 3px;">add</mat-icon>
            Add New Lender
        </button>
        
          <mat-form-field floatLabel="never" style="position: absolute;right: 18vw;">
            <mat-icon id="search-icon" matPrefix>search</mat-icon>
            <input id="search-input" type="search" matInput placeholder="Search" (keyup.enter)="searchByLenderCodeOrLenderName($event)" [(ngModel)]="search">
            <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="repopulateLendersList($event); search=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
         
    </div>

    <br />

    <mat-table [dataSource]="dataSource">
        <!-- Position Column -->
        <ng-container matColumnDef="lenderName">
            <mat-header-cell *matHeaderCellDef class="center"> Lender Name </mat-header-cell>
            <mat-cell *matCellDef="let element" id="lender_name"> {{element.lenderName}} </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="primaryContact">
            <mat-header-cell *matHeaderCellDef class="center" id="contact_details_header"> Contact Details </mat-header-cell>
            <mat-cell *matCellDef="let element" id="contact_details">
                {{element.contactDetails.firstName+" "+element.contactDetails.lastName}}<br />
                {{element.contactDetails.email}}<br />
                {{element.contactDetails.phoneNumber}}
            </mat-cell>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef class="center"> Lender Code </mat-header-cell>
            <mat-cell *matCellDef="let element" id="lender_code"> {{element.lenderCode}} </mat-cell>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="isActive">
            <mat-header-cell *matHeaderCellDef class="center"> Status </mat-header-cell>
            <mat-cell *matCellDef="let element" id="isActive"> {{element.sentToExpert===true ? 'Published to Expert' : 'Pending'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="details">
            <mat-header-cell *matHeaderCellDef class="center">&nbsp; Details</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index"><button mat-stroked-button (click)="viewLender($event, i)" #buttons style="font-family: sans-serif; font-size: 13px; color: white; background-color:#493694">View</button></mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" style="min-height: 6vw;max-height: 13vw;height: unset;"></mat-row>
    </mat-table>
    <div class="noLendersMessage" [hidden]=getNoLenders()>
        <p> For the moment, there are no lenders added to Private Lender Gateway. </p>
    </div>
    <div [hidden] = "displayPaginator()" style="text-align-last: right; overflow: hidden; margin-right: 7em; margin-bottom: 3em;">
        <app-paginator
            [pageSize] = "10"
            [activePage] = "getActivePage()"
            [nrOfPages] = "getNumberOfPages()"
            [pageSizeOptions]="getPageSizeOptions()"
            (onPageChange) = "onPageChange($event)" 
            style="width: 40em;">
        </app-paginator>    
    </div>
</div>

