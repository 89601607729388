export class LoanDecision {

    private loanAppId: string;
    private applicationId: string;
    private status: string;
    private notes: string;
    private documentPDF: string;
    private pdfName: string;
    private denialReason: string;
    private isMessageToBroker: boolean;

    constructor(loanAppId: string, applicationId: string, status: string, notes: string, pdf: string, pdfName: string, denialReason: string, isMessageToBroker: boolean) {
        this.loanAppId = loanAppId;
        this.applicationId = applicationId;
        this.notes = notes;
        this.status = status;
        this.documentPDF = pdf;
        this.pdfName = pdfName;
        this.denialReason = denialReason;
        this.isMessageToBroker = isMessageToBroker;
    }

    public setPdfName(pdfName: string) {
        this.pdfName = pdfName;
    }

}