import { Component, OnInit, EventEmitter, Output, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Validation } from '../constants/validation';
import { AsciiCodes } from '../constants/asciiCodes';
import { PromptModel } from '../../../models/prompt.model';
import { PromptsMessages } from '../constants/promptsMessages';
import { GeneralPromptComponent } from '../general-prompt/general-prompt.component';
import * as _ from "lodash";
import { TestBed } from '@angular/core/testing';
import { AccountDetailsCsComponent } from '../../account-details-cs/account-details-cs.component';
import { LenderService } from 'src/app/services/api/lender.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatRadioButton } from "@angular/material/radio";
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { LenderLanguage } from 'src/app/models/lender-language';
import { LanguageService } from 'src/app/services/language-service/language.service';
class CrossFieldErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return (control.dirty || control.touched) && control.invalid;
    }
}
@Component({
    selector: 'app-user-prompt',
    templateUrl: './user-prompt.component.html',
    styleUrls: ['./user-prompt.component.scss']
})

export class UserPromptComponent implements OnInit {
    public addUserForm: FormGroup;
    @Output() sendPromptText: EventEmitter<any> = new EventEmitter<any>();
    public onSave = new EventEmitter();
    public onDelete = new EventEmitter();
    public saveDialogMessage: any;
    public formReceivedData: any;
    public userToEdit: any;
    public isEditDialogue = false;
    public sumbitButtonText;    
    public promptComponentLenderUser = PromptsMessages.LENDER_USER_EMAIL_ALREADY_EXISTS;
    
    public emailError = false;
    public lenderNameForEmailError = '';
    public madminUserRadioGroup = false;
    public profileDetails: any;
    private jwtHelper = new JwtHelperService();

    @ViewChild('radioUserAdminYes') userAdminYes: MatRadioButton;
    @ViewChild('radioUserAdminNo') userAdminNo: MatRadioButton;
    constructor(private cookieService: CookieService, private lenderService: LenderService, private formBuilder: FormBuilder, private dialog: MatDialog, public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any, private changeDetector: ChangeDetectorRef) {
        this.addUserForm = this.formBuilder.group({
            firstName: ['', [Validators.required, Validators.pattern('^[ a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$')]],
            lastName: ['', [Validators.required, Validators.pattern('^[ a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$')]],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, Validators.maxLength(Validation.PHONE_LENGTH), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]],
            ext: ['', [Validators.maxLength(Validation.EXTENSION_LENGTH), Validators.pattern("[0-9]{5}")]],
            adminUserRadioGroup: ['', [Validators.required]]
        });
        if (!data.accountDetails) {
            this.saveDialogMessage = PromptsMessages.USER_DIALOGUE_SAVE_MESSAGE_LENDER_REGISTRATION;
        }
        this.formReceivedData = data;
    }

    ngOnInit() {
        this.changeDetector.detectChanges();
        if (this.formReceivedData.editData) {
            this.isEditDialogue = this.formReceivedData.editData.editedUser;
            this.addUserForm.get('firstName').setValue(this.formReceivedData.editData.firstName);
            this.addUserForm.get('firstName').setErrors(null);
            this.addUserForm.get('lastName').setValue(this.formReceivedData.editData.lastName);
            this.addUserForm.get('lastName').setErrors(null);
            this.addUserForm.get('email').setValue(this.formReceivedData.editData.email);
            this.addUserForm.get('email').setErrors(null);
            this.addUserForm.get('phone').setValue(this.formReceivedData.editData.phone);
            this.addUserForm.get('phone').setErrors(null);
            this.addUserForm.get('ext').setValue(this.formReceivedData.editData.ext);
            this.addUserForm.get('ext').setErrors(null);
            if (this.formReceivedData.editData.isAdmin === 'yes' || this.formReceivedData.editData.isAdmin === 'no'){
                this.userAdminYes.checked = this.formReceivedData.editData.isAdmin === 'yes' ? true : false;
                this.userAdminNo.checked = this.formReceivedData.editData.isAdmin === 'no' ? true : false;
            }
            else if (this.formReceivedData.editData.isAdmin === true || this.formReceivedData.editData.isAdmin === false){
                this.userAdminYes.checked = this.formReceivedData.editData.isAdmin == true ? true : false;
                this.userAdminNo.checked = this.formReceivedData.editData.isAdmin == false ? true : false;
            }
            this.addUserForm.get('adminUserRadioGroup').setErrors(null);
        }
        if (this.isEditDialogue) {
            this.sumbitButtonText = "Save"
        } else {
            this.sumbitButtonText = "Add User"
        }
    }

    eliminateExtraSpaces(value) {
        if (value.match(/^\s* {1}$/)) {
            value = value.substring(0, value.length - 1);
        }
        else {
            if (value.length > 2 && value.substring(value.length - 2, value.length).match(/^\s* {2}$/)) {
                value = value.substring(0, value.length - 1);
            }
        }
        return value
    }

    changeFieldNoSpacesAndDigits(event: any, name: string): void {
        if (event) {
            var value = this.eliminateExtraSpaces(event.target.value);
            if (value.match(/^\s* {1}$/) || value.match(".*[0-9].*")) {
                value = value.substring(0, value.length - 1);
            }
            else if (value.length > 2 && value.substring(value.length - 2, value.length).match(/^\s* {2}$/)) {
                value = value.substring(0, value.length - 1);
            }
            this.addUserForm.get(name).setValue(value);
            this.addUserForm.get(name).markAsTouched();
        }
    }

    changeFieldNoSpaces(event: any, name: string) {
        if (event) {
            var value = this.eliminateExtraSpaces(event.target.value);

            this.addUserForm.get(name).setValue(value);
            this.addUserForm.get(name).markAsTouched();
        }
    }

    changePhone(fn: string): void {
        if (fn) {
            var formatted = this.formatPhoneText(fn);
            this.addUserForm.get('phone').setValue(formatted);
            this.addUserForm.get('phone').markAsTouched();
        }
    }

    formatPhoneText(value): string {
        value = value.replace(/[^\w\s]/gi, "");

        if (value.length > 3 && value.length <= 6)
            value = value.slice(0, 3) + "-" + value.slice(3);
        else if (value.length > 6)
            value = value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6);

        return value;
    }

    keydownPhone(fn: any): void {
        var filter = [];
        for (var i = 0; i <= 9; i++) {
            filter.push(i + AsciiCodes.KEYPAD_ZERO);
            filter.push(i + AsciiCodes.NUMPAD_ZERO);
        }
        filter.push(AsciiCodes.BACKSPACE_KEY);
        filter.push(AsciiCodes.TAB_KEY);
        filter.push(AsciiCodes.DELETE_KEY);
        filter.push(AsciiCodes.LEFT_ARROW_KEY);
        filter.push(AsciiCodes.RIGHT_ARROW_KEY);
        if (filter.indexOf(fn.keyCode) < 0) {
            fn.preventDefault();
        }
    }

    changeContactField(event: any, name: string) {
        if (event) {
            this.addUserForm.get(name).setValue(event.target.value);
            this.addUserForm.get(name).markAsTouched();
        }
    }

    changeEmailField(event: any, name: string) {
        if (event) {
            this.addUserForm.get(name).setValue(event.target.value);
            this.addUserForm.get(name).markAsTouched();
        }

        this.lenderService.getProfileDetailsByEmail(event.target.value).subscribe(response => {
            if (response != null && response.length > 0) {
                this.addUserForm.controls['email'].setErrors({'alreadyUsed': true});
                this.emailError = true;
                this.lenderNameForEmailError = response[0].lenderName;
            } else {
                this.emailError = false;
                this.lenderNameForEmailError = '';
            }
        });
    }

    public getEmailError() {
        return this.emailError;
    }

    public getLenderNameForEmailError() {
        return this.lenderNameForEmailError;
    }

    public  cancel() {
        if (this.addUserForm.touched) {
            this.translatedMessages();
            if ( this.translatedMessages() == false){
                var promptInfo = new PromptModel([{ content: PromptsMessages.messages.fr.CANCEL_MESSAGE, isTextBold: false }], true, true, '', '', '', false, false, false, false, false);
            }
            else if ( this.translatedMessages() == true){
                var promptInfo = new PromptModel([{ content: PromptsMessages.messages.en.CANCEL_MESSAGE, isTextBold: false }], true, true, '', '', '', false, false, false, false, false);
            }

            this.sendPromptText.emit(promptInfo);
            this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
        }else{
            this.dialogRef.close();
        }
    }

     translatedMessages(){
        const token = this.cookieService.get('IdToken');

        if (!token) {
            return;
        }

          const tokenData = this.jwtHelper.decodeToken(token);
          const lang = this.cookieService.get(LanguageService.LANGUAGE);
                if (lang === LenderLanguage.FR) {
                    this.cookieService.set(LanguageService.LANGUAGE, LanguageService.FR_LOWER);
                    return false;
                }
                else{
                    this.cookieService.set(LanguageService.LANGUAGE, LanguageService.ENG_LOWER);
                    return true;
                }
    }

    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    public save() {
        var id = this.formReceivedData.editData ? this.formReceivedData.editData.id : this.makeid(3);
        this.dialogRef.close({
            firstName: this.addUserForm.get('firstName').value, lastName: this.addUserForm.get('lastName').value,
            email: this.addUserForm.get('email').value.toLowerCase(), phone: this.addUserForm.get('phone').value, ext: this.addUserForm.get('ext').value,
            id: id, profileType: 'LENDER', isAdmin : this.userAdminYes.checked == true? true : false, isActive : true
            
        });
        this.onSave.emit();
        }   
    
    public delete() {
        var id = id = this.formReceivedData.editData.id;

        if (this.translatedMessages() == false){
            var promptInfo = new PromptModel([{
                content: PromptsMessages.messages.fr.DELETE_MESSAGE + this.addUserForm.get('firstName').value + " " + this.addUserForm.get('lastName').value + " lender. " + PromptsMessages.messages.fr.DELETE_USER_LENDER,
                isTextBold: false
            }],true, true, '', "Ok", "Annuler", false, false, false, false, false);
        }
        else {
            var promptInfo = new PromptModel([{
                content: PromptsMessages.messages.en.DELETE_MESSAGE + this.addUserForm.get('firstName').value + " " + this.addUserForm.get('lastName').value + " lender. " + PromptsMessages.messages.en.DELETE_USER_LENDER,
                isTextBold: false
            }],true, true, '', "Ok", "Cancel", false, false, false, false, false);
        }

        this.sendPromptText.emit(promptInfo);
        var generalPromptRef = this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
        generalPromptRef.afterOpened().subscribe(() => {
            generalPromptRef.componentInstance.onOk.subscribe(() => {
                this.dialogRef.close({ id });
                this.onDelete.emit();
            })
            generalPromptRef.componentInstance.onCancel.subscribe(() => {
                generalPromptRef.close();
            })
        });
    }

}