<div>
    <div class="col-sm-9" id="reset-pass-div">
        <div id="logo-div">
            <img id="logo-img" src="{{'logoSrc' | translate}}" />
        </div>
        <div class="row">
            <div id="reset-div">
                {{'loginResetPassword' | translate}}
            </div>
        </div>
        
        <div *ngIf="finishedRessetingPassword" id="acknowledgement-div" >       
            <div class="row mt-2">
                <div id="acknowledgement-info-div">
                    <ul>
                        <li>{{'loginRequestSent' | translate}}</li>
                    </ul>
                    <div id="acknowledgement-details">{{'loginCheckEmail' | translate}}</div>
                </div>
            </div>

            <div id="login-button-div">
                <button  mat-flat-button color="primary" dense id="login-btn" routerLink="/login">
                    <i class="arrow-icon" class="fa fa-long-arrow-right"></i> {{'login' | translate}}
                </button>
            </div>
        </div>

        <div style="height:20px"></div>
        <div *ngIf="!finishedRessetingPassword" id="form-div">
            <form [formGroup]="resetPasswordForm" id="reset-form">
                <div class="col-sm-12 mt-4">
                    <mat-form-field>
                        <input matInput id="email-input" (input)="changeFieldNoSpaces($event, 'emailInput')"
                         type="text" formControlName="emailInput" placeholder=" {{emailPlaceHolder}} " (click)="checkPlaceHolder()" (blur)="checkPlaceHolder()"/>
                    </mat-form-field>
                </div>
                <div class="row mt-3">
                    <div id="reset-pass-instruction-div">
                        {{'loginForgotPasswordText' | translate}}
                    </div>
                </div>      

                <div style="height:20px"></div>
                <div id="button-div" class="row  justify-content-center mt-2 mb-2">
                    <button  mat-flat-button color="white" dense id="cancel-btn" routerLink="/login">{{'loginButtonCancel' | translate}}</button>

                    <button  mat-flat-button color="primary" dense id="reset-password-btn" (click)="resetPassword()">
                        <i class="arrow-icon" class="fa fa-long-arrow-right"></i> {{'loginButtonReset' | translate}}
                    </button>
                </div>
                <div style="height:50px"></div>

                <div *ngIf="isUsernameIncorrect" class="col-sm-12 mt-4">
                    <div class="d-flex">
                        <div class="validation-icon-position">
                            <img src="../../../../assets/invalidCheck.svg" class="is-invalid"/>
                        </div>
                        <div [ngClass]="{
                          'is-invalid': isUsernameIncorrect}">
                          {{'loginNoAccount' | translate}}
                        </div>
                    </div>
                </div>
            </form>
        </div>

        
    </div>

</div>
