import {Component, EventEmitter} from '@angular/core';
import {INTERNAL_ROLES} from './internal.roles';
import {User} from './User';
import {MatDialogRef} from '@angular/material/dialog';
import {UserService} from './user-service';
import {SnackbarComponent} from '../components/common/snackbar/snackbar.component';

@Component(
    {
        selector: 'app-add-user',
        templateUrl: 'add-user.component.html',
        styleUrls: ['add-user.component.css']
    }
)
export class AddUserComponent {
    public onOk = new EventEmitter();
    public onCancel = new EventEmitter();
    public user = new User('', true,'', '', []);
    public roles = INTERNAL_ROLES;

    constructor(public dialogRef: MatDialogRef<AddUserComponent>, private userService: UserService,
                private snackBarCustom: SnackbarComponent) {
    }
    add() {
        this.userService.addUser(this.user).subscribe((user) => {
            this.snackBarCustom.openSnackBar('User added');
            this.onOk.emit(user);
            this.dialogRef.close();
        }, () => {
            this.snackBarCustom.openSnackBar('There was an error updating the user');
        });
    }

    cancel() {
        this.dialogRef.close();
        this.onCancel.emit();
    }
}
