import {Injectable, OnInit} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ApplicationInsightsService {

    constructor() {
    }

    public setup() {
    }

    logPageView(name?: string, uri?: string) {
        console.log({
            name,
            uri
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        console.log({name}, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        console.log({name, average}, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        console.log({exception, severityLevel});
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        console.log({message}, properties);
    }
}
