export class LenderCodeLenderNameReportCSVDTO {
   
    Date: Date;
    LenderCode: string;
    LenderName: string;
    Status: string;

    constructor(date: Date, lenderCode: string, lenderName: string, status: string) {
        this.Date = date;
        this.LenderCode = lenderCode;
        this.LenderName = lenderName;
        this.Status = status;
    }

}