import {ChangeDetectorRef, Component} from '@angular/core';
import {UserService} from './user-service';
import {User} from './User';
import {SnackbarComponent} from '../components/common/snackbar/snackbar.component';
import {INTERNAL_ROLES} from './internal.roles';
import {MatDialog} from '@angular/material/dialog';
import {AddUserComponent} from './add-user.component';
import { MatTableDataSource } from "@angular/material/table";

const ROLES_COLUMN = 'Roles';

@Component(
    {
        selector: 'app-roles',
        templateUrl: 'internal-users.component.html',
        styleUrls: ['internal-users.component.css']
    }
)
export class InternalUsersComponent {
    public roles = INTERNAL_ROLES;
    public columns = ['Email', 'Name', ROLES_COLUMN, 'Actions'];
    public searchBy = ['Email', 'Name', ROLES_COLUMN];
    public users: User[];
    public matUsers: MatTableDataSource<User>;
    public filterColumn = 'Name';
    public filterValue: string;

    constructor(private userService: UserService,
                private snackBarCustom: SnackbarComponent,
                public dialog: MatDialog,
                private changeDetectorRefs: ChangeDetectorRef) {
        userService.getUsers().subscribe(users => {
            this.users = users;
            this.matUsers = new MatTableDataSource(this.users);
        });
    }

    update(user) {
        this.userService.updateUser(user).subscribe(result => {
            this.snackBarCustom.openSnackBar('User updated');
        }, result => {
            this.snackBarCustom.openSnackBar('There was an error updating the user');
        });
    }

    activate(user) {
        user.isActive = true;
        this.update(user);
    }

    deactivate(user) {
        user.isActive = false;
        this.update(user);
    }

    addUser() {
        const dialogueBoxRef = this.dialog.open(AddUserComponent, {autoFocus: false});
        dialogueBoxRef.afterOpened().subscribe(() => {
            dialogueBoxRef.componentInstance.onOk.subscribe((user) => {
                this.users.push(user);
                this.matUsers.data = this.users;
                this.changeDetectorRefs.detectChanges();
            });
        });
    }

    filter() {
        if (this.filterColumn) {
            this.matUsers.filterPredicate =
                (data: User, filter: string) => {
                    if (this.filterColumn === ROLES_COLUMN) {
                        return data[this.filterColumn.toLowerCase()] ?
                            data[this.filterColumn.toLowerCase()]
                                .some(role => filter.toLowerCase().split(' ').some(f => f && role.toLowerCase().indexOf(f.toLowerCase()) !== -1) ) : false;
                    } else {
                        return data[this.filterColumn.toLowerCase()] ?
                            filter.toLowerCase().split(' ').some(f => f && data[this.filterColumn.toLowerCase()].toLowerCase().indexOf(f) !== -1)
                            : false;
                    }
                };
            this.matUsers.filter = this.filterValue;
        }
    }

    delete(user) {
        this.userService.deleteUser(user).subscribe(result => {
            this.snackBarCustom.openSnackBar('User deleted');
            this.users.splice(this.users.indexOf(user), 1);
            this.matUsers.data = this.users;
            this.changeDetectorRefs.detectChanges();
        }, result => {
            this.snackBarCustom.openSnackBar('There was an error deleting the user');
        });
    }
}
