export class ContactDetails {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    extension: string;
    email: string;

    constructor(firstName: string, lastName: string, phoneNumber: string, extension: string, email: string) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.extension = extension;
        this.email = email;
    }

    get getFirstName(): string {
        return this.firstName;
    }

    get getLastName(): string {
        return this.lastName;
    }

    get getPhoneNumber(): string {
        return this.phoneNumber;
    }

    get getExtension(): string {
        return this.extension;
    }

    get getEmail(): string {
        return this.email;
    }
}