import { Component, ViewChild, OnInit, HostListener, EventEmitter, Output, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Input } from '@angular/compiler/src/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm, AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Button } from 'primeng';
import { LenderService } from '../../services/api/lender.service';
import { ProfileDetails } from '../../models/profile-details.model';
import { ContactDetails } from '../../models/contact-details.model';
import { AddressInformation } from '../../models/address-information.model';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ProvinceService } from '../../services/province-service/province-service.service';
import { AddressComponent } from '../common/address-component/address-component.component';
import { AsciiCodes } from '../common/constants/asciiCodes';
import { Validation } from '../common/constants/validation';
import { PromptsMessages } from '../common/constants/promptsMessages';
import { PromptModel } from '../../models/prompt.model';
import { GeneralPromptComponent } from '../common/general-prompt/general-prompt.component';
import * as _ from "lodash";
import { ProfileDetailsDTO } from '../../models/dto-profile-details';
import { AuthGuardService } from '../../services/security/auth-guard.service';
import { UserPromptComponent } from '../common/user-prompt/user-prompt.component';
import { AuthService } from 'src/app/services/security/auth.service';
import { ProfileRole } from 'src/app/models/profile-role.model';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatRadioButton } from "@angular/material/radio";

class CrossFieldErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.touched && control.invalid;
    }
}

@Component({
    selector: 'app-lender-registration',
    providers: [
        { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    ],
    templateUrl: './lender-registration.component.html',
    styleUrls: ['./lender-registration.component.scss']
})
export class LenderRegistrationComponent implements OnInit {

    key: number;
    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        this.key = event.keyCode;
        return event.keyCode;
    }
    private lender: ProfileDetails;
    private lenderDTO: ProfileDetailsDTO;
    private contactDetails: ContactDetails;
    private addressInformation: AddressInformation;
    private csvProvincesList: any;
    public lenderCodeMessage: any;
    public registerForm: FormGroup;
    public errorMatcher = new CrossFieldErrorMatcher();
    public registeredEquifax: boolean;
    public registeredTransUnion: boolean;
    public isReqEquifax: boolean;
    public isReqTU: boolean;
    public isReqAcceptTU: boolean;
    public newAddedLenderUser = false;
    public provinces = [];
    public userLendersList = [];
    public authGuardService: AuthGuardService;
    public countries = [
        { "id": 1, "name": "Canada" }
    ];
    countryS = this.countries[0].id;
    public dialogRef: any;
    public buttonDisabledP = true;
    private isSentToExpert;
    public promptComponentLenderUser = PromptsMessages.LENDER_USER_EMAIL_ALREADY_EXISTS;

    @ViewChild('radioFinastraAnswerYes') finastraAnswerYes: MatRadioButton;
    @ViewChild('radioFinastraAnswerNo') finastraAnswerNo: MatRadioButton;
    @ViewChild('radioVettingAnswerYes') vettingAnswerYes: MatRadioButton;
    @ViewChild('radioVettingAnswerNo') vettingAnswerNo: MatRadioButton;
    @ViewChild('radioEquifax1') radioEquifax1: MatRadioButton;
    @ViewChild('radioEquifax2') radioEquifax2: MatRadioButton;
    @ViewChild('radioRegisteredTransUnionYes') registeredTransUnionYes: MatRadioButton;
    @ViewChild('radioRegisteredTransUnionNo') registeredTransUnionNo: MatRadioButton;
    @ViewChild('radioLenderAcceptTransUnionYes') unionAnswerYes: MatRadioButton;
    @ViewChild('radioLenderAcceptTransUnionNo') unionAnswerNo: MatRadioButton;
    @ViewChild('submitButton') submitButton: Button;
    @ViewChild('publishButton') publishButton: Button;
    @ViewChild('phone') phone: Input;
    @ViewChild(AddressComponent) addressComponent: AddressComponent;
    @Output() sendPromptText: EventEmitter<any> = new EventEmitter<any>();

    constructor(public dialog: MatDialog, private provinceService: ProvinceService, private formBuilder: FormBuilder,
        private lenderService: LenderService, private router: Router, private authService: AuthService) {
        this.registerForm = this.formBuilder.group({
            englishName: ['', Validators.compose([Validators.required, Validators.maxLength(Validation.ENGLISH_NAME_LENGTH), Validators.pattern('^([a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ0-9& ])*[A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ0-9& ]*$')])],                                                                    
            frenchName: ['', Validators.compose([Validators.required, Validators.maxLength(Validation.FRENCH_NAME_LENGTH), Validators.pattern('^([a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ0-9& ])*[A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ0-9& ]*$')])],
            credentialNumberEquifax: ['', [Validators.maxLength(Validation.MEMBER_NUMBER_LENGTH), Validators.pattern('[0-9]{3}[a-zA-Z]{2}[0-9]{5}')]],
            credentialNumberTransUnion: ['', [Validators.maxLength(Validation.MEMBER_NUMBER_LENGTH), Validators.pattern('[0-9]{3}[a-zA-Z]{2}[0-9]{5}')]],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            phone: ['', [Validators.required, Validators.maxLength(Validation.PHONE_LENGTH), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]],
            ext: ['', [Validators.maxLength(Validation.EXTENSION_LENGTH), Validators.pattern("[0-9]{5}")]],
            email: ['', [Validators.required]],
            addressLine1: ['', [Validators.required, Validators.maxLength(Validation.ADDRESS_LENGTH)]],
            addressLine2: ['', [Validators.maxLength(Validation.ADDRESS_LENGTH)]],
            city: ['', [Validators.required, Validators.maxLength(Validation.CITY_LENGTH)]],
            postalCode: ['', [Validators.required, Validators.maxLength(Validation.POSTAL_CODE_LENGTH), Validators.pattern("[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1} [0-9]{1}[a-zA-Z]{1}[0-9]{1}")]],
            finastraRadioGroup: ['Yes'],
            vettingRadioGroup: ['Yes'],
            equifaxRadioGroup: ['Yes', [Validators.required]],
            registeredTransUnionRadioGroup: ['Yes', [Validators.required]],
            lenderAcceptTransUnionRadioGroup: ['Yes'],
            province: ['', [Validators.required]],

        });

        this.registerForm.get('equifaxRadioGroup').valueChanges
            .subscribe(() => this.setMemberNumberEquifax());
        this.registerForm.get('registeredTransUnionRadioGroup').valueChanges
            .subscribe(() => this.setMemberNumberTU());
        this.registerForm.get('equifaxRadioGroup').valueChanges
            .subscribe(() => this.setMemberNumberEquifax());
        this.registerForm.valueChanges
            .subscribe(() => this.disablePublishButton());

        this.provinceService.getProvinces().subscribe(data => {
            this.csvProvincesList = this.provinceService.CSV2JSON(data);
            var length = this.csvProvincesList.length;
            this.csvProvincesList.splice(0, 1);
            length = this.csvProvincesList.length;
            this.csvProvincesList.splice(length - 1, 1);
            for (let elem in this.csvProvincesList) {
                this.provinces.push({ 'id': elem, 'name': this.csvProvincesList[elem][0] });
            }
        });
    }

    disablePublishButton() {
        if (this.registerForm.valid) {
            if (this.finAns == false || this.vetAns == false) {
                this.buttonDisabledP = true;
            } else if (this.finAns == true && this.vetAns == true && this.isSentToExpert==false) {
                this.buttonDisabledP = false;
            }
        }
        else {
            this.buttonDisabledP = true;
        }
    }

     setMemberNumberEquifax(): void {
        const memberNumberEquifax = this.registerForm.get('credentialNumberEquifax');
        if (this.radioEquifax1.checked) {
            if (memberNumberEquifax.disabled) {
                memberNumberEquifax.enable();
            }
            memberNumberEquifax.setValidators([Validators.required, Validators.maxLength(10), Validators.pattern("[0-9]{3}[a-zA-Z]{2}[0-9]{5}")]);
            memberNumberEquifax.updateValueAndValidity();
            this.isReqEquifax = true;
        }
     }

    setMemberNumberTU(): void {
        const memberNumberTU = this.registerForm.get('credentialNumberTransUnion');
        if (this.registeredTransUnionYes.checked) {
            if (memberNumberTU.disabled) {
                memberNumberTU.enable();
            }
            memberNumberTU.setValidators([Validators.required, Validators.maxLength(10), Validators.pattern("[0-9]{3}[a-zA-Z]{2}[0-9]{5}")]);
            memberNumberTU.updateValueAndValidity();
            this.isReqTU = true;
        }
    }

    onCancel() {
        if (this.registerForm.get('englishName').value == "") {
            this.registerForm.get('englishName').markAsTouched();
        }
        if (this.registerForm.get('frenchName').value == "") {
            this.registerForm.get('frenchName').markAsTouched();
        }

        if (this.registeredEquifax && this.registerForm.get('credentialNumberEquifax').value == "") {
            this.registerForm.get('credentialNumberEquifax').markAsTouched();
        }
        if (this.registeredTransUnion && this.registerForm.get('credentialNumberTransUnion').value == "") {
            this.registerForm.get('credentialNumberTransUnion').markAsTouched();
        }

        //contact details
        if (this.registerForm.get('firstName').value == "") {
            this.registerForm.get('firstName').markAsTouched();
        }
        if (this.registerForm.get('lastName').value == "") {
            this.registerForm.get('lastName').markAsTouched();
        }
        if (this.registerForm.get('phone').value == "") {
            this.registerForm.get('phone').markAsTouched();
        }
        if (this.registerForm.get('email').value == "") {
            this.registerForm.get('email').markAsTouched();
        }

        //address information
        if (this.registerForm.get('addressLine1').value == "") {
            this.registerForm.get('addressLine1').markAsTouched();
        }
        if (this.registerForm.get('addressLine2').value == "") {
            this.registerForm.get('addressLine2').markAsTouched();
        }
        if (this.registerForm.get('city').value == "") {
            this.registerForm.get('city').markAsTouched();
        }
        if (this.registerForm.get('postalCode').value == "") {
            this.registerForm.get('postalCode').markAsTouched();
        }
        if (this.registerForm.get('province').value == "") {
            this.registerForm.get('province').markAsTouched();
        }

        if (this.registerForm.touched || this.registerForm.invalid) {
            var promptInfo = new PromptModel([{ content: PromptsMessages.CANCEL_MESSAGE, isTextBold: false }], true, true, '/lendersList', '', '', false, false, false, false, false);
            this.sendPromptText.emit(promptInfo);
            this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
        } else {
            this.router.navigateByUrl('/lendersList');
        }
    }

    onSubmit() {
        var promptInfo;
        if (this.finAns == false || this.vetAns == false) {
            this.sendLenderToDatabase(false).subscribe(respEmail => {
                promptInfo = new PromptModel([{ content: PromptsMessages.SAVE_BTN_LR_INCOMPLETE_FORM, isTextBold: false }], true, false, '/lendersList', '', '', false, false, false, false, false);
                this.sendPromptText.emit(promptInfo);
                this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
            }, () => {
                if (promptInfo != null) {
                    this.router.navigateByUrl('/lendersList');
                }

            });
        }

        if (this.finAns == true && this.vetAns == true) {
            this.sendLenderToDatabase(false).subscribe(resp => {
                promptInfo = new PromptModel([{ content: PromptsMessages.SAVED_BUT_NOT_PUBLISHED, isTextBold: false }], true, false, '/lendersList', '', '', false, false, false, false, false);
                this.sendPromptText.emit(promptInfo);
                this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
            }, () => {
                if (promptInfo != null) {
                    this.router.navigateByUrl('/lendersList');
                }
            });
        }
    }

    ngOnInit() {
        this.onEnglishNameChanges();
        this.registeredEquifax = true;
        this.registeredTransUnion = true;
        this.isReqEquifax = true;
        this.isReqTU = true;
        this.finAns = true;
        this.vetAns = true;
        this.isSentToExpert = false;
    }

    ngAfterViewInit() {
        this.registerForm.addControl('addressLookup', this.addressComponent.addressForm);
        this.addressComponent.addressForm.setParent(this.registerForm);
    }

    onPublish() {
        var promptInfo;
        this.isSentToExpert=true;
        this.buttonDisabledP = true;
        this.sendLenderToDatabase(true).subscribe(resp => {
                promptInfo = new PromptModel([{ content: PromptsMessages.MSG1_PUBLISHED_TO_EXPERT, isTextBold: false },
                { content: PromptsMessages.MSG2_PUBLISHED_TO_EXPERT, isTextBold: true }], true, false, '/lendersList', '', '', false, false, false, false, false);
            this.sendPromptText.emit(promptInfo);
            this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
        }, () => {
            if (promptInfo != null)
                this.router.navigateByUrl('/lendersList');
        });
    }

    changeFieldNoSpaces(event: any, name: string): void {
        if (event) {
            var value = event.target.value;
            if (value.match(/^\s* {1}$/)) {
                value = value.substring(0, value.length - 1);
            }
            else if (value.length > 2 && value.substring(value.length - 2, value.length).match(/^\s* {2}$/)) {
                value = value.substring(0, value.length - 1);
            }

            this.registerForm.get(name).setValue(value);
            this.registerForm.get(name).markAsTouched();

            if (this.getFinAns() && this.getVetAns() && !this.registerForm.invalid) {
                this.buttonDisabledP = false;
            }
        }
    }

    changeFieldNoSpacesAndDigits(event: any, name: string): void {
        if (event) {
            var value = event.target.value;
            if (value.match(/^\s* {1}$/) || value.match(".*[0-9].*")) {
                value = value.substring(0, value.length - 1);
            }
            else if (value.length > 2 && value.substring(value.length - 2, value.length).match(/^\s* {2}$/)) {
                value = value.substring(0, value.length - 1);
            }
            this.registerForm.get(name).setValue(value);
            this.registerForm.get(name).markAsTouched();
        }
    }

    changeMemberField(event: any, name: string) {
        if (event) {
            var value = event.target.value;
            if (value.match(/^\s* {1}$/)) {
                value = value.substring(0, value.length - 1);
            }

            this.registerForm.get(name).setValue(value);
            this.registerForm.get(name).markAsTouched();
        }
    }

    changePhone(fn: string): void {
        if (fn) {
            var formatted = this.formatPhoneText(fn);
            this.registerForm.get('phone').setValue(formatted);
            this.registerForm.get('phone').markAsTouched();
        }
    }

    formatPhoneText(value): string {
        value = value.replace(/[^\w\s]/gi, "");

        if (value.length > 3 && value.length <= 6)
            value = value.slice(0, 3) + "-" + value.slice(3);
        else if (value.length > 6)
            value = value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6);

        return value;
    }

    keydownPhone(fn: any): void {
        var filter = [];

        //add key codes for digits 0 - 9 into this filter
        for (var i = 0; i <= 9; i++) {
            filter.push(i + AsciiCodes.KEYPAD_ZERO);
            filter.push(i + AsciiCodes.NUMPAD_ZERO);
        }

        //or for editing the keyboard input
        filter.push(AsciiCodes.BACKSPACE_KEY);
        filter.push(AsciiCodes.TAB_KEY);
        filter.push(AsciiCodes.DELETE_KEY);
        filter.push(AsciiCodes.LEFT_ARROW_KEY);
        filter.push(AsciiCodes.RIGHT_ARROW_KEY);
        if (filter.indexOf(fn.keyCode) < 0) {
            fn.preventDefault();
        }
    }

    changeExt(fn: string): void {
        if (fn) {
            this.registerForm.get('ext').setValue(fn);
            this.registerForm.get('ext').markAsTouched();
        }
    }
    changeEmail(fn: string): void {
        if (fn) {
            this.registerForm.get('email').setValue(fn);
            this.registerForm.get('email').markAsTouched();
        }
    }

    setAddressValues(addressInfo: AddressInformation) {
        this.registerForm.get('addressLine1').setValue(addressInfo.addressTwo + " " + addressInfo.addressOne);
        this.registerForm.get('addressLine1').markAsTouched();
        this.registerForm.get('city').setValue(addressInfo.city);
        this.registerForm.get('city').markAsTouched();
        this.registerForm.get('postalCode').setValue(addressInfo.postalCode);
        this.registerForm.get('postalCode').markAsTouched();
        this.registerForm.get('province').setValue(addressInfo.province);
        this.registerForm.get('province').markAsTouched();
    }

    keydownPC(fn: any): void {
        var filter = [];

        //add key codes for digits 0 - 9 into this filter
        for (var i = 0; i <= 9; i++) {
            filter.push(i + AsciiCodes.KEYPAD_ZERO);
            filter.push(i + AsciiCodes.NUMPAD_ZERO);
        }

        //add key codes for digits letters into this filter
        for (var i = 0; i <= 25; i++) {
            filter.push(i + AsciiCodes.ALPHA_UPPER);
            filter.push(i + AsciiCodes.ALPHA_LOWER);
        }

        //add other keys that might be needed for navigation
        //or for editing the keyboard input
        filter.push(AsciiCodes.BACKSPACE_KEY);
        filter.push(AsciiCodes.TAB_KEY);
        filter.push(AsciiCodes.DELETE_KEY);
        filter.push(AsciiCodes.LEFT_ARROW_KEY);
        filter.push(AsciiCodes.RIGHT_ARROW_KEY);
        if (filter.indexOf(fn.keyCode) < 0) {
            fn.preventDefault();
        }
    }

    formatPCText(value): string {
        value = value.replace(" ", "");
        value = value.replace(")", "");
        value = value.replace("(", "");

        if (value.length > 3 && value.length <= 6)
            value = value.slice(0, 3) + " " + value.slice(3);
        else if (value.length > 6)
            value = value.slice(0, 3) + " " + value.slice(3, 6) + value.slice(6);

        return value;
    }

    changePC(fn: string): void {
        if (fn) {
            var formatted = this.formatPCText(fn);
            this.registerForm.get('postalCode').setValue(formatted);
            this.registerForm.get('postalCode').markAsTouched();
        }
    }

    changeProvince(fn: any) {
        if (fn) {
            this.registerForm.get('province').markAsTouched();
            this.registerForm.get('province').setValue(fn.value);
        }
    }

    changeContactField(event: any, name: string) {
        if (event) {
            this.registerForm.get(name).setValue(event.target.value);
            this.registerForm.get(name).markAsTouched();
        }
    }

    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    generateId(name) {
        name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        let id;
        _.replace(name, / +(?= )/g, '');
        _.replace(name, /[^a-zA-Z ]/g, '');
        if (_.endsWith(name, ' ')) {
            name += '*';
        }
        _.replace(name, '  ', ' ');
        name = _.toLower(name);
        name = name.replace(/[0-9]/g, '');
        let words = _.words(name);
        if (words.length >= 3) {
            id = words[0][0] + words[1][0] + words[2][0];
        } else if (words.length === 2 && words[0].length > 1) {
            id = words[0][0] + words[0][1] + words[1][0];
        } else if (words.length === 2 && words[0].length === 1) {
            id = words[0][0] + words[0][0] + words[1][0];
        } else if (words.length === 1 && words[0].length >= 3) {
            id = words[0][0] + words[0][1] + words[0][2];
        } else if (words.length === 1 && words[0].length === 2) {
            id = words[0][0] + words[0][1] + words[0][0];
        } else if (words.length === 1 && words[0].length === 1) {
            id = words[0][0] + words[0][0] + words[0][0];
        }
        if (id)
            return _.toUpper(id);
        return '***';
    }

    sendLenderToDatabase(isPublishedToExpert: boolean): Observable<ProfileDetails> {
        var lenderName = this.registerForm.get("englishName").value;
        var lenderFrenchName = this.registerForm.get("frenchName").value;

        if (lenderName.substring(lenderName.length - 1, lenderName.length).match(/^\s* {1}$/)) {
            lenderName = lenderName.substring(0, lenderName.length - 1);
        }
        if (lenderFrenchName.substring(lenderFrenchName.length - 1, lenderFrenchName.length).match(/^\s* {1}$/)) {
            lenderFrenchName = lenderFrenchName.substring(0, lenderFrenchName.length - 1);
        }
         lenderFrenchName = encodeURI(lenderFrenchName.toString().replace(/%/g, '~~pct~~'));
        var equifaxMember = this.radioEquifax1.checked;
        var equifaxCredentialNumber = equifaxMember ? this.registerForm.get("credentialNumberEquifax").value : '';
        var transUnionMember = this.registeredTransUnionYes.checked;
        var transUnionMemberNumber = transUnionMember ? this.registerForm.get("credentialNumberTransUnion").value : '';
        var acceptTUBureaus = this.unionAnswerYes.checked;
        var finastraContractsCompleted = this.finAns;
        var lenderVettingCompleted = this.vetAns;

        //contact details
        var firstName = encodeURI(this.registerForm.get("firstName").value.toString().replace(/%/g, '~~pct~~'));
        var lastName = encodeURI(this.registerForm.get("lastName").value.toString().replace(/%/g, '~~pct~~'));
        var phoneNumber = this.registerForm.get("phone").value;
        var extension = this.registerForm.get("ext").value;
        var email = this.registerForm.get("email").value;
        this.contactDetails = new ContactDetails(firstName, lastName, phoneNumber, extension, email.toLowerCase());
        //address information
        var addressOne = encodeURI(this.registerForm.get("addressLine1").value.toString().replace(/%/g, '~~pct~~'));
        var addressTwo = encodeURI(this.registerForm.get("addressLine2").value.toString().replace(/%/g, '~~pct~~'));
        var city = encodeURI(this.registerForm.get("city").value.toString().replace(/%/g, '~~pct~~'));
        var postalCode = this.registerForm.get("postalCode").value;
        var province = this.registerForm.get("province").value;
        this.addressInformation = new AddressInformation(addressOne, addressTwo,
            city, postalCode, province);

        var lenderCode = this.generateId(lenderName);
        var id = this.makeid(3);
        var isActive = true;
        var publishedToExpert = isPublishedToExpert;
        this.lender = new ProfileDetails(lenderName, lenderFrenchName, equifaxMember, equifaxCredentialNumber,
            transUnionMember, transUnionMemberNumber, acceptTUBureaus, finastraContractsCompleted,
            lenderVettingCompleted, this.contactDetails, this.addressInformation, lenderCode, id, isActive, publishedToExpert, null, null,0, []);
        this.lenderDTO = new ProfileDetailsDTO(this.lender, null);
        this.lenderDTO.lenderUsers = [];
        if (this.userLendersList.length > 0) {
            for (var i = 0; i < this.userLendersList.length; i++) {
                var userContactDetails = new ContactDetails(this.userLendersList[i].firstName, this.userLendersList[i].lastName, this.userLendersList[i].phone,
                    this.userLendersList[i].ext, this.userLendersList[i].email.toLowerCase());
                var user = new ProfileDetails(lenderName, lenderFrenchName, equifaxMember, equifaxCredentialNumber,
                    transUnionMember, transUnionMemberNumber, acceptTUBureaus, finastraContractsCompleted,
                    lenderVettingCompleted, userContactDetails, this.addressInformation, null, this.userLendersList[i].id,
                    isActive, publishedToExpert, null,this.userLendersList[i].stripe_clientId,0, this.userLendersList[i].profileRole);
                user.setFk_LenderAccount = lenderCode;
                user.setProfileType = this.userLendersList[i].profileType;
                if (this.lenderDTO.lenderUsers.length > 0) {
                    for (let i = 0; i < this.lenderDTO.lenderUsers.length; i++) {
                        if (user.id == this.lenderDTO.lenderUsers[i].id) {
                            this.lenderDTO.lenderUsers.splice(i, 1);
                        }
                    }
                }
                this.lenderDTO.lenderUsers.push(user);
            }
        }
        return this.lenderService.addLender(this.lenderDTO);
    }

    onEnglishNameChanges(): void {
        this.registerForm.get("englishName").valueChanges.subscribe(val => {
            this.lenderCodeMessage = `${this.generateId(val)}***`;
        })
    }

    onRegisteredEquifax(): void {
        this.registeredEquifax = true;
    }

    onNotRegisteredEquifax(): void {
        const memberNumberEquifax = this.registerForm.get('credentialNumberEquifax');

        if (!this.radioEquifax1.checked && memberNumberEquifax.value != '') {
            var promptInfo = new PromptModel([{ content: PromptsMessages.MSG1_RADIO_BUTTONS_PROMPT, isTextBold: false }, { content: PromptsMessages.MSG2_RADIO_BUTTONS_PROMPT, isTextBold: false }], true, true, '', '', '', false, false, false, false, false);
            this.sendPromptText.emit(promptInfo);
            this.dialogRef = this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });

            this.dialogRef.afterOpened().subscribe(() =>{
                this.dialogRef.componentInstance.onCancel.subscribe(() => {
                    this.radioEquifax1.checked = true;
                    memberNumberEquifax.enable();
                    this.registeredEquifax = true;
                });
                this.dialogRef.componentInstance.onOk.subscribe(() => {
                    this.registeredEquifax = false;
                    memberNumberEquifax.setValue('');
                    memberNumberEquifax.disable()
                });
            });       
        } else {
            this.registeredEquifax = false;
            memberNumberEquifax.disable()
        }
    }

    onRegisteredTransUnion(): void {
        this.registeredTransUnion = true;
    }

    onLenderAcceptTransUnionBureau(event: any): void {
        this.unionAnswerYes.checked = true;
    }

    lenderNotAcceptTransUnionBureau(event: any): void {
        this.unionAnswerNo.checked = true;
    }

    finAns:boolean = true;
    vetAns:boolean = true;

    onFinastraAnswerYes(event: any) {
        this.finastraAnswerYes.checked = true;
        this.finastraAnswerNo.checked = false;
        this.finAns = true;
    }

    onFinastraAnswerNo(event: any) {
        this.finastraAnswerNo.checked = true;
        this.finastraAnswerYes.checked = false;
        this.finAns = false;
    }

    onVettingAnswerYes(event: any) {
        this.vettingAnswerYes.checked = true;
        this.vettingAnswerNo.checked = false;
        this.vetAns = true;
    }

    onVettingAnswerNo(event: any) {
        this.vettingAnswerNo.checked = true;
        this.vettingAnswerYes.checked = false;
        this.vetAns = false;
    }

    getFinAns() {
        return this.finAns
    }

    getVetAns() {
        return this.vetAns
    }

    getDisabledButton() {
        this.disablePublishButton()
        return this.buttonDisabledP
    }

    onNotRegisteredTransUnion(): void {
        const memberNumberTU = this.registerForm.get('credentialNumberTransUnion');

        if (memberNumberTU.value != '') {
            var promptInfo = new PromptModel([{ content: PromptsMessages.MSG1_RADIO_BUTTONS_PROMPT, isTextBold: false }, { content: PromptsMessages.MSG2_RADIO_BUTTONS_PROMPT, isTextBold: false }], true, true, '', '', '', false, false, false, false, false);
            this.sendPromptText.emit(promptInfo);
            this.dialogRef = this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
            
            this.dialogRef.afterOpened().subscribe(() =>{
                this.dialogRef.componentInstance.onCancel.subscribe(() => {
                    this.registeredTransUnionYes.checked = true;
                    memberNumberTU.enable();
                    this.registeredTransUnion = true;
                });
                this.dialogRef.componentInstance.onOk.subscribe(() => {
                    memberNumberTU.setValue('');
                    this.registeredTransUnion = false;
                    memberNumberTU.disable()
                });
            });            
        } else {
            this.registeredTransUnion = false;
            memberNumberTU.disable()
        }
    }

    onAddUser(event: any) {
        var dialogueInfo = { accountDetails: true }
        this.sendPromptText.emit(dialogueInfo);
        let dialogueBoxRef = this.dialog.open(UserPromptComponent, {
            autoFocus: false, data: dialogueInfo,
            minHeight: 'calc(60vh - 90px)',
            height: 'auto'
        });
        dialogueBoxRef.afterOpened().subscribe(() => {
            dialogueBoxRef.componentInstance.onSave.subscribe(() => {
                dialogueBoxRef.afterClosed().subscribe(data => {
                    this.lenderService.getProfileDetailsByEmail(data.email).subscribe(response => {
                        if (response != null && response.length > 0) {
                            var promptInfo = new PromptModel([{ content: this.promptComponentLenderUser, isTextBold: false },], true, false, '', "Ok", "No", false, false, false, false, false);
                            this.sendPromptText.emit(promptInfo);
                            this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
                        }
                        else {
                            data.profileRole = data.isAdmin === true ? [ProfileRole.LENDER_USER, ProfileRole.LENDER_ADMIN] : [ProfileRole.LENDER_USER];
                            this.userLendersList.push(data);
                        }
                    });
                });
            });
        });
    }

    onEditExistingUser(event: any, userId: string) {
        var editUser;
        if (this.userLendersList.length > 0) {
            for (let i = 0; i < this.userLendersList.length; i++) {
                if (this.userLendersList[i].id == userId) {
                    editUser = {
                        firstName: this.userLendersList[i].firstName, lastName: this.userLendersList[i].lastName,
                        email: this.userLendersList[i].email.toLowerCase(), isAdmin:this.userLendersList[i].isAdmin, phone: this.userLendersList[i].phone, ext: this.userLendersList[i].ext,
                        id: this.userLendersList[i].id, profileType: this.userLendersList[i].profileType, editedUser: true
                    }
                }
            }
        }

        var dialogueInfo = {
            accountDetails: true, editData: editUser
        }
        this.sendPromptText.emit(dialogueInfo);
        let dialogueBoxRef = this.dialog.open(UserPromptComponent, {
            autoFocus: false, data: dialogueInfo,
            minHeight: 'calc(60vh - 90px)',
            height: 'auto'
        });
        dialogueBoxRef.afterOpened().subscribe(() => {
            dialogueBoxRef.componentInstance.onSave.subscribe(() => {
                dialogueBoxRef.afterClosed().subscribe(data => {
                    if (this.userLendersList.length > 0) {
                        for (let i = 0; i < this.userLendersList.length; i++) {
                            if (this.userLendersList[i].id == data.id) {
                                this.userLendersList[i].firstName = data.firstName;
                                this.userLendersList[i].lastName = data.lastName;
                                this.lenderService.getProfileDetailsByEmail(data.email).subscribe(response => {
                                    if (response != null && response.length > 0) {
                                        var promptInfo = new PromptModel([{ content: this.promptComponentLenderUser, isTextBold: false },], true, false, '', "Ok", "No", false, false, false, false, false);
                                        this.sendPromptText.emit(promptInfo);
                                        this.dialog.open(GeneralPromptComponent, { autoFocus: false, data: promptInfo });
                                    }
                                    else {
                                        this.userLendersList[i].email = data.email.toLowerCase();
                                    }
                                });
                                this.userLendersList[i].phone = data.phone;
                                this.userLendersList[i].profileType = data.profileType;
                            }
                        }
                    }
                });
            });
    
            dialogueBoxRef.componentInstance.onDelete.subscribe(() => {
                dialogueBoxRef.afterClosed().subscribe(data => {
                    var searchedIndex = null;
    
                    if (this.userLendersList.length > 0) {
                        for (let i = 0; i < this.userLendersList.length; i++) {
                            if (this.userLendersList[i].id == data.id) {
                                searchedIndex = i;
                            }
                        }
    
                        this.userLendersList.splice(searchedIndex, 1)
                    }
                });
            });
        });
    }
}