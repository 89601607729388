<body>
    <div class="errorTitle">
        {{ 'page_not_found' | translate }}
    </div>
    <div class="iconBox">
        <img class="errorIcon" src="../../assets/Icon-2.svg" />
    </div>
    <div class="errorMessage">
        {{'permissions_error' | translate}}
    </div>
    <div class="buttonBox">
        <button id="payButton" mat-stroked-button dense class="buttonBack" (click)="exit()"> 
            {{ 'Login to your account' | translate }}</button>
    </div>
    <div class="supportBox">
        <a href="mailto: abc@example.com" class="supportLink">{{'Support' | translate}}</a>
    </div>
</body>
