<ng-container>
    <ng-container>    
        <button mat-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" 
        (mouseenter)="mouseEnterIcon(menuTrigger)" (mouseleave)="mouseLeaveIcon(menuTrigger)" 
        ng-init>
            <mat-icon id="avatar-button" alt="account icon" aria-hidded=false aria-label="Account icon" aria-hidden="false" color="gradient" style='font-size:26px' alt="Menu Icon">
                account_circle
            </mat-icon>
        </button>

        <mat-menu #menu="matMenu" class="uxg-user-profile" id="menu" direction="down" yPosition="below">

        <span (mouseenter)="mouseEnterMenu()" (mouseleave)="mouseLeaveMenu()">
            <div class="uxg-menu-header">
                <div class="uxg-avatar-title-block">
                    <div class="uxg-avatar">{{userNameInitial}}</div>
                    <div class="englishName">{{userEnglishName}}</div>
                    <span class="uxg-caption">{{userEmail}}</span>
                </div>
            </div>
            <mat-divider></mat-divider>

            <button *ngIf="isLenderUser()" mat-menu-item (click)="viewAccountDetails()" alt="Account Details" tabindex="-6">
                <mat-icon role="menu icon" aria-label="Account details option">dashboard</mat-icon>
                {{'accountDetails' | translate}}
            </button>

            <button mat-menu-item *ngIf="isLenderUser() && isPublishedToExpert" alt="Dashboards" tabindex="-5">
                <a href="/leads">
                    <div hidden>MyDeals</div>
                <mat-icon role="menu icon" aria-label="Dashboards option">dashboard</mat-icon>
                {{'dashboard' | translate}}
                </a>
            </button>
            <button mat-menu-item *ngIf="isLenderUser() && !isPublishedToExpert" alt="Dashboard" tabindex="-4">
                <a href="/leads">
                    <div hidden>MyDeals</div>
                <mat-icon role="menu icon" aria-label="Dashboard option">dashboard</mat-icon>
                {{'dashboard' | translate}}
                </a>
            </button>
            <button mat-menu-item *ngIf="isCsAdmin() && !singleRole()" (click)="goto('/lendersList')" alt="Lenders List" tabindex="-4">
                    <a href="/lendersList">
                        <div hidden>Lenders List</div>
                    <mat-icon role="menu icon" aria-label="Dashboard option">dashboard</mat-icon>
                        {{'lenderslist' | translate}}
                    </a>
                </button>
            <button mat-menu-item *ngIf="isReporting() && !singleRole()" (click)="goto('/reports')" alt="Reporting" tabindex="-4">
                    <a href="/reports">
                        <div hidden>Reports</div>
                    <mat-icon role="menu icon" aria-label="Dashboard option">dashboard</mat-icon>
                        {{'reporting' | translate}}
                    </a>
                </button>

            <button mat-menu-item *ngIf="isSystemAdmin() && !singleRole()" (click)="goto('/SystemAccess')" alt="UserAdmin" tabindex="-4">
                    <a href="/SystemAccess">
                        <div hidden>System Access</div>
                    <mat-icon role="menu icon" aria-label="Dashboard option">dashboard</mat-icon>
                        {{'SystemAccess' | translate}}
                    </a>
                </button>

            <button mat-menu-item *ngIf="isLenderUser()" (click)="openHelp()" alt="Help" tabindex="-3">
                <mat-icon role="menu icon" aria-label="Help option">dashboard</mat-icon>
                {{'help' | translate}}
            </button>

            <!-- <button mat-menu-item alt="Reports" tabindex="-2">
                <a href="/reports">
                    <mat-icon role="menu icon" aria-label="Reports option">dashboard</mat-icon>
                    Reports
                </a>
            </button> -->

            <mat-divider></mat-divider>
            <div class="uxg-menu-footer" id="menu-logout-footer">
                <button mat-menu-item (click)="logout()" id="menu-hidden-logout-button">
                <button mat-flat-button color="primary" dense (click)="logout()" tabindex="-1" id="menu-logout-button">
                    <mat-icon role="menu icon" aria-label="Exit application option">exit_to_app</mat-icon>
                    {{'logout' | translate}}
                </button>
                    </button>
            </div>
        </span>
        </mat-menu>
    </ng-container>

</ng-container>
