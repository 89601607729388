import { ProfileDetails } from './profile-details.model';

export class ProfileDetailsDTO{
    lenderAccount: ProfileDetails;
    lenderUsers: ProfileDetails[];
    
    constructor(lenderAccount: ProfileDetails, lenderUsers: ProfileDetails[]){
        this.lenderAccount = lenderAccount;
        this.lenderUsers = lenderUsers;
    }

    get getLenderAccount(): ProfileDetails{
        return this.lenderAccount;
    }

    get getLenderUsers(): ProfileDetails[]{
        return this.lenderUsers;
    }

}