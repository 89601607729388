<div *ngIf="allDataFetched" style="max-width: 100%; min-width: 100%;overflow: hidden;">

    <app-header (getIndex)="setIndex($event)" style="max-width: 100%; min-width: 100%;"></app-header>

    <div class="container px-3 py-3 col-md-10 col-sm-10" id="table-group-div" style="min-width: 85%; max-width: 85%;">
        <div style="align-content:flex-start" class="row col-sm-12">
            <div class="col-sm-6 d-flex mb-3" style="min-width: 30rem;">
                <div class="ml-2" style="font-size: 16px; font-family: Roboto; font-weight: 500;">
                    {{primaryApplicantGroup?.applicant[0]?.name.firstName+ " "+
                    primaryApplicantGroup?.applicant[0]?.name.lastName +
                    getApplicationSummaryTitleAddress()}}</div>
                <div class="ml-3 mt-1">
                    <div class="decision-chip">
                        {{getApplicationDecisionChip()}}
                    </div>
                </div>
            </div>
            <div class="col-sm-7 d-flex mb-3" id="buttons-div" style="min-width: 85%;max-width: 85%; display: inline-block!important;">
                <button id="approve-button" mat-flat-button color="accent" dense class="approve-button"
                    (click)="onApprove()" *ngIf="canBeApproved" style="min-width: 6rem;">
                    <mat-icon style="margin-right: 4px; margin-left: -4px;" role="button icon"
                        aria-label="Approve button">check</mat-icon>
                        {{'approveButton' | translate}}
                </button>
                <button id="fund-button" mat-flat-button color="primary" dense (click)="onFund()"
                    *ngIf="canBeFunded">
                    <img src="../../../assets/credit_score_icon.svg"
                        style="margin-right: 4px; margin-left: -10px;font-size: 10px;" aria-label="Fund button"
                        role="button icon" />
                        {{'fundButton' | translate}}
                </button>
                <button id="decline-button" mat-flat-button color="accent" dense class="decline-button"
                    (click)="onDecline($event)"
                    *ngIf="canBeDeclined" style="min-width: 6rem;">
                    <mat-icon style="margin-right: 4px; margin-left: -4px;" role="button icon"
                        aria-label="Decline button">clear</mat-icon>
                        {{'declineButton' | translate}}
                </button>
                <button id="message-broker-button" mat-flat-button color="primary" dense id="message-to-broker-button"
                    *ngIf="loanStatus != '4'" (click)="messageToBroker()">
                    <mat-icon style="margin-right: 1px; margin-left: -5px;" role="button icon"
                        aria-label="Message Broker button">message</mat-icon>
                        {{'messageBrokerButton' | translate}}
                </button>

                <button id="download-pdf-button" mat-flat-button color="primary" dense id="to-pdf-button"
                        (click)="pdfGenerator()" style="min-width: 10rem;">
                    <mat-icon style="margin-right: 4px; margin-left: -10px;" role="button icon"
                              aria-label="Download  as PDF button">vertical_align_bottom</mat-icon>
                    {{'downloadPdfButton' | translate}}
                </button>
            </div>

            <div *ngIf="isEligibleForRemove" class="remove-div col-sm-4 flex-row ">
                <div>
                    <button mat-flat-button color="primary" dense id="remove-button" (click)="removeDeal()"
                        [disabled]="removedDeal">
                        {{'removeButton' | translate}}
                    </button>
                </div>
                <div class="material-icons" id="remove-info" matTooltip="{{infoHoverMessage}}"
                    matTooltipClass="custom-tooltip">
                    help_outline
                </div>
            </div>
        </div>

        <div class="row mt-4">
        </div>
        <div class="row mt-4">
        </div>

        <mat-tab-group id="tabs-group" class="mat-tab mat-elevation-z0 overflow-hidden col-sm-12" #tabGroup
            animationDuration="0ms" (selectedTabChange)="onTabChange($event)" [selectedIndex]='selectedIndex'
            mat-stretch-tabs style="min-width: 100%!important;max-width: 100%!important;display: unset;">
            <mat-tab label="{{'theStoryLabel' | translate}}" aria-label="The story  Tab" role="tableOption" id="story-tab">
            </mat-tab>
            <mat-tab label="{{'propertyLabel' | translate}}" aria-label="Property  Tab" role="tableOption" id="property-tab">
            </mat-tab>
            <mat-tab label="{{'financingLabel' | translate}}" aria-label="Financing  Tab" role="tableOption" id="financing-tab">
            </mat-tab>
            <mat-tab label="{{'applicantLabel' | translate}}" aria-label="Applicant Table Tab" role="tableOption" id="applicant-tab">
            </mat-tab>
        </mat-tab-group>

        <div class="col-sm-12">
            <div id="applicant-container" *ngIf="displayApplicantData">
                <div id="applicantData" class="border-dark">

                    <button *ngIf="displayCreditBureau" id="credit-bureau-button" mat-flat-button color="primary" dense
                        (click)="openCreditBureau()" style="min-width: 10rem;margin-left: 10px;font-family: sans-serif;font-size: 12px;">
                        {{'viewCreditBureau' | translate}}
                    </button>
                
                    <!--Primary Applicant Section-->
                    <div *ngFor="let applicant of primaryApplicantGroup?.applicant; let i = index"
                        id="primary-applicant-section">

                        <!-- Logic required for the persistence of currentAddress field-->
                        <div *ngIf="getCurrentAddress(applicant.addressDetail)"
                            class="border-top border-bottom  border-dark"></div>

                        <div class="format col-sm-12">
                            <h1 class="applicant-group-paragraph">{{createName(applicant?.name)}} </h1>
                        </div>

                        <div id="applicant-address-div" class="format col-sm-12 border-bottom border-dark"
                            *ngIf="createAddress(currentAddress?.address)">
                            <strong>{{'address' | translate}}:</strong> {{createAddress(currentAddress?.address)}}
                        </div>

                        <div id="applicant-currentAddress-div" class="composite col-sm-12 border-bottom  border-dark"
                            style="padding-bottom:10px; padding-top:10px">
                            <div class="format col-sm-4 " *ngIf="currentAddress?.residentialStatusDd">
                                <strong>{{'residentialStatus_appSummary' | translate}}:</strong> {{getLanguageBasedValue(currentAddress?.residentialStatusDd, false)}}
                            </div>
                            <div class="format col-sm-6 border-left border-dark"
                                *ngIf="currentAddress?.monthsAtAddress">
                                <strong>{{'timeAtResidence_appSummary' | translate}}:</strong> {{formatTime(currentAddress?.monthsAtAddress)}}
                            </div>
                        </div>

                        <div class="composite col-sm-12 border-bottom  border-dark" id="applicant-phone-div">
                            <div class="format col-sm-4" *ngIf="getNumber(applicant.phone, 'Work')"> 
                                <strong>{{'workPhone_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Work')}}   
                            </div>                            
                            <div class="format col-sm-4 border-left border-dark" *ngIf="getNumber(applicant.phone, 'Cell')"> 
                                <strong>{{'cellPhone_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Cell')}}
                            </div>                            
                            <div class="format col-sm-4 border-left border-dark" *ngIf="getNumber(applicant.phone, 'Home')"> 
                                <strong>{{'homePhone_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Home')}}
                            </div>                            
                        </div>

                        <div class="composite col-sm-12 border-bottom  border-dark" id="applicant-contact-div">
                            <div class="format col-sm-4" *ngIf="getNumber(applicant.phone, 'Fax')">
                                <strong>{{'faxNumber_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Fax')}}
                            </div>
                            <div class="format col-sm-6 border-left border-dark" *ngIf="applicant.emailAddress">
                                <strong>{{'email_appSummary' | translate}}:</strong> {{applicant.emailAddress}}
                            </div>
                        </div>

                        <div class="composite col-sm-12 border-bottom  border-dark" id="applicant-status-div">
                            <div class="format col-sm-4" *ngIf="applicant.maritalStatusDd">
                                <strong>{{'maritalStatus_appSummary' | translate}}:</strong> {{getLanguageBasedValue(applicant.maritalStatusDd, false)}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark" *ngIf="applicant.birthDate">
                                <strong>{{'dateOfBirth_appSummary' | translate}}:</strong> {{applicant.birthDate | date: 'yyyy-MM-dd'}}
                            </div>
                            <div class="format col-sm-4 border-left border-dark"
                                *ngIf="applicant.numberOfDependents != null || applicant.numberOfDependents != undefined">
                                <strong>{{'dependents_appSummary' | translate}}:</strong> {{applicant.numberOfDependents}}
                            </div>
                        </div>

                        <div class="composite col-sm-12 border-bottom  border-dark" id="applicant-sin-div">
                            <div class="format col-sm-4 " *ngIf="applicant.socialInsuranceNumber">
                                <strong>{{'sin_appSummary' | translate}}:</strong> {{formatPhoneNumberAndSin(applicant.socialInsuranceNumber)}}
                            </div>
                        </div>

                        <!-- Logic required for the persistence of previousAddress field-->
                        <div *ngIf="getPreviousAddress(applicant.addressDetail)"></div>

                        <div *ngIf="previousAddresses !== null && previousAddresses.length != 0">
                            <div *ngFor="let previousAddress of previousAddresses">
                                <div id="applicant-previous-address-div"
                                    class="format col-sm-12 border-bottom  border-dark"
                                    *ngIf="createAddress(previousAddress?.address)">
                                    <strong>{{'previousAddress_appSummary' | translate}}:</strong> {{createAddress(previousAddress?.address)}}
                                </div>

                                <div class="composite col-sm-12 border-bottom  border-dark">
                                    <div class="format col-sm-4 border-left border-dark"
                                        *ngIf="previousAddress?.residentialStatusDd">
                                        <strong>{{'residentialStatus_appSummary' | translate}}:</strong> {{getLanguageBasedValue(previousAddress?.residentialStatusDd, false)}}
                                    </div>
                                    <div class="format col-sm-6"
                                        *ngIf="previousAddress?.monthsAtAddress !== 0 && previousAddress?.monthsAtAddress !== undefined">
                                        <strong>{{'timeAtResidence_appSummary' | translate}}:</strong>
                                        {{formatTime(previousAddress?.monthsAtAddress)}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Logic required for the persistence of currentEmployement field-->
                        <div *ngIf="getEmployment(applicant.employmentHistory, 'Current')"></div>

                        <div *ngIf="getCurrentEmployments(applicant.employmentHistory) != null && getCurrentEmployments(applicant.employmentHistory).length != 0"
                            id="applicant-employment-div">
                            <div *ngFor="let currentEmployment of getCurrentEmployments(applicant.employmentHistory)">
                                <div class="composite col-sm-12 border-bottom  border-dark" style="padding-top: 20px">
                                    <div class="format col-sm-4">
                                        <strong>{{'currentEmployer_appSummary' | translate}}</strong>
                                    </div>
                                </div>

                                <div class="composite col-sm-12 border-bottom  border-dark">
                                    <div class="format col-sm-4" *ngIf="currentEmployment.employerName != null">
                                        {{currentEmployment.employerName}}
                                    </div>
                                    <div class="format col-sm-6 border-left border-dark"
                                        *ngIf="currentEmployment.monthsOfService !== 0 && currentEmployment.monthsOfService !== undefined">
                                        <strong>{{'timeAtJob_appSummary' | translate}}:</strong> {{formatTime(currentEmployment.monthsOfService)}}
                                    </div>
                                </div>

                                <div class="composite col-sm-12 border-bottom  border-dark">
                                    <div class="format col-sm-4" *ngIf="currentEmployment.occupationDd">
                                        <strong>{{'occupation_appSummary' | translate}}:</strong> {{getLanguageBasedValue(currentEmployment.occupationDd, false)}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark"
                                        *ngIf="currentEmployment.jobTitle">
                                        <strong>{{'jobTitle_appSummary' | translate}}:</strong> {{currentEmployment.jobTitle}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark"
                                        *ngIf="currentEmployment.employmentHistoryTypeDd">
                                        <strong>{{'employmentType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(currentEmployment.employmentHistoryTypeDd, false)}}
                                    </div>
                                </div>

                                <div class="composite col-sm-12 border-bottom  border-dark">
                                    <div class="format col-sm-4" *ngIf="currentEmployment.income.incomeTypeDd">
                                        <strong>{{'incomeType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(currentEmployment.income.incomeTypeDd, false)}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark"
                                        *ngIf="currentEmployment.income.incomeAmount">
                                        <strong>{{'annualIncome_appSummary' | translate}}:</strong>
                                        {{calculateAnnualIncome(currentEmployment.income, false) |
                                        currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Logic required for the persistence of previousEmployement field-->
                        <div *ngIf="getEmployment(applicant.employmentHistory, 'Previous')"></div>

                        <div
                            *ngIf="getPreviousEmployments(applicant.employmentHistory) != null && getPreviousEmployments(applicant.employmentHistory).length != 0">
                            <div *ngFor="let prevEmployment of getPreviousEmployments(applicant.employmentHistory)">

                                <div class="composite col-sm-12 border-bottom  border-dark" style="padding-top: 20px">
                                    <div class="format col-sm-4">
                                        <strong>{{'previousEmployer_appSummary' | translate}}</strong>
                                    </div>
                                </div>

                                <div class="composite col-sm-12 border-bottom  border-dark">
                                    <div class="format col-sm-4" *ngIf="prevEmployment.employerName">
                                        {{prevEmployment.employerName}}
                                    </div>
                                    <div class="format col-sm-6 border-left border-dark"
                                        *ngIf="prevEmployment.monthsOfService !== 0 && prevEmployment.monthsOfService !== undefined">
                                        <strong>{{'timeAtJob_appSummary' | translate}}:</strong> {{formatTime(prevEmployment.monthsOfService)}}
                                    </div>
                                </div>

                                <div class="composite col-sm-12 border-bottom  border-dark">
                                    <div class="format col-sm-4" *ngIf="prevEmployment.occupationDd">
                                        <strong>{{'occupation_appSummary' | translate}}:</strong> {{getLanguageBasedValue(prevEmployment.occupationDd, false)}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark"
                                        *ngIf="prevEmployment.jobTitle">
                                        <strong>{{'jobTitle_appSummary' | translate}}:</strong> {{prevEmployment.jobTitle}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark"
                                        *ngIf="prevEmployment.employmentHistoryTypeDd">
                                        <strong>{{'employmentType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(prevEmployment.employmentHistoryTypeDd, false)}}
                                    </div>
                                </div>

                                <div class="composite col-sm-12 border-bottom  border-dark">
                                    <div class="format col-sm-4" *ngIf="prevEmployment.income?.incomeTypeDd">
                                        <strong>{{'incomeType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(prevEmployment.income.incomeTypeDd, false)}}
                                    </div>
                                    <div class="format col-sm-6 border-left border-dark"
                                        *ngIf="prevEmployment.income?.incomeAmount">
                                        <strong>{{'annualIncome_appSummary' | translate}}:</strong>
                                        {{calculateAnnualIncome(prevEmployment.income, true) |
                                        currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Other Income-->
                    <div id="applicant-otherincome-div"
                        *ngIf="primaryApplicantGroup?.applicant[0].otherIncome != null && primaryApplicantGroup?.applicant[0].otherIncome.length !== 0">
                        <div class="col-sm-12 border-bottom  border-dark">
                            <h1 class="applicant-group-paragraph" for="other-income-details">{{'otherIncome_appSummary' | translate}}</h1>
                        </div>

                        <div class="composite col-sm-12 border-bottom  border-dark">
                            <div class="format col-sm-3"><strong>Type</strong></div>
                            <div class="format col-sm-3 border-left border-dark"
                                *ngIf="displayOtherIncomeDescriptionColumns[0]"><strong>{{'description_appSummary' | translate}}</strong></div>
                            <div class="format col-sm-3 border-left border-dark"><strong>{{'period_appSummary' | translate}}</strong></div>
                            <div class="format col-sm-3 border-left border-dark"><strong>{{'amount_appSummary' | translate}}</strong></div>
                        </div>

                        <div id="other-income-details" class="composite col-sm-12 border-bottom  border-dark"
                            *ngFor="let otherIncome of primaryApplicantGroup?.applicant[0].otherIncome">
                            <div class="format col-sm-3">{{getLanguageBasedValue(otherIncome.incomeTypeDd, false)}}</div>
                            <div class="format col-sm-3 border-left border-dark"
                                *ngIf="displayOtherIncomeDescriptionColumns[0]">{{otherIncome.incomeDescription}}</div>
                            <div class="format col-sm-3 border-left border-dark">{{getLanguageBasedValue(otherIncome.incomePeriodDd, false)}}</div>
                            <div class="format col-sm-3 border-left border-dark">{{otherIncome.incomeAmount |
                                currency:'USD':'symbol':'1.2-2'}}</div>
                        </div>

                        <div class="composite col-sm-12 border-bottom  border-dark">
                            <div class="format col-sm-3"></div>
                            <div class="format col-sm-3" *ngIf="displayOtherIncomeDescriptionColumns[0]"></div>
                            <div class="format col-sm-3 "><strong>{{'total_appSummary' | translate}}</strong></div>
                            <div class="format col-sm-3 border-left border-dark">
                                <strong>{{caluclateTotalOtherIncome(primaryApplicantGroup?.applicant[0].otherIncome) |
                                    currency:'USD':'symbol':'1.2-2'}}</strong>
                            </div>
                        </div>
                    </div>

                    <!--Financial-->
                    <div id="applicant-financial-div"
                        *ngIf="primaryApplicantGroup?.applicant[0].asset != null && primaryApplicantGroup?.applicant[0].asset.length !== 0">
                        <div class="col-sm-12 border-bottom  border-dark">
                            <h1 role="financial-details" class="applicant-group-paragraph">{{'financial_appSummary' | translate}}</h1>
                        </div>

                        <div class="composite col-sm-12 border-bottom  border-dark">
                            <div class="format col-sm-3"><strong>{{'assets_appSummary' | translate}}</strong></div>
                            <div class="format col-sm-3 border-left border-dark"
                                *ngIf="displayAssetsDescriptionColumns[0]"><strong>{{'description_appSummary' | translate}}</strong></div>
                            <div class="format col-sm-3 border-left border-dark"><strong>{{'value_appSummary' | translate}}</strong></div>
                        </div>

                        <div id="financial-details" class="composite col-sm-12 border-bottom  border-dark"
                            *ngFor="let asset of primaryApplicantGroup?.applicant[0].asset">
                            <div class="format col-sm-3">{{getLanguageBasedValue(asset.assetTypeDd, false)}}</div>
                            <div class="format col-sm-3 border-left border-dark"
                                *ngIf="displayAssetsDescriptionColumns[0]">{{asset.assetDescription}}</div>
                            <div class="format col-sm-3 border-left border-dark">{{asset.assetValue |
                                currency:'USD':'symbol':'1.2-2'}}</div>
                        </div>

                        <div class="composite col-sm-12 border-bottom  border-dark">
                            <div class="format col-sm-3" *ngIf="displayAssetsDescriptionColumns[0]"></div>
                            <div class="format col-sm-3 "><strong>{{'total_appSummary' | translate}}: </strong></div>
                            <div class="format col-sm-3 border-left border-dark">
                                <strong>{{caluclateTotalAssetValue(primaryApplicantGroup?.applicant[0].asset) |
                                    currency:'USD':'symbol':'1.2-2'}}</strong>
                            </div>
                        </div>
                    </div>

                    <!--Liabilities-->
                    <div id="applicant-liabilities-div"
                        *ngIf="primaryApplicantGroup?.applicant[0].liability != null &&  primaryApplicantGroup?.applicant[0].liability.broker != null">
                        <div class="composite col-sm-12 border-bottom  border-dark">
                            <div class="format col-sm-3" for="liability-type"><strong>{{'liabiliies_appSummary' | translate}}</strong></div>
                            <div class="format col-sm-3 border-left border-dark" for="liability-description"
                                *ngIf="displayLiabilitiesDescriptionColumns[0]"><strong>{{'description_appSummary' | translate}}</strong></div>
                            <div class="format col-sm-1 border-left border-dark" for="liability-creditlimit" style="max-width: 100px;min-width: 100px;">
                                <strong>{{'value_appSummary' | translate}}</strong>
                            </div>
                            <div class="format col-sm-1 border-left border-dark" for="liability-amount" style="max-width: 100px;min-width: 100px;">
                                <strong>{{'balance_appSummary' | translate}}</strong>
                            </div>
                            <div class="format col-sm-2 border-left border-dark" for="liability-monthly-payment" style="max-width: 141px;min-width: 141px;">
                                <strong>{{'monthlyPayment_appSummary' | translate}}</strong>
                            </div>
                            <div class="format col-sm-1 border-left border-dark" for="liability-payoff"
                                *ngIf="displayPayOffColumns[0]"><strong>{{'payoff_appSummary' | translate}}</strong></div>
                        </div>

                        <div class="composite col-sm-12 border-bottom  border-dark"
                            *ngFor="let liability of primaryApplicantGroup?.applicant[0].liability.broker">
                            <div class="format col-sm-3" id="liability-type">{{getLanguageBasedValue(liability.liabilityTypeDd, false)}}</div>
                            <div class="format col-sm-3 border-left border-dark" id="liability-description"
                                *ngIf="displayLiabilitiesDescriptionColumns[0]">{{liability.liabilityDescription}}</div>
                            <div class="format col-sm-1 border-left border-dark" id="liability-creditlimit" style="max-width: 100px;min-width: 100px;">
                                {{liability.creditLimit | currency:'USD':'symbol':'1.2-2'}}</div>
                            <div class="format col-sm-1 border-left border-dark" id="liability-amount" style="max-width: 100px;min-width: 100px;">
                                {{liability.liabilityAmount | currency:'USD':'symbol':'1.2-2'}}</div>
                            <div class="format col-sm-2 border-left border-dark" id="liability-monthly-payment" style="max-width: 141px;min-width: 141px;">
                                {{liability.liabilityMonthlyPayment | currency:'USD':'symbol':'1.2-2'}}</div>
                            <div class="format col-sm-1 border-left border-dark" id="liability-payoff"
                                *ngIf="displayPayOffColumns[0] && liability.liabilityPayOffTypeDd">{{getLanguageBasedValue(liability.liabilityPayOffTypeDd, false)}}</div>
                        </div>

                        <div class="composite col-sm-12 border-bottom  border-dark">
                            <div class="format col-sm-3" *ngIf="displayLiabilitiesDescriptionColumns[0]"></div>
                            <div class="format col-sm-3"><strong>{{'total_appSummary' | translate}}</strong></div>
                            <div class="format col-sm-1 border-left border-dark" style="max-width: 100px;min-width: 100px;">
                                <strong>{{caluclateTotalValue(primaryApplicantGroup?.applicant[0].liability.broker) |
                                    currency:'USD':'symbol':'1.2-2'}}</strong>
                            </div>
                            <div class="format col-sm-1 border-left border-dark" style="max-width: 100px;min-width: 100px;">
                                <strong>{{caluclateTotalBalance(primaryApplicantGroup?.applicant[0].liability.broker) |
                                    currency:'USD':'symbol':'1.2-2'}}</strong>
                            </div>
                            <div class="format col-sm-2 border-left border-dark">
                                <strong>{{caluclateTotalMonthlyPayment(primaryApplicantGroup?.applicant[0].liability.broker)
                                    | currency:'USD':'symbol':'1.2-2'}}</strong>
                            </div>
                        </div>
                    </div>

                    <!--Other Properties-->
                    <div id="applicant-otherproperties-div"
                        *ngIf="primaryApplicantGroup?.applicant[0].otherProperty != null && primaryApplicantGroup?.applicant[0].otherProperty.length !== 0"
                        class="border-bottom border-dark">
                        <div class="col-sm-12 border-bottom  border-dark">
                            <h1 class="applicant-group-paragraph" for="other-properties-appgroup">{{'otherProperties_appSummary' | translate}}</h1>
                        </div>

                        <div *ngFor="let otherProperty of primaryApplicantGroup?.applicant[0].otherProperty"
                            class="mb-3" id="other-properties-appgroup">
                            <div class="format col-sm-12 border-bottom  border-dark"
                                *ngIf="createAddress(otherProperty?.property.address)">
                                <strong>{{'address_appSummary' | translate}}:</strong> {{ createAddress(otherProperty?.property.address)}}
                            </div>

                            <div class="format col-sm-12 border-bottom  border-dark"
                                *ngIf="otherProperty?.property.estimatedAppraisalValue">
                                <strong>{{'propertyValue_appSummary' | translate}}:</strong> {{ otherProperty?.property.estimatedAppraisalValue |
                                currency:'USD':'symbol':'1.2-2'}}
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-4">
                                    <strong>{{'monthlyRentalIncome_appSummary' | translate}}:</strong>
                                    {{getRentalIncome(otherProperty?.property.rentalIncome) |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4  border-left border-dark"
                                    *ngIf="otherProperty?.property.rentalOffsetOption">
                                    <strong>{{'rentalOffsetOption_appSummary' | translate}}:</strong>
                                    {{getLanguageBasedValue(otherProperty?.property.rentalOffsetOption, false)}}
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-6">
                                    <strong>{{'offset_appSummary' | translate}} %:</strong> {{getOffset(otherProperty?.property.rentalIncome)}}
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-4">
                                    <strong>{{'propertyTaxes_appSummary' | translate}}:</strong>
                                    {{getExpense(otherProperty?.property.propertyExpense, 'Municipal Taxes') |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark">
                                    <strong>{{'condoFees_appSummary' | translate}}:</strong> {{getExpense(otherProperty?.property.propertyExpense,
                                    'Condo Fees') | currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark">
                                    <strong>{{'heating_appSummary' | translate}}:</strong> {{getExpense(otherProperty?.property.propertyExpense,
                                    'Heating Expense') | currency:'USD':'symbol':'1.2-2'}}
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-4">
                                    <strong>{{'hydro_appSummary' | translate}}:</strong> {{getExpense(otherProperty?.property.propertyExpense,
                                    'Hydro') | currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark">
                                    <strong>{{'repairs_appSummary' | translate}}:</strong> {{getExpense(otherProperty?.property.propertyExpense,
                                    'Repairs') | currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark">
                                    <strong>{{'mgmtExpenses_appSummary' | translate}}:</strong>
                                    {{getExpense(otherProperty?.property.propertyExpense, 'Management Expenses') |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-4">
                                    <strong>{{'interestCharges_appSummary' | translate}}:</strong>
                                    {{getExpense(otherProperty?.property.propertyExpense, 'Interest Charges') |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark">
                                    <strong>{{'generalExpenses_appSummary' | translate}}:</strong>
                                    {{getExpense(otherProperty?.property.propertyExpense, 'General Expenses') |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark">
                                    <strong>{{'insurance_appSummary' | translate}}:</strong> {{getExpense(otherProperty?.property.propertyExpense,
                                    'Insurance') | currency:'USD':'symbol':'1.2-2'}}
                                </div>
                            </div>

                            <div *ngIf="otherProperty.mortgage != null && otherProperty.mortgage.length != 0">
                                <div *ngFor="let mortgage of otherProperty.mortgage">
                                    <div class="composite col-sm-12 border-bottom  border-dark">
                                        <div class="format col-sm-4" *ngIf="mortgage.mortgageTypeDd">
                                            <strong>{{'mortgageType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.mortgageTypeDd, false)}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="mortgage.balanceRemaining">
                                            <strong>{{'mortgageBalance_appSummary' | translate}}:</strong> {{mortgage.balanceRemaining |
                                            currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="mortgage.pAndIPaymentAmount">
                                            <strong>{{'frequencyPayment_appSummary' | translate}}:</strong> {{mortgage.pAndIPaymentAmount |
                                            currency:'USD':'symbol':'1.2-2'}}
                                        </div>

                                    </div>
                                    <div class="composite col-sm-12 border-bottom border-dark">
                                        <div class="format col-sm-4" *ngIf="mortgage.paymentFrequencyDd">
                                            <strong>{{'paymentFrequency_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentFrequencyDd, false)}}
                                        </div>
                                    </div>

                                    <div class="composite col-sm-12 border-bottom border-dark">
                                        <div class="format col-sm-3" *ngIf="mortgage.maturityDate">
                                            <strong>{{'maturityDate_appSummary' | translate}}:</strong> {{mortgage.maturityDate | date:
                                            'yyyy-MM-dd'}}
                                        </div>
                                        <div class="format col-sm-3 border-left border-dark"
                                            *ngIf="mortgage.interestTypeDd">
                                            <strong>{{'rateType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.interestTypeDd, false)}}
                                        </div>
                                        <div class="format col-sm-3 border-left border-dark"
                                            *ngIf="mortgage.paymentTermDd">
                                            <strong>{{'termType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentTermDd, false)}}
                                        </div>
                                        <div class="format col-sm-3 border-left border-dark"
                                            *ngIf="mortgage.rate !=null && mortgage.rate.interestRate != null">
                                            <strong>{{'mtgInterestRate_appSummary' | translate}}:</strong> {{mortgage.rate.interestRate/100 |
                                            percent:'1.3-5'}}
                                        </div>
                                    </div>


                                    <div class="composite col-sm-12 border-dark">
                                        <div class="format col-sm-6" *ngIf="mortgage.existingMortgageHolder">
                                            <strong>{{'mortgageHolder_appSummary' | translate}}:</strong> {{mortgage.existingMortgageHolder}}
                                        </div>
                                        <div class="format col-sm-3 border-left border-dark"
                                            *ngIf="mortgage.payoffTypeDd">
                                            <strong>{{'payoff_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.payoffTypeDd, false)}}
                                        </div>
                                        <div class="format col-sm-3 border-left border-dark"
                                            *ngIf="mortgage.loanTypeDd">
                                            <strong>{{'loanType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.loanTypeDd, false)}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!----------Rest of Applicat groups + Co-signor and Guarantor groups---------->
                    <div *ngFor="let applicantGroup of allApplicantGroups; let j = index" id="applicant-group-div">

                        <hr class="applicantgroup-line">

                        <!--Primary Applicant Section-->
                        <div *ngFor="let applicant of applicantGroup?.applicant; let i = index">

                            <!-- Logic required for the persistence of currentAddress field-->
                            <div *ngIf="getCurrentAddress(applicant.addressDetail)"></div>

                            <div class="border-bottom  border-dark"
                                *ngIf="getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) === 'Applicant'
                                 || getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) === 0">
                                <h1 class="other-applicant-group-paragraph ml-3">{{ createName(applicant.name)}}</h1>
                            </div>
                            <div class="border-bottom  border-dark"
                                *ngIf="getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) === 'Co Signer'
                                 || getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) === 1">
                                <h1 class="other-applicant-group-paragraph ml-3">{{'coSigner_appSummary' | translate}} - {{
                                    createName(applicant.name)}}</h1>
                            </div>
                            <div class="border-bottom  border-dark"
                                *ngIf="getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) === 'Guarantor'
                                 || getLanguageBasedValue(applicantGroup.applicantGroupTypeDd, true) === 2">
                                <h1 class="other-applicant-group-paragraph ml-3">{{'guarantor_appSummary' | translate}} - {{
                                    createName(applicant.name)}}</h1>
                            </div>

                            <div class="format col-sm-12 border-bottom  border-dark"
                                *ngIf="createAddress(currentAddress?.address)">
                                <strong>{{'address_appSummary' | translate}}:</strong> {{createAddress(currentAddress?.address)}}
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-4" *ngIf="currentAddress?.residentialStatusDd">
                                    <strong>{{'residentialStatus_appSummary' | translate}}:</strong> {{getLanguageBasedValue(currentAddress?.residentialStatusDd, false)}}
                                </div>
                                <div class="format col-sm-6 border-left border-dark"
                                    *ngIf="currentAddress?.monthsAtAddress">
                                    <strong>{{'timeAtResidence_appSummary' | translate}}:</strong> {{formatTime(currentAddress?.monthsAtAddress)}}
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-4" *ngIf="getNumber(applicant.phone, 'Work')">
                                    <strong>{{'workPhone_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Work')}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark"
                                    *ngIf="getNumber(applicant.phone, 'Cell')">
                                    <strong>{{'cellPhone_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Cell')}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark"
                                    *ngIf="getNumber(applicant.phone, 'Home')">
                                    <strong>{{'homePhone_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Home')}}
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-4" *ngIf="getNumber(applicant.phone, 'Fax')">
                                    <strong>{{'faxNumber_appSummary' | translate}}:</strong> {{getNumber(applicant.phone, 'Fax')}}
                                </div>
                                <div class="format col-sm-6 border-left border-dark" *ngIf="applicant.emailAddress">
                                    <strong>{{'email_appSummary' | translate}}:</strong> {{applicant.emailAddress}}
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-4" *ngIf="applicant.maritalStatusDd">
                                    <strong>{{'maritalStatus_appSummary' | translate}}:</strong> {{getLanguageBasedValue(applicant.maritalStatusDd, false)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="applicant.birthDate">
                                    <strong>{{'dateOfBirth_appSummary' | translate}}:</strong> {{applicant.birthDate | date: 'yyyy-MM-dd'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark"
                                    *ngIf="applicant.numberOfDependents != null || applicant.numberOfDependents != undefined">
                                    <strong>{{'dependents_appSummary' | translate}}:</strong> {{applicant.numberOfDependents}}
                                </div>
                            </div>
                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-4" *ngIf="applicant.socialInsuranceNumber">
                                    <strong>{{'sin_appSummary' | translate}}:</strong> {{formatPhoneNumberAndSin(applicant.socialInsuranceNumber)}}
                                </div>
                            </div>

                            <!-- Logic required for the persistence of previousAddress field-->
                            <div *ngIf="getPreviousAddress(applicant.addressDetail)"></div>

                            <div *ngIf="previousAddresses !== null && previousAddresses.length != 0">
                                <div *ngFor="let previousAddress of previousAddresses">
                                    <div class="format col-sm-12 border-bottom  border-dark"
                                        *ngIf="createAddress(previousAddress?.address)">
                                        <strong>{{'previousAddress_appSummary' | translate}}:</strong> {{createAddress(previousAddress?.address)}}
                                    </div>

                                    <div class="composite col-sm-12 border-bottom  border-dark">
                                        <div class="format col-sm-4" *ngIf="previousAddress?.residentialStatusDd">
                                            <strong>{{'residentialStatus_appSummary' | translate}}:</strong>
                                            {{getLanguageBasedValue(previousAddress?.residentialStatusDd, false)}}
                                        </div>
                                        <div class="format col-sm-6 border-left border-dark"
                                            *ngIf="previousAddress?.monthsAtAddress !== 0 && previousAddress?.monthsAtAddress !== undefined">
                                            <strong>{{'timeAtResidence_appSummary' | translate}}:</strong>
                                            {{formatTime(previousAddress?.monthsAtAddress)}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Logic required for the persistence of currentEmployement field-->
                            <div *ngIf="getEmployment(applicant.employmentHistory, 'Current')"></div>

                            <div
                                *ngIf="getCurrentEmployments(applicant.employmentHistory) != null && getCurrentEmployments(applicant.employmentHistory).length != 0">
                                <div
                                    *ngFor="let currentEmployment of getCurrentEmployments(applicant.employmentHistory)">
                                    <div class="composite col-sm-12 border-bottom  border-dark"
                                        style="padding-top: 20px">
                                        <div class="format col-sm-4">
                                            <strong>{{'currentEmployer_appSummary' | translate}}</strong>
                                        </div>
                                    </div>
                                    <div class="composite col-sm-12 border-bottom  border-dark">
                                        <div class="format col-sm-4" *ngIf="currentEmployment.employerName">
                                            {{currentEmployment.employerName}}
                                        </div>
                                        <div class="format col-sm-6 border-left border-dark"
                                            *ngIf="currentEmployment.monthsOfService !== 0 && currentEmployment.monthsOfService !== undefined">
                                            <strong>{{'timeAtJob_appSummary' | translate}}:</strong>
                                            {{formatTime(currentEmployment.monthsOfService)}}
                                        </div>
                                    </div>

                                    <div class="composite col-sm-12 border-bottom  border-dark">
                                        <div class="format col-sm-4" *ngIf="currentEmployment.occupationDd">
                                            <strong>{{'occupation_appSummary' | translate}}:</strong> {{getLanguageBasedValue(currentEmployment.occupationDd, false)}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="currentEmployment.jobTitle">
                                            <strong>{{'jobTitle_appSummary' | translate}}:</strong> {{currentEmployment.jobTitle}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="currentEmployment.employmentHistoryTypeDd">
                                            <strong>{{'employmentType_appSummary' | translate}}:</strong>
                                            {{getLanguageBasedValue(currentEmployment.employmentHistoryTypeDd, false)}}
                                        </div>
                                    </div>

                                    <div class="composite col-sm-12 border-bottom  border-dark">
                                        <div class="format col-sm-4"></div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="currentEmployment.income.incomeTypeDd">
                                            <strong>{{'incomeType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(currentEmployment.income.incomeTypeDd, false)}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="currentEmployment.income.incomeAmount">
                                            <strong>{{'annualIncome_appSummary' | translate}}:</strong>
                                            {{calculateAnnualIncome(currentEmployment.income, false) |
                                            currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Logic required for the persistence of previous Employement field-->
                            <div *ngIf="getEmployment(applicant.employmentHistory, 'Previous')"></div>

                            <div
                                *ngIf="getPreviousEmployments(applicant.employmentHistory) != null && getPreviousEmployments(applicant.employmentHistory).length > 0">
                                <div *ngFor="let prevEmployment of getPreviousEmployments(applicant.employmentHistory)">

                                    <div class="composite col-sm-12 border-bottom  border-dark"
                                        style="padding-top: 20px">
                                        <div class="format col-sm-4">
                                            <strong>{{'previousEmployer_appSummary' | translate}}</strong>
                                        </div>
                                    </div>
                                    <div class="composite col-sm-12 border-bottom  border-dark">
                                        <div class="format col-sm-4" *ngIf="prevEmployment.employerName">
                                            {{prevEmployment.employerName}}
                                        </div>
                                        <div class="format col-sm-6 border-left border-dark"
                                            *ngIf="prevEmployment.monthsOfService !== 0 && prevEmployment.monthsOfService !== undefined">
                                            <strong>{{'timeAtJob_appSummary' | translate}}:</strong>
                                            {{formatTime(prevEmployment.monthsOfService)}}
                                        </div>
                                    </div>

                                    <div class="composite col-sm-12 border-bottom  border-dark">
                                        <div class="format col-sm-4" *ngIf="prevEmployment.occupationDd">
                                            <strong>{{'occupation_appSummary' | translate}}:</strong> {{getLanguageBasedValue(prevEmployment.occupationDd, false)}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="prevEmployment.jobTitle">
                                            <strong>{{'jobTitle_appSummary' | translate}}:</strong> {{prevEmployment.jobTitle}}
                                        </div>
                                        <div class="format col-sm-4 border-left border-dark"
                                            *ngIf="prevEmployment.employmentHistoryTypeDd">
                                            <strong>{{'employmentType_appSummary' | translate}}:</strong>
                                            {{getLanguageBasedValue(prevEmployment.employmentHistoryTypeDd, false)}}
                                        </div>
                                    </div>

                                    <div class="composite col-sm-12 border-bottom  border-dark">
                                        <div class="format col-sm-4" *ngIf="prevEmployment.income?.incomeTypeDd">
                                            <strong>{{'incomeType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(prevEmployment.income.incomeTypeDd, false)}}
                                        </div>
                                        <div class="format col-sm-6" *ngIf="prevEmployment.income?.incomeAmount">
                                            <strong>{{'annualIncome_appSummary' | translate}}:</strong>
                                            {{calculateAnnualIncome(prevEmployment.income, true) |
                                            currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--Other Income-->
                        <div id="otherincome-applicant-group-div"
                            *ngIf="applicantGroup?.applicant[0].otherIncome != null && applicantGroup?.applicant[0].otherIncome.length !== 0">
                            <div class="col-sm-12 border-bottom  border-dark">
                                <h1 class="applicant-group-paragraph">{{'otherIncome_appSummary' | translate}}</h1>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-3"><strong>{{'type_appSummary' | translate}}</strong></div>
                                <div class="format col-sm-3 border-left border-dark"
                                    *ngIf="displayOtherIncomeDescriptionColumns[j+1]"><strong>{{'description_appSummary' | translate}}</strong></div>
                                <div class="format col-sm-3 border-left border-dark"><strong>{{'period_appSummary' | translate}}</strong></div>
                                <div class="format col-sm-3 border-left border-dark"><strong>{{'amount_appSummary' | translate}}</strong></div>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark"
                                *ngFor="let otherIncome of applicantGroup?.applicant[0].otherIncome">
                                <div class="format col-sm-3">{{getLanguageBasedValue(otherIncome.incomeTypeDd, false)}}</div>
                                <div class="format col-sm-3 border-left border-dark"
                                    *ngIf="displayOtherIncomeDescriptionColumns[j+1]">{{otherIncome.incomeDescription}}
                                </div>
                                <div class="format col-sm-3 border-left border-dark">{{getLanguageBasedValue(otherIncome.incomePeriodDd, false)}}
                                </div>
                                <div class="format col-sm-3 border-left border-dark">{{otherIncome.incomeAmount |
                                    currency:'USD':'symbol':'1.2-2'}}</div>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-3"></div>
                                <div class="format col-sm-3" *ngIf="displayOtherIncomeDescriptionColumns[j+1]"></div>
                                <div class="format col-sm-3"><strong>{{'total_appSummary' | translate}}</strong></div>
                                <div class="format col-sm-3 border-left border-dark">
                                    <strong>{{caluclateTotalOtherIncome(applicantGroup?.applicant[0].otherIncome) |
                                        currency:'USD':'symbol':'1.2-2'}}</strong>
                                </div>
                            </div>
                        </div>

                        <!--Financial-->
                        <div id="financial-applicant-group-div"
                            *ngIf="applicantGroup?.applicant[0].asset != null && applicantGroup?.applicant[0].asset.length !== 0">
                            <div class="col-sm-12 border-bottom  border-dark">
                                <h1 class="applicant-group-paragraph">{{'financial_appSummary' | translate}}</h1>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-3"><strong>{{'assets_appSummary' | translate}}</strong></div>
                                <div class="format col-sm-3 border-left border-dark"
                                    *ngIf="displayAssetsDescriptionColumns[j+1]"><strong>{{'description_appSummary' | translate}}</strong></div>
                                <div class="format col-sm-3 border-left border-dark"><strong>{{'value_appSummary' | translate}}</strong></div>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark"
                                *ngFor="let asset of applicantGroup?.applicant[0].asset">
                                <div class="format col-sm-3">{{getLanguageBasedValue(asset.assetTypeDd, false)}}</div>
                                <div class="format col-sm-3 border-left border-dark"
                                    *ngIf="displayAssetsDescriptionColumns[j+1]">{{asset.assetDescription}}</div>
                                <div class="format col-sm-3 border-left border-dark">{{asset.assetValue |
                                    currency:'USD':'symbol':'1.2-2'}}</div>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-3" *ngIf="displayAssetsDescriptionColumns[j+1]"></div>
                                <div class="format col-sm-3"><strong>{{'total_appSummary' | translate}}</strong></div>
                                <div class="format col-sm-3 border-left border-dark">
                                    <strong>{{caluclateTotalAssetValue(applicantGroup?.applicant[0].asset) |
                                        currency:'USD':'symbol':'1.2-2'}}</strong>
                                </div>
                            </div>
                        </div>

                        <!--Liabilities-->
                        <div id="liabilities-applicant-group-div"
                            *ngIf="applicantGroup?.applicant[0].liability != null && applicantGroup?.applicant[0].liability.broker != 0">
                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-3"><strong>{{'liabiliies_appSummary' | translate}}</strong></div>
                                <div class="format col-sm-3 border-left border-dark"
                                    *ngIf="displayLiabilitiesDescriptionColumns[j+1]"><strong>{{'description_appSummary' | translate}}</strong></div>
                                <div class="format col-sm-1 border-left border-dark" style="max-width: 100px;min-width: 100px;"><strong>{{'value_appSummary' | translate}}</strong></div>
                                <div class="format col-sm-1 border-left border-dark" style="max-width: 100px;min-width: 100px;"><strong>{{'balance_appSummary' | translate}}</strong></div>
                                <div class="format col-sm-2 border-left border-dark" style="max-width: 141px;min-width: 141px;"><strong>{{'monthlyPayment_appSummary' | translate}}</strong>
                                </div>
                                <div class="format col-sm-1 border-left border-dark" *ngIf="displayPayOffColumns[j+1]">
                                    <strong>{{'payoff_appSummary' | translate}}</strong>
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark"
                                *ngFor="let liability of applicantGroup?.applicant[0].liability.broker">
                                <div class="format col-sm-3">{{getLanguageBasedValue(liability.liabilityTypeDd, false)}}</div>
                                <div class="format col-sm-3 border-left border-dark"
                                    *ngIf="displayLiabilitiesDescriptionColumns[j+1]">{{liability.liabilityDescription}}
                                </div>
                                <div class="format col-sm-1 border-left border-dark" style="max-width: 100px;min-width: 100px;">{{liability.creditLimit |
                                    currency:'USD':'symbol':'1.2-2'}}</div>
                                <div class="format col-sm-1 border-left border-dark" style="max-width: 100px;min-width: 100px;">{{liability.liabilityAmount |
                                    currency:'USD':'symbol':'1.2-2'}}</div>
                                <div class="format col-sm-2 border-left border-dark" style="max-width: 141px;min-width: 141px;">{{liability.liabilityMonthlyPayment
                                    | currency:'USD':'symbol':'1.2-2'}}</div>
                                <div class="format col-sm-1 border-left border-dark" *ngIf="displayPayOffColumns[j+1] && liability.liabilityPayOffTypeDd">
                                    {{getLanguageBasedValue(liability.liabilityPayOffTypeDd, false)}}</div>
                            </div>

                            <div class="composite col-sm-12 border-bottom  border-dark">
                                <div class="format col-sm-3" *ngIf="displayLiabilitiesDescriptionColumns[j+1]"></div>
                                <div class="format col-sm-3"><strong>{{'total_appSummary' | translate}}</strong></div>
                                <div class="format col-sm-1 border-left border-dark" style="max-width: 100px;min-width: 100px;">
                                    <strong>{{caluclateTotalValue(applicantGroup?.applicant[0].liability.broker) |
                                        currency:'USD':'symbol':'1.2-2'}}</strong>
                                </div>
                                <div class="format col-sm-1 border-left border-dark" style="max-width: 100px;min-width: 100px;">
                                    <strong>{{caluclateTotalBalance(applicantGroup?.applicant[0].liability.broker) |
                                        currency:'USD':'symbol':'1.2-2'}}</strong>
                                </div>
                                <div class="format col-sm-2 border-left border-dark">
                                    <strong>{{caluclateTotalMonthlyPayment(applicantGroup?.applicant[0].liability.broker)
                                        | currency:'USD':'symbol':'1.2-2'}}</strong>
                                </div>
                            </div>
                        </div>

                        <!--Other Properties-->
                        <div id="otherproperties-applicant-group-div"
                            *ngIf="applicantGroup?.applicant[0].otherProperty != null && applicantGroup?.applicant[0].otherProperty.length !== 0"
                            class="border-bottom border-dark">
                            <div class="col-sm-12 border-bottom  border-dark">
                                <h1 class="applicant-group-paragraph" for="otherProperty-appgroup">{{'otherProperties_appSummary' | translate}}</h1>
                            </div>

                            <div *ngFor="let otherProperty of applicantGroup?.applicant[0].otherProperty" class="mb-3"
                                id="otherProperty-appgroup">
                                <div class="format col-sm-12 border-bottom  border-dark"
                                    *ngIf="createAddress(otherProperty?.property.address)">
                                    <strong>{{'address_appSummary' | translate}}:</strong> {{createAddress(otherProperty?.property.address)}}
                                </div>

                                <div class="format col-sm-12 border-bottom  border-dark"
                                    *ngIf="otherProperty?.property.estimatedAppraisalValue">
                                    <strong>{{'propertyValue_appSummary' | translate}}:</strong> {{ otherProperty?.property.estimatedAppraisalValue
                                    | currency:'USD':'symbol':'1.2-2'}}
                                </div>

                                <div class="composite col-sm-12 border-bottom  border-dark">
                                    <div class="format col-sm-4">
                                        <strong>{{'monthlyRentalIncome_appSummary' | translate}}:</strong>
                                        {{getRentalIncome(otherProperty?.property.rentalIncome) |
                                        currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                    <div class="format col-sm-4  border-left border-dark"
                                        *ngIf="otherProperty?.property.rentalOffsetOption">
                                        <strong>{{'rentalOffsetOption_appSummary' | translate}}:</strong>
                                        {{getLanguageBasedValue(otherProperty?.property.rentalOffsetOption, false)}}
                                    </div>
                                </div>

                                <div class="composite col-sm-12 border-bottom  border-dark">
                                    <div class="format col-sm-6">
                                        <strong>{{'offset_appSummary' | translate}} %:</strong> {{getOffset(otherProperty?.property.rentalIncome)}}
                                    </div>
                                </div>

                                <div class="composite col-sm-12 border-bottom  border-dark">
                                    <div class="format col-sm-4">
                                        <strong>{{'propertyTaxes_appSummary' | translate}}:</strong>
                                        {{getExpense(otherProperty?.property.propertyExpense, 'Municipal Taxes') |
                                        currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                    
                                    <div class="format col-sm-4 border-left border-dark">
                                        <strong>{{'condoFees_appSummary' | translate}}:</strong>
                                        {{getExpense(otherProperty?.property.propertyExpense, 'Condo Fees') |
                                        currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark">
                                        <strong>{{'heating_appSummary' | translate}}:</strong> {{getExpense(otherProperty?.property.propertyExpense,
                                        'Heating Expense') | currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                </div>

                                <div class="composite col-sm-12 border-bottom  border-dark">
                                    <div class="format col-sm-4">
                                        <strong>{{'hydro_appSummary' | translate}}:</strong> {{getExpense(otherProperty?.property.propertyExpense,
                                        'Hydro') | currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark">
                                        <strong>{{'repairs_appSummary' | translate}}:</strong> {{getExpense(otherProperty?.property.propertyExpense,
                                        'Repairs') | currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark">
                                        <strong>{{'mgmtExpenses_appSummary' | translate}}:</strong>
                                        {{getExpense(otherProperty?.property.propertyExpense, 'Management Expenses') |
                                        currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                </div>

                                <div class="composite col-sm-12 border-bottom  border-dark">
                                    <div class="format col-sm-4">
                                        <strong>{{'interestCharges_appSummary' | translate}}:</strong>
                                        {{getExpense(otherProperty?.property.propertyExpense, 'Interest Charges') |
                                        currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark">
                                        <strong>{{'generalExpenses_appSummary' | translate}}:</strong>
                                        {{getExpense(otherProperty?.property.propertyExpense, 'General Expenses') |
                                        currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark">
                                        <strong>{{'insurance_appSummary' | translate}}:</strong> {{getExpense(otherProperty?.property.propertyExpense,
                                        'Insurance') | currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                </div>

                                <div *ngIf="otherProperty.mortgage != null && otherProperty.mortgage.length != 0">
                                    <div *ngFor="let mortgage of otherProperty.mortgage">
                                        <div class="composite col-sm-12 border-bottom  border-dark">
                                            <div class="format col-sm-4" *ngIf="mortgage.mortgageTypeDd">
                                                <strong>{{'mortgageType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.mortgageTypeDd, false)}}
                                            </div>
                                            <div class="format col-sm-4 border-left border-dark"
                                                *ngIf="mortgage.balanceRemaining">
                                                <strong>{{'mortgageBalance_appSummary' | translate}}:</strong> {{mortgage.balanceRemaining |
                                                currency:'USD':'symbol':'1.2-2'}}
                                            </div>
                                            <div class="format col-sm-4 border-left border-dark"
                                                *ngIf="mortgage.pAndIPaymentAmount">
                                                <strong>{{'frequencyPayment_appSummary' | translate}}:</strong> {{mortgage.pAndIPaymentAmount |
                                                currency:'USD':'symbol':'1.2-2'}}
                                            </div>
                                        </div>
                                        <div class="composite col-sm-12 border-bottom  border-dark">
                                            <div class="format col-sm-4" *ngIf="mortgage.paymentFrequencyDd">
                                                <strong>{{'paymentFrequency_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentFrequencyDd, false)}}
                                            </div>
                                        </div>

                                        <div class="composite col-sm-12 border-bottom  border-dark">
                                            <div class="format col-sm-3" *ngIf="mortgage.maturityDate">
                                                <strong>{{'maturityDate_appSummary' | translate}}:</strong> {{mortgage.maturityDate | date:
                                                'yyyy-MM-dd'}}
                                            </div>
                                            <div class="format col-sm-3 border-left border-dark"
                                                *ngIf="mortgage.interestTypeDd">
                                                <strong>{{'rateType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.interestTypeDd, false)}}
                                            </div>
                                            <div class="format col-sm-3 border-left border-dark"
                                                *ngIf="mortgage.paymentTermDd">
                                                <strong>{{'termType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentTermDd, false)}}
                                            </div>
                                            <div class="format col-sm-3 border-left border-dark"
                                                *ngIf="mortgage.rate !=null && mortgage.rate.interestRate != null">
                                                <strong>{{'mtgInterestRate_appSummary' | translate}}:</strong> {{mortgage.rate.interestRate/100 |
                                                percent:'1.3-5'}}
                                            </div>
                                        </div>

                                        <div class="composite col-sm-12 border-bottom  border-dark">
                                            <div class="format col-sm-6" *ngIf="mortgage.existingMortgageHolder">
                                                <strong>{{'mortgageHolder_appSummary' | translate}}:</strong> {{mortgage.existingMortgageHolder}}
                                            </div>
                                            <div class="format col-sm-3 border-left border-dark"
                                                *ngIf="mortgage.payoffTypeDd">
                                                <strong>{{'payoff_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.payoffTypeDd, false)}}
                                            </div>
                                            <div class="format col-sm-3 border-left border-dark"
                                                *ngIf="mortgage.loanTypeDd">
                                                <strong>{{'loanType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.loanTypeDd, false)}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="financing-container" *ngIf="displayFinancingData">
                <div id="financingData" class="border-dark">

                    <div class="col-sm-12 border-dark">
                        <h1 class="applicant-group-paragraph" for="financing-lender-loan">{{'requestedMortgage_appSummary' | translate}}</h1>
                    </div>

                    <div id="financing-lender-loan" class="composite col-sm-12 border-bottom border-dark"
                        *ngIf="searchMortgage?.requested.lenderSubmission.lenderProfile.lenderName">
                        <div class="format col-sm-4">
                            <strong>{{'lender_appSummary' | translate}}:</strong>
                            {{searchMortgage.requested.lenderSubmission.lenderProfile.lenderName}}
                        </div>
                        <div class="format col-sm-6 border-left border-dark"
                            *ngIf="searchMortgage?.requested.loanTypeDd">
                            <strong>{{'loanType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(searchMortgage.requested.loanTypeDd, false)}}
                        </div>
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="financing-mortgage-details">
                        <div class="format col-sm-4"
                            *ngIf="searchDeal?.dealPurposeDd !== undefined || searchDeal?.refiPurpose !== undefined">
                            <strong>{{'purpose_appSummary' | translate}}:</strong> {{verifyValue(searchDeal.dealPurposeDd, true) + " " +
                            verifyValue(searchDeal.refiPurpose, false)}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark"
                            *ngIf="searchMortgage?.requested.mortgageTypeDd">
                            <strong>{{'mortgageType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(searchMortgage.requested.mortgageTypeDd, false)}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark" *ngIf="searchDeal?.estimatedClosingDate">
                            <strong>{{'closingDate_appSummary' | translate}}:</strong> {{searchDeal?.estimatedClosingDate | date: 'yyyy-MM-dd'}}
                        </div>
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="financing-payment-details">
                        <div class="format col-sm-4" *ngIf="searchMortgage?.requested.paymentFrequencyDd">
                            <strong>{{'paymentFrequency_appSummary' | translate}}:</strong> {{getLanguageBasedValue(searchMortgage?.requested.paymentFrequencyDd, false)}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark"
                            *ngIf="checkPurchaseValue(subjectProperty)">
                            <strong>{{'purchaseValue_appSummary' | translate}}:</strong> {{checkPurchaseValue(subjectProperty) |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark">
                            <strong>{{'insurancePremium_appSummary' | translate}}:</strong> {{searchMortgage?.requested.miPremiumAmount |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="financing-payment-rate">
                        <div class="format col-sm-4" *ngIf="searchMortgage?.requested.pAndIPaymentAmountMonthly">
                            <strong>{{'monthlyPayment_appSummary' | translate}}:</strong> {{searchMortgage?.requested.pAndIPaymentAmountMonthly |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-4 border-dark" *ngIf="searchMortgage?.requested.totalLoanAmount">
                            <strong>{{'totalMortgageAmount_appSummary' | translate}}:</strong> {{searchMortgage?.requested.totalLoanAmount |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark">
                            <strong>{{'rateType_appSummary' | translate}}::</strong> {{getLanguageBasedValue(searchMortgage?.requested.interestTypeDd, false)}}
                        </div>
                    </div>
                    <div class="composite col-sm-12 border-bottom border-dark">
                        <div class="format col-sm-4" *ngIf="searchMortgage?.requested.rate.netRate">
                            <strong>{{'netRate_appSummary' | translate}}:</strong> {{searchMortgage?.requested.rate.netRate/100 | percent:'1.3-5'}}
                        </div>
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="loan-term-details">
                        <div class="format col-sm-4" *ngIf="searchMortgage?.requested.paymentTermDd">
                            <strong>{{'termType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(searchMortgage?.requested.paymentTermDd, false)}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark"
                            *ngIf="searchMortgage?.requested.actualPaymentTerm">
                            <strong>{{'term_appSummary' | translate}}:</strong> {{searchMortgage?.requested.actualPaymentTerm}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark"
                            *ngIf="searchMortgage?.requested.amortizationTerm">
                            <strong>{{'amortization_appSummary' | translate}}:</strong> {{searchMortgage?.requested.amortizationTerm}}
                        </div>
                    </div>

                    <div class="format col-sm-4" *ngIf="searchMortgage?.requested.interestOnlyFlag"
                        style="padding-top:10px; padding-bottom:10px">
                        <strong>{{'repaymentType_appSummary' | translate}}:</strong> {{searchMortgage?.requested.interestOnlyFlag}}
                    </div>

                    <div class="col-sm-12 border-bottom border-dark">
                        <h1 class="applicant-group-paragraph">{{'downPayment_appSummary' | translate}}</h1>
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="downPayment-labels">
                        <div class="format col-sm-4"><strong>Source</strong></div>
                        <div class="format col-sm-4 border-left border-dark"><strong>{{'description_appSummary' | translate}}</strong></div>
                        <div class="format col-sm-4 border-left border-dark"><strong>{{'amount_appSummary' | translate}}</strong></div>
                    </div>

                    <div *ngFor="let downPayment of downPaymentSourceVariable" id="downPayment-details">
                        <div class="composite col-sm-12 border-bottom border-dark">
                            <div class="format col-sm-4">{{getLanguageBasedValue(downPayment.downPaymentSourceTypeDd, false)}}</div>
                            <div class="format col-sm-4 border-left border-dark">{{downPayment.description}}</div>
                            <div class="format col-sm-4 border-left border-dark">{{downPayment.amount |
                                currency:'USD':'symbol':'1.2-2'}}</div>
                        </div>
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="downPayment-total">
                        <div class="format col-sm-4"></div>
                        <div class="format col-sm-4"><strong>{{'total_appSummary' | translate}}</strong></div>
                        <div class="format col-sm-4 border-left border-dark"><strong>{{calculateTotalDownPayments() |
                                currency:'USD':'symbol':'1.2-2'}}</strong></div>
                    </div>

                    <div *ngIf="concurrentMortgages?.length != 0 && concurrentMortgages != undefined">
                        <div class="col-sm-12 border-bottom border-dark">
                            <h1 class="applicant-group-paragraph">{{'concurrent_appSummary' | translate}}</h1>
                        </div>

                        <div id="concurrentMortgage-details">
                            <div *ngFor="let concurrentMortgage of concurrentMortgages">
                                <div class="composite col-sm-12 border-bottom border-dark">
                                    <div class="format col-sm-4" *ngIf="concurrentMortgage.mortgageTypeDd">
                                        <strong>{{'mortgageType_appSummary' | translate}}: </strong> {{getLanguageBasedValue(concurrentMortgage.mortgageTypeDd, false)}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark"
                                        *ngIf="concurrentMortgage.maturityDate">
                                        <strong>{{'maturityDate_appSummary' | translate}}: </strong> {{concurrentMortgage.maturityDate | date:
                                        'yyyy-MM-dd'}}
                                    </div>
                                </div>

                                <div class="composite col-sm-12 border-bottom border-dark">
                                    <div class="format col-sm-4" *ngIf="concurrentMortgage.totalLoanAmount">
                                        <strong>{{'totalMortgageAmount_appSummary' | translate}}: </strong> {{concurrentMortgage.totalLoanAmount |
                                        currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark"
                                        *ngIf="concurrentMortgage.rate.netRate">
                                        <strong>{{'netRate_appSummary' | translate}}: </strong> {{concurrentMortgage.rate.netRate/100 |
                                        percent:'1.3-5'}}
                                    </div>
                                    <div class="format col-sm-4 border-left border-dark"
                                        *ngIf="concurrentMortgage.pAndIPaymentAmountMonthly">
                                        <strong>{{'monthlyPayment_appSummary' | translate}}: </strong>
                                        {{concurrentMortgage.pAndIPaymentAmountMonthly |
                                        currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="existingMortgages?.length !=0" id="existing-mortgages-container">
                        <div class="col-sm-12">
                            <h1 class="applicant-group-paragraph" for="all-existing-mortgages">{{'existingMortgages_appSummary' | translate}}</h1>
                        </div>

                        <div *ngFor="let mortgage of existingMortgages" id="all-existing-mortgages">

                            <div class="composite col-sm-12 border-bottom border-dark">
                                <div class="format col-sm-4" *ngIf="mortgage.mortgageTypeDd">
                                    <strong>{{'mortgageType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.mortgageTypeDd, false)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.balanceRemaining">
                                    <strong>{{'mortgageBalance_appSummary' | translate}}:</strong> {{ mortgage.balanceRemaining |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark"
                                    *ngIf="mortgage.pAndIPaymentAmount">
                                    <strong>{{'frequencyPayment_appSummary' | translate}}:</strong> {{mortgage.pAndIPaymentAmount |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                            </div>
                            <div class="composite col-sm-12 border-bottom border-dark"
                                id="existing-mortgages-paymentFrequency">
                                <div class="format col-sm-4" *ngIf="mortgage.paymentFrequencyDd">
                                    <strong>{{'paymentFrequency_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentFrequencyDd, false)}}
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom border-dark" id="existing-mortgages-details">
                                <div class="format col-sm-4" *ngIf="mortgage.maturityDate">
                                    <strong>{{'maturityDate_appSummary' | translate}}:</strong> {{mortgage.maturityDate | date: 'yyyy-MM-dd'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.interestTypeDd">
                                    <strong>{{'rateType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.interestTypeDd, false)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.paymentTermDd">
                                    <strong>{{'termType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentTermDd, false)}}
                                </div>
                            </div>
                            <div class="composite col-sm-12 border-bottom border-dark"
                                id="existing-mortgages-interestRate">
                                <div class="format col-sm-4" *ngIf="mortgage.rate">
                                    <div *ngIf="mortgage.rate.interestRate">
                                        <strong>{{'mtgInterestRate_appSummary' | translate}}:</strong> {{mortgage.rate.interestRate/100 |
                                        percent:'1.3-5'}}
                                    </div>
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom border-dark" id="existing-mortgages-holder">
                                <div class="format col-sm-4" *ngIf="mortgage.existingMortgageHolder">
                                    <strong>{{'mortgageHolder_appSummary' | translate}}:</strong> {{mortgage.existingMortgageHolder}}
                                </div>
                                <div class="format col-sm-6" *ngIf="mortgage.loanTypeDd">
                                    <strong>{{'loanType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.loanTypeDd, false)}}
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom border-dark" id="existing-mortgages-amount">
                                <div class="format col-sm-4" *ngIf="mortgage.originalMortgageAmount">
                                    <strong>{{'originalMtgAmt_appSummary' | translate}}:</strong> {{mortgage.originalMortgageAmount |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-6 border-left border-dark"
                                    *ngIf="mortgage.currentMortgageNumber">
                                    <strong>{{'mortgage_appSummary' | translate}} #:</strong> {{mortgage.currentMortgageNumber}}
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom border-dark"
                                id="existing-mortgages-other-details">
                                <div class="format col-sm-4" *ngIf="mortgage.insuredFlag">
                                    <strong>{{'insured_appSummary' | translate}}:</strong> {{convertValue(mortgage.insuredFlag)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.mortgageInsurerId">
                                    <strong>{{'insurer_appSummary' | translate}}:</strong> {{getInsurer(mortgage.mortgageInsurerId)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.miReferenceNumber">
                                    <strong>{{'insuranceAccount_appSummary' | translate}} #:</strong> {{mortgage.miReferenceNumber}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="refinanceSwiftETOMortgages?.length !=0" id="refinance-details">
                        <div class="col-sm-12 border-bottom border-dark">
                            <h1 class="applicant-group-paragraph" for="refinance-swiftETO-mortgages">{{'refinanceSwitchEto_appSummary' | translate}}</h1>
                        </div>

                        <div *ngFor="let mortgage of refinanceSwiftETOMortgages" id="refinance-swiftETO-mortgages">

                            <div class="composite col-sm-12 border-bottom border-dark" id="refinance-mortgage-details">
                                <div class="format col-sm-4" *ngIf="mortgage.mortgageTypeDd">
                                    <strong>{{'mortgageType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.mortgageTypeDd, false)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.balanceRemaining">
                                    <strong>{{'mortgageBalance_appSummary' | translate}}:</strong> {{ mortgage.balanceRemaining |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark"
                                    *ngIf="mortgage.pAndIPaymentAmount">
                                    <strong>{{'frequencyPayment_appSummary' | translate}}:</strong> {{mortgage.pAndIPaymentAmount |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                            </div>
                            <div class="composite col-sm-12 border-bottom border-dark" id="refinance-payment-frequency">
                                <div class="format col-sm-4" *ngIf="mortgage.paymentFrequencyDd">
                                    <strong>{{'paymentFrequency_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentFrequencyDd, false)}}
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom border-dark" id="refinance-mortgage-type">
                                <div class="format col-sm-4" *ngIf="mortgage.maturityDate">
                                    <strong>{{'maturityDate_appSummary' | translate}}:</strong> {{mortgage.maturityDate | date: 'yyyy-MM-dd'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.interestTypeDd">
                                    <strong>{{'rateType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.interestTypeDd, false)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.paymentTermDd">
                                    <strong>{{'termType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.paymentTermDd, false)}}
                                </div>
                            </div>
                            <div class="composite col-sm-12 border-bottom border-dark" id="refinance-mortgage-interest">
                                <div class="format col-sm-4" *ngIf="mortgage.rate">
                                    <div *ngIf="mortgage.rate.interestRate">
                                        <strong>{{'mtgInterestRate_appSummary' | translate}}:</strong> {{mortgage.rate.interestRate/100 |
                                        percent:'1.3-5'}}
                                    </div>
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom border-dark" id="refinance-mortgage-holder">
                                <div class="format col-sm-4" *ngIf="mortgage.existingMortgageHolder">
                                    <strong>{{'mortgageHolder_appSummary' | translate}}:</strong> {{mortgage.existingMortgageHolder}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.loanTypeDd">
                                    <strong>{{'loanType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(mortgage.loanTypeDd, false)}}
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom border-dark" id="refinance-mortgage-amount">
                                <div class="format col-sm-4" *ngIf="mortgage.originalMortgageAmount">
                                    <strong>{{'originalMtgAmt_appSummary' | translate}}:</strong> {{mortgage.originalMortgageAmount |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark"
                                    *ngIf="mortgage.currentMortgageNumber">
                                    <strong>{{'mortgage_appSummary' | translate}} #:</strong> {{mortgage.currentMortgageNumber}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark"
                                    *ngIf="mortgage.refiBlendedAmortization">
                                    <strong>{{'blendedAmortization_appSummary' | translate}}:</strong> {{mortgage.refiBlendedAmortization}}
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom border-dark">
                                <div class="format col-sm-4" *ngIf="mortgage.insuredFlag">
                                    <strong>{{'insured_appSummary' | translate}}:</strong> {{convertValue(mortgage.insuredFlag)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.mortgageInsurerId">
                                    <strong>{{'insurer_appSummary' | translate}}:</strong> {{getInsurer(mortgage.mortgageInsurerId)}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark" *ngIf="mortgage.miReferenceNumber">
                                    <strong>{{'insuranceAccount_appSummary' | translate}} #:</strong> {{mortgage.miReferenceNumber}}
                                </div>
                            </div>

                            <div class="composite col-sm-12 border-bottom border-dark">
                                <div class="format col-sm-4" *ngIf="subjectProperty.originalPurchasePrice">
                                    <strong>{{'originalPurchasePrice_appSummary' | translate}}:</strong> {{subjectProperty.originalPurchasePrice |
                                    currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="format col-sm-4 border-left border-dark"
                                    *ngIf="subjectProperty.refiOrigPurchaseDate">
                                    <strong>{{'purchaseDate_appSummary' | translate}}:</strong> {{subjectProperty.refiOrigPurchaseDate | date:
                                    'yyyy-MM-dd'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="property-container" *ngIf="displayPropertyData">
                <div class="propertyData" class="border-dark">
                    <div class="col-sm-12 border-bottom border-dark">
                        <h1 class="applicant-group-paragraph" role="property-address">{{'subjectProperty_appSummary' | translate}}</h1>
                    </div>
                    <div class="format col-sm-12 border-bottom border-dark" id="property-address">
                        <strong>{{'propertyAddress_appSummary' | translate}}:</strong>
                        {{createAddress(subjectProperty.address)}}
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="property-lines">
                        <div class="format col-sm-4" *ngIf="subjectProperty?.legalLine1">
                            <strong>{{'lot_appSummary' | translate}}:</strong> {{ subjectProperty?.legalLine1}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark" *ngIf="subjectProperty?.legalLine2">
                            <strong>{{'block_appSummary' | translate}}:</strong> {{ subjectProperty?.legalLine2}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark" *ngIf="subjectProperty?.legalLine3">
                            <strong>{{'concessionTownship_appSummary' | translate}}:</strong> {{ subjectProperty?.legalLine3}}
                        </div>
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="property-date">
                        <div class="format col-sm-4" *ngIf="subjectProperty?.appraisalDateAct">
                            <strong>{{'appraisedDate_appSummary' | translate}}:</strong> {{subjectProperty?.appraisalDateAct | date: 'yyyy-MM-dd'}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark" *ngIf="subjectProperty?.occupancyTypeDd">
                            <strong>{{'occupancy_appSummary' | translate}}:</strong> {{getLanguageBasedValue(subjectProperty?.occupancyTypeDd, false)}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark" *ngIf="subjectProperty?.structureAge">
                            <strong>{{'age_appSummary' | translate}}:</strong> {{subjectProperty?.structureAge}} {{'years' | translate}}
                        </div>
                    </div>
                    <div class="composite col-sm-12 border-bottom border-dark" id="property-heating">
                        <div class="format col-sm-4" *ngIf="subjectProperty?.heatTypeDd">
                            <strong>{{'heatingType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(subjectProperty?.heatTypeDd, false)}}
                        </div>
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="property-type">
                        <div class="format col-sm-4" *ngIf="subjectProperty?.livingSpace">
                            <strong>{{'livingSpace_appSummary' | translate}}:</strong> {{subjectProperty?.livingSpace + " " +
                            verifyValue(subjectProperty.livingSpaceUnitOfMeasurDd, true)}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark" *ngIf="subjectProperty?.lotSize">
                            <strong>{{'lotSize_appSummary' | translate}}:</strong> {{subjectProperty?.lotSize + " " +
                            verifyValue(subjectProperty.lotSizeUnitOfMeasureDd, true)}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark" *ngIf="subjectProperty?.dwellingTypeDd">
                            <strong>{{'dwellingType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(subjectProperty?.dwellingTypeDd, false)}}
                        </div>
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="property-details">
                        <div class="format col-sm-4" *ngIf="subjectProperty?.dwellingStyleDd">
                            <strong>{{'dwellingStyle_appSummary' | translate}}:</strong> {{getLanguageBasedValue(subjectProperty?.dwellingStyleDd, false)}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark" *ngIf="subjectProperty?.garageSizeDd">
                            <strong>{{'garageSize_appSummary' | translate}}:</strong> {{getLanguageBasedValue(subjectProperty?.garageSizeDd, false)}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark" *ngIf="subjectProperty?.garageTypeDd">
                            <strong>{{'garageType_appSummary' | translate}}:</strong> {{getLanguageBasedValue(subjectProperty?.garageTypeDd, false)}}
                        </div>
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="property-taxes">
                        <div class="format col-sm-4" *ngIf="subjectProperty?.taxationYear">
                            <strong>{{'taxationYear_appSummary' | translate}}:</strong> {{subjectProperty?.taxationYear}}
                        </div>
                        <div class="format col-sm-6 border-left border-dark" *ngIf="loanApplication?.deal.taxPayorDd">
                            <strong>{{'taxesPaidBy_appSummary' | translate}}:</strong> {{getLanguageBasedValue(loanApplication?.deal.taxPayorDd, false)}}
                        </div>
                    </div>

                    <div class="col-sm-12 format border-bottom border-dark" *ngIf="subjectProperty?.insulatedWithUffi"
                        id="property-env">
                        <strong>{{'enviromentalHazard_appSummary' | translate}}:</strong> {{convertValue(subjectProperty?.insulatedWithUffi)}}
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="property-price">
                        <div class="format col-sm-4" *ngIf="subjectProperty?.purchasePrice">
                            <strong>{{'purchasePrice_appSummary' | translate}}:</strong> {{subjectProperty?.purchasePrice |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark"
                            *ngIf="subjectProperty?.estimatedAppraisalValue">
                            <strong>{{'estimatedValue_appSummary' | translate}}:</strong> {{subjectProperty?.estimatedAppraisalValue |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark"
                            *ngIf="subjectProperty?.actualAppraisalValue">
                            <strong>{{'appraisedValue_appSummary' | translate}}:</strong> {{subjectProperty?.actualAppraisalValue |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="property-costs">
                        <div class="format col-sm-4">
                            <strong>{{'heatingCost_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense, 'Heating Expense') | currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-4  border-left border-dark">
                            <strong>{{'condoFees_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense, 'Condo Fees') |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark">
                            <strong>{{'annualTaxes_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense, 'Municipal Taxes') | currency:'USD':'symbol':'1.2-2'}}
                        </div>
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="property-improvements">
                        <div class="format col-sm-4 border-right border-dark"
                            *ngIf="loanApplication?.deal.refiImprovementsDesc">
                            <strong>{{'improvements_appSummary' | translate}}:</strong> {{loanApplication?.deal.refiImprovementsDesc}}
                        </div>
                        <div class="format col-sm-6" *ngIf="loanApplication?.deal.refiImprovementAmount">
                            <strong>{{'valueOfImprovements_appSummary' | translate}}:</strong> {{loanApplication?.deal.refiImprovementAmount |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                    </div>

                    <div class="col-sm-12 format border-bottom border-dark" id="property-total-costs">
                        <strong>{{'totalExpenses_appSummary' | translate}}:</strong> {{getTotalExpenses(subjectProperty?.propertyExpense) |
                        currency:'USD':'symbol':'1.2-2'}}
                    </div>

                    <div class="col-sm-12 border-bottom border-dark">
                        <h1 class="applicant-group-paragraph">{{'rentalPropertyExpense_appSummary' | translate}}</h1>
                    </div>

                    <div class="composite col-sm-12 border-bottom  border-dark">
                        <div class="format col-sm-6">
                            <strong>{{'monthlyRentalIncome_appSummary' | translate}}:</strong> {{getRentalIncome(subjectProperty?.rentalIncome) |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-6  border-left border-dark"
                            *ngIf="subjectProperty?.rentalOffsetOption">
                            <strong>{{'rentalOffsetOption_appSummary' | translate}}:</strong> {{getLanguageBasedValue(subjectProperty?.rentalOffsetOption, false)}}
                        </div>
                    </div>

                    <div class="composite col-sm-12 border-bottom  border-dark">
                        <div class="format col-sm-6">
                            <strong>{{'offset_appSummary' | translate}} %:</strong> {{getOffset(subjectProperty?.rentalIncome)}}
                        </div>
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="property-insurance-details">
                        <div class="format col-sm-4">
                            <strong>{{'insurance_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense, 'Insurance') |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark">
                            <strong>{{'hydro_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense, 'Hydro') |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark">
                            <strong>{{'managementExpenses_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense,
                            'Management Expenses') | currency:'USD':'symbol':'1.2-2'}}
                        </div>
                    </div>

                    <div class="composite col-sm-12 border-bottom border-dark" id="property-repairs">
                        <div class="format col-sm-4">
                            <strong>{{'repairs_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense, 'Repairs') |
                            currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark">
                            <strong>{{'interestCharges_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense, 'Interest
                            Charges') | currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="format col-sm-4 border-left border-dark">
                            <strong>{{'generalExpenses_appSummary' | translate}}:</strong> {{getExpense(subjectProperty?.propertyExpense, 'General
                            Expenses') | currency:'USD':'symbol':'1.2-2'}}
                        </div>
                    </div>
                </div>
            </div>

            <!--Originator Details/The Story-->
            <div id="story-container" *ngIf="displayOriginatorDetails">
                <div id="originatorDetails" class="border-dark">
                    <div class="col-sm-12 border-bottom  border-dark">
                        <h1 class="applicant-group-paragraph" for="story-firm-details">{{'firm_appSummary' | translate}}</h1>
                    </div>
                    <div id="story-firm-details">
                        <div class="format col-sm-12 border-bottom border-dark" id="story-firm-name">
                            {{firmName}}
                        </div>

                        <div class="format col-sm-12 border-bottom border-dark" id="story-firm-address">
                            <div *ngIf="addressProvince == 'Quebec' && languageSelected == 'fr'; then contentFirmAddress else other_contentFA">
                                </div>
                                <ng-template #contentFirmAddress> {{verifyValue(firmAddress.streetNumber, false) + " " +verifyValue(firmAddress.streetTypeDd, true) + " " + verifyValue(firmAddress.streetName, false) + " " }}</ng-template>

                                <ng-template #other_contentFA>{{verifyValue(firmAddress.streetNumber, false) + " " +verifyValue(firmAddress.streetName, false) + " " + verifyValue(firmAddress.streetTypeDd, true) + " " }}</ng-template>

                            {{verifyValue(firmAddress.streetDirectionDd, true) + " " +
                            verifyUnitNumber(firmAddress?.unitNumber) + " " + verifyValue(firmAddress.city, false) + ", " }}
                            {{verifyValue(firmAddress.provinceDd, true) + " " + verifyValue(firmAddress.postalFsa, false) + " " +
                            verifyValue(firmAddress.postalLdu, false)}}
                        </div>

                        <div class="col-sm-12 border-bottom  border-dark">
                            <h1 class="applicant-group-paragraph">{{'broker_appSummary' | translate}}</h1>
                        </div>
                        <div class="format col-sm-12 border-bottom border-dark"
                            *ngIf="agentContact?.contactName.contactFirstName != null" id="story-contact">
                            {{agentContact?.contactName.contactFirstName + " " +
                            verifyValue(agentContact.contactName.contactMiddleInitial, false) + " " +
                            verifyValue(agentContact.contactName.contactLastName, false)}}
                        </div>

                        <div class="format col-sm-12 border-bottom border-dark" id="story-unity-address">
                            <div class="d-flex">
                                <div *ngIf="verifyUnitNumber(agentAddress?.unitNumber)" id="story-unity-number">
                                    {{verifyUnitNumber(agentAddress?.unitNumber)+" - " }}
                                </div>

                               <div *ngIf="agentProvince == 'Quebec' && languageSelected == 'fr'; then contentAgentProvince else other_contentAP">
                                </div>
                                <ng-template #contentAgentProvince> {{verifyValue(agentAddress.streetNumber, false) + " " + verifyValue(agentAddress.streetTypeDd, true) + " " + verifyValue(agentAddress.streetName, false) + " " }}</ng-template>

                                <ng-template #other_contentAP> {{verifyValue(agentAddress.streetNumber, false) + " " + verifyValue(agentAddress.streetName, false) + " " + verifyValue(agentAddress.streetTypeDd, true) + " " }}</ng-template>

                                {{verifyValue(agentAddress.streetDirectionDd, true) + " " + " " +
                                verifyValue(agentAddress.city, false) + ", " }}
                                {{verifyValue(agentAddress.provinceDd, true) + " " + verifyValue(agentAddress.postalFsa, false) + " "
                                + verifyValue(agentAddress.postalLdu, false)}}
                            </div>
                        </div>

                        <div class="composite col-sm-12 border-bottom border-dark" id="story-contact-phone">
                            <div class="format col-sm-3 border-dark" *ngFor="let contact of agentContact?.contactPhone">
                                <div class="d-inline">
                                    <strong>{{getLanguageBasedValue(contact?.phoneTypeDd, false)}}: </strong>
                                    {{formatPhoneNumberAndSin(contact.phoneNumber)}} <div
                                        *ngIf="contact.phoneExtension">{{" Ext. " +contact.phoneExtension}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="format col-sm-12 border-bottom border-dark" id="story-email">
                            <strong>{{'email_appSummary' | translate}}: </strong><a
                                href="mailto:{{agentContact?.contactEmailAddress}}">{{agentContact?.contactEmailAddress}}</a>
                        </div>

                        <div *ngIf="agentContact?.preferredContactMethodDd"
                            class="format col-sm-12 border-bottom border-dark" id="story-contact-method">
                            <strong>{{'preferredMethod_appSummary' | translate}}:</strong> {{getLanguageBasedValue(agentContact?.preferredContactMethodDd, false)}}
                        </div>

                        <div *ngIf="getDealNotes()" class="col-sm-12 border-bottom  border-dark">
                            <h1 class="applicant-group-paragraph">{{'dealNotes_appSummary' | translate}}</h1>
                        </div>
                        <div *ngIf="getDealNotes()" class="format col-sm-12 border-bottom border-dark">
                            <div *ngFor="let line of getDealNotes().split('\n')">{{ line }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="space"></div>

    <app-footer style="min-width: 100%;max-width: 100%;"></app-footer>

</div>