import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {Injectable} from '@angular/core';
import {catchError, delayWhen, retryWhen, switchAll, tap} from 'rxjs/internal/operators';
import {AppConfigService} from '../services/config-service/app-config.service';
import {EMPTY, Observable, Subject, timer} from 'rxjs';
import {AuthGuardService} from '../services/security/auth-guard.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private socket: WebSocketSubject<any>;
    private messagesSubject$ = new Subject();
    public messages = this.messagesSubject$.pipe(catchError(e => { throw e; }));

    public lenderCode: string;
    constructor(private appConfigService: AppConfigService) {
    }

    getNewWebSocket() {
        return webSocket({
            url: this.getNotificationServiceUrl(),
            closeObserver: {
                next: () => {
                    this.socket = undefined;
                    this.connect(true);
                }
            },
        });
    }

    getNotificationServiceUrl() {
        const lenderCode = this.getLenderCode();
        return this.getApiEndpointNotificationService()
            + `?lender=${lenderCode}&event=new-loan-app`;
    }

    getLenderCode() {
        return this.lenderCode;
    }

    private getApiEndpointNotificationService() {
        return AppConfigService
            .settings
            .apiEndpoint_notificationService;
    }

    connect(reconnect: boolean): void {
        console.log('NotificationService.connect');
        if (!this.socket || this.socket.closed) {
            this.socket = this.getNewWebSocket();
            const that = this;
            const messages = this.socket.pipe(
                reconnect ? this.reconnect : (o => o),
                tap({
                    error: error => console.log(error),
                    complete: () => {
                        console.log('[NotificationService] Connection Closed');
                        that.connect(true);
                    }
                }),
                catchError(_ => {
                    return EMPTY;
                }));
            messages.subscribe(d => {
                this.messagesSubject$.next(d);
            });
        }
    }

    private reconnect(observable: Observable<any>): Observable<any> {
        return observable.pipe(retryWhen(
            errors => errors.pipe(tap(val => console.log('[Data Service] Try to reconnect', val)),
            delayWhen(_ => timer(1000)))));
    }

    sendMessage(msg: any) {
        this.socket.next(msg);
    }

    close() {
        this.socket.complete();
    }
}
